import React, {useState} from 'react';
import LanguagePicker from '../LanguagePicker';
import {dangerColor, grayColor, primaryColor, warningColor} from '../../assets/jss/nextjs-material-dashboard';
import styled from '@mui/styles/styled';
import CoralLogo from '../CoralLogo';
import {useConfig} from 'context/clientConfig/clientConfigContext';
import {Box, IconButton, ListItemIcon, ListItemText, Menu, MenuList, Select, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {NAVIGATION_ITEMS} from '../../config';
import MenuItem from '@mui/material/MenuItem';
import './style.css';
import {
  BarChart,
  BorderStyle,
  BubbleChart, FilterDrama,
  Layers, ListAlt,
  LocationOn,
  Map, Notifications,
  Polyline,
  PowerSettingsNew,
  PrecisionManufacturing,
  SatelliteAlt,
  Straighten,
  Warehouse,
  Water
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import {useTranslation} from 'react-i18next';
import {useMapContext} from '../../context/map/mapContext';
import {successColor} from '../PortManagement/DockStats/colors';
import MapIcon from '@mui/icons-material/Map';
import BarChartIcon from '@mui/icons-material/StackedBarChart';
import MMLOGO from '../../assets/images/logo/mobileMonitoring-white.png';
import {isMobileMonitoring} from '../../utils';


export default function HeaderMobile({handleClientChange, logout}) {
  const {t} = useTranslation();
  const isMM = isMobileMonitoring();
  const {state: {clientId, email, config: {modules, roles}}} = useConfig();
  const {
    state: {selectedMarkers, gisLayers, satLayers, wmsLayers, selectedLayers},
    dispatch: mapDispatch
  } = useMapContext();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [toggleGIS, setToggleGIS] = useState(false);
  const [toggleSatellite, setToggleSatellite] = useState(false);
  const [selectedItem, setSelectedItemState] = useState('air');

  const setSelectedItem = item => {
    setSelectedItemState(item);
    navigate('#' + item);
    location.reload();
  };
  const open = Boolean(anchorEl);
  const toggleMarkers = id => mapDispatch({type: 'toggle-markers', id});
  const toggleLayer = layerId => mapDispatch({type: 'toggle-layer', layerId});

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const gisItems = !gisLayers ? [] : gisLayers.map(({layerId, label}) => <Box key={layerId} display="flex"
                                                                              flexDirection={'column'}>
    <MenuItem
      onClick={() => {
        toggleLayer(layerId);
        handleClose();
      }}
      sx={{marginLeft: 2}}
      selected={selectedLayers.includes(layerId)}>
      <ListItemIcon>
        {icons[layerId]}
      </ListItemIcon>
      <ListItemText>
        {t('mapSideBar.' + layerId, label)}
      </ListItemText>
    </MenuItem>
  </Box>);

  const satelliteItems = !satLayers ? [] : satLayers
    .flatMap(({param, layers}) => layers.map(l => <MenuItem
      key={param}
      onClick={() => {
        toggleLayer(param);
        handleClose();
      }}
      sx={{marginLeft: 2}}
      selected={selectedLayers.includes(param)}>
      <ListItemIcon>
        {icons[param]}
      </ListItemIcon>
      <ListItemText>
        {t('mapSideBar.' + param, param)} {l.date}
      </ListItemText>
    </MenuItem>));

  const wmsItems = !wmsLayers ? [] : wmsLayers
    .map(({layerName}) => <MenuItem
      key={layerName}
      onClick={() => {
        toggleLayer(layerName);
        handleClose();
      }}
      sx={{marginLeft: 2}}
      selected={selectedLayers.includes(layerName)}>
      <ListItemIcon>
        {icons[layerName]}
      </ListItemIcon>
      <ListItemText>
        {t('mapSideBar.' + layerName, layerName)}
      </ListItemText>
    </MenuItem>);


  const mapSubmenuMarkers = [
    modules.AIR ? <MenuItem key={'AIR'}
                            selected={selectedMarkers.includes('AIR')}
                            onClick={() => {
                              toggleMarkers('AIR');
                              handleClose();
                            }}>
      <ListItemIcon>
        {icons['AIR']}
      </ListItemIcon>
      <ListItemText>{t('mapSideBar.airQuality')}</ListItemText>
    </MenuItem> : null,
    modules.WATER ? <MenuItem key={'WATER'}
                              selected={selectedMarkers.includes('WATER')}
                              onClick={() => {
                                toggleMarkers('WATER');
                                handleClose();
                              }}>
      <ListItemIcon>
        {icons['WATER']}
      </ListItemIcon>
      <ListItemText>{t('mapSideBar.waterQuality')}</ListItemText>
    </MenuItem> : null,
    modules.GIS ? <MenuItem key={'GIS'}
                            onClick={() => {
                              setToggleGIS(!toggleGIS);
                            }}>
      <ListItemIcon>
        {icons['GIS']}
      </ListItemIcon>
      <ListItemText>GIS</ListItemText>
    </MenuItem> : null,
    toggleGIS ? gisItems : [],
    modules.SATELLITE ? <MenuItem key={'SATELLITE'}
                                  onClick={() => {
                                    setToggleSatellite(!toggleSatellite);
                                  }}>
      <ListItemIcon>
        {icons['SATELLITE']}
      </ListItemIcon>
      <ListItemText>Satellite</ListItemText>
    </MenuItem> : null,
    toggleSatellite ? [...satelliteItems, ...wmsItems] : [],
  ];

  const measurementsSubmenuMarkers = [{
    id: 'air',
    visible: modules.AIR,
    icon: icons['AIR']
  }, {
    id: 'water',
    visible: modules.WATER,
    icon: icons['WATER']
  }, {
    id: 'weather',
    visible: modules.WEATHER,
    icon: icons['WEATHER']
  }, {
    id: 'analytics',
    visible: true,
    icon: icons['ANALYTICS']
  }, {
    id: 'reports.reports',
    visible: true,
    icon: icons['REPORTS']
  }
  ].filter(({visible}) => visible)
    .map(({id, label, icon}) => <MenuItem
      selected={selectedItem === id}
      onClick={() => {
        setSelectedItem(id);
        handleClose();
      }}>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText>{t('measurements.' + id)}</ListItemText>
    </MenuItem>);


  const mobileMenu = NAVIGATION_ITEMS(modules, roles).map(({id, route, label}) => <div key={id}>
    <MenuItem
      key={id}
      sx={{
        backgroundColor: window.location.href.includes(route.slice(3)) ? '#fb8c00' : 'white',
        color: window.location.href.includes(route.slice(3)) ? 'whitesmoke' : 'inherit',
      }}
      onClick={() => navigate(route)}>
      <ListItemText>{t('menu', {returnObjects: true})[label]}</ListItemText>
    </MenuItem>
    {id === 'mapView' && window.location.href.includes('/map') && <>
      <Divider/>
      <MenuList dense>
        {mapSubmenuMarkers}
      </MenuList>
    </>}

    {id === 'measurementsView' && window.location.href.includes('/measurements') && <>
      <Divider/>
      <MenuList dense>
        {measurementsSubmenuMarkers}
      </MenuList>
    </>}
    <Divider/>
  </div>);


  return <Box
    zIndex={2}
    width={'100%'}
    p={1}
    display={'flex'}
    justifyContent={'space-between'}
    sx={{
      background: 'linear-gradient(60deg, #192231, #007dc1)',
      maxHeight: '60px'
    }}
  >
    {/*Hamburger Menu*/}
    <MenuIcon
      sx={{
        color: 'white',
        display: 'flex', width: '45px', height: '45px'
      }}
      onClick={handleClick}>
    </MenuIcon>

    <Menu
      anchorEl={anchorEl}
      variant={'menu'}
      open={open}
      onClose={handleClose}
    >
      {mobileMenu}
      {/*  ALERTS */}
      <MenuItem
        sx={{
          backgroundColor: window.location.href.includes('episodes',) ? '#fb8c00' : 'white',
          color: window.location.href.includes('episodes') ? 'whitesmoke' : 'inherit',
        }}
        onClick={() => navigate('../measurements#episodes')}>
        <ListItemText>{t('menu', {returnObjects: true})['episodes']}</ListItemText>
        {/* DWA warianty */}
        <ListItemIcon>{icons['ALERTS-GREEN']}</ListItemIcon>
        <Typography variant={'caption'} color={successColor[0]}>0</Typography>
        {/*<ListItemIcon>{icons['ALERTS']}</ListItemIcon>*/}
        {/*<Typography variant={'caption'} color={dangerColor[0]}>+1</Typography>*/}
      </MenuItem>
    </Menu>
    {/* LOGO & EMAIL */}
    <Box sx={{ display: 'inline-block', textAlign: 'center', marginBottom: 3}}>
      {isMM ? <img src={MMLOGO} width={300} style={{padding: 10}} /> : <CoralIconLogo/>}
    </Box>

    {/* RIGHT TOOLBAR: Client Select, Lang Picker, Logout */}
    <Box mt={1} display={'flex'}>
      {roles.includes('admin') && false && <Select
        variant={'standard'}
        display={'flex'}
        size={'small'}
        sx={{
          color: 'white',
          marginRight: '1em',
          textAlign: 'center',
          '&:hover': {color: '#fb8c00'},
          // '@media screen and (max-width: 66.5em)': {display: 'none'}
        }}
        disableUnderline
        value={clientId}
        label="Client"
        onChange={handleClientChange}
      >
        <MenuItem className={'hover-underline-animation'} value={'ugw'}>UG</MenuItem>
        <MenuItem className={'hover-underline-animation'} value={'zpg'}>Port Gdańsk</MenuItem>
        <MenuItem className={'hover-underline-animation'} value={'gdy'}>Port Gdynia</MenuItem>
        <MenuItem className={'hover-underline-animation'} value={'rem'}>Stocznia Remontowa</MenuItem>
        <MenuItem className={'hover-underline-animation'} value={'lip'}>Liepaja</MenuItem>
        <MenuItem className={'hover-underline-animation'} value={'elb'}>Port Elbląg</MenuItem>
      </Select>}

      {roles.includes('admin') && <LanguagePicker/>}

      <Box>
        <IconButton
          size={'small'}
          sx={{color: grayColor[8], ':hover': {backgroundColor: warningColor[0]}}}
          onClick={logout}>
          <PowerSettingsNew/>
        </IconButton>
      </Box>
    </Box>
  </Box>;
}

const CoralIconLogo = styled(CoralLogo)(({theme}) => ({
  fill: theme.palette.primary.contrastText,
}));

const iconStyle = {
  padding: 0.5,
  borderRadius: 10,
  width: 30,
  height: 30,
  backgroundColor: 'white',
  color: primaryColor[2]
};

const icons = {
  'AIR': <BubbleChart sx={iconStyle}/>,
  'WATER': <Water sx={iconStyle}/>,
  'GIS': <Map sx={iconStyle}/>,
  'SATELLITE': <SatelliteAlt sx={iconStyle}/>,
  'ALERTS': <Notifications sx={{...iconStyle, color: dangerColor[0]}}/>,
  'ALERTS-GREEN': <Notifications sx={{...iconStyle, color: successColor[0]}}/>,
  'WEATHER': <FilterDrama sx={iconStyle}/>,
  'ANALYTICS': <BarChart sx={iconStyle}/>,
  'REPORTS': <ListAlt sx={iconStyle}/>,

  'Mapa hydrograficzna': <Map sx={iconStyle}/>,
  'Ewidencja gruntów': <Layers sx={iconStyle}/>,
  'Ortofotomapa': <Map sx={iconStyle}/>,

  'BORDER POINT': <LocationOn sx={iconStyle}/>,
  'ZONE': <Polyline sx={iconStyle}/>,
  'KM': <Straighten sx={iconStyle}/>,
  'DOCK': <PrecisionManufacturing sx={iconStyle}/>,
  'BUILDING': <Warehouse sx={iconStyle}/>,
  'BORDER': <BorderStyle sx={iconStyle}/>,

  'Batymetria': <SatelliteAlt sx={iconStyle}/>,
  'turbidity': <SatelliteAlt sx={iconStyle}/>,
};
