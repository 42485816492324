import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import {useTranslation} from 'react-i18next';
import VesselIcon from '../../components/icons/VesselIcon';
import {Typography} from '@mui/material';
import {vesselColor} from './util';
import VesselCircle from './circle';
import HazardMaterialIcon from '../../assets/images/hazard-material.png';
import {usePlaybackContext} from '../../context/marineTrafficPlayback/playbackContext';

const VesselPlaybackIcon = ({mapBearing, heading, mmsi, speed, status}) => {
  const {t, i18n} = useTranslation();
  const [circle, setCircle] = useState(false);
  const {state: {vessels, visibleLabels}} = usePlaybackContext();

  const vesselInfo = vessels[mmsi];
  // const course = TODO narysować strzałke
  const {shipType, typeName, vesselType, name, flag} = vesselInfo;

  const {fill, stroke} = vesselColor(shipType || vesselType);

  const transform = `rotate(${heading - mapBearing}deg)  scale(1, 1)`;

  return (
      <Box onMouseEnter={() => setCircle(true)}
        onMouseLeave={() => setCircle(false)}>
        {circle && <VesselCircle/>}
        <VesselIcon style={{fill, stroke, transform}}/>
        {typeName?.includes('Hazard') && <img src={HazardMaterialIcon} alt={shipType}/>}
        <Box sx={{float: 'right'}} display={"flex"} flexDirection={"column"}>
          <Box>
            {visibleLabels.includes('name') && <Typography sx={{textShadow: '-2px 0 white, 0 2px white, 2px 0 white, 0 -2px white'}} variant={'caption'}>{name}</Typography>}
            {visibleLabels.includes('name') && visibleLabels.includes('speed') && <Typography sx={{textShadow: '-2px 0 white, 0 2px white, 2px 0 white, 0 -2px white'}} variant={'caption'}>, </Typography>}
            {visibleLabels.includes('speed') && <Typography sx={{textShadow: '-2px 0 white, 0 2px white, 2px 0 white, 0 -2px white'}} variant={'caption'}>{speed} kn</Typography>}
            </Box>
          {visibleLabels.includes('mmsi') && <Typography sx={{textShadow: '-2px 0 white, 0 2px white, 2px 0 white, 0 -2px white'}} variant={'caption'}>{mmsi}</Typography>}
        </Box>
      </Box>
  );
};

VesselPlaybackIcon.propTypes = {
  shipInfo: PropTypes.object,
  mapBearing: PropTypes.number.isRequired
};


export default VesselPlaybackIcon;
