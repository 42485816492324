import React, {useMemo, useState} from 'react';
// @fullcalendar components
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import Flag from 'react-world-flags';

// Custom styles for Calendar
import Box from '@mui/material/Box';
import styles from './styles';
import Swal from 'sweetalert2';
import moment from 'moment';
import {useDockMgmtContext} from '../../../context/dockMgmt/dockMgmtContext';
import {upsertDockEvent} from '../../../api';
import {useTranslation} from 'react-i18next';
import {Typography, useMediaQuery} from '@mui/material';
import DockEventDialog from '../EventDialog/DockEventDialog';
import {useDockEventContext} from '../../../context/dockEvent/dockEventContext';
import {MOBILE_SCREEN} from '../../../assets/jss/nextjs-material-dashboard';


const eventColors = (start, end) => {
  if (moment(start).isAfter(moment.now()) && moment(end).isAfter(moment.now())) {
    return {backgroundColor: '#B7E9F7', textColor: 'black'};
  }
  if (moment(start).isBefore(moment.now()) && moment(end).isBefore(moment.now())) {
    return {backgroundColor: 'lightgrey', textColor: 'black'};
  }
  return {};
};

const eventAdornment = ({start, end}) => {
  if (moment(start).isAfter(moment.now()) && moment(end).isAfter(moment.now())) {
    return ' 🕑';
  }
  if (moment(start).isBefore(moment.now()) && moment(end).isBefore(moment.now())) {
    return ' ✓';
  }
  return ' ️';
};

function DockCalendar({dockId, events, loading}) {
  const classes = styles();
  const {state, dispatch: dockDispatch} = useDockMgmtContext();
  const {dispatch: dockEventDispatch} = useDockEventContext();
  const {selectedVessel} = state;
  const [openDialog, setOpenDialog] = useState(false);
  const {t, i18n} = useTranslation();
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);

  const onDateSelect = (selectionInfo) => {
    dockEventDispatch({
      type: 'open-event-dialog',
      event: {
        editMode: false,
        dockId: dockId,
        start: selectionInfo.start,
        end: selectionInfo.end,
        ...(selectedVessel ? {vessel: selectedVessel} : {})
      }
    });
    setOpenDialog(true);
  };

  const calendarEvents = useMemo(() => events.map(evt => {
    return {
      title: evt.vessel.name + ' - ' + t(`vessels.shipType`, {returnObjects: true})[evt.vessel.type.toLowerCase()] || evt.vessel.type.toLowerCase() + eventAdornment(evt),
      start: evt.start,
      end: evt.end,
      allDay: true,
      flag: evt.vessel.flag,
      extendedProps: {...evt, start: new Date(evt.start), end: new Date(evt.end)},
      ...eventColors(evt.start, evt.end)
    };
  }), [events]);

  /**
   * If you want to have the exact same content as the default, then copy the function from fullcalendar source:
   * https://github.com/fullcalendar/fullcalendar/blob/495d925436e533db2fd591e09a0c887adca77053/packages/common/src/common/StandardEvent.tsx#L79
   */
  function renderInnerContent(innerProps) {
    return (
      <div className="fc-event-main-frame">
        <div className="fc-event-time">
          <Flag style={{marginTop: 5, marginLeft: 5}} width={25} height={15}
                code={innerProps.event.extendedProps.vessel.flag}/>
        </div>
        <div className="fc-event-title-container">
          <div className="fc-event-title fc-sticky">
            {innerProps.event.title || '&nbsp;'}
          </div>
        </div>
      </div>
    );
  }

  const handleDialogClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }

    if (reason === 'created') {
      const days = moment(event.end).diff(moment(event.start), 'days');
      Swal.fire(t('swal.success'), `${t('swal.dockReservedFor')} ${event.vessel.name} [${event.vessel.flag}] ${t('swal.for')} ${days} ${days === 1 ? [t('swal.day')] : [t('swal.days')]}!`, 'success');
      dockDispatch({type: 'add-dock-event', dockId: event.dockId, dockEvent: event});
    }
    if (reason === 'edited') {
      Swal.fire(t('swal.success'), `${t('swal.dockReserUpdated')} ${event.vessel.name} [${event.vessel.flag}]`, 'success');
      dockDispatch({type: 'update-dock-event', dockId: event.dockId, dockEvent: event});
    }
    if (reason === 'deleted') {
      Swal.fire(t('swal.deleted'), `${t('swal.dockReserDelete')}`, 'warning');
      dockDispatch({type: 'delete-dock-event', dockEvent: event});
    }
    dockEventDispatch({type: 'close-event-dialog'});
    setOpenDialog(false);
  };

  const onEventClick = info => {
    // setEditData(info.event.extendedProps);
    // setDateRange(info.event);
    // setOpenDialog(true);
    // dockDispatch({
    //   type: 'set-dock-event',
    //   dockEvent: info.event.extendedProps
    // });
    console.log(info);
    // TODO to zrobić po dodaniu eventów
    dockEventDispatch({type: 'open-event-dialog', event: {...info.event.extendedProps, editMode: true}});
    setOpenDialog(true);
  };

  const onEventMoved = (info) => {
    const evt = info.event.extendedProps;
    Swal.fire({
      title: t('swal.updateDockBooking'),
      html: t('swal.waitUntilOperationComplete'),
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        Swal.hideLoading();
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer');
      }
    });
    const updatedEvent = {...evt, start: info.event.start.getTime(), end: info.event.end.getTime()};
    upsertDockEvent(updatedEvent, evt.dockId)
      .then(r => dockDispatch({
        type: 'update-dock-event',
        dockId: evt.dockId,
        dockEvent: updatedEvent
      }))
      .catch(error => {
        console.error('SAVE ERROR', error);
        Swal.showValidationMessage(`${t('swal.errorMessage')} ${error}`);
      })
      .finally(() => Swal.hideLoading());
  };

  const onEventResize = info => {
    const evt = info.event.extendedProps;
    const days = info.endDelta.days;
    const newEnd = moment(evt.end).add(days, 'days').toDate().getTime();

    Swal.fire({
      title: t('swal.updateReservation'),
      html: t('swal.waitUntilOperationComplete'),
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        Swal.hideLoading();
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer');
      }
    });

    upsertDockEvent({...evt, end: newEnd}, evt.dockId)
      .then(r => dockDispatch({
        type: 'update-dock-event',
        dockId: evt.dockId,
        dockEvent: {timestamp: evt.timestamp, start: evt.start, end: newEnd}
      }))
      .catch(error => {
        console.error('SAVE ERROR', error);
        Swal.showValidationMessage(`${t('swal.errorMessage')} ":" ${error}`);
      })
      .finally(() => Swal.hideLoading());
  };

  return (<Box p={2} pt={0} sx={{'@media screen and (max-width: 50em)': {paddingLeft: 0, paddingRight: 0}}}
               className={classes.calendar}>
      {selectedVessel &&
      <Box sx={{position: 'absolute', top: 120, right: '10%'}} display={'flex'} justifyContent={'center'}>
        <Typography color={'coral'} variant={'h6'}>{t('dockCalendar.selectBookingDatesForTheShip')} {'  '}
          "{selectedVessel.name}"</Typography>
      </Box>
      }
      <FullCalendar
        events={calendarEvents}
        locale={i18n.resolvedLanguage}
        firstDay={1}
        initialView="dayGridMonth"
        selectable={!loading || !isMobile}
        editable={!loading || !isMobile}
        plugins={[dayGridPlugin, interactionPlugin]}
        headerToolbar={{
          start: '',
          center: 'title',
          right: 'prev,next,today'
        }}
        buttonText={{today: t('dockCalendar.today')}}
        dayMaxEvents={2}
        eventMinHeight={3}
        eventClick={!isMobile && onEventClick}
        eventOverlap={false}
        selectOverlap={false}
        select={onDateSelect}
        eventContent={renderInnerContent}
        eventChange={onEventMoved}
        eventResize={onEventResize}
      />
      {/*  New Event Dialog */}
      {/*{openDialog &&*/}
      {/*<NewEventDialog*/}
      {/*  editData={editData}*/}
      {/*  dockId={dockId} onClose={handleDialogClose}/>}*/}

      {openDialog &&
      <DockEventDialog onClose={handleDialogClose}/>}
    </Box>
  );
}

// Setting default values for the props of Calendar
DockCalendar.defaultProps = {};

// Typechecking props for the Calendar
DockCalendar.propTypes = {};

export default DockCalendar;
