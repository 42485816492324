import React from 'react';
import Highcharts from 'highcharts/highstock';
import {Chart, ColumnSeries, HighchartsChart, HighchartsProvider, Tooltip, XAxis, YAxis} from 'react-jsx-highstock';
import applyExporting from 'highcharts/modules/exporting';
import applyDrilldown from 'highcharts/modules/drilldown';
import applyAccessibility from 'highcharts/modules/accessibility';
import {useTranslation} from 'react-i18next';

applyDrilldown(Highcharts);
applyExporting(Highcharts);
applyAccessibility(Highcharts);

const plotOptions = {
  series: {
    borderWidth: 0,
    grouping: false,
    dataLabels: {
      enabled: true,
      format: '{point.y:.1f}'
    }
  },
};

const timeOptions = {
  useUTC: false
};

const options = {};

// TODO change to WaterDevice or water Station for consistency
export default function RainChart({title, data, prevData, drilldown, year, yearPrev}) {
  const {t: translation} = useTranslation();
  const t = translation('openData', {returnObjects: true});

  return <HighchartsProvider Highcharts={Highcharts}>
    <HighchartsChart
      options={options}
      plotOptions={plotOptions}
      time={timeOptions}
      accessibility={{
        announceNewData: {
          enabled: true
        }
      }}
      drilldown={{series: drilldown}}
    >
      <Chart type={'column'}/>
      <Tooltip
        shared
        borderColor={'rgb(124, 181, 236)'}
        headerFormat={`<span style="font-size:11px">${t.rainSum} </span><br>`}
        pointFormat={'<span style="color:{point.color}">\u25CF {series.name} {point.name}</span>: <b>{point.y:.2f}mm</b><br/>'}/>
      <XAxis
        type={'category'}
      />

      <YAxis>
        <YAxis.Title>{t.rainSum} [mm]</YAxis.Title>
        {(year && yearPrev) ?
          <React.Fragment>
            <ColumnSeries color="rgb(158, 159, 163)"
                          pointPlacement={-0.2}
                          linkedTo="main"
                          name={yearPrev}
                          data={prevData}/>
            <ColumnSeries colorByPoint
                          name={year}
                          id={'main'}
                          data={data}
            />
          </React.Fragment>
          :
          <ColumnSeries colorByPoint
                        name={year || yearPrev}
                        id={'main'}
                        data={year ? data : prevData}
          />
        }
      </YAxis>
    </HighchartsChart>
  </HighchartsProvider>;
}
