import React, {useMemo, useReducer} from 'react';
import {Auth} from 'aws-amplify';
import PropTypes from 'prop-types';
import _ from 'lodash';

const initialState = () => {
  return {
    widRosesData: {},
    widPmData: {},
    selectedStation: null,
    selectedPmStation: null,
    dateFrom: new Date(),
    dateTo: new Date()
  };
};


const WindRoseContext = React.createContext(initialState());
// expose the context via a custom hook.
export const useWindRoseContext = () => React.useContext(WindRoseContext);

function reducer(state, action) {
  switch (action.type) {
  case 'set-wind-roses-data':
    return {
      ...state,
      widRosesData: action.widRosesData,
    };
  case 'set-wind-pm-data':
    return {
      ...state,
      widPmData: action.widPmData,
    };
  case 'toggle-selectedStation':
    return {
      ...state,
      selectedStation: state.selectedStation === action.serialNo ? null : action.serialNo,
    };
  case 'set-selectedStation':
    return {
      ...state,
      selectedStation: action.serialNo,
    };
  case 'toggle-selectedPmStation':
    return {
      ...state,
      selectedPmStation: state.selectedPmStation === action.serialNo ? null : action.serialNo,
    };
  case 'set-selectedPmStation':
    return {
      ...state,
      selectedPmStation: action.serialNo,
    };

  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function WindRoseContextProvider({children}) {
  const [surveysReducerState, userDispatch] = useReducer(reducer, initialState());
  const userContext = useMemo(() => {
    return {
      state: surveysReducerState,
      dispatch: userDispatch
    };
  }, [surveysReducerState]);

  return <WindRoseContext.Provider value={userContext}>
    {children}
  </WindRoseContext.Provider>;
}

WindRoseContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};
