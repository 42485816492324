const BASE = [
  [
    18.65861059173642,
    54.41009709151419
  ],
  [
    18.661904962905396,
    54.410264675019846
  ],
  [
    18.66170017805851,
    54.41097974956756
  ],
  [
    18.66156365482817,
    54.41177426221665
  ],
  [
    18.66105169271094,
    54.412687932731274
  ],
  [
    18.66043733817162,
    54.41348241228059
  ],
  [
    18.659140367476425,
    54.41546854380189
  ],
  [
    18.65862840535911,
    54.41616366710289
  ],
  [
    18.658560143743955,
    54.41660059571663
  ],
  [
    18.658389489705343,
    54.41695807930054
  ],
  [
    18.658389489705343,
    54.417136819923684
  ],
  [
    18.657911658396387,
    54.41725597990572
  ],
  [
    18.65753621951083,
    54.416858778617865
  ],
  [
    18.657024257394937,
    54.41673961748083
  ],
  [
    18.65640990285425,
    54.41666017653051
  ],
  [
    18.6557955483149,
    54.416401992378894
  ],
  [
    18.655215324582485,
    54.41590547982426
  ],
  [
    18.65419140034939,
    54.4156075694051
  ],
  [
    18.653542915001765,
    54.41491243667488
  ],
  [
    18.653099214501026,
    54.41443576741881
  ],
  [
    18.652962691269323,
    54.41427687643534
  ],
  [
    18.652689644807225,
    54.413502274072385
  ],
  [
    18.65176811299756,
    54.41258862170483
  ],
  [
    18.651358543303758,
    54.411972887973604
  ],
  [
    18.651358543303758,
    54.411218104978815
  ],
  [
    18.651358543303758,
    54.4106619401968
  ],
  [
    18.651529197343763,
    54.41006604098783
  ],
  [
    18.652007028651354,
    54.4095893153856
  ],
  [
    18.65251899076884,
    54.40929135907015
  ],
  [
    18.65487256387013,
    54.408888486910655
  ],
  [
    18.656672355065353,
    54.40953308046579
  ],
  [
    18.65861059173642,
    54.41009709151419
  ]
].map(e => [e[1], e[0]]);
const LVL_1 = [
  [
    18.66185965721357,
    54.41027739901122
  ],
  [
    18.661535797655546,
    54.411879437937216
  ],
  [
    18.660672172169058,
    54.41326153887178
  ],
  [
    18.658998897787626,
    54.415711512393074
  ],
  [
    18.658567085044382,
    54.41621405296769
  ],
  [
    18.658621061638115,
    54.41668517916173
  ],
  [
    18.660132406239512,
    54.41822415366397
  ],
  [
    18.659754570088865,
    54.41856962979506
  ],
  [
    18.65883696800975,
    54.41872666343764
  ],
  [
    18.65802731911586,
    54.418789476726175
  ],
  [
    18.656839834071377,
    54.41882088333452
  ],
  [
    18.655760302212144,
    54.41860103657183
  ],
  [
    18.654356910796054,
    54.41847540932096
  ],
  [
    18.653115449158918,
    54.41772163773041
  ],
  [
    18.651766034336532,
    54.41737615445254
  ],
  [
    18.650632525884674,
    54.41643391253211
  ],
  [
    18.64890527491059,
    54.415114737458936
  ],
  [
    18.64787971964512,
    54.413826930371556
  ],
  [
    18.646692234600607,
    54.41238202548584
  ],
  [
    18.64582860911412,
    54.41078000619706
  ],
  [
    18.645882585706772,
    54.40968054497654
  ],
  [
    18.6460445154857,
    54.408863783271556
  ],
  [
    18.646152468672142,
    54.40851822534512
  ],
  [
    18.64663825800801,
    54.40839256719542
  ],
  [
    18.648095626016726,
    54.4082354939666
  ],
  [
    18.649499017432873,
    54.40820407924883
  ],
  [
    18.65030866632671,
    54.40810983495052
  ],
  [
    18.651010362035265,
    54.40810983495052
  ],
  [
    18.651820010929157,
    54.40810983495052
  ],
  [
    18.65289954278731,
    54.40817266450671
  ],
  [
    18.654248957610804,
    54.40836115259765
  ],
  [
    18.65522053628368,
    54.40864388310979
  ],
  [
    18.656785857477644,
    54.40920933828605
  ],
  [
    18.658081295708485,
    54.40974337212609
  ],
  [
    18.658621061638115,
    54.41005750643015
  ],
  [
    18.659484687124603,
    54.41015174625224
  ],
  [
    18.66185965721357,
    54.41027739901122
  ]
].map(e => [e[1], e[0]]);
const LVL_2 = [
  [
    18.66184840590668,
    54.41022198966468
  ],
  [
    18.66172624620961,
    54.41192821602263
  ],
  [
    18.658427934369058,
    54.416548889210446
  ],
  [
    18.659283052253812,
    54.416904304039235
  ],
  [
    18.660382489533987,
    54.416904304039235
  ],
  [
    18.661481926814133,
    54.41704646910799
  ],
  [
    18.664047280467003,
    54.41768620581658
  ],
  [
    18.6666126341199,
    54.41861025232575
  ],
  [
    18.669177987772713,
    54.41974751174237
  ],
  [
    18.67149902203144,
    54.42138226687595
  ],
  [
    18.672598459310365,
    54.42372767122947
  ],
  [
    18.674308695079787,
    54.42642827362181
  ],
  [
    18.675408132359962,
    54.427352123058796
  ],
  [
    18.675285972661584,
    54.43175788769315
  ],
  [
    18.672354139914887,
    54.43303689192226
  ],
  [
    18.663070002885206,
    54.43595003028577
  ],
  [
    18.659160892555462,
    54.43651842337482
  ],
  [
    18.65317506736494,
    54.436163178618216
  ],
  [
    18.649876753878402,
    54.43609212842989
  ],
  [
    18.642180692918544,
    54.43609212842989
  ],
  [
    18.629842563443788,
    54.43822355445525
  ],
  [
    18.623856738253295,
    54.43779727812253
  ],
  [
    18.61811523245825,
    54.436589471089036
  ],
  [
    18.615183399711583,
    54.43481321958092
  ],
  [
    18.61078565059219,
    54.43317900017112
  ],
  [
    18.604921985098798,
    54.43040778348953
  ],
  [
    18.606021422378973,
    54.42770744335235
  ],
  [
    18.60186799265503,
    54.4268546673901
  ],
  [
    18.59979127779306,
    54.42635720654985
  ],
  [
    18.600646395677785,
    54.42536226675355
  ],
  [
    18.605899262681874,
    54.42251944851702
  ],
  [
    18.612373726663236,
    54.419463198978576
  ],
  [
    18.61811523245825,
    54.416904303171435
  ],
  [
    18.62507833523182,
    54.41398981061053
  ],
  [
    18.635095430447848,
    54.41036417703833
  ],
  [
    18.64120341533672,
    54.40908446511287
  ],
  [
    18.646211962945358,
    54.4083023992757
  ],
  [
    18.650854031460256,
    54.40801800800014
  ],
  [
    18.654030183602373,
    54.40823130164179
  ],
  [
    18.656962016349013,
    54.40922665729934
  ],
  [
    18.658672252118464,
    54.41015089449067
  ],
  [
    18.66184840590668,
    54.41022198966468
  ]
].map(e => [e[1], e[0]]);
const LVL_3 = [
  [
    18.661811809999307,
    54.41027947689162
  ],
  [
    18.661507496928465,
    54.411908794264434
  ],
  [
    18.658464366215583,
    54.41654845206338
  ],
  [
    18.659864206344196,
    54.416654697619094
  ],
  [
    18.661324909085494,
    54.416477621540025
  ],
  [
    18.66315078751279,
    54.41637137552573
  ],
  [
    18.66540270423988,
    54.41651303681718
  ],
  [
    18.667654620967028,
    54.416654697619094
  ],
  [
    18.670880339521688,
    54.416831772933335
  ],
  [
    18.674106058076347,
    54.417540066540624
  ],
  [
    18.676072050314616,
    54.4178520543941
  ],
  [
    18.689028975332974,
    54.42101354956634
  ],
  [
    18.692790663241794,
    54.4292809226192
  ],
  [
    18.69320862856486,
    54.433900198814854
  ],
  [
    18.691536767272567,
    54.4399774003916
  ],
  [
    18.687357114039543,
    54.44459547062948
  ],
  [
    18.68275949548456,
    54.44945603465908
  ],
  [
    18.676490015636148,
    54.455287949954
  ],
  [
    18.66938460514055,
    54.458446557215154
  ],
  [
    18.65809954141409,
    54.46063314254408
  ],
  [
    18.64472465107002,
    54.46330547709064
  ],
  [
    18.622154523616047,
    54.46233373926316
  ],
  [
    18.61128742521157,
    54.46233373926316
  ],
  [
    18.603346084069784,
    54.46111903453169
  ],
  [
    18.594568812281835,
    54.45966134126374
  ],
  [
    18.584537644524573,
    54.45698876876517
  ],
  [
    18.57826816467619,
    54.45480198874222
  ],
  [
    18.574506476766317,
    54.4533440704939
  ],
  [
    18.569908858211306,
    54.45188610032426
  ],
  [
    18.566983100948647,
    54.451157095768764
  ],
  [
    18.57158071950471,
    54.44629673366745
  ],
  [
    18.57617833805972,
    54.44022046970218
  ],
  [
    18.582447817908104,
    54.436331187635886
  ],
  [
    18.58662747114107,
    54.43292776289434
  ],
  [
    18.590807124373015,
    54.43049657211009
  ],
  [
    18.593732881635646,
    54.42903778839312
  ],
  [
    18.59707660422137,
    54.42733580841485
  ],
  [
    18.609775685267778,
    54.42049104709159
  ],
  [
    18.62374193736943,
    54.41462932382808
  ],
  [
    18.634857054600104,
    54.41036497163924
  ],
  [
    18.64411052878843,
    54.40853997766399
  ],
  [
    18.65296116939387,
    54.40799619593086
  ],
  [
    18.658864855926222,
    54.41005968671998
  ],
  [
    18.661811809999307,
    54.41027947689162
  ]
].map(e => [e[1], e[0]]);
const LVL_4 = [
  [
    18.66180237021021,
    54.41026446389375
  ],
  [
    18.661706527108976,
    54.41199356121814
  ],
  [
    18.658352018546253,
    54.41645541098151
  ],
  [
    18.66065225298925,
    54.41612078909296
  ],
  [
    18.662281585719455,
    54.41545153712147
  ],
  [
    18.66573193738344,
    54.414113000401954
  ],
  [
    18.668319701132162,
    54.41322061830985
  ],
  [
    18.670140720065888,
    54.41310906918264
  ],
  [
    18.672632640712408,
    54.41204933733718
  ],
  [
    18.677712325106626,
    54.4106549115987
  ],
  [
    18.682600323298317,
    54.41048757732338
  ],
  [
    18.68998024213556,
    54.41093380054005
  ],
  [
    18.70148141434953,
    54.412328216794094
  ],
  [
    18.70627356943899,
    54.41388990669998
  ],
  [
    18.70991560730647,
    54.41835155009261
  ],
  [
    18.712695057258685,
    54.42192051520166
  ],
  [
    18.714480522162546,
    54.435181663859794
  ],
  [
    18.721320694147067,
    54.45620754780646
  ],
  [
    18.721320694147067,
    54.476235652298186
  ],
  [
    18.710982412672735,
    54.49525327282757
  ],
  [
    18.701505654654284,
    54.50425853248143
  ],
  [
    18.686859755898098,
    54.50926059714914
  ],
  [
    18.678244521335557,
    54.51576236575161
  ],
  [
    18.66273709912346,
    54.51776270174912
  ],
  [
    18.648091200367247,
    54.51776270174912
  ],
  [
    18.63516834852402,
    54.51676254599619
  ],
  [
    18.617076355943084,
    54.51676254599619
  ],
  [
    18.60156893373096,
    54.51476216101477
  ],
  [
    18.58950760534364,
    54.51326180798563
  ],
  [
    18.581753894238147,
    54.51226154201677
  ],
  [
    18.572277136219697,
    54.510761097138584
  ],
  [
    18.564523425113094,
    54.509760769935895
  ],
  [
    18.55763123746354,
    54.50776004204772
  ],
  [
    18.555186953722682,
    54.5074194486447
  ],
  [
    18.56293430708719,
    54.49577948520715
  ],
  [
    18.568397298475297,
    54.48658000198738
  ],
  [
    18.564771535350115,
    54.47736708296531
  ],
  [
    18.5613693390817,
    54.46691397836297
  ],
  [
    18.563906413495477,
    54.455531600518704
  ],
  [
    18.577359696772653,
    54.44029304489388
  ],
  [
    18.591848220527368,
    54.42924148899678
  ],
  [
    18.610574916830036,
    54.4200389852472
  ],
  [
    18.63256458081677,
    54.41143646635058
  ],
  [
    18.638704765771315,
    54.40963665643502
  ],
  [
    18.644956309047476,
    54.40861454560458
  ],
  [
    18.653100943820448,
    54.408272920885274
  ],
  [
    18.65878795689433,
    54.40998159204376
  ],
  [
    18.66180237021021,
    54.41026446389375
  ]
].map(e => [e[1], e[0]]);
const LVL_5 = [
  [
    18.661944949084443,
    54.41026455475043
  ],
  [
    18.66161863066705,
    54.41197370831668
  ],
  [
    18.658409832888964,
    54.41643616231187
  ],
  [
    18.65955194735156,
    54.416277927568814
  ],
  [
    18.66036774339679,
    54.415898161693946
  ],
  [
    18.662597585920395,
    54.41479049113087
  ],
  [
    18.66618708851979,
    54.4124168105748
  ],
  [
    18.670592387164277,
    54.4109292340477
  ],
  [
    18.67532400422624,
    54.4097264731422
  ],
  [
    18.68016439409473,
    54.408428717860204
  ],
  [
    18.695721345703816,
    54.406542383237706
  ],
  [
    18.718386664269644,
    54.40460728871477
  ],
  [
    18.761011319793795,
    54.41240441981256
  ],
  [
    18.770527416552056,
    54.4354723575932
  ],
  [
    18.7752854649317,
    54.459449241420145
  ],
  [
    18.780043513310375,
    54.48709743378467
  ],
  [
    18.768941400425177,
    54.51748886696831
  ],
  [
    18.764183352046558,
    54.543257815776855
  ],
  [
    18.75308123916136,
    54.56901050077471
  ],
  [
    18.73563506177061,
    54.575446131397086
  ],
  [
    18.71184481987342,
    54.5837190206031
  ],
  [
    18.69281262635579,
    54.5883143444436
  ],
  [
    18.672194416712358,
    54.593828048854675
  ],
  [
    18.640474094183645,
    54.593828048854675
  ],
  [
    18.6103397877809,
    54.59199023031471
  ],
  [
    18.59765165876985,
    54.5855572123412
  ],
  [
    18.575447432999482,
    54.57452681780521
  ],
  [
    18.567517352367048,
    54.56625206229663
  ],
  [
    18.55641523948185,
    54.562573853980695
  ],
  [
    18.552428284875077,
    54.56141799255033
  ],
  [
    18.55499132712083,
    54.55613363731703
  ],
  [
    18.559263064196216,
    54.552665407050426
  ],
  [
    18.55954784666784,
    54.55018791915364
  ],
  [
    18.55954784666784,
    54.54737991762977
  ],
  [
    18.55954784666784,
    54.54407613896868
  ],
  [
    18.55954784666784,
    54.54275455259639
  ],
  [
    18.556130457007214,
    54.54143292341854
  ],
  [
    18.55356741476291,
    54.540937301440636
  ],
  [
    18.55299784981824,
    54.53895475333286
  ],
  [
    18.55185871993183,
    54.53713733295601
  ],
  [
    18.559058379262694,
    54.53306489509227
  ],
  [
    18.561809422745533,
    54.51986472940745
  ],
  [
    18.556016992077602,
    54.508317499902915
  ],
  [
    18.56095229471539,
    54.499154426098805
  ],
  [
    18.56759629218275,
    54.48899882979161
  ],
  [
    18.569256596395665,
    54.48521203999502
  ],
  [
    18.56468797919308,
    54.480625366734444
  ],
  [
    18.562967186709358,
    54.47504597216124
  ],
  [
    18.561519079041688,
    54.46686518226003
  ],
  [
    18.56417334128922,
    54.45595155046185
  ],
  [
    18.57687575429991,
    54.440863019881945
  ],
  [
    18.596757166341852,
    54.42729693538322
  ],
  [
    18.62288998938764,
    54.4151214150355
  ],
  [
    18.630887703969236,
    54.41177647116656
  ],
  [
    18.64009148463861,
    54.40933393708678
  ],
  [
    18.65153510232724,
    54.40814470861852
  ],
  [
    18.661944949084443,
    54.41026455475043
  ]
].map(e => [e[1], e[0]]);
const LVL_6 = [
  [
    18.661820067635148,
    54.410314593515864
  ],
  [
    18.661683989154028,
    54.41181926121669
  ],
  [
    18.658418105588964,
    54.416412115819355
  ],
  [
    18.66059536129805,
    54.415699465208974
  ],
  [
    18.66195614611763,
    54.414353313589004
  ],
  [
    18.66454163727292,
    54.412611169407626
  ],
  [
    18.66835183476519,
    54.4111065307674
  ],
  [
    18.67583615126739,
    54.4088890679177
  ],
  [
    18.681279290542903,
    54.4076218924487
  ],
  [
    18.69202949061011,
    54.40469139919915
  ],
  [
    18.70835890843395,
    54.40374092398466
  ],
  [
    18.71244126288991,
    54.40587946224187
  ],
  [
    18.713938126190612,
    54.40841388170577
  ],
  [
    18.719381265466126,
    54.4088890679177
  ],
  [
    18.724144012330555,
    54.40730509246325
  ],
  [
    18.73013146553339,
    54.40524583290187
  ],
  [
    18.740065194709615,
    54.40279042674297
  ],
  [
    18.751223630222995,
    54.40334488614397
  ],
  [
    18.770274617684578,
    54.40199832888172
  ],
  [
    18.79000599755466,
    54.40342409401836
  ],
  [
    18.79680992164839,
    54.404849809593344
  ],
  [
    18.81391802587143,
    54.40579816462261
  ],
  [
    18.826952771945997,
    54.413384215412606
  ],
  [
    18.92791050104904,
    54.44276690809369
  ],
  [
    18.972717440678252,
    54.47070829661823
  ],
  [
    19.011821678901015,
    54.49531879494964
  ],
  [
    19.02485642497456,
    54.52038733362471
  ],
  [
    19.02974445475266,
    54.53693359734453
  ],
  [
    19.037076499418788,
    54.54827573121628
  ],
  [
    19.033817812900395,
    54.554890520114185
  ],
  [
    19.033817812900395,
    54.56906145593419
  ],
  [
    19.04603788734562,
    54.584171692922695
  ],
  [
    19.034632484530732,
    54.59880439287798
  ],
  [
    19.04236732648119,
    54.6064113320771
  ],
  [
    19.03507821903015,
    54.61526132656014
  ],
  [
    19.02145166857595,
    54.6201873965246
  ],
  [
    19.00782511812175,
    54.625113466489054
  ],
  [
    18.959575023408803,
    54.646534862644714
  ],
  [
    18.89991952588085,
    54.65192757145726
  ],
  [
    18.876609150153676,
    54.64000461353035
  ],
  [
    18.868828827255754,
    54.63276153716234
  ],
  [
    18.8532987744265,
    54.62808165560344
  ],
  [
    18.848009076055185,
    54.619922502551645
  ],
  [
    18.850469107615375,
    54.611763349499846
  ],
  [
    18.852375587037763,
    54.603283561036235
  ],
  [
    18.847639440804254,
    54.59544504339625
  ],
  [
    18.833738731235243,
    54.58714290537916
  ],
  [
    18.816516708836858,
    54.582689862096686
  ],
  [
    18.80536897076475,
    54.58246374472397
  ],
  [
    18.792007024141718,
    54.58608711609687
  ],
  [
    18.774139965102417,
    54.59461665805263
  ],
  [
    18.74172715985174,
    54.60846549279256
  ],
  [
    18.657224177618925,
    54.61457628148012
  ],
  [
    18.597976015904962,
    54.60133674245999
  ],
  [
    18.558184969351725,
    54.59134707366451
  ],
  [
    18.545654242247714,
    54.57960237237795
  ],
  [
    18.55112865089958,
    54.56608222776603
  ],
  [
    18.55291596472602,
    54.55998293299279
  ],
  [
    18.558502840552265,
    54.55476490108374
  ],
  [
    18.560041510753194,
    54.54179681456714
  ],
  [
    18.552919687679307,
    54.543426949245074
  ],
  [
    18.549548754242217,
    54.53862122162511
  ],
  [
    18.54805326562358,
    54.53643876876679
  ],
  [
    18.544657996006006,
    54.5351380954523
  ],
  [
    18.551024618029324,
    54.5336824553602
  ],
  [
    18.557391240054606,
    54.5339904838994
  ],
  [
    18.553976345604983,
    54.53036337621613
  ],
  [
    18.560440312353748,
    54.52938195008906
  ],
  [
    18.559516524790936,
    54.52687224255944
  ],
  [
    18.55517313143007,
    54.525465013981105
  ],
  [
    18.560164767904546,
    54.523753092339625
  ],
  [
    18.5594179069733,
    54.52178148241971
  ],
  [
    18.553351659243184,
    54.520691971173434
  ],
  [
    18.560216973779855,
    54.518292437410224
  ],
  [
    18.55492368991858,
    54.515010749893655
  ],
  [
    18.554366807086737,
    54.51292876152267
  ],
  [
    18.55248007755668,
    54.51051586392403
  ],
  [
    18.56169433259526,
    54.49825724456616
  ],
  [
    18.570394268659346,
    54.48524950523133
  ],
  [
    18.566676148030908,
    54.483488762835194
  ],
  [
    18.56491425723901,
    54.480402151639126
  ],
  [
    18.562632322443037,
    54.47527046798218
  ],
  [
    18.561491521716604,
    54.467486490934846
  ],
  [
    18.56405637964009,
    54.45567362449324
  ],
  [
    18.573675852735995,
    54.44464190960791
  ],
  [
    18.58024714711378,
    54.43822426181863
  ],
  [
    18.58819485622717,
    54.43250704254136
  ],
  [
    18.607975794881842,
    54.422121108397725
  ],
  [
    18.63019977486239,
    54.41228092486443
  ],
  [
    18.63757281811539,
    54.40997624860751
  ],
  [
    18.64563406873628,
    54.40867287575696
  ],
  [
    18.653727068185713,
    54.40840000695147
  ],
  [
    18.661820067635148,
    54.410314593515864
  ]
].map(e => [e[1], e[0]]);
const LVL_7 =  [
  [
    18.661959889919217,
    54.410220785039115
  ],
  [
    18.661690979641975,
    54.41178575360041
  ],
  [
    18.658356492199005,
    54.416449005708074
  ],
  [
    18.65943213331022,
    54.41604216590562
  ],
  [
    18.660453992365092,
    54.415604026218205
  ],
  [
    18.66109937703115,
    54.41466513969198
  ],
  [
    18.662336364308032,
    54.41356974490208
  ],
  [
    18.664218736251,
    54.412505619081145
  ],
  [
    18.66647758258381,
    54.411566661597504
  ],
  [
    18.668521300693556,
    54.41084677963133
  ],
  [
    18.671533095802943,
    54.409907784159344
  ],
  [
    18.675082711466843,
    54.40890616528793
  ],
  [
    18.67782559629893,
    54.40806103033441
  ],
  [
    18.68132142990865,
    54.40699676154222
  ],
  [
    18.685193737906133,
    54.405838555296214
  ],
  [
    18.688097968903946,
    54.40471162052566
  ],
  [
    18.696186311995206,
    54.40339682415521
  ],
  [
    18.701564517547865,
    54.40327160325472
  ],
  [
    18.706082210212486,
    54.40339682415521
  ],
  [
    18.708394838599474,
    54.403803789440616
  ],
  [
    18.71103015931999,
    54.40477422882529
  ],
  [
    18.710976377264785,
    54.40590116187545
  ],
  [
    18.709147787377077,
    54.406464616788384
  ],
  [
    18.709578043821125,
    54.40718457568897
  ],
  [
    18.711406633708833,
    54.40787322020171
  ],
  [
    18.712374710708474,
    54.408154935078386
  ],
  [
    18.714525992929737,
    54.4089061652879
  ],
  [
    18.720119326704463,
    54.40909397068987
  ],
  [
    18.724637019367975,
    54.407403691106566
  ],
  [
    18.727756378588907,
    54.405431610201106
  ],
  [
    18.730445481364683,
    54.404429881994446
  ],
  [
    18.739147836255142,
    54.40211329180431
  ],
  [
    18.75054963202544,
    54.401236710043264
  ],
  [
    18.76168251751963,
    54.40061056874433
  ],
  [
    18.788529384239695,
    54.39951479847184
  ],
  [
    18.81714587508114,
    54.39917292671373
  ],
  [
    18.840049601566733,
    54.401565969185725
  ],
  [
    18.921559164775346,
    54.405276444993206
  ],
  [
    18.965928448413564,
    54.42099303229938
  ],
  [
    19.01222683134091,
    54.443434850164124
  ],
  [
    19.075887107864588,
    54.47931619521128
  ],
  [
    19.11832729221487,
    54.48940215621465
  ],
  [
    19.210924058068457,
    54.515166089266074
  ],
  [
    19.23214415024296,
    54.533079246402565
  ],
  [
    19.261080639571674,
    54.55993424951461
  ],
  [
    19.297733526055765,
    54.583417878828556
  ],
  [
    19.334386412539857,
    54.620293394667954
  ],
  [
    19.38068479546601,
    54.65825142701499
  ],
  [
    19.46533975773724,
    54.66536797302473
  ],
  [
    19.492192290387322,
    54.68842139890663
  ],
  [
    19.501143134604064,
    54.70535026971564
  ],
  [
    19.500329421493745,
    54.72979061449044
  ],
  [
    19.476731741285988,
    54.75280745797764
  ],
  [
    19.45313406107823,
    54.76970945853776
  ],
  [
    19.414889544879316,
    54.788481182200684
  ],
  [
    19.38966443844987,
    54.80724419523497
  ],
  [
    19.37339017623711,
    54.83256044919375
  ],
  [
    19.35630220091403,
    54.856455675815425
  ],
  [
    19.28144892960634,
    54.881757864698386
  ],
  [
    19.25050143764551,
    54.89393704483493
  ],
  [
    19.170689484691394,
    54.89393704483493
  ],
  [
    19.139741992729455,
    54.89393704483493
  ],
  [
    19.089248716371543,
    54.89393704483493
  ],
  [
    19.014323209516988,
    54.88831634229223
  ],
  [
    18.971974009990163,
    54.89206356446377
  ],
  [
    18.921480733632222,
    54.893000315542025
  ],
  [
    18.86935864190741,
    54.89393704483493
  ],
  [
    18.81886536554839,
    54.90892175092847
  ],
  [
    18.78791787358756,
    54.895810438065865
  ],
  [
    18.765114458457816,
    54.871449528538164
  ],
  [
    18.7553415662598,
    54.85176265555489
  ],
  [
    18.7423110433281,
    54.822683512426494
  ],
  [
    18.73253815113003,
    54.798278396863964
  ],
  [
    18.734166966495906,
    54.770100326521714
  ],
  [
    18.73579578186272,
    54.74190261931733
  ],
  [
    18.752083935526088,
    54.723093235096
  ],
  [
    18.774887350655803,
    54.70521623157853
  ],
  [
    18.80583484261777,
    54.691097239598804
  ],
  [
    18.838411149945472,
    54.67885680503204
  ],
  [
    18.857956934342525,
    54.66567067017783
  ],
  [
    18.864472195807906,
    54.65813381349969
  ],
  [
    18.854699303609863,
    54.64399843820294
  ],
  [
    18.83678233457971,
    54.62231464619657
  ],
  [
    18.83678233457971,
    54.61476974738761
  ],
  [
    18.83352470384702,
    54.60533665642484
  ],
  [
    18.830267073113276,
    54.59307036963082
  ],
  [
    18.810721288717275,
    54.592126656052045
  ],
  [
    18.794433135052884,
    54.598732191922636
  ],
  [
    18.774887350655803,
    54.612883304086125
  ],
  [
    18.758599196992463,
    54.62231464619657
  ],
  [
    18.719507628198357,
    54.6251436225794
  ],
  [
    18.670643167206208,
    54.6279724022435
  ],
  [
    18.634809229145787,
    54.62231464619657
  ],
  [
    18.595717660351653,
    54.61194004964429
  ],
  [
    18.559883722291232,
    54.602506302820814
  ],
  [
    18.54522438399374,
    54.590239163294655
  ],
  [
    18.54084023348983,
    54.58947707678243
  ],
  [
    18.537332913085805,
    54.58846093925436
  ],
  [
    18.54127864854013,
    54.585158317226615
  ],
  [
    18.54281310121641,
    54.580839099803285
  ],
  [
    18.546101214095216,
    54.57804406806301
  ],
  [
    18.552458232327723,
    54.560634328614185
  ],
  [
    18.555088722631012,
    54.55669386688018
  ],
  [
    18.55859604303501,
    54.55364292532681
  ],
  [
    18.5601304957124,
    54.542454186944354
  ],
  [
    18.55728079788392,
    54.542454186944354
  ],
  [
    18.552896647379214,
    54.54359863060557
  ],
  [
    18.548731704398506,
    54.538257618893084
  ],
  [
    18.54719725172228,
    54.53660430692457
  ],
  [
    18.545005176469317,
    54.53660430692457
  ],
  [
    18.54084023348983,
    54.53698584640023
  ],
  [
    18.537990535661322,
    54.536222763881796
  ],
  [
    18.53908657328722,
    54.53444218252133
  ],
  [
    18.541497856065348,
    54.53367905244505
  ],
  [
    18.545005176469317,
    54.534823742209426
  ],
  [
    18.54719725172228,
    54.53431499516611
  ],
  [
    18.54938932697408,
    54.53355186271156
  ],
  [
    18.555965552732857,
    54.53355186271156
  ],
  [
    18.556129395632055,
    54.53256487387084
  ],
  [
    18.556293238531254,
    54.53157788503012
  ],
  [
    18.55109740778353,
    54.52953063518099
  ],
  [
    18.559788004211782,
    54.52960390734867
  ],
  [
    18.55950663618634,
    54.52628787429478
  ],
  [
    18.55461533235173,
    54.52632725216138
  ],
  [
    18.552900545022347,
    54.5246502955866
  ],
  [
    18.55594109582879,
    54.52430700334219
  ],
  [
    18.55965396499566,
    54.52406476312528
  ],
  [
    18.559912223081543,
    54.52223563938172
  ],
  [
    18.554858905917122,
    54.52188315081746
  ],
  [
    18.551723193509822,
    54.52036362767991
  ],
  [
    18.554905957130536,
    54.51974528052577
  ],
  [
    18.560037692985624,
    54.51905625584671
  ],
  [
    18.55903366896277,
    54.51713013415273
  ],
  [
    18.55242634976238,
    54.517042360065716
  ],
  [
    18.551392787831404,
    54.512695077877666
  ],
  [
    18.55846513046233,
    54.50322492840713
  ],
  [
    18.568970129337686,
    54.485355708317485
  ],
  [
    18.563217303162148,
    54.47786577448906
  ],
  [
    18.562167322711076,
    54.46764337005706
  ],
  [
    18.563181388275677,
    54.45807594782551
  ],
  [
    18.569368584134672,
    54.449875383881974
  ],
  [
    18.576778369453596,
    54.44101397031647
  ],
  [
    18.58691610614939,
    54.433474552703046
  ],
  [
    18.606592649437438,
    54.42211219418441
  ],
  [
    18.631889312223013,
    54.41160251864671
  ],
  [
    18.638668136481556,
    54.40933870246215
  ],
  [
    18.646583607148365,
    54.40853021333304
  ],
  [
    18.652680443563913,
    54.40825090288076
  ],
  [
    18.661959889919217,
    54.410220785039115
  ]
].map(e => [e[1], e[0]]);


export const OIL_IN_WATER_DATA = [BASE, LVL_1, LVL_2, LVL_3, LVL_4, LVL_5, LVL_6, LVL_7];
