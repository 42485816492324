import {Marker} from '@urbica/react-map-gl';

const style = {
  width: '40px',
  height: '40px',
  color: '#fff',
  background: '#1978c8',
  borderRadius: '50%',
  textAlign: 'center',
  fontSize: 20,
  paddingTop: 10
};

export default function SurveysClusterMarker({longitude, latitude, pointCount, onClick}) {
  return (
    <Marker longitude={longitude} latitude={latitude}>
      <div onClick={onClick} style={{...style}}>{pointCount}</div>
    </Marker>
  );
}

