import React, {useCallback, useEffect, useState} from 'react';

import 'mapbox-gl/dist/mapbox-gl.css';
import MapGL, {Marker} from '@urbica/react-map-gl';
import {useConfig} from 'context/clientConfig/clientConfigContext';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import {INITIAL_MAPSTYLE, MAPBOX_TOKEN} from 'config';
import {usePlaybackContext} from '../../context/marineTrafficPlayback/playbackContext';
import PlaybackSlider from '../MarineTraffic/PlaybackSlider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import {CardContent, Select, TextField, Typography, useMediaQuery} from '@mui/material';
import CardFooter from '../Card/CardFooter';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import AdapterMoment from '@mui/lab/AdapterMoment';
import {DateTimePicker, LocalizationProvider} from '@mui/lab';
import PlaybackCoverImg from 'assets/images/playback-cover-img.png';
import {getMarineTrafficPlayback} from '../../api';
import CircularProgress from '@mui/material/CircularProgress';
import VesselPlaybackIcon from '../MarineTraffic/VesselPlaybackIcon';
import {MOBILE_SCREEN} from "../../assets/jss/nextjs-material-dashboard";

const ONE_MINUTES_MILLIS = 60 * 1000;


const Playback = () => {
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);


  const [selectedRange, setSelectedRange] = useState('7d');
  const [displayedVessels, setDisplayedVessels] = useState({});
  const {portMgmt, clientId} = useConfig().state.config;
  const [viewport, setViewport] = useState({
    latitude: portMgmt.mapCenter[1],
    longitude: portMgmt.mapCenter[0],
    zoom: 10
  });
  const [pointerLocation, setPointerLocation] = useState({lng: portMgmt.mapCenter[0], lat: portMgmt.mapCenter[1]});
  const {
    state: {dateFrom, dateTo, state, timestamp, vesselPositionsByTimestamp},
    dispatch
  } = usePlaybackContext();
  const mapStyleId = INITIAL_MAPSTYLE;


  const handleSelectedRangeChange = event => {
    const value = event.target.value;

    if (value === '1d') {
      dispatch({type: 'set-dates', dateFrom: moment().subtract(1, 'day'), dateTo: moment()});
    } else if (value === '3d') {
      dispatch({type: 'set-dates', dateFrom: moment().subtract(3, 'day'), dateTo: moment()});
    } else if (value === '7d') {
      dispatch({type: 'set-dates', dateFrom: moment().subtract(7, 'day'), dateTo: moment()});
    }

    setSelectedRange(value);
  };

  const onMouseMoveHandler = useCallback(({lngLat}) => setPointerLocation(lngLat), []);

  const onReady = () => {
    dispatch({type: 'set-state', state: 'READY'});
  };

  const onCancel = () => {
    dispatch({type: 'set-state', state: 'HIDDEN'});
  };

  // LOAD DATA WHEN READY
  useEffect(async () => {
    if (state !== 'READY') return;

    // console.log('LOADING DATA for dates', {dateFrom, dateTo});
    setLoading(true);

    try {
      const result = await getMarineTrafficPlayback(clientId, dateFrom.format('YYYY-MM-DD'));
      const flattened = _
        .values(result)
        .flatMap(v => v.positions)
        .map(pos => ({
          ...pos,
          //round to the nearest minute
          timestamp: Math.round(pos.timestamp / ONE_MINUTES_MILLIS) * ONE_MINUTES_MILLIS
        }));
      const byTimestamp = _.groupBy(flattened, 'timestamp');
      dispatch({type: 'update-vessels-position', vessels: result, vesselPositionsByTimestamp: byTimestamp});
      dispatch({type: 'set-timestamp', timestamp: dateFrom.valueOf()});
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }


    for (let m = moment(dateFrom).add(1, 'day'); m.diff(dateTo, 'days') <= 0; m.add(1, 'days')) {
      const result = await getMarineTrafficPlayback(clientId, m.format('YYYY-MM-DD'));
      const flattened = _
        .values(result)
        .flatMap(v => v.positions)
        .map(pos => ({
          ...pos,
          //round to the nearest minute
          timestamp: Math.round(pos.timestamp / ONE_MINUTES_MILLIS) * ONE_MINUTES_MILLIS
        }));
      const byTimestamp = _.groupBy(flattened, 'timestamp');
      dispatch({type: 'update-vessels-position', vessels: result, vesselPositionsByTimestamp: byTimestamp});
    }

  }, [state, dateFrom, dateTo]);


  const updateDisplayedVessels = useCallback((newDisplayedVessels) => {
    setDisplayedVessels({...displayedVessels, ..._.keyBy(newDisplayedVessels, 'mmsi')});
  }, [displayedVessels]);


  useEffect(() => {
    const matchedVessels = vesselPositionsByTimestamp[Math.round(timestamp / ONE_MINUTES_MILLIS) * ONE_MINUTES_MILLIS];
    if (matchedVessels) {
      updateDisplayedVessels(matchedVessels);
    }

  }, [vesselPositionsByTimestamp, timestamp]);


  const vesselsArray = _.values(displayedVessels);
  const vesselsMarkers = vesselsArray
    .map(({heading, latitude, longitude, mmsi, speed, status, timestamp}) => <Marker
      key={mmsi}
      longitude={longitude}
      latitude={latitude}
      anchor="top-left"
      offset={[-11, -11]}
    >
      <VesselPlaybackIcon mapBearing={viewport.bearing} {...{heading, mmsi, speed, status}} />
    </Marker>);

  return <Box sx={{
    height: 'calc(100vh - 88px)',
    '@media screen and (max-width: 50em)': {
      height: 'calc(100vh - 60px)'
    }
  }}>
    {/* POINTER LOCATINO */}
    <Box p={2} sx={{position: 'absolute', top: 90, right: 10, zIndex: 1400}}>
      <Typography sx={{textShadow: '-2px 0 white, 0 2px white, 2px 0 white, 0 -2px white'}} variant={'caption'}>
        {pointerLocation.lat.toFixed(8)}, {pointerLocation.lng.toFixed(8)}
      </Typography>
    </Box>

    {state === 'CONFIG' &&
    <Box position="absolute" left={0} top={0} zIndex={5} width={'100vw'} height={'100vh'}
         sx={{backgroundColor: 'rgba(0,0,0,0.5)'}}/>
    }

    {state === 'CONFIG' &&
    <Box maxWidth={700}
         position={!isMobile ? "absolute" : "relative"}
         left={!isMobile && 'calc( 50vw - 350px )'}
         top={!isMobile ? '40%' : "20%"}
         zIndex={10}

         marginLeft={!isMobile && 2}
         mr={!isMobile && 2}
    >
      <Card sx={{p: !isMobile ? 3 : 0}} elevation={3}>
        <Box sx={{float: 'right'}}><img src={PlaybackCoverImg} height={100}/></Box>
        <CardHeader title={t('playback.configTitle')}/>


        <CardContent>
          <Typography>
            {t('playback.configText')}
          </Typography>

          <Box mt={4}>
            <Typography fontWeight={'bold'} variant={'subtitle2'}>{t('playback.pickDateRange')}</Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Box display={'flex'} gap={2}>

                <Select
                  sx={{width: 150}}
                  value={selectedRange}
                  onChange={handleSelectedRangeChange}
                >
                  <MenuItem value={'custom'}>{t('playback.custom')}</MenuItem>
                  <MenuItem value={'1d'}>{t('playback.1d')}</MenuItem>
                  <MenuItem value={'3d'}>{t('playback.3d')}</MenuItem>
                  <MenuItem value={'7d'}>{t('playback.7d')}</MenuItem>
                </Select>
                <DateTimePicker
                  label={t('playback.dateFrom')}
                  disabled={selectedRange !== 'custom'}
                  value={dateFrom}
                  onChange={newValue => dispatch({type: 'set-dates', dateFrom: newValue})}
                  renderInput={(params) => <TextField {...params} />}
                />

                <DateTimePicker
                  label={t('playback.dateTo')}
                  disabled={selectedRange !== 'custom'}
                  value={dateTo}
                  onChange={newValue => dispatch({type: 'set-dates', dateTo: newValue})}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
          </Box>
        </CardContent>

        <CardFooter>
          <Box ml={'auto'}>
            <Button sx={{mr: 2}} onClick={onCancel}>{t('playback.cancel')}</Button>
            <Button color={'primary'} variant={'contained'} onClick={onReady}>{t('playback.play')}</Button>
          </Box>

        </CardFooter>
      </Card>
    </Box>}
    {loading && <Box sx={{position: 'absolute', top: '40%', left: '40%', zIndex: 1400}}>
      <CircularProgress variant={'indeterminate'} size={200}/>
    </Box>}
    <MapGL
      onMousemove={onMouseMoveHandler}
      style={{height: '100%'}}
      {...viewport}
      onViewportChange={setViewport}
      mapStyle={mapStyleId}
      accessToken={MAPBOX_TOKEN}
    >

      {vesselsMarkers}

      {state !== 'HIDDEN' && <PlaybackSlider/>}
    </MapGL>

  </Box>;
};

export default Playback;
