import React from 'react';
import ChartistGraph from 'react-chartist';


var delays2 = 80,
  durations2 = 500;

const hours = new Date().getHours();
const halfDayBack = hours < 12 ? (12 - hours) : (hours - 12);

const MockData = {
  labels: [
    `Yesterday, ${hours - 1}:00`,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    `${hours < 12 ? 'Yesterday' : 'Today'}, ${halfDayBack}:00`,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    `Today, ${hours}:00`,
  ],
  series: [
    [
      10, 20, 30, 40, 50, 60, 80, 70, 40, 30, 20, 50,
      10, 20, 30, 40, 50, 60, 80, 70, 55, 40, 30, 20,
    ]
  ],
};

const options = {
  axisX: {
    showGrid: false,
  },
  low: 0,
  // high: 150,
  chartPadding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
};

const responsiveOptions = [
  [
    'screen and (max-width: 640px)',
    {
      seriesBarDistance: 5,
      // axisX: {
      //   labelInterpolationFnc: function (value) {
      //     return new Date(value[0]).toLocaleString();
      //   },
      // },
    },
  ],
];

const animation = {
  draw: function (data) {
    if (data.type === 'bar') {
      data.element.animate({
        opacity: {
          begin: (data.index + 1) * delays2,
          dur: durations2,
          from: 0,
          to: 1,
          easing: 'ease',
        },
      });
    }
  },
};

export default function AQIChart({data}) {
  return <ChartistGraph
    // data={MockData}
    data={{...MockData, series: data ? [data]: MockData.series}}
    type="Bar"
    options={options}
    responsiveOptions={responsiveOptions}
    listener={animation}
  />;
}
