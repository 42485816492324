import Box from '@mui/material/Box';
import {primaryColor} from '../../assets/jss/nextjs-material-dashboard';
import React from 'react';

const BASE_S3_URL = 'https://s3.eu-central-1.amazonaws.com/files.coral.com';

export default function DeviceImage({clientId, serialNo}) {
  return <Box position={'absolute'} right={'20%'} top={0}
              sx={{
                border: ' 1px solid ' + primaryColor[0],
                borderRadius: 1,
                padding: 0.1,
                height: 80,
                transition: 'height 0.3s ease',
                '&:hover': {
                  height: 200,
                  boxShadow: '0 4px 4px rgba(0, 0, 0, 0.2)'
                },
                '@media screen and (max-width: 50em)': {display: 'none'},
              }}>
    <img height={'100%'} src={`${BASE_S3_URL}/${clientId}/stations/${serialNo}.png`}/>
  </Box>;
}
