import React, {useState} from 'react';
import Highcharts from 'highcharts/highstock';
import {
  AreaSeries,
  Chart,
  ColumnSeries,
  HighchartsProvider,
  HighchartsStockChart,
  Legend,
  LineSeries,
  Navigator,
  Title,
  Tooltip,
  XAxis,
  YAxis
} from 'react-jsx-highstock';

import applyExporting from 'highcharts/modules/exporting';
import applyOffline from 'highcharts/modules/offline-exporting';

import {getChartMeasurements} from '../../api';
import Box from '@mui/material/Box';
import AirDateRangePicker from './AirDateRangePicker';
import QuickAirDateButtons from './QuickAirDateButtons';
import {MOBILE_SCREEN, primaryColor, roseColor} from '../../assets/jss/nextjs-material-dashboard';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';


applyExporting(Highcharts);
applyOffline(Highcharts);

const NUMBER_OF_BUCKETS = 70;

const plotOptions = {
  column: {
    pointWidth: 6,
    maxPointWidth: 50,
    minPointLength: 3, // By default, 0 values are not shown. To visualize a 0 (or close to zero) point, set the minimal point length to a pixel value like 3
    borderWidth: 0
  },
  series: {
    shadow: true,
    // dataGrouping: {
    //   approximation: 'average',
    //   groupAll: true,
    //   groupPixelWidth: 20
    // }
  }
};


const timeOptions = {
  useUTC: false
};

const options = {};
const ONE_HOUR_MS = 3600000

export default function ENoseChart({device}) {
  const {clientId} = useConfig().state.config;
  const {measurements} = device;
  const {t} = useTranslation();
  const title = device.name;
  const units = ' Index';
  const min = 0;
  const max = null;

  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);

  const afterSetExtremes = (e) => {
    const {serialNo} = device;
    const {chart} = e.target;
    const nowMillis = new Date().getTime();
    const min = e.userMin || (nowMillis - 1000 * 60 * 60 * 24);
    const max = e.userMax || nowMillis;

    // Zabezpieczenie przed ponownym pobraniem danych po narysowaniu vertical Lines
    if (!!e.max && e.type === "afterSetExtremes") return;

    var rangeInHours = null;
    const dateFromHours = rangeInHours ? rangeInHours : Math.max(1, Math.round((nowMillis - min) / (1000 * 60 * 60)));
    const dateToHours = rangeInHours ? 0 : Math.max(0, Math.round((nowMillis - max) / (1000 * 60 * 60)));
    const dateTimeFrom = '-' + dateFromHours + 'h';
    const dateTimeTo = '-' + dateToHours + 'h';
    chart.showLoading(t('general.loading'));
    getChartMeasurements(clientId, serialNo, 'voc,nox,airTemp,RH', dateTimeFrom, dateTimeTo, NUMBER_OF_BUCKETS)
      .then(data => {
        let i = 0;
        if (data[serialNo]) {
          if (device.measurements.includes('voc')) {
            chart.yAxis[0].series[i].setData(data[serialNo].voc, false);
            i++;
          }
          if (device.measurements.includes('nox')) {
            chart.yAxis[0].series[i].setData(data[serialNo].nox, false);
            i++;
          }
          if (device.measurements.includes('airTemp')) {
            chart.yAxis[1].series[0].setData(data[serialNo].airTemp, false);
            i++;
          }
          if (device.measurements.includes('RH')) {
            chart.yAxis[2].series[0].setData(data[serialNo].RH, false);
            i++;
          }

          if (!e.trigger) {
            i = 0;
            if (device.measurements.includes('voc')) {
              chart.navigator.series[i].setData(data[serialNo].voc, false);
              i++;
            }
            if (device.measurements.includes('nox')) {
              chart.navigator.series[i].setData(data[serialNo].nox, false);
              i++;
            }
          }
        }
        chart.hideLoading();
        chart.redraw();
      }).catch(error => console.error(error.message));
  };

  const [xAxisEvents, setXAxisEvents] = useState({afterSetExtremes: afterSetExtremes});

  return (<Box position={'relative'}>
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsStockChart options={options} plotOptions={plotOptions} time={timeOptions}>
          <Chart zooming={{type: 'x'}} panKey={'shift'}/>
          <Legend align={'left'} verticalAlign={'top'} x={40} y={30} useHTML={true}/>
          <Title style={{color: primaryColor[2]}} align={'center'}>{title}</Title>

          <Tooltip shared valueSuffix={' ' + units} valueDecimals={2}/>
          <XAxis
            events={xAxisEvents}
            range={15 * 24 * 60 * 60 * 1000}
            // plotLines={plotVerticalLines(episodes)}
          />

          <YAxis
            min={min}
            softMax={max}
            // plotLines={plotHorizontalNoiseLines}
            useHTML={true}>
            <YAxis.Title>{'VOC & NOx Index'}</YAxis.Title>
            <ColumnSeries color={roseColor[2]} id="series0" name={'VOC Index'}/>
            <ColumnSeries color={'green'} id="series1" name={'NOx Index'}/>
          </YAxis>
          <YAxis opposite={false} visible={true}>
            <YAxis.Title>Temp [°C]</YAxis.Title>
            <LineSeries tooltip={{valueSuffix: ' [°C]'}} color={'red'} id={'temp-series'} name={t('measurements.temperature')}/>
          </YAxis>
          <YAxis min={0} max={100} labels={{enabled: false}}>
            <AreaSeries
              visible={false}
              tooltip={{valueSuffix: ' [%]'}}
              fillColor={{
                linearGradient: [0, 0, 0, 200],
                stops: [
                  [0, Highcharts.getOptions().colors[7]],
                  [1, Highcharts.color(Highcharts.getOptions().colors[7]).setOpacity(0).get('rgba')]
                ]
              }} color={Highcharts.getOptions().colors[7]} name={t('measurements.humidity')} />
          </YAxis>

          <QuickAirDateButtons width={!isMobile ? 200 : "100%"}/>
          <AirDateRangePicker/>

          <Navigator adaptToUpdatedData={false}>
            <Navigator.Series seriesId="series0"/>
            <Navigator.Series seriesId="series1"/>
          </Navigator>

        </HighchartsStockChart>
      </HighchartsProvider>
    </Box>
  );
}
