import React from 'react';

// Draws white circle around vessel Icon
export default function VesselCircle() {
  return <div style={{
    position: 'absolute',
    zIndex: 10,
    top: -9,
    left: -9,
    width: 42,
    height: 42,
    border: '1px solid rgba(0,0,0, 0.8)',
    borderRadius: '50%',
    boxShadow: '0 5px 20px rgba(0, 0, 0, .25)'
  }}>
    <div style={{
      width: 40,
      height: 40,
      border: '5px solid rgba(255,255,255, 0.8)',
      borderRadius: '50%'
    }}>
      <div style={{
        width: 30,
        height: 30,
        border: '1px solid rgba(0,0,0, 0.8)',
        borderRadius: '50%'
      }}>
      </div>
    </div>
  </div>;
}
