import React, {useMemo, useReducer} from 'react';
import PropTypes from 'prop-types';

const initialState = () => {
  return {
    iLayersActive: true,
    iLayers: {},
    selectedILayer: null
  };
};


const ILayersContext = React.createContext(initialState());
// expose the context via a custom hook.
export const useILayersContext = () => React.useContext(ILayersContext);

function reducer(state, action) {
  switch (action.type) {
  case 'set-iLayersActive':
    return {
      ...state,
      iLayersActive: action.iLayersActive
    };
  case 'set-iLayers':
    return {
      ...state,
      iLayers: action.iLayers
    };
  case 'add-iLayer':
    return {
      ...state,
      iLayers: {...state.iLayers, [action.iLayer.layerId]: action.iLayer}
    };
  case 'set-selected-iLayer':
    return {
      ...state,
      selectedILayer: action.selectedILayer
    };

  case 'set-iLayer-attribute':
    return {
      ...state,
      selectedILayer: {...state.selectedILayer, [action.name]: action.value}
    };
  case 'add-iLayer-file':
    return {
      ...state,
      selectedILayer: {...state.selectedILayer, files: [...state.selectedILayer.files, action.file]}
    };

  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function ILayersContextProvider({children}) {
  const [state, dispatch] = useReducer(reducer, initialState());
  const userContext = useMemo(() => {
    return {
      state,
      dispatch
    };
  }, [state]);

  return <ILayersContext.Provider value={userContext}>
    {children}
  </ILayersContext.Provider>;
}

ILayersContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};
