import React from 'react';
import Box from '@mui/material/Box';
import WaterCamera from '../../assets/images/water-camera.png';
import Button from '@mui/material/Button';
import {Tooltip} from '@mui/material';

export default function CameraOnDevice({onClick}) {
  return <Box position={'absolute'} left={'260px'} top={10}
              sx={{'@media screen and (max-width: 50em)': {display: 'none'}}}>
    <Tooltip title={'Urządzenie wyposażone w kamerę'}>
      <Button
        color={'primary'}
        variant={'outlined'}
        onClick={onClick}
      >
        <img height={'25px'} src={WaterCamera} alt={"Camera on Water"}/>
      </Button>
    </Tooltip>

  </Box>;
}
