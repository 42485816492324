import React, {useEffect, useState} from 'react';
import {Slider, Typography, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import {makeStyles} from '@mui/styles';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import {MOBILE_SCREEN, primaryColor} from '../../assets/jss/nextjs-material-dashboard';
import PauseIcon from '@mui/icons-material/Pause';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {useMarineTrafficContext} from '../../context/marineTraffic/marineTrafficContext';
import moment from 'moment';

const TimeLabel = ({children, short}) => <Box>
  <Box width={2} height={short ? 10 : 20}
       sx={{backgroundColor: 'rgba(0,0,0,0.4)', marginLeft: '1.1em', marginTop: -1.3}}/>
  {!short && children}
</Box>;

const VESSELS_POS_INTERVAL_SECONDS = 1;

const useStyles = makeStyles(theme => ({
  margin: {
    height: theme.spacing(3)
  },
  thumb: {
    background: '#ffffff',
    height: 20
  },
  markLabel: {
    color: '#ffffff',
    fontSize: '12px',
  },
  mark: {
    display: 'none',
  },
  rail: {
    backgroundColor: 'rgba(0,0,0,0.9)',
    height: '10px',
  },
  track: {
    display: 'none'
  },
}));


export default function VesselsSlider() {
  const {t} = useTranslation();
  const [iconText, setIconText] = useState('Play');
  const classes = useStyles();

  const {state: {sliderPosition, selectedDate}, dispatch: sliderDispatch} = useMarineTrafficContext();
  const value = sliderPosition;
  const setValue = val => sliderDispatch({type: 'set-slider-position', value: val});

  // const [value, setValue] = React.useState(0);
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);
  const once_per_three_hours = useMediaQuery('(max-width:1400px)');
  const once_per_six_hours = useMediaQuery('(max-width:1000px)');
  const denominator = once_per_six_hours ? 6 : once_per_three_hours ? 3 : 1;
  const [isPlaying, setPlaying] = useState(false);

  const startHour = moment(selectedDate).isSame(new Date(), "day") ? new Date().getHours() : -1;
  const minutesInt = new Date().getMinutes();
  const minutes = moment(selectedDate).isSame(new Date(), "day") ? (minutesInt > 9 ? minutesInt : "0" + minutesInt) : "00";
  const norm = val => val % 24;
  const shift = (val, hours) => norm(val + hours + 1);

  const marks = _.range(0, 24).map(i => ({
    value: i,
    tooltipLabel: shift(i, startHour) > 9 ? shift(i, startHour) + ':' + minutes : '0' + shift(i, startHour) + ':' + minutes,
    label: <TimeLabel
      short={(i + 1) % denominator}>{shift(i, startHour) > 9 ? shift(i, startHour) + ':00' : '0' + shift(i, startHour) + ':00'}</TimeLabel>
  }));


  const labelText = (value) => {
    const idx = Math.round(value % marks.length);
    const d2 = new Date();
    d2.setDate(d2.getDate() - 1);

    // const today = new Date().toLocaleString(window.navigator.language, {weekday: 'short'});
    const date = selectedDate.toLocaleDateString(window.navigator.language);
    // const yesterday = d2.toLocaleString(window.navigator.language, {weekday: 'short'});
    // const dayName = shift(value / 1000, startHour) > startHour ? yesterday : today;
    return <span>{date} <b>{marks[idx].tooltipLabel}</b></span>;
  };

  const onPlay = () => {
    setIconText(t('customSlider.pause'));
    setPlaying(true);
    setValue(0);
  };

  const onPause = () => {
    setIconText(t('customSlider.play'));
    setPlaying(false);
  };

  useEffect(() => {
    if (isPlaying && value < marks.length - 1) {
      setTimeout(() => {
        fetchVesselsPosition(value + 1);
        setValue(value + 1);
      }, 1000 * VESSELS_POS_INTERVAL_SECONDS);
    } else {
      onPause();
    }
  }, [value, isPlaying]);

  const onForward = () => {
    value < marks.length - 1 && setValue(value + 1);
  };

  const onBack = () => {
    value > 0 && setValue(value - 1);
  };

  const fetchVesselsPosition = idx => {
    // if (layersCache[currentParam + '-' + dates[idx]]) {
    //   dispatch({type: 'set-current-survey-date', date: dates[idx]});
    //   return;
    // }
    //
    // setLoading(true);
    // getMapSurveyLayer(clientId, currentParam, dates[idx])
    //   .then(result => {
    //     dispatch({
    //       type: 'add-survey-layer',
    //       param: currentParam,
    //       date: dates[idx],
    //       layer: result.layerGroups[0].filter(l => l.mapboxLayers)[0]
    //     });
    //     dispatch({type: 'set-current-survey-date', date: dates[idx]});
    //   })
    //   .catch(err => console.error(err))
    //   .finally(() => setLoading(false));
  };

  return (
    <Box sx={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '90px',
      padding: ' 0.5em 1.6em',
      background: `linear-gradient(to top, rgba(25, 34, 49,.5), rgba(25, 34, 49, 0))`,
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      {!isMobile && <>
        <Box mr={1} mt={-1}>
          <Box sx={{
            cursor: "pointer",
            width: 40, height: 40, marginBottom: 0.7, textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: primaryColor[2], backgroundColor: 'white',
            borderRadius: '50%'
          }}
               onClick={isPlaying ? onPause : onPlay}>
            {isPlaying ? <PauseIcon p={0} m={0}/> : <PlayArrowRoundedIcon p={0} m={0}/>}
          </Box>
          {!isMobile &&
          <Typography sx={{marginLeft: '.3em'}} variant={'caption'} color={'white'}>{iconText}</Typography>}
        </Box>
        <Box ml={1} mt={-1}>
          <FastRewindIcon
            onClick={onBack}
            sx={{
              cursor: "pointer",
              width: 40, height: 40, marginLeft: '1em', color: primaryColor[2], backgroundColor: 'white',
              borderRadius: '50%', padding: 0.8
            }}/>
          {!isMobile && <Typography sx={{marginLeft: '1em'}} variant={'caption'}
                                    color={'white'}>{t('customSlider.previous')}</Typography>}
        </Box>
      </>}
      <Slider
        sx={{width: isMobile ? '100%' : '90%'}}
        value={value}
        valueLabelFormat={labelText}
        classes={{
          thumb: classes.thumb,
          rail: classes.rail,
          track: classes.track,
          mark: classes.mark,
          markLabel: classes.markLabel,
        }}
        aria-label={'Vessel Slider'}
        valueLabelDisplay={isMobile ? 'auto' : 'on'}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        min={0}
        max={marks.length - 1}
        marks={marks}
      >
      </Slider>

      {!isMobile && <Box position={"relative"} ml={3} mt={-1}>
        <FastForwardIcon
          onClick={onForward}
          sx={{
            cursor: "pointer",
            width: 40, height: 40, color: primaryColor[2], backgroundColor: 'white',
            borderRadius: '50%', padding: 0.8
          }}/>
        <Typography variant={'caption'} color={'white'}>{t('customSlider.next')}</Typography>
      </Box>}

      {isMobile &&
      <Box position={"absolute"} width={"90%"} bottom={70} display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"} flexDirection={"row"}>
          <Box sx={{
            cursor: "pointer",
            width: 40, height: 40, marginBottom: 0.7, textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: primaryColor[2], backgroundColor: 'white',
            borderRadius: '50%',
            zIndex: 3
          }}
               onClick={isPlaying ? onPause : onPlay}>
            {isPlaying ? <PauseIcon p={0} m={0}/> : <PlayArrowRoundedIcon p={0} m={0}/>}
          </Box>
          <Box>
            <FastForwardIcon
              onClick={onForward}
              sx={{
                cursor: "pointer",
                width: 40, height: 40, color: primaryColor[2], backgroundColor: 'white',
                borderRadius: '50%', padding: 0.8, marginLeft: 3
              }}/>
          </Box>
        </Box>
        <Box position={"absolute"} right={0}>
          <FastRewindIcon
            onClick={onBack}
            sx={{
              cursor: "pointer",
              width: 40, height: 40, marginLeft: '1em', color: primaryColor[2], backgroundColor: 'white',
              borderRadius: '50%', padding: 0.8
            }}/>
        </Box>
      </Box>
      }
    </Box>
  );
}
