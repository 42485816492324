import {Marker} from '@urbica/react-map-gl';
import DeviceMarker from '../../components/DeviceMarker/DeviceMarker';
import React, {useEffect} from 'react';
import {useMapContext} from '../../context/map/mapContext';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {
  getActiveMaintenance,
  getCurrentWeather,
  getMeasurements,
  getMockedWaterMeasurements,
  getWaterMeasurements
} from '../../api';
import {useMeasurementsContext} from '../../context/measurements/measurementsContext';
import moment from 'moment';
import _ from 'lodash';
import CustomSlider from '../../components/CustomSlider/CustomSlider';
import {useMaintenance} from '../../context/maintenance/maintenanceContext';

let FETCH_MOCKED_WATER_MEASUREMENTS = false;

export default function DevicesMarkers() {
  const {config: {clientId, devices, modules}, email} = useConfig().state;
  const {state: {selectedMarkers, selectedDevice}, dispatch: mapDispatch} = useMapContext();
  const {state: {selectedDate}, dispatch: measurementsDispatch} = useMeasurementsContext();
  const {dispatch: maintenanceDispatch} = useMaintenance();

  // console.log({modules})

  if (clientId === 'elb') {
    FETCH_MOCKED_WATER_MEASUREMENTS = true;
  }

  // FETCH CURRENT MEASUREMENTS
  useEffect(async () => {
    mapDispatch({type: 'set-loading', loading: true});

    // FETCH ACTIVE MAINTENANCES DATA
    getActiveMaintenance(clientId)
      .then(result => maintenanceDispatch({type: 'set-activeMaintenances', activeMaintenances: result}))
      .catch(err => console.error(err));

    const currentWeather = await getCurrentWeather(clientId);
    measurementsDispatch({type: 'set-current', current: {'weather': currentWeather}});
    let airLast24 = {};
    let agroLast24 = {};
    let waterLast24 = {};
    let dateFrom;
    let dateTo;
    if (moment(selectedDate).isSame(moment(), 'day')) {
      dateFrom = '-24h';
      dateTo = '-0h';
    } else {
      const from = moment(selectedDate).set('hours', 0).set('minute', 0).diff(moment(), 'hours');
      const to = from + 24;
      dateFrom = from + 'h';
      dateTo = to + 'h';
    }

    if (modules.WATER) {
      const waterDevs = devices.filter(d => d.type === 'buoy');
      const waterDevicesSerials = waterDevs.map(d => d.serialNo);
      const waterParams = _.uniq(waterDevs.flatMap(d => d.measurements));
      if (waterDevicesSerials.length > 0) {
        waterLast24 = FETCH_MOCKED_WATER_MEASUREMENTS ?
          await getMockedWaterMeasurements(clientId, waterDevicesSerials, waterParams, dateFrom, dateTo, '1h')
            :
          await getWaterMeasurements(clientId, waterDevicesSerials, waterParams, dateFrom, dateTo, '1h');
      }
      mapDispatch({type: 'add-markers', id: 'WATER'});
    }
    if (modules.AIR) {
      const airDevicesSerials = devices.filter(d => d.type === 'air' || d.type === 'noise').map(d => d.serialNo);
      if (airDevicesSerials.length > 0) {
        const airMeasurements = _.uniq(devices.filter(d => airDevicesSerials.includes(d.serialNo)).flatMap(d => d.measurements));
        airLast24 = await getMeasurements(clientId, airDevicesSerials, airMeasurements, dateFrom, dateTo, '1h');
      }
      mapDispatch({type: 'add-markers', id: 'AIR'});
    }
    if (modules.NOISE) {
      mapDispatch({type: 'add-markers', id: 'NOISE'});
    }
    if (modules.AGRO) {
      const agroDevs = devices.filter(d => d.type === 'agro');
      const agroDevicesSerials = agroDevs.map(d => d.serialNo);
      const agroParams = _.uniq(agroDevs.flatMap(d => [...d.measurements, ...d.weatherMeasurements]));
      console.log({agroParams})
      if (agroDevicesSerials.length > 0) {
        agroLast24 = await getMeasurements(clientId, agroDevicesSerials, agroParams, dateFrom, dateTo, '1h');
      }
      mapDispatch({type: 'add-markers', id: 'AGRO'});
    }
    measurementsDispatch({type: 'set-last24', last24: {...airLast24, ...waterLast24, ...agroLast24}});
    mapDispatch({type: 'set-loading', loading: false});
  }, [devices, clientId, modules, selectedDate]);

  const selectedDevices = selectedMarkers.filter(id => !['WEATHER', 'WIND_PM'].includes(id))
    .flatMap(id => devices
      .filter(d =>
        (id === 'AIR' && 'air' === d.type)
        || (id === 'NOISE' && ('noise' === d.type || (!selectedMarkers.includes('AIR') && d.measurements.includes('noise'))))
        || (id === 'WATER' && 'buoy' === d.type)
        || (['AGRO', 'METEO'].includes(id) && 'agro' === d.type)));
  
  const slider = selectedDevices.length > 0 && !selectedMarkers.includes('MARINE_TRAFFIC') && <CustomSlider key={'slider'}/>;

  return [selectedDevices.map(device => <Marker
    key={device.serialNo}
    longitude={device.location[0]}
    latitude={device.location[1]}
    anchor="top-left"
    offset={[-11, -11]}
  >
    <DeviceMarker
      device={device}
      selectedDevice={selectedDevice}
      onClick={() => mapDispatch({type: 'set-selectedDevice', device})}/>
  </Marker>), slider];
}
