import React, {useEffect, useRef, useState} from 'react';
import {useAxis, useHighcharts} from 'react-jsx-highstock';
import FocusTrap from 'focus-trap-react';
import {DayPicker} from 'react-day-picker';
import {usePopper} from 'react-popper';
import 'react-day-picker/dist/style.css';
import './dateRangePicker.css';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import {Done} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';

const DAY_FORMAT = 'y-MM-dd';
const ONE_DAY = 86400000;

const format = date => {
  return date ? date.toLocaleDateString() : 'N/A';
};

const AirDateRangePicker = () => {
  const {t} = useTranslation();
  const Highcharts = useHighcharts();
  const axis = useAxis('xAxis');
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const popperRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const [selectedRange, setSelectedRange] = useState({from: new Date(), to: new Date()});


  useEffect(() => {
    if (!axis) return;

    const handleAfterSetExtremes = ({min, max}) => {
      setSelectedRange({from: new Date(min), to: new Date(max)});
    };
    Highcharts.addEvent(axis.object, 'afterSetExtremes', handleAfterSetExtremes);

    const {min, max} = axis.getExtremes();
    setSelectedRange({from: new Date(min), to: new Date(max)});

    return () => {
      Highcharts.removeEvent(axis.object, 'afterSetExtremes', handleAfterSetExtremes);
    }
  }, [axis]);


  const handleRangeSelect = (range) => {
    if (!range) return;
    console.log({...range});
    setSelectedRange(range);
  };

  // POPER WITH CALENDAR
  const popper = usePopper(popperRef.current, popperElement, {placement: 'bottom-start'});

  const closePopper = () => {
    setIsPopperOpen(false);
    axis.setExtremes(selectedRange.from.valueOf(), selectedRange.to.valueOf());
  };

  const openPopper = () => {
    setIsPopperOpen(true);
  };


  return (
    <Box position={'absolute'} right={40} top={10}
         sx={{'@media screen and (max-width: 62em)': {top: "2.5em", right: "6em"},}}>
      <div ref={popperRef}>
        <input
          size={10}
          value={format(selectedRange?.from, DAY_FORMAT)}
          placeholder={format(new Date(), DAY_FORMAT)}
          onClick={openPopper}
        />
        {' – '}
        <input
          size={10}
          value={format(selectedRange?.to, DAY_FORMAT)}
          placeholder={format(new Date(), DAY_FORMAT)}
          onClick={openPopper}
        />
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: () => setIsPopperOpen(false)
          }}
        >
          <div
            tabIndex={-1}
            style={{
              // background: 'white',
              color: "white",
              background: 'rgba(6,47,79,0.7)',
              borderRadius: 4,
              boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.04), 0px 4px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px -1px rgba(0, 0, 0, 0.09)'
            }}
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              disabled={{after: new Date()}}
              mode="range"
              selected={selectedRange}
              onSelect={handleRangeSelect}
              initialFocus={isPopperOpen}
              footer={<Box textAlign={"center"} color={"white"} textTransform={"uppercase"}>
                <Button color={'inherit'} onClick={closePopper}
                        startIcon={<Done/>}>{t('measurements.confirmDates')}</Button>
              </Box>}
            />
          </div>
        </FocusTrap>
      )}
    </Box>
  );
};

export default AirDateRangePicker;
