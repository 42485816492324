import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Auth} from 'aws-amplify';

import {useConfig} from 'context/clientConfig/clientConfigContext';
import CoverImg from 'assets/images/login/login-cover.png';
import UGCoverImg from 'assets/images/login/ug-borucino-login.jpg';


import CoverLayout from '../pages/CoverLayout';
import SuiInput from '../components/SuiInput';
import SuiBox from '../components/SuiBox';
import SuiTypography from '../components/SuiTypography';
import SuiButton from '../components/SuiButton';
import coralLogo from 'assets/images/logo/coral-logo.png';
import MMLogo from 'assets/images/logo/mobileMonitoring-logo.png';
import {isMobileMonitoring} from '../utils';
import {Visibility, VisibilityOff} from '@mui/icons-material';


export default function ChangePassword() {
  const {t} = useTranslation();
  const isMM = isMobileMonitoring();
  const translatedLabels = {
    username: t('login.username'),
    password: t('login.password'),
    button: t('login.button'),
  };
  const {dispatch} = useConfig();
  const navigate = useNavigate();
  const [login, setLogin] = useState('');
  const [currentPasswd, setCurrentPasswd] = useState('');
  const [currentPasswdVisible, setCurrentPasswdVisible] = useState(false);
  const [newPasswd, setNewPasswd] = useState('');
  const [newPasswdVisible, setNewPasswdVisible] = useState(false);
  const [confirmPasswd, setConfirmPasswd] = useState('');
  const [confirmPasswdVisible, setConfirmPasswdVisible] = useState(false);
  const [error, setError] = useState(false);
  const [changePasswdSuccess, setChangePasswdSuccess] = useState(false);

  const handleChangePasswd = async () => {

    if (!(login.length > 3 && currentPasswd.length > 5 && newPasswd.length > 5 && confirmPasswd.length > 5)) {
      setError(t('login.wrongPasswdFormat'));
      return;
    }

    if (newPasswd !== confirmPasswd) {
      setError(t('login.passwordNotMatch'));
      return;
    }

    const user = await Auth.signIn(login, currentPasswd);
    // console.log(user);
    Auth.changePassword(user, currentPasswd, newPasswd).then(data => {
      // console.log('Reset Password Success', data);
      setChangePasswdSuccess(true);
      // navigate('/', {replace: true});
    })
      .catch(err => {
        // console.log({err});
        // console.log(err.code);
        setError(t(`loginErrors.${err.code}`, err.message));
        // setError(t(`loginErrors.${error}`));
      });


  };

  return (
    <CoverLayout
      title={<img width={'80%'} src={isMM ? MMLogo : coralLogo}/>}
      description={changePasswdSuccess ? t('login.passwdChanged') : t('login.changePassword')}
      image={isMM ? UGCoverImg : CoverImg}
    >
      {error && <SuiBox mb={2} lineHeight={1.25}>
        <SuiBox mb={1} ml={0.5}>
          <SuiTypography component="label" textColor={"error"} variant="caption">
            {error}
          </SuiTypography>
        </SuiBox>
      </SuiBox>}

      {changePasswdSuccess ? <SuiBox mt={4} mb={1}>
          <SuiButton href={"/"} variant="gradient" buttonColor="info" fullWidth >
            {t('login.goToSystem')}
          </SuiButton>
        </SuiBox> :
        <SuiBox component="form" role="form">
          <SuiBox mb={2} lineHeight={1.25}>
            <SuiBox mb={1} ml={0.5}>
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                {t('login.email')}
              </SuiTypography>
            </SuiBox>
            <SuiInput type="email" placeholder={t('login.email')+ '...'}
                      error={!!error}
                      onChange={({currentTarget}) => {
                        setLogin(currentTarget.value);
                        setError(false);
                      }}/>
          </SuiBox>
          <SuiBox mb={2} lineHeight={1.25}>
            <SuiBox mb={1} ml={0.5}>
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                {t('login.currentPasswd')}
              </SuiTypography>
            </SuiBox>
            <SuiInput
                      placeholder={t('login.currentPasswd')}
                      type={currentPasswdVisible ? 'text' : 'password'}
                      withIcon={{
                        icon: !currentPasswdVisible ? <VisibilityOff sx={{cursor: 'pointer'}} onClick={() => setCurrentPasswdVisible(true)} />
                          :
                          <Visibility sx={{cursor: 'pointer'}} onClick={() => setCurrentPasswdVisible(false)} />,
                        direction: "right",
                      }}
                      error={!!error}
                      onChange={({currentTarget}) => {
                        setCurrentPasswd(currentTarget.value);
                        setError(false);
                      }}/>
          </SuiBox>

          <SuiBox mb={2} lineHeight={1.25}>
            <SuiBox mb={1} ml={0.5}>
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                {t('login.newPasswd')}
              </SuiTypography>
            </SuiBox>
            <SuiInput placeholder={t('login.currentPasswd') + '...'} error={!!error}
                      type={newPasswdVisible ? 'text' : 'password'}
                      withIcon={{
                        icon: !newPasswdVisible ? <VisibilityOff sx={{cursor: 'pointer'}} onClick={() => setNewPasswdVisible(true)} />
                          :
                          <Visibility sx={{cursor: 'pointer'}} onClick={() => setNewPasswdVisible(false)} />,
                        direction: "right",
                      }}
                      onChange={({currentTarget}) => {
                        setNewPasswd(currentTarget.value);
                        setError(false);
                      }}/>
          </SuiBox>

          <SuiBox mb={2} lineHeight={1.25}>
            <SuiBox mb={1} ml={0.5}>
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                {t('login.confirmPasswd')}
              </SuiTypography>
            </SuiBox>
            <SuiInput placeholder={t('login.confirmPasswd') + '...'}
                      type={confirmPasswdVisible ? 'text' : 'password'}
                      withIcon={{
                        icon: !confirmPasswdVisible ? <VisibilityOff sx={{cursor: 'pointer'}} onClick={() => setConfirmPasswdVisible(true)} />
                          :
                          <Visibility sx={{cursor: 'pointer'}} onClick={() => setConfirmPasswdVisible(false)} />,
                        direction: "right",
                      }}
                      error={!!error}
                      onChange={({currentTarget}) => {
                        setConfirmPasswd(currentTarget.value);
                        setError(false);
                      }}/>
          </SuiBox>

          <SuiBox mt={4} mb={1}>
            <SuiButton variant="gradient" buttonColor="info" fullWidth onClick={handleChangePasswd}>
              {t('login.changeButton')}
            </SuiButton>
          </SuiBox>
        </SuiBox>}

    </CoverLayout>
  );
}
