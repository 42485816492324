import React, {useMemo, useReducer} from 'react';
import PropTypes from 'prop-types';

const initialState = () => {
  return {
    selectedDate: new Date(),
    current: {},
    last24: {},
    sliderPosition: 23,

  //  DIAG STUFF MAYBE WILL BE MOVED, diag map where key is serialNo
    diagBySerialNo: {}
  };
};


const MeasurementsContext = React.createContext(initialState());
// expose the context via a custom hook.
export const useMeasurementsContext = () => React.useContext(MeasurementsContext);

function reducer(state, action) {
  switch (action.type) {
  case 'add-diag':
    return {
      ...state,
      diagBySerialNo: {
        ...state.diagBySerialNo,
        [action.serialNo]: action.data
      }
    };

    case 'set-selectedDate':
    return {
      ...state,
      selectedDate: action.selectedDate
    };
  case 'set-current':
    return {
      ...state,
      current: action.current
    };
  case 'update-current':
    return {
      ...state,
      current: {...state.current, ...action.current}
    };
  case 'set-last24':
    return {
      ...state,
      last24: action.last24
    };
  case 'set-slider-position':
    return {
      ...state,
      sliderPosition: action.value
    };

  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function MeasurementsContextProvider({children}) {
  const [measuremetsReducerState, userDispatch] = useReducer(reducer, initialState());
  const userContext = useMemo(() => {
    return {
      state: measuremetsReducerState,
      dispatch: userDispatch
    };
  }, [measuremetsReducerState]);

  return <MeasurementsContext.Provider value={userContext}>
    {children}
  </MeasurementsContext.Provider>;
}

MeasurementsContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};
