import React, {useEffect, useState} from 'react';
import {Drawer, IconButton, Tab, Tooltip, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import {
  grayColor,
  infoColor,
  primaryCardHeader,
  primaryColor,
  warningColor
} from '../../assets/jss/nextjs-material-dashboard';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import PM10Icon from '../icons/PM10Icon';
import PM25Icon from '../icons/PM25Icon';
import PM1Icon from '../icons/PM1Icon';
import TemperatureIcon from '../icons/TemperatureIcon';
import HumidityIcon from '../icons/HumidityIcon';
import PressureIcon from '../icons/PressureIcon';
import WindIcon from '../icons/WindIcon';
import {
  AccessTime,
  Battery0Bar,
  Battery1Bar,
  Battery2Bar,
  Battery3Bar,
  Battery4Bar,
  Battery5Bar,
  Battery6Bar,
  BatteryFull,
  BlurCircular,
  BubbleChartOutlined,
  Close,
  Construction,
  DeviceThermostat,
  ElectricBolt,
  EnergySavingsLeaf,
  LensBlur,
  LocationOn,
  OilBarrel,
  Opacity,
  SignalCellular0Bar,
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  Thermostat,
  VolumeUp,
  Water
} from '@mui/icons-material';
import {useMeasurementsContext} from '../../context/measurements/measurementsContext';
import AQIChart from '../Charts/AQIChart';
import WaterMiniChart from '../Charts/WaterMiniChart';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
// import AnwilLogoImg from '../../assets/images/logo/Anwil-logo.gif';
import {
  colorByNoise,
  colorByOil,
  colorByPh,
  colorByPm10,
  colorByPm25,
  colorsByO2,
  ThresholdByMeasurement,
  ValueSlider
} from '../measurementsUtil';
import {getColorBetweenGreenAndRed, isAdmin} from '../../utils';
import DevicePhoto from '../DevicePhoto/DevicePhoto';
import moment from 'moment';
import WaterCamera from '../../assets/images/water-camera-white.png';
import AmmoniaImg from '../../assets/images/ammonia-white.png';
import CameraDeviceModal from '../CameraDevice/CameraDeviceModal';
import Button from '@mui/material/Button';
import {initialMaintenanceObj, useMaintenance} from '../../context/maintenance/maintenanceContext';


export default function DeviceSidebar({onClose, device}) {
  const {t, i18n} = useTranslation();
  const {config: {clientId}, email: userEmail} = useConfig().state;
  const {state: {selectedMaintenance, activeMaintenances}, dispatch: maintenanceDispatch} = useMaintenance();
  const [chartData, setChartData] = useState(null);
  const {config: {modules}} = useConfig().state;
  const {serialNo, name, type, camera, location, measurements} = device;
  const {state: {current, last24, sliderPosition, selectedDate}} = useMeasurementsContext();
  const [showCameraModal, setShowCameraModal] = useState(false);

  const createMaintenance = () => maintenanceDispatch({
    type: 'set-selectedMaintenance',
    selectedMaintenance: initialMaintenanceObj(clientId, userEmail, serialNo)
  });
  const editMaintenance = (m) => isAdmin(userEmail) && maintenanceDispatch({
    type: 'set-selectedMaintenance',
    selectedMaintenance: m
  });

  const [selectedMes, setSelectedMes] = useState('PM10');
  useEffect(() => {
    if (type === 'air') {
      setSelectedMes(serialNo.includes('PM100') ? 'PM100' : 'PM10');
    } else if (type === 'noise') {
      setSelectedMes('noise');
    } else if (type === 'agro') {
      setSelectedMes('soilTemp');
    } else if (measurements.includes('chlorophyll')) {
      setSelectedMes('chlorophyll');
    } else {
      setSelectedMes('temp');
    }
  }, [type, serialNo]);

  const [tab, setTab] = useState('weather'); // weather | diag

  const changeTab = (event, newValue) => {
    setTab(newValue);

    setSelectedMes(newValue);
    setSelectedMes(newValue === 'diag' ? 'batteryLvl' : 'o2');
    // setDiagLoading(true);
    // if (newValue === 'diag') {
    //   getDiag(clientId, serialNo).then(result => {
    //     measurementsDispatch({type: 'update-current', current: {diag: result}});
    //   })
    //     .catch(err => console.error(err))
    //     .finally(() => setDiagLoading(false));
    // }
  };

  const readValueSafe = measurement => last24[serialNo] && last24[serialNo][measurement] && last24[serialNo][measurement][sliderPosition] && last24[serialNo][measurement][sliderPosition][1];

  // const {
  //   batteryLvl,
  //   batteryLvl24,
  //   signalStr,
  //   mVoltage,
  //   timestamp: diagTimestamp
  // } = current.diag || {};

  const batteryLvl24 = null; // TODO
  const noiseVal = readValueSafe('noise');
  const chlorophyllVal = readValueSafe('chlorophyll');
  const nh4nVal = readValueSafe('NH4-N');

  const pm100Val = readValueSafe('PM100');
  const pm10Val = readValueSafe('PM10');
  const pm25Val = readValueSafe('PM25');
  const pm1Val = readValueSafe('PM1');
  const vocVal = readValueSafe('voc');
  const noxVal = readValueSafe('nox');
  const tempVal = readValueSafe('temp');
  const oilVal = readValueSafe('oil');
  const o2Val = readValueSafe('o2');
  const doVal = readValueSafe('do');
  const phVal = readValueSafe('ph');
  const ecVal = readValueSafe('ec');
  const turbidityVal = readValueSafe('turbidity');
  const no3Val = readValueSafe('no3');
  const orpVal = readValueSafe('orp');
  const dhtHumidity = readValueSafe('dhtHumidity');
  const dhtTemp = readValueSafe('dhtTemp');
  const interval = readValueSafe('interval');
  const signalStrengthLvl = readValueSafe('signalStrengthLvl');
  const weatherData = current['weather'];
  // AGRO PARAMS
  const soilHumidity = readValueSafe('soilHumidity');
  const soilTemp = readValueSafe('soilTemp');
  const soilSurfTemp = readValueSafe('soilSurfTemp');
  const leafWetnessYMSD = readValueSafe('leafWetnessYMSD');
  const leafTemp = readValueSafe('leafTemp');

  const diagTimestamp = sliderPosition;

  useEffect(() => {
    if (last24 && last24[serialNo] && last24[serialNo][selectedMes]) {
      setChartData(last24[serialNo][selectedMes].map(a => a[1]));
    }
  }, [last24, serialNo, selectedMes]);

  const headerColor = () => {
    if (type === 'buoy') {
      if (chlorophyllVal) return colorsByO2(o2Val);
      if (o2Val) return colorsByO2(o2Val);
      if (phVal) return colorByPh(phVal);
      if (oilVal) return colorByOil(oilVal);
    } else if (type === 'air' && measurements.length === 1 && measurements.includes('noise')) {
      return colorByNoise(noiseVal);
    } else {
      return colorByPm10(pm10Val || -1, pm100Val);
    }
  };

  return <Drawer
    variant={'persistent'}
    ModalProps={{
      keepMounted: true,
    }}
    PaperProps={{
      sx: {
        border: 'none',
        borderRadius: 2,
        minWidth: 370,
        maxWidth: 470,
        width: '30%',
        ...primaryCardHeader,
      },
    }}
    anchor={'left'}
    open={true}
    onClose={onClose}
  >
    <Box sx={{
      ...primaryCardHeader,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      border: activeMaintenances[serialNo] ? `2px solid ${warningColor[0]}` : 'none',
      borderRadius: 2,
    }}>

      <Box>
        {/* HEADER */}
        <Box p={2} sx={{
          // backgroundColor: type === 'buoy' ? (o2Val ? getColorBetweenGreenAndRed(o2Val) : (oilVal ? colorByOil(oilVal) : colorsBySerialNo[serialNo])) : colorByPm10(pm10Val || -1),
          backgroundColor: headerColor(),
          color: 'rgb(41,50,60)',
          marginBottom: '.3em',
          height: 100,
        }}>
          <IconButton sx={{
            backgroundColor: 'rgba(0,0,0,0.1)',
            color: 'whitesmoke',
            zIndex: 1000,
            position: 'absolute', right: 1, top: 1
          }} onClick={onClose}>
            <Close/>
          </IconButton>
          {activeMaintenances[serialNo] ? <Button
              color={'warning'}
              variant={'contained'}
              startIcon={<Construction/>}
              size={'small'}
              sx={{position: 'absolute', right: 150, top: 5}}
              onClick={() => editMaintenance(activeMaintenances[serialNo])}>
              W serwisie
            </Button> :
            <Button
              color={'warning'}
              variant={'contained'}
              startIcon={<Construction/>}
              size={'small'}
              sx={{position: 'absolute', left: 150, top: 5}}
              onClick={createMaintenance}>
              Serwis
            </Button>}

          {/*<Box position={'absolute'} right={10} top={0}>*/}
          {/*  <img src={AnwilLogoImg} />*/}
          {/*</Box>*/}

          <IconButton sx={{
            // backgroundColor: 'rgba(0,0,0,0.1)',
            color: 'whitesmoke',
            position: 'absolute', left: 1, top: 1
          }} onClick={onClose}>
            {type === 'air' ? <BubbleChartOutlined/> :
              <Water/>}
          </IconButton>
          <Box flexDirection={'column'} display={'flex'} gap={1} mt={4}>
            <Typography sx={{color: 'whitesmoke'}} textOverflow={'ellipsis'} fontWeight={'bold'}
                        variant={'h5'}>{name}</Typography>
            <Tooltip title={'Zobacz w GoogleMaps'} placement={'top-end'}><a target={'_blank'}
                                                                            href={`http://www.google.com/maps/place/${location[1]},${location[0]}`}>
              <Typography sx={{
                'display': 'flex',
                alignItems: 'flex-start',
                'position': 'absolute',
                textDecoration: 'underline',
                right: 10,
                top: 80,
                color: 'whitesmoke'
              }}
                          variant={'caption'}>
                {location[1].toFixed(6)}, {location[0].toFixed(6)}
                <LocationOn sx={{pb: 0.5}}/>
              </Typography>
            </a></Tooltip>
          </Box>
        </Box>
        <Box sx={{color: 'whitesmoke'}}>
          <Box p={1} pl={2}>
            <Typography sx={{
              '&::before': {
                content: '""',
                display: 'inline-block',
                width: '7px',
                height: '7px',
                // backgroundColor: 'rgb(148, 165, 184)',
                borderRadius: '50%',
                backgroundColor: 'rgb(255, 107, 107)',
                animation: '1s ease-in-out 0s infinite normal none running myEffect',
                marginRight: 1
              },
              '@keyframes myEffect': {
                '0%': {
                  transform: 'scale(0.9)',
                  opacity: 0.7
                },
                '50%': {
                  transform: 'scale(1)',
                  opacity: 1
                },
                '100%': {
                  transform: 'scale(0.9)',
                  opacity: 0.7
                }
              }
            }} fontWeight={'bold'} variant={'caption'}>
              {sliderPosition > 22 ? t('deviceSidebar.liveResult') : 'Z dnia: ' + moment(selectedDate)
                .subtract(22 - sliderPosition, 'hour').format('DD.MM.YYYY HH:00')}
            </Typography>
          </Box>
          <Box pl={2}>
            <Typography variant={'caption'}>{t('deviceSidebar.source')}: </Typography>
            <Typography variant={'caption'}>
              {clientId === 'atm' ?
                <a style={{color: 'whitesmoke'}} target={'_blank'} href={'https://atmesys.com'}>Atmesys</a> :
                ((type === 'air' || type === 'noise') ?
                  <a style={{color: 'whitesmoke'}} target={'_blank'} href={'https://seadata.pl'}>YetiBox SeaData</a> :
                  <a style={{color: 'whitesmoke'}} target={'_blank'} href={'https://mobilemonitoring.pl'}>Mobile
                    Monitoring</a>)
              }
            </Typography>
            {type !== 'air' ? <><br/><br/></> : null}

            <DevicePhoto clientId={clientId} serialNo={serialNo}/>

          </Box>

          {(type === 'air' && !(measurements.length === 1 && measurements.includes('noise'))) && <><Box ml={1} mt={4}>
            <Typography sx={{
              fontSize: '80%',
              position: 'relative',
              paddingRight: '10px',
              '&::before': {
                content: '""',
                borderTop: `1px solid ${grayColor[4]}`,
                display: 'block',
                top: '50%',
                right: 10,
                width: '73%',
                position: 'absolute',
              }
            }}>{t('deviceSidebar.particulates')}</Typography>
          </Box>
            {/* TODO VOC Icon */}
            {vocVal && <MRow label={'VOC Index (0-500)'} name={'voc'} value={vocVal} unit={' '} Icon={PM1Icon}
                             onClick={() => setSelectedMes('voc')}
                             color={getColorBetweenGreenAndRed((vocVal / ThresholdByMeasurement['voc']) * 100, true)}
                             last/>}
            {noxVal && <MRow label={'NOx Index (0-500)'}  name={'nox'} value={noxVal} unit={' '} Icon={PM1Icon}
                             onClick={() => setSelectedMes('nox')}
                             color={getColorBetweenGreenAndRed((noxVal / ThresholdByMeasurement['nox']) * 100, true)}
                             last/>}

            {pm100Val ? <>
                <br/>
                <br/>
                <MRow name={'PM100'} value={pm100Val} unit={'µg/m³'} norm={100} Icon={PM10Icon}
                      onClick={() => setSelectedMes('PM100')} color={colorByPm10(pm10Val, pm100Val)}/>
                <br/>
                <br/>
              </> :
              <><MRow name={'PM10'} value={pm10Val} unit={'µg/m³'} norm={50} Icon={PM10Icon}
                      onClick={() => setSelectedMes('PM10')} color={colorByPm10(pm10Val)}/>
                <MRow name={'PM2.5'} value={pm25Val} unit={'µg/m³'} norm={25} Icon={PM25Icon}
                      onClick={() => setSelectedMes('PM25')} color={colorByPm25(pm25Val)}/>
                {pm1Val && <MRow name={'PM1'} value={pm1Val} unit={'µg/m³'} Icon={PM1Icon}
                                 onClick={() => setSelectedMes('PM1')} color={colorByPm25(pm1Val)}
                                 last/>}

                <br/>
              </>}
          </>}
          {/* NOISE */}
          {measurements.includes('noise') &&
            <MRow name={'noise'} label={t('deviceSidebar.noise')} value={noiseVal} unit={'dBA'} Icon={VolumeUp}
                  onClick={() => setSelectedMes('noise')} color={colorByNoise(noiseVal)}/>}

          {/* AGGRO PARAMS: soilHumidity, soilTemp, soilSurfTemp, leafWetnessYMSD, leafTemp */}
          {[
            ['soilHumidity', soilHumidity, '%'],
            ['soilTemp', soilTemp, '°C'],
            ['soilSurfTemp', soilSurfTemp, '°C'],
            ['leafWetnessYMSD', leafWetnessYMSD, '%'],
            ['leafTemp', leafTemp, '°C'],
          ].filter((a => measurements.includes(a[0])))
            .map(([id, val, unit], i) =>
              <MRow key={i} name={id} value={val} unit={unit} label={t('deviceSidebar.' + id)} Icon={AgroIcons[id]}
                    onClick={() => setSelectedMes(id)}
                    color={'white'}/>)
          }

          {/* WATER  PARAMS */}
          {measurements.includes('NH4-N') &&
            <MRow name={'NH4-N'} value={nh4nVal} unit={'mg/L'} label={t('deviceSidebar.nh4n')}
                  Icon={WaterIcons['NH4-N']}
                  onClick={() => setSelectedMes('NH4-N')}
                  color={'white'}/>}

          {measurements.includes('oil') &&
            <MRow name={'oil'} value={oilVal} unit={'ppm'} label={t('deviceSidebar.oil')} Icon={WaterIcons['oil']}
                  onClick={() => setSelectedMes('oil')}
                  color={'white'}/>}
          {measurements.includes('chlorophyll') &&
            <MRow name={'chlorophyll'} value={chlorophyllVal} unit={'µg/L'} label={t('deviceSidebar.chlorophyll')}
                  Icon={WaterIcons['oil']}
                  onClick={() => setSelectedMes('chlorophyll')}
                  color={'white'}/>}
          {measurements.includes('temp') &&
            <MRow name={'temp'} value={tempVal} unit={'°C'} label={t('deviceSidebar.temp')} Icon={WaterIcons['temp']}
                  onClick={() => setSelectedMes('temp')}
                  color={infoColor[0]}/>}
          {measurements.includes('do') &&
            <MRow name={'do'} value={doVal} unit={'mg/L'} label={t('deviceSidebar.do')} Icon={WaterIcons['do']}
                  onClick={() => setSelectedMes('do')}
                  color={colorsByO2(o2Val)}/>}
          {measurements.includes('o2') &&
            <MRow name={'o2'} value={o2Val} unit={'%'} label={t('deviceSidebar.o2')} Icon={WaterIcons['o2']}
                  onClick={() => setSelectedMes('o2')}
                  color={colorsByO2(o2Val)}/>}
          {measurements.includes('ph') &&
            <MRow name={'ph'} value={phVal} unit={''} label={t('deviceSidebar.ph')} Icon={WaterIcons['ph']}
                  onClick={() => setSelectedMes('ph')}
                  color={colorByPh(phVal)}/>}
          {measurements.includes('ec') &&
            <MRow name={'ec'} value={ecVal} unit={'µS/cm'} label={t('deviceSidebar.ec')} Icon={WaterIcons['ec']}
                  onClick={() => setSelectedMes('ec')}
                  color={getColorBetweenGreenAndRed((ecVal / ThresholdByMeasurement['ec']) * 100, true)}/>}
          {measurements.includes('orp') &&
            <MRow name={'orp'} value={orpVal} unit={'mV'} label={t('deviceSidebar.orp')} Icon={WaterIcons['orp']}
                  onClick={() => setSelectedMes('orp')}
                  color={getColorBetweenGreenAndRed((orpVal / ThresholdByMeasurement['orp']) * 100, true)}/>}
          {measurements.includes('turbidity') &&
            <MRow name={'turbidity'} value={turbidityVal} unit={'NTU'} label={t('deviceSidebar.turbidity')}
                  Icon={WaterIcons['turbidity']}
                  onClick={() => setSelectedMes('turbidity')}
                  color={getColorBetweenGreenAndRed((turbidityVal / ThresholdByMeasurement['turbidity']) * 100, true)}/>}
          {measurements.includes('no3') &&
            <MRow name={'no3'} value={no3Val} unit={'mg/L'} label={t('deviceSidebar.no3')}
                  Icon={WaterIcons['no3']}
                  onClick={() => setSelectedMes('no3')}
                  color={getColorBetweenGreenAndRed((no3Val / ThresholdByMeasurement['no3']) * 100, true)}/>}
        </Box>
      </Box>

      <Box display={'flex'} flexDirection={'column'} gap={2} mt={2} sx={{height: '100%'}}>
        {/* TABS: WEATHER, DIAGNOSTICS */}
        <TabContext value={tab}>
          <TabList variant={'standard'} value={tab}
                   onChange={changeTab} aria-label="Device info Tabs">
            {/* DEVICE CAMERA */}
            {camera === 'photo' &&
              <Box position={'absolute'} right={10}>
                <Tooltip title={'Kamera / Zdjęcia'}>
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    sx={{borderColor: 'white'}}
                    onClick={() => setShowCameraModal(true)}
                  >
                    <img height={'25px'} src={WaterCamera} alt={'Camera on Water'}/>
                  </Button></Tooltip>
              </Box>}
            <Tab value="weather" label={<Typography sx={{color: 'whitesmoke', fontSize: 'x-small'}}>
              {t('deviceSidebar.weatherData')}
            </Typography>}/>
            {type === 'buoy' && modules.DIAGNOSTICS &&
              <Tab value="diag" label={<Typography sx={{color: 'whitesmoke', fontSize: 'x-small'}}>
                Diagnostyka
              </Typography>}/>}
          </TabList>
          <TabPanel value={'weather'}
                    sx={{
                      height: 50,
                      padding: 0,
                      color: 'whitesmoke',
                      pl: 1,
                      pt: 2,
                      display: 'flex',
                      justifyContent: 'space-evenly'
                    }}>
            <WeatherItem value={weatherData.temp} units={'°C'} name={t('deviceSidebar.temperature')}
                         Icon={TemperatureIcon}/>
            <WeatherItem value={weatherData.humidity} units={'%'} name={t('deviceSidebar.humidity')}
                         Icon={HumidityIcon}/>
            <WeatherItem value={weatherData.pressure > 2000 ? weatherData.pressure / 100 : weatherData.pressure}
                         units={'hPa'} name={t('deviceSidebar.pressure')}
                         Icon={PressureIcon}/>
            <WeatherItem value={weatherData.windSpeed} units={'km/h'} name={t('deviceSidebar.wind')} Icon={WindIcon}
                         windBearing={weatherData.windBearing} windSymbol={weatherData.windDir}/>
          </TabPanel>
          <TabPanel value={'diag'} sx={{
            height: 60,
            padding: 0,
            color: 'whitesmoke',
            // pl: 1,
            pb: 4,
            position: 'relative',
          }}>
            <Typography variant={'caption'} sx={{position: 'absolute', left: 10, top: -40}}>
              Dane: {23 - diagTimestamp}h temu</Typography>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              justifyContent: 'space-evenly'
            }}>
              <Tooltip title={'Poziom naładowania baterii'} placement={'top'}>
                <Box display={'flex'} onClick={() => setSelectedMes('batteryLvl')}>
                  <BatteryIcon lvl={45}/>
                  <Typography>{45}%</Typography>
                </Box>
              </Tooltip>

              <Tooltip title={'Siła sygnału GSM: ' + (signalStrengthLvl || 0)} placement={'top'}>
                <span onClick={() => setSelectedMes('signalStrengthLvl')}><SignalIcon
                  lvl={signalStrengthLvl || 0}/></span>
              </Tooltip>

            </Box>

            <Box mt={2} sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              justifyContent: 'space-evenly'
            }}>
              <Tooltip title={'Wilgotność wzgl. wewnątrz bojki'} placement={'top'}>
                <Box display={'flex'} gap={1} onClick={() => setSelectedMes('dhtHumidity')}>
                  <Opacity/>
                  <Typography>{(dhtHumidity || 0).toFixed(1)}%</Typography>
                </Box>
              </Tooltip>

              <Tooltip title={'Temperatura wewnątrz bojki'} placement={'top'}>
                <Box display={'flex'} onClick={() => setSelectedMes('dhtTemp')}>
                  <Thermostat/>
                  <Typography>{(dhtTemp || 0).toFixed(1)} C</Typography>
                </Box>
              </Tooltip>

              <Tooltip title={'Interwał pomiaru w minutach'} placement={'top'}>
                <Box display={'flex'} gap={1} onClick={() => setSelectedMes('interval')}>
                  <AccessTime/>
                  <Typography>{interval} min</Typography>
                </Box>
              </Tooltip>
            </Box>
          </TabPanel>
        </TabContext>

        {/*  CHART */}
        <Box pt={4} pb={4}>
          <Typography sx={{pl: 1}}
                      color={'whitesmoke'}>{t('deviceSidebar', {returnObjects: true})[selectedMes] || selectedMes} -
            {t('deviceSidebar.last24')}</Typography>
          {type === 'air' ? <AQIChart
            data={chartData}
          /> : <WaterMiniChart data={chartData}/>}
        </Box>
      </Box>
    </Box>

    {showCameraModal && <CameraDeviceModal cameraStation={device} onClose={() => setShowCameraModal(false)}/>}
  </Drawer>;
}

export const WeatherItem = ({name, Icon, value, units, windBearing, windSymbol}) => <Box>
  <Box display={'flex'} gap={0.5}>
    <Box sx={{
      borderRadius: '50%',
      border: `2px solid ${grayColor[3]}`,
      display: 'inline-block',
      width: '30px',
      height: '30px',
    }}>
      <Icon color={'white'}
            sx={{transform: windBearing ? `rotate(${windBearing}deg)` : 'none', width: 25, height: 25}}/>
      {windSymbol && <Box
        sx={{
          transform: 'translate(30%, -160%)',
          fontSize: '0.5rem',
          lineHeight: 1,
          backgroundColor: primaryColor[3],
          borderRadius: '50%',
          width: 15,
          height: 15,
          display: 'flex',
          // -moz-box-pack: center;
          justifyContent: 'center',
          // -moz-box-align: center;
          alignItems: 'center',
          letterSpacing: -1
        }}
        aria-label={'Wind Bearing'}>
        {windSymbol}
      </Box>}
    </Box>
    <Box display={'flex'} flexDirection={'column'}>
      <Typography fontWeight={'bold'}
                  sx={{margin: 0, lineHeight: 1, color: 'whitesmoke', marginRight: 2}}>{Math.round(value)}</Typography>
      <Typography fontWeight={'bold'} variant={'caption'}
                  sx={{margin: 0, lineHeight: 1, color: grayColor[4]}}>{units}</Typography>
    </Box>
  </Box>
  <Typography sx={{
    marginTop: 0.5,
    fontSize: '0.65rem',
    fontWeight: 'normal',
    lineHeight: 1,
    opacity: 0.7,
    textTransform: 'uppercase',
    paddingLeft: '2px',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }}>{name}</Typography>
</Box>;

export const MRow = ({onClick, name, value, unit, norm, Icon, color, label}) => <Box
  onClick={onClick}
  // display={'flex'}
  pb={1}
  ml={-1.1}
  borderBottom={(ThresholdByMeasurement[name] || name === 'o2') ? 'none' : '1px solid gray'}
  sx={{marginTop: 2, cursor: 'pointer'}}>
  <Box display={'flex'}>
    <Box ml={3.1} flex={1} display={'flex'} gap={1} alignItems={'center'}>
      <Box sx={{
        borderRadius: '50%',
        border: `2px solid ${color}`,
        display: 'inline-block',
        width: '40px',
        height: '40px',
      }}>
        <Icon color={'white'} sx={{width: 35, height: 35}}/>
      </Box>
      <Typography fontSize={'0.9rem'} fontWeight={'bold'} sx={{color: 'whitesmoke'}}>{label ? label : name}</Typography>
    </Box>
    <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
      {norm && <Typography fontWeight={'bold'}
                           sx={{color: grayColor[4], marginRight: 1}}>{Math.round((value / norm) * 100)}%</Typography>}
      <Typography fontWeight={'bold'} variant={'h6'}
                  sx={{
                    color: 'whitesmoke',
                    marginRight: 2
                  }}>{(['ph', 'do'].includes(name) ? value?.toFixed(2) : Math.round(value)) || '0'} <span
        style={{
          fontSize: 'smaller',
          fontWeight: 'normal',
          marginRight: unit ? 0 : 35
        }}>{unit ? unit : ''}</span></Typography>
    </Box>
  </Box>

  {/* PROGRESS BAR */}
  <Box>
    {ThresholdByMeasurement[name] && <ValueSlider
      measurementType={name}
      value={value || 0}
    />}
  </Box>

</Box>;

const AgroIcons = {
  'soilHumidity': () => <Opacity sx={{margin: 0.7}}/>,
  'soilTemp': () => <DeviceThermostat sx={{margin: 0.8}}/>,
  'soilSurfTemp': () => <DeviceThermostat sx={{margin: 0.8}}/>,
  'leafWetnessYMSD': () => <EnergySavingsLeaf sx={{margin: 0.8}}/>,
  'leafTemp': () => <DeviceThermostat sx={{margin: 0.8}}/>,
};


const WaterIcons = {
  'noise': () => <VolumeUp sx={{margin: 0.7}}/>,
  'oil': () => <OilBarrel sx={{margin: 0.7}}/>,
  'o2': () => <Typography sx={{margin: 1}} fontWeight={'bold'} fontSize={'0.8rem'}>O<sub>%</sub></Typography>,
  'do': () => <Typography sx={{margin: 1}} fontWeight={'bold'} fontSize={'0.8rem'}>O<sub>2</sub></Typography>,
  'ph': () => <Typography sx={{margin: 1}} fontWeight={'bold'} fontSize={'0.8rem'}>pH</Typography>,
  'turbidity': () => <LensBlur sx={{margin: 0.8}}/>,
  'no3': () => <Typography sx={{margin: 1}} fontWeight={'bold'} fontSize={'0.8rem'}>NO<sub>3</sub></Typography>,
  // TODO zmienić na soloniczkę
  'ec': () => <ElectricBolt sx={{margin: 0.7}}/>,
  'orp': () => <Typography sx={{margin: .8, marginTop: 1.2}} fontWeight={'bold'} fontSize={'0.7rem'}>ORP</Typography>,
  'temp': () => <DeviceThermostat sx={{margin: 0.8}}/>,
  'chlorophyll': () => <BlurCircular sx={{margin: 0.8}}/>,
  'NH4-N': () => <img src={AmmoniaImg} width={20} style={{margin: 9, color: 'white'}}/>
//   <LensBlur sx={{margin: 0.8}}/>,
};

const BatteryIcon = ({lvl}) => {
  if (lvl <= 10) return <Battery0Bar color={'error'}/>;
  if (lvl <= 20) return <Battery1Bar color={'error'}/>;
  if (lvl <= 40) return <Battery2Bar color={'warning'}/>;
  if (lvl <= 60) return <Battery3Bar color={'inherit'}/>;
  if (lvl <= 70) return <Battery4Bar color={'success'}/>;
  if (lvl <= 80) return <Battery5Bar color={'success'}/>;
  if (lvl <= 90) return <Battery6Bar color={'success'}/>;
  return <BatteryFull color={'success'}/>;
};

const SignalIcon = ({lvl}) => {
  if (lvl <= 1) return <SignalCellular0Bar/>;
  if (lvl <= 3) return <SignalCellular1Bar/>;
  if (lvl <= 4) return <SignalCellular2Bar/>;
  if (lvl <= 6) return <SignalCellular3Bar/>;
  return <SignalCellular4Bar/>;
};
