// ##############################
// // // variables used to create animation on charts
// #############################
var delays = 80,
  durations = 500;
var delays2 = 80,
  durations2 = 500;

// ##############################
// // // Passagers
// #############################

const passengersChart = {
  data: {
    labels: [
      '\'92',
      '\'93',
      '\'94',
      '\'95',
      '\'96',
      '\'97',
      '\'98',
      '\'99',
      '\'00',
      '\'01',
      '\'02',
      '\'03',
      '\'04',
      '\'05',
      '\'06',
      '\'07',
      '\'08',
      '\'09',
      '\'10',
      '\'11',
      '\'12',
      '\'13',
      '\'14',
      '\'15',
      '\'16',
      '\'17',
      '\'18',
      '\'19'],
    series: [
      [
        65.000,
        85.000,
        24.625,
        34.168,
        34.168,
        31.462,
        28.733,
        37.245,
        33.874,
        32.078,
        33.731,
        35.685,
        45.076,
        55.025,
        41.511,
        33.270,
        33.297,
        32.899,
        39.323,
        38.221,
        33.448,
        6.555,
        8.315,
        26.976,
        41.520,
        29.579,
        26.600,
        41.560
      ]
    ],
  },
  options: {
    low: 0,
    high: 90, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  // for animation
  animation: {
    draw: function (data) {
      if (data.type === 'line' || data.type === 'area') {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
          },
        });
      } else if (data.type === 'point') {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: 'ease',
          },
        });
      }
    },
  },
};

// ##############################
// // // CARGO TOTAL
// #############################

const cargoTotalChart = {
  data: {
    labels: [
      '\'09',
      '\'10',
      '\'11',
      '\'12',
      '\'13',
      '\'14',
      '\'15',
      '\'16',
      '\'17',
      '\'18',
      '\'19'
    ],
    series: [[
      4.0,
      64.8,
      113.5,
      168.5,
      285.5,
      358.3,
      206.9,
      139.5,
      99.1,
      115.9,
      89.3
    ]],
  },
  options: {
    axisX: {
      showGrid: false,
    },
    low: 0,
    high: 400,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0,
    },
  },
  responsiveOptions: [
    [
      'screen and (max-width: 640px)',
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          },
        },
      },
    ],
  ],
  animation: {
    draw: function (data) {
      if (data.type === 'bar') {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: 'ease',
          },
        });
      }
    },
  },
};

// ##############################
// // // REVENUE
// #############################

const revenueChart = {
  data: {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    series: [[220, 130, 380, 950, 2580, 4590, 5040, 6210, 3610, 50, 0, 0]],
  },
  options: {
    axisX: {
      showGrid: false,
    },
    low: 0,
    high: 9000,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0,
    },
  },
  responsiveOptions: [
    [
      'screen and (max-width: 640px)',
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          },
        },
      },
    ],
  ],
  animation: {
    draw: function (data) {
      if (data.type === 'bar') {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: 'ease',
          },
        });
      }
    },
  },
};

// ##############################
// // // CARGO TYPES
// #############################

const cargoTypesChart = {
  data: {
    labels: ['materiały budowlane /drobnica/',
      'węgiel',
      'zboża/soja',
      'konstrukcje stalowe',
      'drewno'
    ],
    series: [[1062.6, 446.8, 97.1, 26.2, 13.6]],
  },
  options: {
    low: 0,
    high: 1100, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  animation: {
    draw: function (data) {
      if (data.type === 'line' || data.type === 'area') {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
          },
        });
      } else if (data.type === 'point') {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: 'ease',
          },
        });
      }
    },
  },
};

module.exports = {
  passengersChart,
  cargoTotalChart,
  cargoTypesChart,
};
