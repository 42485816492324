import {Card, CardContent, CardHeader, Table, TableContainer, TableFooter, Tooltip, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import {AddAlert, CalendarMonth, FileDownloadOff} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import React, {useEffect, useMemo, useState} from 'react';
import {grayColor, primaryColor, successColor} from '../../assets/jss/nextjs-material-dashboard';
import {useTranslation} from 'react-i18next';
import TableSortLabel from '@mui/material/TableSortLabel';
import {visuallyHidden} from '@mui/utils';
import {BUCKET, getComparator, stableSort, TCell, THeader} from '../Table/tableUtills';
import {getEpisodesStats} from '../../api';
import DatePicker, {registerLocale} from 'react-datepicker';
import moment from 'moment';
import ExcelIcon from 'assets/images/Excel-icon.png';
import {REGION} from '../../aws-exports';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import lv from 'date-fns/locale/lv';
import pl from 'date-fns/locale/pl';

registerLocale('lv', lv);
registerLocale('pl', pl);


const makeS3Url = (year, month, clientId) => {
  const monthWithZero = month > 9 ? month : '0' + month;
  return `https://s3.${REGION}.amazonaws.com/${BUCKET}/${clientId}/monthly/${clientId === 'lip' ? 'Report' : 'Raport'}-${year}-${monthWithZero}.xlsx`;
};

export default function EpisodesStats() {
  const {t, i18n} = useTranslation();
  const {clientId} = useConfig().state.config;
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [episodesByYear, setEpisodesByYear] = useState({});
  const [episodes, setEpisodes] = useState([]);

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('timestamp');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  useEffect(() => {
    setLoading(true);
    getEpisodesStats(clientId, year)
      .then(result => {
        setEpisodesByYear({...episodesByYear, ...result});
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [clientId, year]);

  useEffect(() => {
    if (episodesByYear[year]) {
      setEpisodes(episodesByYear[year][month].stations);
    }
  }, [year, month, episodesByYear]);

  const onDateChange = date => {
    setYear(date.getFullYear());
    setMonth(date.getMonth() + 1);
    setSelectedDate(new Date(date));
  };

  const rows = useMemo(() => episodes.map(({alarm, allowable, name,}) => ({
    stationName: name,
    allowable,
    alarm,
    sum: alarm + allowable,
    downloadLink: makeS3Url(year, month, clientId)
  })), [episodes, year, month, clientId]);

  const yearTableRows = episodesByYear[year] ? t('general.months', {returnObjects: true}).slice(1).map((monthName, idx) => {
    return ({
      monthName,
      allowable: episodesByYear[year][idx + 1].stations.reduce((accumulator, elem) => accumulator + elem.allowable, 0),
      alarm: episodesByYear[year][idx + 1].stations.reduce((accumulator, elem) => accumulator + elem.alarm, 0),
      sum: episodesByYear[year][idx + 1].stations.reduce((accumulator, elem) => accumulator + elem.allowable + elem.alarm, 0),
      downloadLink: makeS3Url(year, idx + 1, clientId)
    });
  }) : [];

  return <Card variant={'outlined'} sx={{
      minHeight: '100%',
      maxWidth: 'calc(100vw - 160px)',
      '@media screen and (max-width: 50em)': {maxWidth: '100vh'}
    }}>
      <CardHeader
        action={<Box pr={2}>
          <DatePicker
            customInput={
              <Button variant={'outlined'} endIcon={<CalendarMonth/>}>
                {moment(selectedDate).locale(i18n.resolvedLanguage).format('MMMM YYYY')}
              </Button>
            }
            locale={i18n.resolvedLanguage}
            showMonthYearPicker
            onChange={onDateChange}
            selected={selectedDate}
            maxDate={new Date()}
          />
        </Box>}
        title={<Button sx={{'@media screen and (max-width: 50em)': {display: 'none'}}}
                       size={'large'} startIcon={<AddAlert/>}
                       color={'error'}>{t('episodes.headingStats')}</Button>}
        subheader={<Typography
          sx={{marginLeft: 2, color: grayColor[3], '@media screen and (max-width: 50em)': {display: 'none'}}}
          variant={'caption'}>{t('episodes.subheadingStats')}</Typography>}
      />
      {/*MOBILE CARD TITLE*/}
      <Typography sx={{
        color: primaryColor[2],
        // textAlign: 'center',
        fontSize: '1.25rem',
        position: 'absolute',
        top: 80,
        left: 10,
        // paddingTop: '1em',
        '@media screen and (min-width: 50em)': {display: 'none'}
      }}>{t('measurements.episodes')} </Typography>

      <CardContent>
        <Box display="flex">
          {loading ? <CircularProgress size={100} sx={{margin: 'auto', marginTop: 10}}/> :
            <Box display="flex" flexDirection={'column'} gap={4}>
              {/* MONTH EPISODES */}
              <TableContainer>
                <Typography sx={{mb: 1}} fontWeight={'bold'} color={'primary'}>
                  {t('episodes.selectedMonth')}: {moment(selectedDate).locale(i18n.resolvedLanguage).format('MMMM').toUpperCase()}</Typography>
                <Table aria-label="Episodes table">
                  <TableHead>
                    <TableRow sx={{backgroundColor: 'whitesmoke'}}>
                      <TableCell width={20}><THeader>#</THeader></TableCell>
                      {[{id: 'stationName', label: 'stationName'},
                        {id: 'allowable', label: 'allowable'},
                        {id: 'alarms', label: 'alarms'},
                        {id: 'sum', label: 'sum'},
                        {id: 'download', label: 'download'},
                      ].map(({id, label}) =>
                        <TableCell key={id} sortDirection={orderBy === id ? order : false}
                                   align="center">
                          <TableSortLabel
                            active={orderBy === id}
                            direction={orderBy === id ? order : 'asc'}
                            onClick={event => handleRequestSort(event, id)}
                          >
                            <Typography sx={{color: primaryColor[2]}}>
                              {t('episodes.' + label)}
                            </Typography>
                            {orderBy === id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </Box>
                            ) : null}
                          </TableSortLabel>

                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                      .map(({stationName, allowable, alarm, sum, downloadLink}, idx) => (
                        <TableRow
                          key={idx}
                          sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                          <TableCell width={20} align={'center'}><TCell>{idx + 1}</TCell></TableCell>
                          <TableCell align="center"><TCell>
                            <a style={{color: primaryColor[2]}}
                               href={`/#/pl/map?stationName=${stationName}`}>{stationName}</a>
                          </TCell></TableCell>
                          <TableCell align="center"><TCell>{allowable}</TCell></TableCell>
                          <TableCell align="center"><TCell>{alarm}</TCell></TableCell>
                          <TableCell align="center"><TCell>{sum}</TCell></TableCell>
                          <TableCell align="center"><TCell>
                            {
                              moment().isSame(moment(selectedDate), 'month') ?
                                <Tooltip placement={'top'} title={t('episodes.downloadNotAvailable')}>
                                  <Button style={{color: grayColor[2]}}><FileDownloadOff/></Button>
                                </Tooltip>
                                : <Tooltip placement={'top'} title={t('episodes.downloadDetails')}>
                                  <Button disabled={moment().isSame(moment(selectedDate), 'month')} target={'_blank'}
                                          style={{color: primaryColor[2]}} href={downloadLink}>
                                    <img width={30} src={ExcelIcon}/>
                                  </Button>
                                </Tooltip>
                            }
                          </TCell></TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                {rows.length === 0 && <TableFooter>
                  <Typography fontWeight={'bold'} sx={{color: successColor[1], mt: 4, ml: 4}}>
                    {t('episodes.noEpisodes')}
                  </Typography>
                </TableFooter>}
              </TableContainer>


              {/*  YEAR EPISODES */}
              <TableContainer>
                <Typography sx={{mb: 1}} fontWeight={'bold'} color={'primary'}>
                  {t('episodes.selectedYear')} {selectedDate.getFullYear()}</Typography>

                <Table aria-label="Year Episodes table">
                  <TableHead>
                    <TableRow sx={{backgroundColor: 'whitesmoke'}}>
                      <TableCell width={20}><THeader>#</THeader></TableCell>
                      {[{id: 'monthName', label: 'month'},
                        {id: 'allowable', label: 'allowable'},
                        {id: 'alarms', label: 'alarms'},
                        {id: 'sum', label: 'sum'},
                        {id: 'download', label: 'download'},
                      ].map(({id, label}) =>
                        <TableCell key={id} align="center">
                          <Typography sx={{color: primaryColor[2]}}>
                            {t('episodes.' + label)}
                          </Typography>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {yearTableRows.map(({monthName, allowable, alarm, sum, downloadLink}, idx) => (
                      <TableRow
                        key={idx}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                      >
                        <TableCell width={20} align={'center'}><TCell>{idx + 1}</TCell></TableCell>
                        <TableCell align="center"><TCell>
                          {monthName}
                        </TCell></TableCell>
                        <TableCell align="center"><TCell>{allowable}</TCell></TableCell>
                        <TableCell align="center"><TCell>{alarm}</TCell></TableCell>
                        <TableCell align="center"><TCell>{sum}</TCell></TableCell>
                        <TableCell align="center"><TCell>
                          {
                            selectedDate.getFullYear() === new Date().getFullYear() && idx >= new Date().getMonth() ?
                              <Tooltip placement={'top'} title={t('episodes.downloadNotAvailable')}>
                                <Button style={{color: grayColor[2]}}><FileDownloadOff/></Button>
                              </Tooltip>
                              : <Tooltip placement={'top'} title={t('episodes.downloadDetails')}>
                                <Button disabled={moment().isSame(moment(selectedDate), 'month') &&
                                selectedDate.getFullYear() === new Date().getFullYear() && idx >= new Date().getMonth()} target={'_blank'}
                                        style={{color: primaryColor[2]}} href={downloadLink}>
                                  <img width={30} src={ExcelIcon}/>
                                </Button>
                              </Tooltip>
                          }
                        </TCell></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          }
        </Box>
      </CardContent>
    </Card>;
}

