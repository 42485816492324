import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import {alpha} from '@mui/system/colorManipulator';
import {ExpandCircleDownOutlined, InfoOutlined} from '@mui/icons-material';
import {useRiskContext} from '../../context/risk/riskContext';
import RiskInfoTooltip from './RiskInfoTooltip';
import {generateRandom, pdfReportS3Link, RISK_COLOR, xlsxReportS3Link} from '../../utils';
import WaterRiskInfoTooltip from './WaterRiskInfoTooltip';
import RiskAndWeatherTooltip from './RiskAndWeatherTooltip';
import './RiskInfoTooltip.css';
import Fade from '@mui/material/Fade';
import PdfIcon from 'assets/images/PDF-icon.png';
import XlsIcon from 'assets/images/Excel-icon.png';
import {useState} from 'react';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import moment from 'moment';


export default function RiskLegend({onWater}) {
  const {t} = useTranslation();
  const {clientId} = useConfig().state.config;
  const labels = t('risk.riskLevelLabels', {returnObjects: true});
  const {state: {riskAreas, riskSliderValue}} = useRiskContext();
  const [downloadSectionExpanded, setDownloadSectionExpanded] = useState(false);
  const pollutionRiskLvl = onWater ? generateRandom(1, 4) : riskAreas.length && riskAreas[0].values.length > riskSliderValue && riskAreas[0].values[riskSliderValue].risk_pollution;
  const riskTimestamp = onWater ? null : riskAreas.length && riskAreas[0].values.length > riskSliderValue && riskAreas[0].values[riskSliderValue].timestamp;
  const riskMoment = riskTimestamp ? moment(riskTimestamp) : moment.now();

  const toggleExpand = () => setDownloadSectionExpanded(!downloadSectionExpanded);

  return <Box sx={{
    px: 1,
    pb: 2,
    borderRadius: 2,
    color: 'white',
    backgroundColor: 'rgba(6,47,79,0.4)',
    cursor: 'pointer',
    textAlign: 'center',
  }}>
    <Typography textTransform={'uppercase'} variant={'caption'} fontSize={10}>
      {onWater ? t('risk.riskWaterLegendTitle') : t('risk.riskLegendTitle')}
    </Typography>
    <Box position={'absolute'} top={0} right={2}>
      {onWater ? <WaterRiskInfoTooltip riskLvl={pollutionRiskLvl}/> : <RiskInfoTooltip/>}
    </Box>
    <Box sx={{display: 'flex', gap: 0.4}}>
      {[1, 2, 3, 4].map(lvl => ({lvl, color: RISK_COLOR[lvl], label: labels[lvl]}))
        .map(({lvl, label, color}) =>
          <Tooltip TransitionComponent={Fade}
                   key={lvl}
                   TransitionProps={{timeout: 600}}
                   classes={{popper: 'risk-popper', tooltip: 'risk-tooltip'}}
                   disableHoverListener={lvl !== pollutionRiskLvl}
                   arrow
                   placement={'bottom'}
                   title={
                     onWater ?
                       <Box>WERSJA DEMO. <br/> <br/> Ryzyko wygenerowane LOSOWO. <br/> Wymaga określenia obszaru prac i
                         parametryzacji modelu.</Box> :
                       <RiskAndWeatherTooltip/>
                   }>
            <Box sx={{
              backgroundColor: lvl === pollutionRiskLvl ? color : alpha(color, 0.2),
              width: lvl === pollutionRiskLvl ? 72 : 70,
              borderRadius: 1,
              lineHeight: 1,
              position: 'relative'
            }}>
              {lvl === pollutionRiskLvl && <Box position={'absolute'} top={-5} right={2}>
                <InfoOutlined sx={{width: 8}}/>
              </Box>}
              <Typography textTransform={'uppercase'} variant={'caption'} fontSize={10}>{label}</Typography>
            </Box>
          </Tooltip>
        )}
    </Box>
    {/*  LINKS TO DOWNLOAD PDF PNG and XLSX */}
    {pollutionRiskLvl && <Box
      pt={2}
      display={downloadSectionExpanded ? 'flex' : 'none'}
      justifyContent={'space-around'}
      alignItems={'center'}>
      <Tooltip title={t('risk.downloadXls')}>
        <a href={xlsxReportS3Link(clientId, riskMoment)}>
          <img src={XlsIcon} style={{width: 30, height: 30}}/>
        </a>
      </Tooltip>
      <Tooltip title={t('risk.downloadPdf')}>
        <a href={pdfReportS3Link(clientId, riskMoment)}>
          <img src={PdfIcon} style={{width: 30, height: 30}}/>
        </a>
      </Tooltip>
    </Box>}
    {pollutionRiskLvl && <ExpandCircleDownOutlined
      onClick={toggleExpand}
      sx={{
        // transform: 'rotate(180deg)',
        position: 'absolute', right: 0, bottom: -3, width: 18,
        animationName: downloadSectionExpanded ? 'obroc' : 'reverse',
        animationDuration: '0.5s',
        animationFillMode: 'forwards',
        '@keyframes obroc': {
          '0%': {
            transform: 'rotate(0deg)'
          },
          '100%': {
            transform: 'rotate(180deg)'
          }
        },
        '@keyframes reverse': {
          '0%': {
            transform: 'rotate(180deg)'
          },
          '100%': {
            transform: 'rotate(0deg)'
          }
        }
      }}/>}
  </Box>;
}
