import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ca from './i18n/ca.json';
import en from './i18n/en.json';
import es from './i18n/es.json';
import fr from './i18n/fr.json';
import pl from './i18n/pl.json';
import lv from './i18n/lv.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
      checkWhitelist: true
    },
    resources: {
      ca: {
        translation: ca
      },
      en: {
        translation: en
      },
      es: {
        translation: es
      },
      fr: {
        translation: fr
      },
      pl: {
        translation: pl
      },
      lv: {
        translation: lv
      },
    },
    load: 'languageOnly',
    whitelist: ['ca', 'en', 'es', 'fr', 'pl', 'lv'],
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    useSuspense: true,
  });
