import {Box, Chip, TextField} from '@mui/material';
import {useNotificationsContext} from '../../context/notifications/notificationsContext';
import React, {useRef} from 'react';
import {formatPhoneNumber, validatePhone} from '../../utils';
import {useTranslation} from 'react-i18next';

export default function ContactSMSInput() {
  const {t} = useTranslation();
  const {state: {currentConfig}, dispatch} = useNotificationsContext();
  const phoneNumbers = currentConfig.smsRecipients;

  const tagRef = useRef();

  const handleDelete = (phone) => {
    dispatch({type: 'update-currentConfig', name: 'smsRecipients', value: phoneNumbers.filter((val) => val !== phone)});
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch({type: 'update-currentConfig', name: 'smsRecipients', value: [...phoneNumbers, tagRef.current.value]});
    tagRef.current.value = '';
  };

  return <TextField
      inputRef={tagRef}
      variant="standard"
      size="small"
      sx={{margin: '1rem 0'}}
      margin="none"
      placeholder={phoneNumbers.length < 5 ? t('risk.addPhoneNo') : ''}
      inputProps={{
        style: {
          flex: 1,
          minWidth: 300,
          alignSelf: 'flex-end'
        }
      }}
      onKeyDown={event => event.key === 'Enter' && handleOnSubmit(event)}
      InputProps={{
        endAdornment: (
          <Box flex={3} marginBottom={1} width={'60vw'}  sx={{display: 'flex', flexWrap: 'wrap', gap: 2}}>
            {phoneNumbers.map((phone, index) => {
              return (
                <Tags phone={phone} key={index} onDelete={() => handleDelete(phone)}/>
              );
            })}
          </Box>
        ),
      }}
    />;
}

const Tags = ({phone, onDelete}) => {
  return (
    <Chip
      sx={{paddingTop: 2, paddingBottom: 2}}
      label={phone.includes("+48") ? "(+48) " + formatPhoneNumber(phone.slice(3).trim()): formatPhoneNumber(phone.replace("+48", "(+48) "))}
      color={validatePhone(phone.replace(" ", "")) ? 'primary' : 'error'}
      onDelete={onDelete}
    />);
};
