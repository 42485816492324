import Box from '@mui/material/Box';
import {
  AirTwoTone,
  DirectionsBoat,
  InfoOutlined,
  InvertColorsTwoTone,
  Kayaking,
  Landslide,
  PrecisionManufacturing,
  Public, PublicTwoTone,
  Rowing,
  Sailing,
  School,
  SouthAmerica,
  Support,
  ThermostatTwoTone,
  Thunderstorm,
  Tsunami,
  Visibility,
  Waves,
  WavesTwoTone
} from '@mui/icons-material';
import {Divider, Typography} from '@mui/material';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import './RiskInfoTooltip.css';
import {useTranslation} from 'react-i18next';
import PressureIcon from '../../components/icons/PressureIcon';
import {primaryColor} from '../../assets/jss/nextjs-material-dashboard';
import {RISK_COLOR} from '../../utils';
import {useRiskContext} from '../../context/risk/riskContext';


export default function WaterRiskInfoTooltip({riskLvl}) {
  const {t} = useTranslation();
  const {state: {riskAreas, riskSliderValue}} = useRiskContext();
  // const labels = t('risk.riskLevelLabels', {returnObjects: true});
  const temp = riskAreas.length && riskAreas[0].values[riskSliderValue].temperature;
  const windSpeed = riskAreas.length && riskAreas[0].values[riskSliderValue].windSpeed;
  const humidity = riskAreas.length && riskAreas[0].values[riskSliderValue].humidity;
  const pressure = riskAreas.length && riskAreas[0].values[riskSliderValue].pressure;
  //TODO:  ???? chekc rainProb
  const rainProb = riskAreas.length && riskAreas[0].values[riskSliderValue].rainProb;

  const dividerColor = RISK_COLOR[riskLvl] || 'grey';

  const titleComp = <Box>
    <Typography>
      Ocena ryzyka operacji na morzu wymaga uwzględnienia różnych parametrów związanych z planowaną operacją.
    </Typography>
    <br/>
    {/* WEATHER */}
    <Box>
      <Box float={'left'} display={'flex'} gap={1} mb={1}>
        <Typography>POGODA {' '}</Typography>
        <WavesTwoTone /> <Tsunami /> <Thunderstorm/> <AirTwoTone/> <ThermostatTwoTone/> <Visibility />
      </Box>
      <Box ml={3}>
        <Typography variant={'caption'}>Warunki pogodowe są kluczowe dla oceny ryzyka operacji na morzu.</Typography>
        <br/>
        <Typography variant={'caption'}>Należy brać pod uwagę takie czynniki jak prędkość i kierunek wiatru, wysokość fal, długość fali, temperatura wody, opady deszczu czy śniegu, widoczność.</Typography>
      </Box>
    </Box>
    <Divider sx={{my: 1}} color={dividerColor}/>

    {/* Warunki Podłoza */}

    <Box>
      <Box float={'left'} display={'flex'} gap={1} mb={1}>
        <Typography>Charakterystyka obszaru i warunki podłoża {' '}</Typography>
        <PublicTwoTone /> <Landslide />
      </Box>
      <Box ml={3}>
        <Typography variant={'caption'}>
          Charakterystyka geograficzna obszaru, w którym odbywać się będzie operacja ma kluczowe zanczenie w ocenie ryzyka.</Typography>
        <br/>
        <Typography variant={'caption'}>Typ podłoża morskiego (np. piasek, skały) oraz jego stabilność i nośność, mogą wpłynąć na bezpieczeństwo operacji.</Typography>
        <Typography variant={'caption'}>W zależności od tego, czy będzie to przybrzeżne morze, pełne morze, czy ocean, warunki pogodowe i fale mogą być zupełnie inne.</Typography>
      </Box>
    </Box>
    <Divider sx={{my: 1}} color={dividerColor}/>
    <Box>
      <Box float={'left'} display={'flex'} gap={1} mb={1}>
        <Typography>Stan techniczny jednostki{' '}</Typography>
        <DirectionsBoat /> <Sailing /> <Rowing /> <Kayaking />
      </Box>
      <Box ml={3}>
        <Typography variant={'caption'}>jej wyposażenie i zdolność do przetrwania w różnych warunkach mogą wpłynąć na ryzyko operacji.</Typography>
      </Box>
    </Box>
    <Divider sx={{my: 1}} color={dividerColor}/>
    <Box>
      <Box float={'left'} display={'flex'} gap={1} mb={1}>
        <Typography>Charakterystyka operacji {' '}</Typography>
        <PrecisionManufacturing />
      </Box>
      <Box ml={3}>
        <Typography variant={'caption'}>rodzaj operacji, czas trwania, intensywność pracy, podejmowane działania, może wpłynąć na ocenę ryzyka.</Typography>
      </Box>
    </Box>
    <Divider sx={{my: 1}} color={dividerColor}/>

    <Box>
      <Box float={'left'} display={'flex'} gap={1} mb={1}>
        <Typography>Kwalifikacje i doświadczenie personelu{' '}</Typography>
        <School />
      </Box>
      <Box ml={3}>
        <Typography variant={'caption'}>Kwalifikacje i doświadczenie personelu wykonującego operację, ich zdolność do radzenia sobie z nieprzewidzianymi sytuacjami, wpływają znacząco na bezpieczeństwo.</Typography>
      </Box>
    </Box>
    <Divider sx={{my: 1}} color={dividerColor}/>
    <Box>
      <Box float={'left'} display={'flex'} gap={1} mb={1}>
        <Typography>Zasoby ratunkowe {' '}</Typography>
        <Support />
      </Box>
      <Box ml={3}>
        <Typography variant={'caption'}>dostępność i stan sprzętu ratunkowego, procedury ewakuacji, a także zdolność personelu do wykonania działań ratowniczych, obniża ryzyko.</Typography>
      </Box>
    </Box>
  </Box>;

  return <Tooltip TransitionComponent={Fade}
                  classes={{popper: 'risk-popper', tooltip: 'risk-tooltip'}}
                  TransitionProps={{timeout: 600}}
                  placement={'right'}
                  title={titleComp}>
    <InfoOutlined sx={{width: 18}}/>
  </Tooltip>;
}
