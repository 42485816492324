import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Auth} from 'aws-amplify';
import {useConfig} from 'context/clientConfig/clientConfigContext';
import {getClientConfig} from '../api';
import {useMeasurementsContext} from '../context/measurements/measurementsContext';

const Authentication = ({children}) => {
  const [isCurrentAuthenticationChecked, setCurrentAuthenticationChecked] = useState(false);
  const {dispatch, state} = useConfig();
  const {dispatch: measurementsDispatch} = useMeasurementsContext();

  useEffect( () => {
    Auth.currentSession().then(
      async () => {
        const user = await Auth.currentAuthenticatedUser();
        if (state?.email !== user.attributes.email) {
          const email = user.attributes.email;
          const clientId = email.includes("@seadata.pl") ? "zpg" : user.attributes['custom:clientId'];
          const roles = user.attributes['custom:role'];
          //  TEST DEVICES???? co to jest
          getClientConfig(clientId, roles).then(result => {
            if (clientId === "zpg" && (email?.includes('@seadata.pl') || email?.includes('camdrone') || email?.includes('mateuszblazejak'))) {
              result.devices = [...result.testDevices, ...result.devices];
            }
            if (email === 'rafal.gluszewski@ug.edu.pl') {
              result.devices = result.devices.filter(d => d.serialNo === 'UGW_WATER_BORUCINO');
              if (result?.modules?.SURVEYS) {
                delete result.modules.SURVEYS
              }
            }
            if (email === 'michalina.bielawska@armaag.gda.pl') {
              result.devices = result.devices.filter(d => !d.externalProvider);
              if (result?.modules?.WATER) {
                delete result.modules.WATER
              }
            }
            if (clientId === 'ugw') {
              measurementsDispatch({type: 'set-selectedDate', selectedDate: new Date(2024, 8, 1, 10, 45,0,0)});
              if (email.includes("@seadata.pl")) {
                result.devices = result.devices.filter(d => d.serialNo.includes("E_NOSE_"));
                result.modules.AIR = 1;
              }
            }
            dispatch({type: 'set-user', email, clientId, config: result});
            setCurrentAuthenticationChecked(true);
          }).catch(error => {
            console.log('error getting config in', {error});
          });
        }
      }
    )
      .catch((e) => {
        console.log('error', e);
        setCurrentAuthenticationChecked(true);
      });

  }, []);

  return isCurrentAuthenticationChecked ? children : <> </>;
};

Authentication.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Authentication;
