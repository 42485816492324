import React, {useState} from 'react';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import {LocationOn} from '@mui/icons-material';
import moment from 'moment';
import {grayColor, primaryColor} from '../../assets/jss/nextjs-material-dashboard';

export default function MaintenancePhoto({photoObj, idx}) {
  const {createdBy, lat, lon, timestamp, url} = photoObj;
  const [expanded, setExpanded] = useState(false);

  const togglePhoto = () => setExpanded(!expanded);

  return <Box display={'flex'} flexDirection={'column'}>
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      onClick={togglePhoto}
      sx={{
        position: 'relative',
        width: expanded ? '80%' : 100,
        height: expanded ? '80%' : 100,
        cursor: expanded ? 'zoom-out' : 'pointer',
        overflow: expanded ? 'visible' : 'hidden',
      }}>

      <Typography variant={'caption'}
                  sx={{marginLeft: 1, color: grayColor[1]}}>{idx === 0 ? 'Przed' : 'Po'}</Typography>

      <a target={'_blank'} href={`http://www.google.com/maps/place/${lat},${lon}`}>
        <LocationOn sx={{color: primaryColor[2], right: 0, top: -2, position: 'absolute'}}/>
      </a>
      <img alt={'device Photo'}
           style={{
             height: '100%',
             width: '100%',
             borderRadius: 5,
             border: ' 1px solid whitesmoke',
           }}
           src={url}
      />

    </Box>
    <Typography sx={{color: grayColor[0]}} variant={'caption'}>
      {moment(timestamp).format('lll')}
    </Typography>
    <Typography sx={{color: primaryColor[2]}} variant={'caption'}>
      {createdBy}
    </Typography>
  </Box>;
};
