import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MapGL, {Layer, Marker, Source} from '@urbica/react-map-gl';
import {INITIAL_MAPSTYLE, MAPBOX_TOKEN} from 'config';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {useMeasurementsContext} from '../../context/measurements/measurementsContext';
import {isHighLevel, waterLevelStations, waterStations} from './waterStations';
import {getWaterLevelTrends} from '../../api';
import WaterStationInfoPopup from './WaterStationInfoPopup';
import WaterStationModal from './WaterStationModal';
import WaterLevelModal from './WaterLevelModal';


export default function OpenDataMap() {
  const {config} = useConfig().state;
  const {clientId, modules} = config ? config : {};
  const {state: {selectedDate}, dispatch: measurementsDispatch} = useMeasurementsContext();
  const [mapStyleId, setMapStyleId] = useState(INITIAL_MAPSTYLE);
  const [trends, setTrends] = useState(null);
  const [hoveredStation, setHoveredStation] = useState(null);
  const [hoveredWaterLevelStation, setHoveredWaterLevelStation] = useState(null);
  const [clickedStation, setClickedStation] = useState(null);
  const [clickedWaterLevelStation, setClickedWaterLevelStation] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [viewport, setViewport] = useState({
    latitude: config && config.uiSettings && config.uiSettings.mapCenter[1],
    longitude: config && config.uiSettings && config.uiSettings.mapCenter[0] + 0.05,
    zoom: config && config.uiSettings && config.uiSettings.mapZoom - 1
  });

  useEffect(() => {
    setViewport({
      latitude: config && config.uiSettings && config.uiSettings.mapCenter[1],
      longitude: config && config.uiSettings && config.uiSettings.mapCenter[0] + 0.05,
      zoom: config && config.uiSettings && config.uiSettings.mapZoom - 1
    });
  }, [config]);

  useEffect(() => [...waterLevelStations[clientId], ...waterStations[clientId]].length && getWaterLevelTrends([...waterLevelStations[clientId], ...waterStations[clientId]].map(s => s.trendsId))
      .then(result => setTrends(result))
      .catch(err => setError(err.response.data))
    , [clientId]);

  const onWaterLayerEnter = station => (e) => {
    e.target.setPaintProperty(e.features[0].layer.id, 'fill-opacity', 0.4);
    e.target.setPaintProperty(e.features[0].layer.id.replace('-layer', '-line'), 'line-width', 3);
    setHoveredWaterLevelStation(station);
  };
  const onWaterLayerLeave = (e) => {
    setHoveredWaterLevelStation(null);
    e.target.setPaintProperty(e.features[0].layer.id, 'fill-opacity', 0.3);
    e.target.setPaintProperty(e.features[0].layer.id.replace('-layer', '-line'), 'line-width', 1);
  };

  const onWaterLayerClick = (e) => setClickedWaterLevelStation(e.features[0].layer);

  const waterLevelSources = waterLevelStations[clientId] ? waterLevelStations[clientId].map(station =>
    <Source
      key={station.serialNo + '-source'}
      id={station.serialNo + '-source'}
      type={'geojson'}
      data={{
        'type': 'FeatureCollection',
        'features': [
          {
            'type': 'Feature',
            'geometry': station.geometry
          }
        ]
      }}/>) : [];

  const waterLevelLayers = waterLevelStations[clientId] ? waterLevelStations[clientId].flatMap(station =>
    [<Layer
      key={station.serialNo + '-layer'}
      id={station.serialNo + '-layer'}
      type="fill"
      source={station.serialNo + '-source'}
      paint={{
        'fill-color': trends ? (isHighLevel(trends[station.trendsId].water.last, station) ? 'green' : 'red') : 'blue',
        'fill-opacity': 0.3,
      }}
      onEnter={onWaterLayerEnter(station)}
      onLeave={onWaterLayerLeave}
      onClick={onWaterLayerClick}
      radius={10}/>,
      <Layer
        key={station.serialNo + '-line'}
        id={station.serialNo + '-line'}
        type="line"
        source={station.serialNo + '-source'}
        paint={{
          'line-color': trends ? (isHighLevel(trends[station.trendsId].water.last, station) ? 'green' : 'red') : 'blue',
          'line-width': 1,
        }}
        radius={0}/>
    ]) : [];

  const waterLevelStationsMarkers = (trends && waterLevelStations[clientId]) ? waterLevelStations[clientId].map(station => {
    const waterLvl = trends[station.trendsId].water.last;
      const isHigh = isHighLevel(waterLvl, station);
      const fontSize = Math.min(44, Math.max(20, 14 + (viewport.zoom - 13) * 15));
      return <Marker
        key={station.serialNo}
        longitude={station.location.lng}
        latitude={station.location.lat}
        anchor="top-left"
        offset={[-11, -20]}
      >
        <Typography variant={'subtitle2'} fontWeight={'bold'} fontSize={fontSize} color={isHigh ? 'green' : 'red'}>
          {waterLvl} m {isHigh ? '↑' : '↓'}
        </Typography>
      </Marker>;
    }
  ) : [];

  const waterStationsMarkers = (waterStations[clientId] || []).map(s => <Marker
    key={s.serialNo}
    longitude={s.location.lng}
    latitude={s.location.lat}
    anchor="top-left"
    offset={[-11, -11]}
  >
    <Box
      onMouseEnter={e=> setHoveredStation(s)}
      onMouseLeave={e=> setHoveredStation(null)}
      onClick={() => setClickedStation(s)}
    >
      <img src={'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'}/>
    </Box>
  </Marker>);

  console.log({hoveredStation, trends});

  return <>
    {error && <Box p={2} sx={{
      position: 'absolute',
      top: '10%',
      left: '40%',
      zIndex: 1400,
      backgroundColor: 'rgba(255,255,255, .5)'
    }}>
      <Typography color={'error'} variant={'subtitle2'}>{error}</Typography>
    </Box>}
    {loading && <Box sx={{position: 'absolute', top: '40%', left: '40%', zIndex: 1400}}>
      <CircularProgress variant={'indeterminate'} size={200}/>
    </Box>}
    <Box sx={{
      height: 'calc(100vh - 88px)',
      '@media screen and (max-width: 50em)': {
        height: 'calc(100vh - 60px)'
      }
    }}>
      <MapGL
        style={{height: '100%'}}
        {...viewport}
        mapStyle={mapStyleId}
        onViewportChange={setViewport}
        accessToken={MAPBOX_TOKEN}
      >
        {waterLevelSources}
        {waterLevelLayers}
        {waterLevelStationsMarkers}
        {waterStationsMarkers}
        {hoveredStation && <WaterStationInfoPopup waterStation={hoveredStation} trends={trends && trends[hoveredStation.trendsId]} />}
        {hoveredWaterLevelStation && <WaterStationInfoPopup waterStation={hoveredWaterLevelStation} trends={trends && trends[hoveredWaterLevelStation.trendsId]} />}

        {clickedStation && <WaterStationModal waterStation={clickedStation} onClose={() => setClickedStation(null)}/>}
        {clickedWaterLevelStation && <WaterLevelModal stationNo={clickedWaterLevelStation.id.split("-")[0]} onClose={() => setClickedWaterLevelStation(null)}/>}
      </MapGL>

    </Box>
  </>;
}
