import React, {useMemo, useReducer} from 'react';
import moment from 'moment';

const initialState = () => {
  return {
    editMode: false,
    dockId: null,
    start: new Date().getTime(),
    end: new Date().getTime(),
    vessel: {
      mmsi: '',
      imo: '',
      name: '',
      flag: 'PL',
      type: '',
      imgId: null,
      mtUrl: null,
      dwt: null
    },
    files: [],
    notes: '',
    loadingInfo: {
      agents: [null],
      cargoTypes: [null]
    },
    unloadingInfo: {
      agents: [null],
      cargoTypes: [null]
    }
    // dockEvent : {
    // {
    // loadingInfo: {
    //  agent: "Agent Name"],
    //  cargoTypes: [
    //    {cargoName, amount, unit, description}
    //  ]
    // },
    // unloadingInfo: {...},
    // files: [],
    // notes: ""
    // }
  };
};

const DockEventContext = React.createContext(initialState());
// expose the context via a custom hook.
export const useDockEventContext = () => React.useContext(DockEventContext);

function reducer(state, action) {
  switch (action.type) {
  case 'open-event-dialog':
    return {
      ...state,
      ...action.event,
    };
  case 'close-event-dialog':
    return {
      ...initialState(),
      dockId: state.dockId
    };
  case 'add-cargo-agent':
    return {
      ...state,
      loadingInfo: action.unloading ? state.loadingInfo : {
        ...state.loadingInfo,
        agents: [...state.loadingInfo.agents, null]
      },
      unloadingInfo: action.unloading ? {
        ...state.unloadingInfo,
        agents: [...state.unloadingInfo.agents, null]
      } : state.unloadingInfo,
    };
  case 'remove-cargo-agent':
    if (action.unloading) {
      return {
        ...state,
        unloadingInfo: {
          ...state.unloadingInfo,
          agents: state.unloadingInfo.agents.filter((_val, idx) => idx != action.idx)
        }
      };
    } else {
      return {
        ...state,
        loadingInfo: {
          ...state.loadingInfo,
          agents: state.loadingInfo.agents.filter((_val, idx) => idx != action.idx)
        }
      };
    }
  case 'update-cargo-agent':
    if (action.unloading) {
      state.unloadingInfo.agents[action.idx] = action.agent;
      const updatedCargoAgents = state.unloadingInfo.agents;
      return {
        ...state,
        unloadingInfo: {
          ...state.unloadingInfo,
          agents: updatedCargoAgents
        }
      };
    } else {
      state.loadingInfo.agents[action.idx] = action.agent;
      const updatedCargoAgents = state.loadingInfo.agents;
      return {
        ...state,
        loadingInfo: {
          ...state.loadingInfo,
          agents: updatedCargoAgents
        }
      };
    }
  case 'add-cargo-type':
    return {
      ...state,
      loadingInfo: action.unloading ? state.loadingInfo : {
        ...state.loadingInfo,
        cargoTypes: [...state.loadingInfo.cargoTypes, null]
      },
      unloadingInfo: action.unloading ? {
        ...state.unloadingInfo,
        cargoTypes: [...state.unloadingInfo.cargoTypes, null]
      } : state.unloadingInfo,
    };
  case 'remove-cargo-type':
    if (action.unloading) {
      return {
        ...state,
        unloadingInfo: {
          ...state.unloadingInfo,
          cargoTypes: state.unloadingInfo.cargoTypes.filter((_val, idx) => idx != action.idx)
        }
      };
    } else {
      return {
        ...state,
        loadingInfo: {
          ...state.loadingInfo,
          cargoTypes: state.loadingInfo.cargoTypes.filter((_val, idx) => idx != action.idx)
        }
      };
    }
  case 'update-cargo-type':
    if (action.unloading) {
      state.unloadingInfo.cargoTypes[action.idx] = {...state.unloadingInfo.cargoTypes[action.idx], ...action.cargoType};
      const updatedCargoTypes = state.unloadingInfo.cargoTypes;
      return {
        ...state,
        unloadingInfo: {
          ...state.unloadingInfo,
          cargoTypes: updatedCargoTypes
        }
      };
    } else {
      state.loadingInfo.cargoTypes[action.idx] = {...state.loadingInfo.cargoTypes[action.idx], ...action.cargoType};
      const updatedCargoTypes = state.loadingInfo.cargoTypes;
      return {
        ...state,
        loadingInfo: {
          ...state.loadingInfo,
          cargoTypes: updatedCargoTypes
        }
      };
    }
  case 'reset-cargo-type' :
    return action.unloading ?
      {
        ...state,
        unloadingInfo: initialState().unloadingInfo
      } : {
        ...state,
        loadingInfo: initialState().loadingInfo
      };
  case 'update-dock-event':
    return {
      ...state,
      ...action.event
    };
    case 'delete-dock-event-file':
      return {
        ...state,
        files: state.files.filter(f => f.s3Key !== action.s3Key)
      };
  case 'update-vessel-data':
    return {
      ...state,
      vessel: {
        ...state.vessel,
        ...action.vessel
      }
    };
  case 'clear-vessel-data':
    return {
      ...state,
      vessel: {
        mmsi: '',
        imo: '',
        name: '',
        flag: 'PL',
        type: '',
        imgId: null,
        mtUrl: null,
        dwt: null
      },
    };
  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function DockEventContextProvider(props) {

  const [state, dispatch] = useReducer(reducer, initialState());
  const context = useMemo(() => {
    return {
      state,
      dispatch
    };
  }, [state]);

  return <DockEventContext.Provider value={context}>
    {props.children}
  </DockEventContext.Provider>;
}
