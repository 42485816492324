import {
  Card,
  CardContent,
  Drawer,
  FormControlLabel,
  Table,
  TableContainer,
  TableFooter,
  TablePagination,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import React, {useEffect, useMemo, useState} from 'react';
import {
  dangerColor,
  grayColor,
  MOBILE_SCREEN,
  primaryColor,
  successColor,
  TABLET_SCREEN,
  warningColor,
  whiteColor
} from '../../assets/jss/nextjs-material-dashboard';
import {getBerthCalls} from '../../api';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {getComparator, human, stableSort, TablePaginationActions, TCell, THeader, TypeIcon} from './PortCalls';
import {useTranslation} from 'react-i18next';
import TableSortLabel from '@mui/material/TableSortLabel';
import {visuallyHidden} from '@mui/utils';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import DatePicker from "react-datepicker";
import {Replay} from "@mui/icons-material";

const moreActionStyle = {
  cursor: "pointer",
  position: 'fixed',
  right: '3%',
  bottom: '2%',
  width: "40px",
  height: "40px",
  boxShadow: "0 5px 20px rgba(0, 0, 0, .50)",
  backgroundColor: "transparent",
  borderRadius: "50%",
  padding: "5px",
  border: "2px solid #fb8c00",
  zIndex: 1000,
  color: "#007dc1"
}

const ALL_COLUMNS = ['draught_val', 'undock_time_val', 'commercial_market', 'time_at_port_val', 'dock_time_val'];

export default function BerthCallsMobile() {
  const {t, i18n} = useTranslation();
  const {clientId} = useConfig().state.config;
  const [startDate, setStartDate] = useState(moment().subtract(30, 'day').toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [berthCalls, setBerthCalls] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('dock_time_val');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [openDrawer, setOpenDrawer] = useState(false);

  const [columns, setColumns] = useState( ['name']);

  function handleChange(event) {
    event.target.checked ? setColumns([...columns, event.target.name]) : setColumns(columns.filter(id => id !== event.target.name));
  }


  function toggleDrawer() {
    setOpenDrawer(!openDrawer);
  }

  useEffect(() => {
    setLoading(true);
    // console.log("FETCH PORT CALLS for:", moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
    getBerthCalls(clientId, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
      .then(result => setBerthCalls(result))
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, [clientId, startDate, endDate]);

  const onDateRangeChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const rows = useMemo(() => berthCalls.map((pc, idx) => ({
    id: pc.recordId,
    lp: idx + 1,
    name: pc.vessel_name,
    type: TypeIcon(pc.vessel_type, t),
    url: `https://www.marinetraffic.com/pl/ais/details/ships/shipid:0/mmsi:${pc.mmsi}/imo:0`,
    dock_time: pc.dock_timestamp ? new Date(pc.dock_timestamp).toLocaleString() : '-',
    dock_time_val: pc.dock_timestamp,
    undock_time: pc.undock_timestamp ? new Date(pc.undock_timestamp).toLocaleString() : '-',
    undock_time_val: pc.undock_timestamp,
    draught: pc.draught.toFixed(1) + 'm',
    draught_val: pc.draught,
    time_at_port: human(pc.time_at_port),
    time_at_port_val: pc.time_at_port,
    time_at_berth: human(pc.time_at_berth),
    // operation: pc.port_operation,
    commercial_market: t('berthCalls.commercialMarkets', {returnObjects: true})[pc.commercial_market] || pc.commercial_market,
  })), [berthCalls]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const resetAll = () => {
    setPage(0);
    setRowsPerPage(10);
    setOrder('desc');
    setOrderBy('dock_time_val');
    setStartDate(moment().subtract(30, 'day').toDate());
    setEndDate(new Date());
  };

  return <>
    <Tooltip title={"Filter"}><SettingsOutlinedIcon style={moreActionStyle} onClick={toggleDrawer}/></Tooltip>
    <Card variant={'outlined'} sx={{minHeight: '100%'}}>
      <Box mt={1} p={2} display={'flex'}>
        <DatePicker
          locale={i18n.resolvedLanguage}
          maxDate={new Date()}
          minDate={new Date(2022, 4, 1)}
          selectsRange
          monthsShown={2}
          startDate={startDate}
          endDate={endDate}
          onChange={onDateRangeChange}
        />
        <Tooltip title={t('portCalls.resetAll')}>
          <Button startIcon={<Replay/>} onClick={resetAll} color={'info'}/>
        </Tooltip>
      </Box>
      <Typography sx={{
        color: primaryColor[2],
        textAlign: 'center',
        fontSize: '1.25rem',
        paddingTop: '1em',
      }}>{t('berthCalls.berthCalls')}
      </Typography>

      <CardContent>
        <Box display="flex">
          {loading ? <CircularProgress size={100} sx={{margin: 'auto', marginTop: 10}}/> :
            <TableContainer>
              <Table size={'medium'} aria-label="reports table">
                <TableHead>
                  <TableRow sx={{backgroundColor: 'whitesmoke'}}>
                    <TableCell width={20}><THeader>Lp</THeader></TableCell>
                    {[{id: 'name', label: 'vessel'},
                      {id: 'dock_time_val', label: 'dockTime'},
                      {id: 'undock_time_val', label: 'undockTime'},
                      {id: 'time_at_port_val', label: 'timeAtPort'},
                      {id: 'draught_val', label: 'draught'},
                      // {id: 'portOperation', label: 'portOperation'},
                      {id: "commercial_market", label: 'commercialMarket'}]
                      .filter(({id}) => columns.includes(id))
                      .map(({id, label}) =>
                        <TableCell key={id} sortDirection={orderBy === id ? order : false}
                                   align="center">
                          <TableSortLabel
                            active={orderBy === id}
                            direction={orderBy === id ? order : 'asc'}
                            onClick={event => handleRequestSort(event, id)}
                          >
                            <Typography sx={{color: primaryColor[2]}}>
                              {t('berthCalls.' + label)}
                            </Typography>
                            {orderBy === id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </Box>
                            ) : null}
                          </TableSortLabel>

                          {/*<THeader>{t('portCalls.' + cellId)}</THeader>*/}

                        </TableCell>
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                      ? stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : stableSort(rows, getComparator(order, orderBy))
                  ).map(({
                           id,
                           lp,
                           dock_time,
                           undock_time,
                           url,
                           name,
                           type,
                           time_at_port,
                           // time_at_berth,
                           // operation,
                           draught,
                           commercial_market
                         }) => (
                    <TableRow
                      key={id}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                      <TableCell width={20} align={'center'}><TCell>{lp}</TCell></TableCell>
                      <TableCell align="center"><TCell>
                        <Box display={'flex'} gap={1}>
                          <Box>{type}</Box>
                          <a target={'_blank'} style={{color: primaryColor[2]}}
                             href={url}>{name}</a>
                        </Box>
                      </TCell></TableCell>
                      {columns.includes('dock_time_val') &&
                      <TableCell align="center"><TCell>{dock_time}</TCell></TableCell>}
                      {columns.includes('undock_time_val') &&
                      <TableCell align="center"><TCell>{undock_time}</TCell></TableCell>}
                      {columns.includes('time_at_port_val') &&
                      <TableCell align="center"><TCell>{time_at_port}</TCell></TableCell>}
                      {columns.includes('draught_val') &&
                      <TableCell align="center"><TCell>{draught}</TCell></TableCell>}
                      {/*{columns.includes('portOperation') &&*/}
                      {/*<TableCell align="center"><TCell>{operation}</TCell></TableCell>}*/}
                      {columns.includes("commercial_market") &&
                      <TableCell align="center">{commercial_market}</TableCell>}
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={{border: "none"}}
                      translate={'yes'}
                      rowsPerPageOptions={[5, 10, 25, 50, {label: t("general.all"), value: -1}]}
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage={t("general.rowsPerPage")}
                      labelDisplayedRows={({
                                             from,
                                             to,
                                             count
                                           }) => `${from}–${to} z ${count !== -1 ? count : `${t("general.moreThan")}${to}`}`}
                      // labelDisplayedRows={({ from, to, count }) =>`${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          }
        </Box>
      </CardContent>
    </Card>

    <Drawer
      open={openDrawer}
      anchor={'right'}
      onClose={toggleDrawer}
      hideBackdrop={true}
    >
      <Box sx={{
        textAlign: "center",
        paddingTop: "1em", color: whiteColor,
        background: 'linear-gradient(140deg, #192231,#007dc1)',
        height: '60px'
      }}>
        <Typography variant={"h6"}>{t("general.selectColumns")}</Typography>
      </Box>
      {[
        {id: 'dock_time_val', label: t("berthCalls.dockTime")},
        {id: 'undock_time_val', label: t("berthCalls.undockTime")},
        {id: 'time_at_port_val', label: t("berthCalls.timeAtPort")},
        {id: 'draught_val', label: t("berthCalls.draught")},
        // {id: 'portOperation', label: t("berthCalls.portOperation")},
        {id: 'commercial_market', label: t("berthCalls.commercialMarket")},
      ].map(({id, label}) =>
        <FormControlLabel
          sx={{color: grayColor[7], marginTop: "1em", paddingTop: "2em", marginLeft: "1em", fontSize: "3px"}}
          key={id}
          name={id}
          label={label}
          control={<Checkbox size="small" sx={{
            color: "#000000",
            '&.Mui-checked': {
              color: warningColor[2],
            },
          }} checked={columns.includes(id)} onChange={handleChange}/>}
        />)}
      <Button variant={"contained"} color={"success"} sx={{
        position: "absolute", bottom: "3%", right: "20%"
      }}
              onClick={toggleDrawer}>{t("general.confirm")}</Button>
    </Drawer>
  </>
}

