import {Popup} from '@urbica/react-map-gl';
import {Box, Table} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import {MeasurementsUnits as U} from '../../components/measurementsUtil';

export default function WaterStationInfoPopup({waterStation, trends}) {
  console.log({trends});

  const {t} = useTranslation();
  const {name, location, serialNo} = waterStation;
  const tableRows = [];
  const tr = t('openData.MEASUREMENTS', {returnObjects: true});
  const loading = !trends;

  if (!loading) {
    if (waterStation.rain) tableRows.push({unit: "mm", color: infoColor, name: tr.rain, values: fmtRainValue('rain', trends)});
    if (waterStation.water) tableRows.push({unit: "m", name: tr.water, values: fmtValue('water', trends)});
    if (waterStation.winddir) tableRows.push({name: tr.winddir, values: fmtValue('winddir', trends)});
    if (waterStation.windlevel) tableRows.push({unit: "m/s", color: warningColor, name: tr.windlevel, values: fmtValue('windlevel', trends)});
    if (waterStation.humidity) tableRows.push({unit: "%", name: tr.humidity, values: fmtValue('humidity', trends)});
    if (waterStation.temp) tableRows.push({unit: U.temp, color: dangerColor, name: tr.temp, values: fmtValue('temp', trends)});
    if (waterStation.pressure) tableRows.push({unit: U.pressure, name: tr.pressure, values: fmtValue('pressure', trends)});
  }

  return <Popup
    latitude={location.lat}
    longitude={location.lng}
    anchor={'bottom'}
    offset={[-15, -60]}
    closeButton={false}
    closeOnClick={false}
    maxWidth={'800px'}
  >
    <Box width={410} pb={2}>
      <Typography variant={'h5'} fontWeight={'bold'}>{name}</Typography>
      {loading ? <Box pt={2} sx={{textAlign: 'center'}}>
        <CircularProgress color={'info'} variant={'indeterminate'} size={180}/>
      </Box> : <>

        <p>{t('openData.lastMeasurement')} {trends.last || '-'}</p>
        <Table size="small" aria-label="measurements trends">
          <TableHead>
            <TableRow>
              <TableCell size={'small'} padding="none"> </TableCell>
              <TableCell sx={{color: 'whitesmoke', fontWeight: 'bold'}} size={'small'}
                         padding="none">{t.last}</TableCell>
              <TableCell sx={{color: 'whitesmoke', fontWeight: 'bold'}} size={'small'} padding="none">30 min</TableCell>
              <TableCell sx={{color: 'whitesmoke', fontWeight: 'bold'}} size={'small'} padding="none">60 min</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.map((row) => (
              <TableRow key={row.name} style={{backgroundColor: row.color}}>
                <TableCell style={{opacity: 1.0, fontWeight: 'bold', color: 'white'}} size={'small'}>
                  {row.name} {row.unit ? `[${row.unit}]` : ''}
                </TableCell>
                <TableCell sx={{color: 'whitesmoke'}} size={'small'}>{row.values[0]}</TableCell>
                <TableCell sx={{color: 'whitesmoke'}} size={'small'}>{row.values[1]}</TableCell>
                <TableCell sx={{color: 'whitesmoke'}} size={'small'}>{row.values[2]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </>}

    </Box>
  </Popup>;
}

const warningColor = 'rgba(255, 152, 0, 0.5)';
const dangerColor = 'rgba(244, 67, 54, 0.5)';
const infoColor = 'rgba(0, 172, 193, 0.5)';


const EMPTY = ['-', '-', '-'];

const fmtRainValue = (value, trends) => {
  return trends ? trends[value] ? [trends[value].last, trends[value].sum30, trends[value].sum60] || EMPTY : EMPTY : EMPTY;
};

const fmtValue = (value, trends) => {
  return trends ? trends[value] ? [trends[value].last, trends[value].avg30, trends[value].avg60] || EMPTY : EMPTY : EMPTY;
};
