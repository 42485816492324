import React, {useEffect, useState} from 'react';
import {IconButton, TextField, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useDockEventContext} from '../../../context/dockEvent/dockEventContext';
import Box from '@mui/material/Box';
import {vesselImg} from '../../MarineTraffic/util';
import Flag from 'react-world-flags';
import {TypeIcon} from '../PortCalls';
import {HighlightOff} from '@mui/icons-material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import {getVesselImg} from '../../../api';

export default function VesselInfo() {
  const {t} = useTranslation();
  const [altVesselImg, setAltVesselImg] = useState(null);
  const {state: dockEvent, dispatch} = useDockEventContext();
  const {vessel} = dockEvent;
  const {mmsi, name, flag, type, imgId, notes} = vessel;

  useEffect(() => !imgId && getVesselImg(mmsi, null)
    .then(imgId => setAltVesselImg(imgId)), [imgId]);

  return <Card sx={{elevation: 0, boxShadow: 'none'}}>
    <CardHeader
      avatar={<Box mt={-3}>
        <Flag style={{border: '1px solid #ADA9B4'}}
              width={35}
              height={25}
              code={flag}/>
      </Box>}
      title={<Typography color={'primary'} variant={'h6'}>{name}</Typography>}
      subheader={<Box ml={-6.5} sx={{display: 'flex', gap: 2, justifyContent: 'flex-start'}}>
        <Box>
          <Typography sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1}}
                      variant={'body2'}> {TypeIcon(type, t)}{t(`vessels.shipType`, {returnObjects: true})[type.toLowerCase()] || type.toLowerCase()}</Typography>
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
          <Typography sx={{display: 'flex', gap: 1}}
                      variant={'body2'}><b>MMSI:</b> <Typography variant={'body2'}>{mmsi}</Typography> </Typography>
        </Box>
      </Box>}
      action={<IconButton
        color={'error'}
        sx={{marginTop: -2}}
        onClick={() => dispatch({type: 'clear-vessel-data'})}>
        <HighlightOff/>
      </IconButton>}
    />
    <CardContent sx={{display: 'flex', gap: 4}}>
      <Box minWidth={400} sx={{display: 'flex', alignItems: 'flex-end'}}>
        <TextField
          fullWidth
          id="additional-notes"
          label={t('dockEventDialog.additionalNotes')}
          multiline
          rows={4}
          value={notes}
          onChange={evt => dispatch({type: 'update-dock-event', notes: evt.target.value})}
        />
      </Box>
      <Box minWidth={200}>
        <img src={vesselImg(imgId || altVesselImg)} alt="Vessel image"
             style={{
               marginRight: 20,
               borderRadius: '0.25em',
               maxHeight: 200,
               objectFit: 'contain',
               border: '1px solid #ADA9B4',
             }}/>
      </Box>

    </CardContent>
  </Card>;
}
