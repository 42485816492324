import React, {useEffect, useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import moment from 'moment';
import {ReactComponent as ICMIcon} from 'assets/svg/ICM-logo-black.svg';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import {useTranslation} from 'react-i18next';

const BASE_URL = 'https://meteograms.s3.eu-central-1.amazonaws.com';
const METEO_PL_BASE_URL = 'https://www.meteo.pl/um/metco/mgram_pict.php?ntype=0u&';
const CUT_OFF_DATE_FROM = moment('2023-05-01');
const INTERVAL_SECONDS = 2;
const METEO_HOURS_GENERATION = [0, 6, 12, 18];

const showLabel = (idx, sliderLength) => {
  const days = sliderLength / 4;
  if (days <= 7) {
    if (days <= 1) {
      return true;
    } else {
      return !(idx % 4);
    }
  } else {
    return !(idx % Math.round(sliderLength / 8));
  }
};

export default function MeteoPLSlider({dateFrom, dateTo, row, col, lang}) {
  const {t, i18n} = useTranslation();
  moment.locale(i18n.language);
  const mgramLang = lang === 'pl' ? 'pl' : 'en'; // only two supported: pl,en
  const [mgramUrl, setMgramUrl] = useState(moment().diff(moment(dateFrom), 'days') > 2 ?
    `${BASE_URL}/${row}_${col}/${dateFrom.format('YYYYMMDD00')}.jpg` : `${METEO_PL_BASE_URL}fdate=${dateFrom.format('YYYYMMDD00')}&row=${row}&col=${col}&lang=${mgramLang}`);
  const [isPlaying, setPlaying] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);

  const marks = useMemo(() => generateDatesBetween(moment.max(dateFrom, CUT_OFF_DATE_FROM), dateTo.subtract(1, 'day'))
    .flatMap(date => METEO_HOURS_GENERATION.map(hh => moment(date.hour(hh))))
    .map((date, idx, array) => ({
      date,
      value: idx,
      tooltipLabel: <Typography variant={'caption'}>{date.format('YYYY-MM-DD HH:00')}</Typography>,
      label: showLabel(idx, array.length) ? <Typography
        variant={'caption'}>{date.format(array.length === 4 ? 'Do MMM HH:00' : 'Do MMM')}</Typography> : null
    })), [dateFrom, dateTo]);


  const onSliderChange = value => {
    const dateStr = marks[value].date.format('YYYYMMDDHH');
    const url = moment().diff(moment(marks[value].date), 'days') > 2 ?
      `${BASE_URL}/${row}_${col}/${dateStr}.jpg` : `${METEO_PL_BASE_URL}fdate=${dateStr}&row=${row}&col=${col}&lang=${mgramLang}`;
    setMgramUrl(url);
  };

  function valueLabelFormat(value) {
    return marks[value].tooltipLabel;
  }

  const play = () => {
    setSliderValue(0);
    // setIconText(t('customSlider.pause'));
    setPlaying(true);
  };

  const pause = () => {
    // setIconText(t('customSlider.play'));
    setPlaying(false);
  };

  useEffect(() => {
    if (isPlaying && sliderValue < marks.length - 1) {
      setTimeout(() => {
        onSliderChange(sliderValue + 1);
        setSliderValue(sliderValue + 1);
      }, 1000 * INTERVAL_SECONDS);
    } else {
      pause();
    }
  }, [sliderValue, isPlaying]);

  return <Box mt={5} textAlign={'center'} width={'100%'}>
    {/*  ICM SLIDER */}
    <Box display={'flex'} gap={10}>
      <ICMIcon width={100}/>
      <Box mb={5} width={'100%'} justifyContent={'flex-start'} display={'flex'} gap={5}>
        <Box sx={{
          cursor: 'pointer',
          width: 40, height: 40,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          backgroundColor: 'rgb(52,99,132)',
          borderRadius: '50%'
        }}
             onClick={isPlaying ? pause : play}>
          {isPlaying ? <PauseIcon p={0} m={0}/> : <PlayArrowRoundedIcon p={0} m={0}/>}
        </Box>
        <Slider
          sx={{width: '80%'}}
          value={sliderValue}
          valueLabelFormat={valueLabelFormat}
          valueLabelDisplay={isPlaying ? 'on' : 'auto'}
          aria-label={'Meteo Slider'}
          onChange={(event, newValue) => setSliderValue(newValue)}
          onChangeCommitted={(e, val) => onSliderChange(val)}
          max={marks.length - 1}
          marks={marks}
        />
      </Box>
    </Box>

    {/*  ICM IMG */}
    <img src={mgramUrl}/>
  </Box>;
}


function generateDatesBetween(start, end) {
  const startDate = moment(start);
  const endDate = moment(end);

  const dates = [];

  while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
    dates.push(startDate.clone());  // Store the clone to keep each date instance unique
    startDate.add(1, 'days');
  }

  return dates;
}
