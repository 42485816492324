import React from 'react';
import Box from '@mui/material/Box';
import Header from '../components/Header/Header';
import {useConfig} from '../context/clientConfig/clientConfigContext';
import {useTranslation} from 'react-i18next';
import DiagnosticChart from '../components/AirChart/DiagnosticChart';

const Diagnostics = () => {
  const {t} = useTranslation();
  const {config: {devices}, email} = useConfig().state;

  // const waterDevices = devices.filter(d => !d.diagMeasurements);
  const waterDevices = devices.filter(d => d.serialNo.includes('WATER_') || d.serialNo.includes('PM100'));

  return <Box>
    <Header/>
    <Box display={'flex'}>
      {/* CHARTS */}
      <Box sx={{
        width: '100vw',
        height: 'calc(100vh - 88px)',
        '@media screen and (max-width: 50em)': {
          height: 'calc(100vh - 60px)'
        }
      }}>

        {waterDevices.reverse().map(device => <Box key={device.serialNo} p={2}>
          <DiagnosticChart device={device}/>
        </Box>)
        }
      </Box>
    </Box>

  </Box>;
};

export default Diagnostics;

const ALL_DIAG_MEASUREMENTS = [
  'batRemainCapacity',
  'tempEnv',
  'tempInternal',
  'lightTimeDay',
  'batVoltage',
  'batCurrent',
  'batPower',
  'loadVoltage',
  'loadCurrent',
  'loadPower',
  'solarVoltage',
  'solarCurrent',
  'solarPower',
  'eGeneratedDay',
  'eGeneratedTotal',
  'eConsumedDay',
  'eConsumedTotal'
];
