// @mui material components
import {makeStyles} from "@mui/styles";

const styles = makeStyles((theme) => {
  const {palette, shadows, typography} = theme

  const {grey, dark, light} = palette;
  const {fontWeightMedium, fontWeightBold, pxToRem, size} = typography;

  return {
    calendar: {
      height: "500px",

      "& .fc-media-screen": {
        height: "100%",
        color: dark.main,
      },

      "& .fc-theme-standard .fc-scrollgrid": {
        border: "none",
      },

      "& .fc-theme-standard thead tr th": {
        borderLeft: "none",
        borderRight: "none",
      },

      "& .fc-theme-standard td, .fc-theme-standard th": {
        borderColor: light.main,
      },

      "& .fc th": {
        textAlign: "center",
      },

      "& .fc .fc-col-header-cell-cushion": {
        fontSize: size.sm,
        fontWeight: fontWeightMedium,
        color: grey[500],
      },

      "& .fc .fc-daygrid-day-number": {
        color: grey[700],
        fontSize: size.sm,
        fontWeight: fontWeightMedium,
        width: "100%",
        textAlign: "center",
        // lineHeight: 1.4,
      },

      "& .fc .fc-daygrid-day-frame": {
        // minHeight: 110
      },

      "& .fc-scrollgrid-section.fc-scrollgrid-section-header > td": {
        border: "none",
      },

      "& .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid > td": {
        border: "none",
      },

      "& .fc-scrollgrid-sync-table": {
        height: "auto !important",
      },

      "& .fc .fc-view-harness-active > .fc-view": {
        position: "static",
        height: "100%",
      },

      "& .fc .fc-scroller-liquid-absolute": {
        position: "static",
      },

      "& .fc-daygrid-event": {
        margin: `${pxToRem(0.5)} ${pxToRem(2)}`,
        border: "none",
        borderRadius: pxToRem(5.6),
        fontSize: size.sm,
        fontWeight: fontWeightMedium,
      },

      "& .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events": {
        minHeight: pxToRem(32),
      },

      "& .fc-event-title": {
        padding: `${pxToRem(3)} ${pxToRem(4.8)} ${pxToRem(2.5)}`,
      },

      "& .fc-button, .fc-today-button, .fc-button:disabled": {
        // backgroundColor: secondary.main,
        // borderColor: secondary.main,
        fontSize: size.sm,
        boxShadow: shadows[4],
        opacity: 1,
        transition: `all 150ms ease-in`,

        "&:hover, &:focus, &:active": {
          transform: "scale(1.02)",
          boxShadow: `${shadows[4]} !important`,
          // backgroundColor: `${secondary.main} !important`,
          // borderColor: `${secondary.main} !important`,
        },
      },

      "& .fc .fc-button .fc-icon": {
        fontSize: size.sm,
      },

      "& .fc-toolbar-title": {
        fontSize: size.lg,
        fontWeight: fontWeightBold,
      },

    },


  };
});

export default styles;
