import React from 'react';
import PropTypes from 'prop-types';
import {Navigate} from 'react-router-dom';
import {useConfig} from 'context/clientConfig/clientConfigContext';

function PrivateRoute({children}) {
  const {state} = useConfig();
  return state.clientId ? children : <Navigate to='../login'/>;
}

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};

export default PrivateRoute;
