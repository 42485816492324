import React, {useEffect, useState} from 'react';

import {Popup} from '@urbica/react-map-gl';
import {Box, Divider, IconButton, Tooltip, Typography} from '@mui/material';
import {TypeIcon, vesselImg} from './util';
import Flag from 'react-world-flags';
import {Close, ContentCopy, Download} from '@mui/icons-material';
import './util';
import humanizeDuration from 'humanize-duration';
import {useTranslation} from 'react-i18next';
import {fetchVesselData, getVesselImg} from '../../api';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import Button from '@mui/material/Button';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import VesselDockStatus from './VesselDockStatus';
import PropTypes from 'prop-types';
import MarineTrafficLogo from 'assets/images/MarineTrafficLogo.png';


export default function VesselPopup({vessel, onClose}) {
  const {t, i18n} = useTranslation();
  const {email, config: {roles}} = useConfig().state;

  const [imgId, setImgId] = useState(null);
  const [loadingImg, setLoadingImg] = useState(false);

  const {
    longitude, latitude, course, timestamp, destination,
    eta, flag, grt, rot, length, draught, mmsi,
    shipName, name, shipType, vesselType, speed, status, typeName, width, yearBuilt
  } = vessel;

  console.log({vessel})

  // fetch vessel photo
  useEffect(() => {
    setLoadingImg(true);
    getVesselImg(vessel.mmsi, vessel.imo)
      .then(imgId => setImgId(imgId))
      .catch(err => console.warn('Vessel img not found: ' + result.mmsi))
      .finally(() => setLoadingImg(false));
  }, [vessel]);

  const onFetchVesselData = (mmsi, imo) => {
    console.log('FETCH', mmsi, imo);
    fetchVesselData(mmsi, imo)
      .then(() => console.log('DONE FETCH DATA AGAIN'))
      .catch(err => console.log(err));
  };

  return <Popup
    anchor={'bottom'}
    longitude={longitude}
    latitude={latitude}
    closeButton={false}
    onClose={onClose}
    closeOnClick={false}
    maxWidth={'400px'}
  >
    <Box display={'flex'} sx={{cursor: 'auto'}} p={1} flexDirection={'column'}>
      {/* HEADER */}
      <Box display={'flex'} m={0} alignItems={'center'} gap={1} height={50}>
        <Box><span><TypeIcon shipType={shipType || vesselType} typeName={typeName || vesselType || t('vessels.unknownType')}/></span></Box>
        <Box><Flag style={{border: '1px solid #ADA9B4'}} title={flag} height={25} width={35} code={flag}/></Box>
        <Box width={'100%'}>
          <Typography variant={'h6'} lineHeight={1}>
            {shipName || name || ('MMSI: ' + mmsi)}
          </Typography>
          <Typography variant={'caption'}>
            {typeName ? t(`vessels.shipType`, {returnObjects: true})[typeName.toLowerCase()] || typeName.toLowerCase() : (vesselType || t('vessels.unknownType'))}
          </Typography>

          {yearBuilt && <Typography ml={1} variant={'caption'}>
            ({t('vessels.builtIn')} {yearBuilt})
          </Typography>}
        </Box>
        <Box justifySelf={'flex-end'}>
          <IconButton sx={{color: 'white'}} onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      {/* VESSEL IMG */}
      <Box sx={{margin: '0 auto'}}>
        {loadingImg ? <DirectionsBoatIcon
            sx={{color: 'lightblue', height: 265, width: 350, objectFit: 'cover'}}/> :
          <Box>
            <img width={350}
                 src={vesselImg(imgId)}
                 alt="Vessel image"
            />
          </Box>
        }
      </Box>
      {/* VESSEL INFO */}
      <Box display={'flex'} flexDirection={'row'} mb={2} p={1}>
        {/* LEFT BOX */}
        <Box flex={1}>
          <Typography>
            <b>MMSI:</b>
            <Tooltip placement={'top'} title={'Kliknij aby skopiować numer mmsi do schowka'}>
              <Button
                onClick={() => navigator.clipboard.writeText(mmsi)}
                sx={{pb: 1.1}} color={'inherit'} endIcon={<ContentCopy/>}>{mmsi}</Button>
            </Tooltip>
          </Typography>

          {length && <><Box>
            <Typography fontWeight={'bold'} variant={'body2'}>{t('vessels.dimensions')}:</Typography>
            <Typography variant={'caption'}>{length}m x {width}m</Typography>
          </Box>
            <Divider/></>}
          {grt && <><Box mt={1}>
            <Typography fontWeight={'bold'} variant={'body2'}>{t('vessels.grt')}:</Typography>
            <Typography variant={'caption'}>{grt}</Typography>
          </Box>
            <Divider/></>}
          {rot && rot !== '0' && <><Box mt={1}>
            <Typography fontWeight={'bold'} variant={'body2'}>{t('vessels.rot')}:</Typography>
            <Typography variant={'caption'}>{rot}</Typography>
          </Box>
            <Divider/>
          </>}
        </Box>
        {/* RIGHT BOX */}
        <Box display={'flex'} flex={1} textAlign={'right'} flexDirection={'column'}>
          <Box flex={1}>
            <span><Tooltip placement={'top'} title={t('vessels.destinationTooltip')}>
              <Typography>{destination}</Typography></Tooltip></span>
          </Box>
          <Box flex={1}>
            <Tooltip placement={'top'} title={t('vessels.seeOnMarineTraffic')}>
              <Button target={'_blank'} href={'https://www.marinetraffic.com/pl/ais/details/ships/mmsi:' + mmsi}>
                <img width={40} src={MarineTrafficLogo}/>
              </Button>
            </Tooltip>
          </Box>
          {eta && <Box flex={3}>
            <span><Tooltip placement={'top'}
                           title={t('vessels.etaTooltip')}>
              <Box>
                <Typography mr={8} fontWeight={'bold'} variant={'body2'}>ETA:</Typography>
                <Typography variant={'caption'}>{eta.replace('T', ' ').slice(0, 16)}</Typography>
              </Box>
            </Tooltip></span>
          </Box>}
        </Box>
      </Box>
      <Box display={'flex'} gap={1} justifyContent={'space-around'} alignItems={'flex-start'}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
          <Box><Typography variant={'caption'}>{t('general.status')}:</Typography></Box>
          <Box><Typography fontWeight={'bold'}
                           variant={'body1'}> {status ? t(`vessels.statusLabels.${status}`) : 'N/A'}</Typography></Box>
        </Box>
        <Divider orientation="vertical" flexItem/>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
          <Box><Typography variant={'caption'}>{t('vessels.speed')}/{t('vessels.course')}:</Typography></Box>
          <Box><Typography fontWeight={'bold'} variant={'body1'}>{(speed / 10).toFixed(1)}kn
            / {course.toFixed(1)}°</Typography></Box>
        </Box>
        <Divider orientation="vertical" flexItem/>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
          <Box><Typography variant={'caption'}>{t('vessels.draught')}:</Typography></Box>
          <Box>{draught &&
          <Typography fontWeight={'bold'} variant={'body1'}>{(draught / 10).toFixed(2)}m</Typography>}</Box>
        </Box>
      </Box>
      <Box mt={2}>
        <Typography fontSize={11}
                    variant={'caption'}>{t('vessels.positionReceived')}: {humanizeDuration(new Date().getTime() - timestamp, {
          units: ['d', 'h', 'm'],
          largest: 2,
          round: true,
          language: i18n.resolvedLanguage
        })} {t('vessels.ago')}</Typography>
      </Box>
      {/* TODO Wyłączone pobieranie danych */}
      {/*<Box position={'absolute'} bottom={10} right={10}>*/}
      {/*  {roles.includes('admin') &&*/}
      {/*  <Button onClick={() => onFetchVesselData(vessel.mmsi, vessel.imo)} color={'success'} startIcon={<Download/>}/>}*/}
      {/*  /!*<VesselDockStatus vessel={vessel}/>*!/*/}
      {/*</Box>*/}
    </Box>
  </Popup>;
}

VesselPopup.propTypes = {
  vessel: PropTypes.shape({
    course: PropTypes.number,
    destination: PropTypes.string,
    draught: PropTypes.number,
    dwt: PropTypes.number,
    eta: PropTypes.string,
    flag: PropTypes.string,
    grt: PropTypes.number,
    heading: PropTypes.number,
    imgId: PropTypes.string,
    latitude: PropTypes.number,
    length: PropTypes.number,
    longitude: PropTypes.number,
    mmsi: PropTypes.string,
    rot: PropTypes.string,
    shipName: PropTypes.string,
    shipType: PropTypes.string,
    speed: PropTypes.number,
    status: PropTypes.string,
    timestamp: PropTypes.number,
    typeName: PropTypes.string,
    width: PropTypes.number,
    yearBuilt: PropTypes.string
  }).isRequired,
  onClose: PropTypes.func
};
