import moment from 'moment';
import {NEW, STARTED} from './MaintenanceStatus';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import React from 'react';
import {grayColor} from '../../assets/jss/nextjs-material-dashboard';
import humanizeDuration from 'humanize-duration';

export default function DurationStats({status, createdAt, language, photos}) {
  let serviceDuration = -1;
  if (photos.length === 2) {
    serviceDuration = moment(photos[1].timestamp) - moment(photos[0].timestamp);
  }

  if (status === NEW) {
    return <Box>
      {/*<Typography color={grayColor[1]}>*/}
      {/*  Trwa diagnoza.*/}
      {/*</Typography>*/}
      <Typography color={grayColor[1]}>
        Zgłoszono: <Typography fontWeight={'bold'}>{moment(createdAt).fromNow()}</Typography>
      </Typography>
    </Box>;
  } else if (status === STARTED) {
    return <Box gap={1}>
      <Box display={'flex'} gap={1}>
        <Typography color={grayColor[1]}>
          Reakcja:
        </Typography>
        <Duration timedelta={moment(photos[1]?.timestamp) - moment(createdAt)} language={language}/>
      </Box>
      {serviceDuration > 0 && <Box display={'flex'} gap={1}>
        <Typography color={grayColor[1]}>
          Serwis trwał:
        </Typography>
        <Duration timedelta={serviceDuration} language={language}/>
      </Box>}
    </Box>;
  } else {
    return <Box gap={1}>
      <Box display={'flex'} gap={1}>
        <Typography color={grayColor[1]}>
          Reakcja: {' '}
        </Typography>
        <Duration timedelta={moment(photos[1]?.timestamp) - moment(createdAt)} language={language}/>
      </Box>
      <Box display={'flex'} gap={1}>
        <Typography color={grayColor[1]}>
          Serwis:
        </Typography>
        <Duration timedelta={serviceDuration} language={language}/>
      </Box>
      {/*<Box display={'flex'} gap={1}>*/}
      {/*  <Typography color={grayColor[1]}>*/}
      {/*    Niedostępność:*/}
      {/*  </Typography>*/}
      {/*  <Duration timedelta={updatedAt - createdAt} language={language}/>*/}
      {/*</Box>*/}
    </Box>;
  }
};

const Duration = ({timedelta, language}) => <Typography color={grayColor[1]}
                                                        fontWeight={'bold'}>{humanizeDuration(timedelta, {
  units: ['d', 'h', 'm'],
  largest: 2,
  round: true,
  language
})}</Typography>;
