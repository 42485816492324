import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const AisStationIcon = (props) => <SvgIcon {...props} viewBox="0 0 24 24">

    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="50%" id="a">
        <stop stopColor="#06ED44" offset="0%"/>
        <stop stopColor="#04B675" offset="100%"/>
      </linearGradient>
    </defs>

    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z"/>
      <path d="M11.34 9v11h1.33V9L18 4H6l5.34 5zM9.65 5.19h1.76v1.62L9.65 5.19zm2.95 1.62V5.19h1.75L12.6 6.81z"
  fill="url(#a)"/>
    </g>


</SvgIcon>;

export default AisStationIcon;
