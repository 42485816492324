import React from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import SoftUITheme from './assets/theme';
import defaultTheme from './theme';
import CssBaseline from '@mui/material/CssBaseline';
import Authentication from './auth/Authentication';
import Routes from './routes/Routes';
import {useConfig} from './context/clientConfig/clientConfigContext';

export default function App() {
  const {email} = useConfig().state;
  return <ThemeProvider theme={email ? defaultTheme() : SoftUITheme}>
    <CssBaseline/>
    <Authentication>
      <Routes/>
    </Authentication>
  </ThemeProvider>;
}
