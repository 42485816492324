import React, {useState} from 'react';
import Highcharts from 'highcharts/highstock';
import {
  AreaSeries,
  Chart,
  ColumnSeries,
  HighchartsProvider,
  HighchartsStockChart,
  Legend,
  LineSeries,
  Title,
  Tooltip,
  XAxis,
  YAxis
} from 'react-jsx-highstock';
import addWindBarbModule from 'highcharts/modules/windbarb';
import applyExporting from 'highcharts/modules/exporting';
import applyOffline from 'highcharts/modules/offline-exporting';
import Box from '@mui/material/Box';
import {primaryColor} from '../../assets/jss/nextjs-material-dashboard';
import {Divider, FormControlLabel, Switch} from '@mui/material';
import {useTranslation} from 'react-i18next';


applyExporting(Highcharts);
applyOffline(Highcharts);
addWindBarbModule(Highcharts);

const plotOptions = {
  column: {
    pointWidth: 6,
    maxPointWidth: 50,
    minPointLength: 3, // By default, 0 values are not shown. To visualize a 0 (or close to zero) point, set the minimal point length to a pixel value like 3
    borderWidth: 0
  },
  series: {
    turboThreshold: 1000000,
    shadow: true,
    dataGrouping: {
      approximation: 'average',
      groupAll: true,
      groupPixelWidth: 20,
      //. An array determining what time intervals the ticks are allowed to fall on.
      // Each array item is an array where the first value is the time unit and
      // the second value another array of allowed multiples.
      units: [
        [
          'millisecond', // unit name
          [] // allowed multiples
        ], [
          'second',
          []
        ], [
          'minute',
          [10]
        ], [
          'hour',
          [1, 4, 12]
        ], [
          'day',
          [1, 2]
        ], [
          'week',
          [1, 2]
        ], [
          'month',
          [1, 2, 3, 4, 6]
        ], [
          'year',
          null
        ]]
    }
  }
};


const timeOptions = {
  useUTC: false
};

const options = {};


export default function AnalyticChart({title, units, seriesList, isWeather, weatherData, icon}) {
  const {t} = useTranslation();
  const [columns, setColumns] = useState(false);
  const handleColumnsToggle = () => setColumns(!columns);

  const [temp, setTemp] = useState(false);
  const [wind, setWind] = useState(false);
  const [rain, setRain] = useState(false);
  const [pressure, setPressure] = useState(false);
  const [humidity, setHumidity] = useState(false);

  const handleTempToggle = () => setTemp(!temp);
  const handleWindToggle = () => setWind(!wind);
  const handleHumidityToggle = () => setHumidity(!humidity);
  const handlePressureToggle = () => setPressure(!pressure);
  const handleRainToggle = () => setRain(!rain);

  const tempData = weatherData.temperature;
  const windData = weatherData.windlevel;
  const rainData = weatherData.rain;
  const windBarbData = weatherData.windbearing;
  const pressureData = weatherData.pressure;
  const humidityData = weatherData.humidity;

  return (<Box position={'relative'} mt={5}>
      <HighchartsProvider Highcharts={Highcharts}>
        <Box position={'absolute'} right={40} top={5} zIndex={100} sx={{display: "flex", gap: 2}}>
          <Box
            sx={{
              display: "flex", position: "relative", gap: 2,
              '@media screen and (max-width: 40em)': {top: "2em", right: "3.3em"}
            }}>
            <FormControlLabel
              control={
                <Switch
                  size={'small'}
                  checked={columns}
                  onChange={() =>
                    handleColumnsToggle()}
                  value="columnsSwitch"
                />
              }
              label={t('measurements.columns')}
            />
          </Box>
        </Box>

        <HighchartsStockChart options={options} plotOptions={plotOptions} time={timeOptions}>
          <Chart zooming={{type: 'x'}} panning={{enabled: true}} panKey={'shift'} />
          <Legend align={'left'} verticalAlign={'top'} x={40} y={30}/>
          <Tooltip split valueDecimals={2} />

          <Title style={{color: primaryColor[2]}} align={'center'}>{title}</Title>
          {icon && <Box position={'absolute'} sx={{top: 5, left: 50}}>
            {icon}
          </Box>}
          {isWeather && <Box pl={4}>
            <FormControlLabel
              control={
                <Switch
                  size={'small'}
                  color={'error'}
                  checked={temp}
                  onChange={handleTempToggle}
                  value="temp"
                />
              }
              label={t('measurements.temperature')}
            />
            <FormControlLabel
              control={
                <Switch
                  size={'small'}
                  checked={wind}
                  onChange={handleWindToggle}
                  value="wind"
                />
              }
              label={t('measurements.windSpeed')}
            />
            <FormControlLabel
              control={
                <Switch
                  size={'small'}
                  color={'info'}
                  checked={humidity}
                  onChange={handleHumidityToggle}
                  value="humidity"
                />
              }
              label={t('measurements.humidity')}
            />
            <FormControlLabel
              control={
                <Switch
                  size={'small'}
                  checked={pressure}
                  onChange={handlePressureToggle}
                  value="pressure"
                />
              }
              label={t('measurements.pressure')}
            />
            <FormControlLabel
              control={
                <Switch
                  size={'small'}
                  color={'success'}
                  checked={rain}
                  onChange={handleRainToggle}
                  value="rain"
                />
              }
              label={t('measurements.rain')}
            />
          </Box>}
          {/*<Tooltip shared={false} valueSuffix={' ' + units} valueDecimals={2}/>*/}
          <XAxis/>

          <YAxis>
            <YAxis.Title>{units}</YAxis.Title>

            {seriesList.filter(series => series.data.length > 0).map(series => {
              const id = series.name.replace(' ', '');
              if (columns) {
                return <ColumnSeries tooltip={{valueSuffix: ' ' + units}} yAxis={0} key={id} id={id} name={series.name} data={series.data}/>;
              } else {
                return <LineSeries tooltip={{valueSuffix: ' ' + units}} yAxis={0} key={id} id={id} name={series.name} data={series.data}/>;
              }
            })}
          </YAxis>

          {temp && <YAxis opposite={false}>
            <YAxis.Title>Temp [°C]</YAxis.Title>
            <LineSeries tooltip={{valueSuffix: ' [°C]'}} color={'red'} id={'temp-series'} name={t('measurements.temperature')} data={tempData}/>
          </YAxis>}

          {wind && <YAxis opposite={false}>
            <YAxis.Title>{t('measurements.windSpeed')} [m/s]</YAxis.Title>
            <LineSeries tooltip={{valueSuffix: ' [m/s]'}} color={'blue'} id={'wind-series'} name={t('measurements.windSpeed')} data={windData}/>
            {/*<SplineSeries  id="wind-station2" color={"#0d233a"} name={`Prędkość wiatru`}*/}
            {/*              data={windData}/>*/}
            {/*<WindBarbSeries  id="wind-barb-series2" color="#434348"*/}
            {/*                name={`Kierunek wiatru`} data={windBarbData} showInLegend={false}/>*/}
          </YAxis>}

          {pressure &&
          <YAxis min={995} max={1040} labels={{enabled: false}}>
            {/*<YAxis.Title>hPa</YAxis.Title>*/}
            <AreaSeries
              tooltip={{valueSuffix: ' hPa'}}
              fillColor={{
              linearGradient: [0, 0, 0, 200],
              stops: [
                [0, Highcharts.getOptions().colors[2]],
                [1, Highcharts.color(Highcharts.getOptions().colors[2]).setOpacity(0).get('rgba')]
              ]
            }} color={Highcharts.getOptions().colors[2]} name={t('measurements.pressure')} data={pressureData}/>
          </YAxis>}

          {humidity &&
          <YAxis min={0} max={100} labels={{enabled: false}}>
            {/*<YAxis.Title>%</YAxis.Title>*/}
            <AreaSeries
              tooltip={{valueSuffix: ' [%]'}}
              fillColor={{
              linearGradient: [0, 0, 0, 200],
              stops: [
                [0, Highcharts.getOptions().colors[7]],
                [1, Highcharts.color(Highcharts.getOptions().colors[7]).setOpacity(0).get('rgba')]
              ]
            }} color={Highcharts.getOptions().colors[7]} name={t('measurements.humidity')} data={humidityData}/>
          </YAxis>}

          {rain &&
          <YAxis labels={{enabled: false}}>
            <ColumnSeries tooltip={{valueSuffix: ' [mm]'}} color={'green'} yAxis={0} key={'rain'} id={'rain'} name={t('measurements.rain')}
                          data={rainData}/>
          </YAxis>}

        </HighchartsStockChart>
      </HighchartsProvider>
      <Divider sx={{pt:5, width: '100%'}} />
    </Box>
  );
}
