import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Pm25Icon = (props) => <SvgIcon {...props} viewBox="0 0 104 104">
  <g fill={props.color} fillRule="evenodd">
    <g >
      <path
        d="M67.535 48.766a3.544 3.544 0 00-3.545-3.544c-2.658 0-3.544 1.587-3.544 3.544a3.544 3.544 0 107.089 0zm6.822-18.626a4.122 4.122 0 00-4.122 4.122c0 2.276.25 4.121 2.526 4.121 4.787 0 5.717-1.845 5.717-4.121a4.122 4.122 0 00-4.121-4.122zm-39.153 1.904c2.6 0 4.265-.323 4.706-2.884.76-4.403-2.107-6.528-4.706-6.528a4.706 4.706 0 000 9.412zm-10.718 5.401a2.224 2.224 0 002.224-2.224c0-1.228 0-2.224-2.224-2.224a2.224 2.224 0 000 4.448zm27.75 1.056c0-2.277-.831-4.124-4.124-4.124a4.124 4.124 0 104.124 4.124zm-7.55 10.21c-2.4 0-4.346.807-4.346 4.346a4.346 4.346 0 104.345-4.345zM63.09 16.998c-2.07 0-5.192.04-4.654 3.748.544 3.747 2.585 3.747 4.654 3.747a3.747 3.747 0 000-7.495zM76.99 46.421a2.756 2.756 0 00-2.757 2.756c0 1.522.69 2.757 2.757 2.757a2.756 2.756 0 000-5.513zM59.088 32.579c-3.185 0-4.311 1.93-4.311 4.31a4.311 4.311 0 108.622 0c0-2.38-.933-4.31-4.311-4.31z"
         opacity=".904" fillRule="nonzero"></path>
    </g>
    <path
      d="M27 71.38a10.1 10.1 0 011.64-.24c.587-.04 1.167-.06 1.74-.06.613 0 1.237.06 1.87.18s1.21.34 1.73.66.943.76 1.27 1.32c.327.56.49 1.273.49 2.14 0 .853-.153 1.573-.46 2.16a4.048 4.048 0 01-1.22 1.43 4.937 4.937 0 01-1.74.79c-.653.16-1.327.24-2.02.24h-.33a12.025 12.025 0 01-.97-.04 3.437 3.437 0 01-.34-.04v5.32H27V71.38zm3.42 1.14c-.347 0-.68.007-1 .02-.32.013-.573.047-.76.1v5.8c.067.027.173.043.32.05l.46.02c.16.007.313.01.46.01h.32c.453 0 .903-.043 1.35-.13.447-.087.85-.243 1.21-.47.36-.227.65-.547.87-.96.22-.413.33-.94.33-1.58 0-.547-.103-1.003-.31-1.37a2.478 2.478 0 00-.82-.88 3.456 3.456 0 00-1.15-.47 5.974 5.974 0 00-1.28-.14zm18.52 3.32l.2-2.1h-.1l-.76 1.92-3.64 6.32h-.5l-3.86-6.34-.72-1.9h-.1l.28 2.08v9.42h-1.56v-14h1.24l4.38 7.16.66 1.56h.04l.62-1.6 4.18-7.12h1.3v14h-1.66v-9.4zm12.28-1.42c0 .707-.13 1.43-.39 2.17s-.6 1.483-1.02 2.23c-.42.747-.89 1.477-1.41 2.19-.52.713-1.04 1.39-1.56 2.03l-.98.86v.08l1.28-.22h4.36v1.48h-7.96v-.58c.293-.307.647-.687 1.06-1.14a33.154 33.154 0 002.61-3.27c.433-.62.827-1.25 1.18-1.89.353-.64.637-1.273.85-1.9.213-.627.32-1.22.32-1.78 0-.653-.187-1.197-.56-1.63-.373-.433-.94-.65-1.7-.65-.507 0-1 .103-1.48.31-.48.207-.893.443-1.24.71l-.66-1.14c.467-.4 1.02-.713 1.66-.94a6.126 6.126 0 012.06-.34c.573 0 1.08.083 1.52.25.44.167.813.403 1.12.71.307.307.54.67.7 1.09.16.42.24.877.24 1.37zm2.96 9.9c0-.36.103-.643.31-.85.207-.207.483-.31.83-.31.36 0 .647.103.86.31.213.207.32.49.32.85 0 .347-.107.627-.32.84-.213.213-.5.32-.86.32-.347 0-.623-.107-.83-.32-.207-.213-.31-.493-.31-.84zm7.56-.24c.92 0 1.68-.267 2.28-.8.6-.533.9-1.307.9-2.32 0-.973-.297-1.7-.89-2.18-.593-.48-1.41-.72-2.45-.72l-1.68.1v-6.92h6.24v1.48H71.4v3.98l.86-.04c1.32.013 2.36.38 3.12 1.1.76.72 1.14 1.753 1.14 3.1 0 .747-.127 1.41-.38 1.99a4.122 4.122 0 01-1.03 1.45c-.433.387-.943.68-1.53.88-.587.2-1.213.3-1.88.3-.6 0-1.107-.033-1.52-.1a6.155 6.155 0 01-1.16-.3l.42-1.36c.307.12.637.21.99.27.353.06.79.09 1.31.09z"
      opacity=".9"></path>
    <g>
      <path
        d="M49.595 31.102c2.578 0 4.23-.32 4.668-2.861.754-4.368-2.09-6.476-4.668-6.476a4.669 4.669 0 000 9.337zm-9.79 11.332c0-2.579-.941-4.67-4.669-4.67a4.669 4.669 0 104.67 4.67zm-7.669 10.33c-2.578 0-4.668.867-4.668 4.67a4.669 4.669 0 104.668-4.67z"
        opacity=".9" fillRule="nonzero"></path>
    </g>
  </g>
</SvgIcon>;

export default Pm25Icon;
