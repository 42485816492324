// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';


import SuiBox from '../components/SuiBox';

function PageLayout({ background, children }) {
  return (
    <SuiBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      backgroundColor={background}
      sx={{overflowX: "hidden !important"}}
    >
      {children}
    </SuiBox>
  );
}

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
  background: "default",
};

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export default PageLayout;
