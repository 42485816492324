import React, {useEffect, useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import Header from '../components/Header/Header';
import Button from '@mui/material/Button';
import {MOBILE_SCREEN} from '../assets/jss/nextjs-material-dashboard';
import {ArrowBackIos, EmailOutlined, FolderCopy, FormatListNumbered, MoreHoriz, Settings} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Drawer, useMediaQuery} from '@mui/material';
import Episodes from '../components/Notifications/Episodes';
import EpisodesStats from '../components/Notifications/EpisodesStats';
import NotifConfig from '../components/Notifications/NotifConfig';


const drawerIconStyle = {
  position: 'fixed',
  bottom: '2%',
  left: '2%',
  border: '2px solid #fb8c00',
  borderRadius: '50%',
  width: '55px',
  height: '55px',
  zIndex: 1000,
  textAlign: 'center',
  boxShadow: '0 5px 20px rgba(0, 0, 0, .50)'
};


const Notifications = () => {
  const {t} = useTranslation();
  const splitted = window.location.href.split('#');
  const selectedByUrl = splitted[splitted.length - 1];
  const [selectedItem, setSelectedItemState] = useState(selectedByUrl);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);
  const [openDrawer, setOpenDrawer] = useState(false);

  function toggleDrawer() {
    setOpenDrawer(openDrawer => !openDrawer);
  }

  const setSelectedItem = item => {
    navigate('#' + item);
    setSelectedItemState(item);
  };

  useEffect(() => {
    if (window.location.href.includes("notifications") && !window.location.href.includes("notifications#")) {
      navigate('#list');
      setSelectedItemState("list");
    } else {
      const splitted = window.location.href.split('#');
      setSelectedItemState(splitted[splitted.length - 1]);
    }
  }, [window.location.href])

  const leftMenuItems = useMemo(() => [
    ['list', <FormatListNumbered/>],
    ['stats', <FolderCopy />],
    ['config', <Settings/>]
  ], []);

  return <Box>
    <Header/>
    <Box display={'flex'}>
      {/* SIDEBAR */}
      <Box pt={10} sx={{
        mt: 4.5,
        pl: 1,
        pr: 1,
        gap: 2,
        width: 200,
        display: "flex",
        flexDirection: "column",
        height: 'calc(100vh - 88px)',
        '@media screen and (max-width: 50em)': {
          height: 'calc(100vh - 60px)', display: 'none'
        }
      }}>
        {leftMenuItems.map(([id, icon]) => <Button
            key={id}
            sx={{alignSelf: "flex-start", width: 180, justifyContent: "flex-start", alignItems: "flex-start"}}
            variant={selectedItem === id ? 'contained' : 'text'}
            onClick={() => setSelectedItem(id)} startIcon={icon}>
            {t('episodes.' + id)}
          </Button>
        )}
      </Box>
      {/* CONTENT */}
      <Box sx={{
        width: '100vw',
        height: 'calc(100vh - 88px)',
        '@media screen and (max-width: 50em)': {
          height: 'calc(100vh - 60px)'
        }
      }}>
        {selectedItem === 'list' && <Episodes/>}
        {selectedItem === 'stats' && <EpisodesStats/>}
        {selectedItem === 'config' && <NotifConfig />}
      </Box>
    </Box>

    {/*MOBILE SIDEBAR*/}
    {isMobile && selectedItem.includes('reports') && <Box style={drawerIconStyle}>
      <MoreHoriz sx={{
        cursor: 'pointer',
        width: '50px',
        height: '50px',
        color: '#007dc1',
        display: 'flex',
        justifyContent: 'center',
      }} onClick={toggleDrawer}/>
    </Box>}
    <Drawer anchor={'left'}
            open={openDrawer}
            onClose={toggleDrawer}>
      <Box justifyContent={'flex-end'} alignItems={'flex-start'} height={'80vh'} mr={1} mb={5} display={'flex'}
           position={'relative'} flexDirection={'column'} gap={1} p={2}>
        { leftMenuItems.map(([id, icon]) => <Button
          key={id}
          color={'info'}
          variant={selectedItem === id ? 'contained' : 'text'}
          sx={{pl: 2, fontSize: '12px'}}
          onClick={() => {
            setSelectedItem(id);
            toggleDrawer();
          }} startIcon={icon}>
          {t('measurements.' + id)}
        </Button>)}

        <Button sx={{mt: 10}}
                fullWidth variant={'contained'}
                startIcon={<ArrowBackIos/>}
                onClick={toggleDrawer}
        />
      </Box>
    </Drawer>
  </Box>;
};

export default Notifications;
