import React, {useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import {MAPSTYLES} from '../../config';
import BaseMapIcon from '../../assets/images/base-map-icon.png';


export default function BaseMapSelector({selectedStyleId, onStyleChange}) {
  const [showPopup, setShowPopup] = useState(false);

  const options = useMemo(() =>
    MAPSTYLES.map(({label, thumbnail, id}) =>
      <Box key={id} sx={{cursor: 'pointer'}} onClick={() => onStyleChange(id)}>
        <input type={'radio'} checked={selectedStyleId === id}/>
        <a>{label}</a>
      </Box>
    ), [selectedStyleId]);

  return <Box
    p={0.5}
    onMouseEnter={() => setShowPopup(true)}
    onMouseLeave={() => setShowPopup(false)}
    sx={{
      borderRadius: 1,
      position: 'absolute',
      top: 40,
      right: 10,
      backgroundColor: "inherit"
    }}
  >
    {showPopup ? <Box p={2}
                      sx={{
                        borderRadius: 1,
                        color: 'white',
                        background: 'rgba(92,101,126,1)'
                      }}
    >
      {options}
    </Box> : <img src={BaseMapIcon}/>}
  </Box>;
}
