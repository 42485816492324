import {Box, Card, CardContent, CardHeader, Modal, Select} from '@mui/material';
import Typography from '@mui/material/Typography';
import {Close} from '@mui/icons-material';
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import range from 'lodash/range';
import MenuItem from '@mui/material/MenuItem';
import {useTranslation} from 'react-i18next';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import CircularProgress from '@mui/material/CircularProgress';
import {getWaterMapData} from '../../api';
import RainChart from '../../components/RainChart/RainChart';
import {compareStr, compare} from './openDataUtils';

export default function WaterStationModal({waterStation, onClose}) {
  const {t} = useTranslation();
  const {clientId, uiSettings} = useConfig().state.config;
  const [firstYear, setFirstYear] = useState(moment().year());
  const [secondYear, setSecondYear] = useState(moment().year() - 1);
  const [loading, setLoading] = useState(true);
  const [waterMapData, setWaterMapData] = useState(true);

  useEffect(() => {
    setLoading(true);
    getWaterMapData(clientId, waterStation.serialNo, firstYear, secondYear)
      .then(result => setWaterMapData(result))
      .finally(()=>setLoading(false));
  }, [firstYear, secondYear]);

  return <Modal open={true} sx={{left: '10%', right: '10%', top: '5%'}} >
    <Card elevation={0} sx={{p: 2, maxHeight: '92vh'}}>
      <CardHeader
        action={<Close sx={{cursor: 'pointer'}} color={'error'} onClick={onClose}/>}
        title={<Typography color={'primary'} variant={'h4'}>{t('openData.rainLevel')} {waterStation.name}. {t('openData.yearComparision')} {firstYear}-{secondYear}</Typography>}
        subheader={<Box mt={3} display={'flex'} gap={2}>
          <Typography color={'primary'} variant={'body1'}>{t('openData.chooseYears')}: </Typography>
          <Select
            sx={{minWidth: 100}}
            size={'small'}
            variant={'standard'}
            value={firstYear}
            onChange={e => setFirstYear(e.target.value)}
          >
            {range(uiSettings.reportsYear, moment().year() + 1)
              .filter(y => y !== secondYear)
              .map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
          </Select>
          <Typography variant={'button'}> - </Typography>
          <Select
            sx={{minWidth: 100}}
            variant={'standard'}
            size={'small'}
            value={secondYear}
            onChange={e => setSecondYear(e.target.value)}
          >
            {range(uiSettings.reportsYear, moment().year() + 1)
              .filter(y => y !== firstYear)
              .map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
          </Select>
        </Box>}
      />
      <CardContent>
        {loading ? <Box><CircularProgress size={100} sx={{marginLeft: "calc(50% - 50px)", marginTop: 10}}/></Box> :
          <RainChart
            title={waterStation.name}
            year={secondYear}
            yearPrev={firstYear}
            data={waterMapData[secondYear] ? waterMapData[secondYear].sort(compare) : []}
            prevData={waterMapData[firstYear] ? waterMapData[firstYear].sort(compare) : []}
            drilldown={waterMapData["drilldown"].map(elem => {
              return {
                ...elem,
                data: elem.data.sort(compareStr)
              };
            })}
          />
        }
      </CardContent>
    </Card>
  </Modal>;
}
