import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableContainer,
  TableFooter,
  TablePagination,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import Button from '@mui/material/Button';
import {Anchor, FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, Replay} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import React, {useEffect, useMemo, useState} from 'react';
import {
  dangerColor,
  grayColor,
  primaryColor,
  successColor,
  TABLET_SCREEN
} from '../../assets/jss/nextjs-material-dashboard';
import {getPortCalls} from '../../api';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import humanizeDuration from 'humanize-duration';
import v3 from '../../assets/images/vesselTypes/vi3.png';
import v7 from '../../assets/images/vesselTypes/vi7.png';
import v9 from '../../assets/images/vesselTypes/vi9.png';
import HazardMaterialIcon from '../../assets/images/hazard-material.png';
import {useTranslation} from 'react-i18next';
import TableSortLabel from '@mui/material/TableSortLabel';
import {visuallyHidden} from '@mui/utils';
import DatePicker from 'react-datepicker';
import moment from 'moment';


export const human = minutes => humanizeDuration(minutes * 60000, {
  units: ['d', 'h', 'm'],
  language: 'en',
  delimiter: ' ',
  largest: 2,
  round: true
}).replace(' days', 'd').replace(' day', 'd').replace(' hours', 'h').replace(' hour', 'h').replace(' minutes', 'm').replace(' minute', 'm');

export const vesselIcon = type => {
  if (type.toLowerCase().includes('pleasure') || type.toLowerCase().includes('sailing vessel')) {
    return v9;
  }
  //Inland, Motor Freighter
  if (type.toLowerCase().includes('cargo') || type.toLowerCase().includes('inland, motor freighter')) {
    return v7;
  }

  if (type.toLowerCase().includes('special') || type.toLowerCase().includes('other') || type.toLowerCase().includes('pilot') || type.toLowerCase().includes('dredger')) {
    return v3;
  }

  // '1': v1,  // Reserved
  // '2': v2, // Wing In Ground
  // '3': v3, // Special or Tug
  // '4': v4, // High-Speed Craft
  // '5': v3, // Special Category
  // '6': v6, // Passenger
  // '7': , // Cargo
  // '8': v8, // Tanker
  // '9': v9, //Other
  // '30': v2, // Fishing
  // '37': v9, // Pleasure Craft
  // '52': v3, // Tug
};

export const TypeIcon = (shipType, t) => {
  const imgSrc = shipType && vesselIcon(shipType);
  const title = t('portCalls.shipTypes', {returnObjects: true})[shipType] || shipType;
  if (shipType.includes('Hazard')) {
    return imgSrc ? [
      <Tooltip title={title}>
        <img src={imgSrc} alt={shipType}/>
      </Tooltip>,
      <Tooltip title={title}>
        <img src={HazardMaterialIcon} alt={shipType}/>
      </Tooltip>] : shipType;
  } else {
    return imgSrc ? <Tooltip title={title}><img src={imgSrc} alt={shipType}/></Tooltip> : shipType;
  }
};


export default function PortCalls() {
  const {t, i18n} = useTranslation();
  const {clientId} = useConfig().state.config;
  const [startDate, setStartDate] = useState(moment().subtract(30, 'day').toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [portCalls, setPortCalls] = useState([]);
  const isTablet = useMediaQuery(`(max-width:${TABLET_SCREEN})`);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('ata_atd_val');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    setLoading(true);
    console.log('FETCH PORT CALLSf for:', moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
    getPortCalls(clientId, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
      .then(result => setPortCalls(result))
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, [clientId, startDate, endDate]);

  const onDateRangeChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const rows = useMemo(() => portCalls.map((pc, idx) => ({
    id: pc.recordId,
    lp: idx + 1,
    name: pc.vessel_name,
    type: [TypeIcon(pc.vessel_type, t),],
    draught: pc.draught.toFixed(1) + 'm',
    draught_val: pc.draught,
    url: `https://www.marinetraffic.com/pl/ais/details/ships/shipid:0/mmsi:${pc.mmsi}/imo:0`,
    call_type: t('portCalls.portCallTypes', {returnObjects: true})[pc.port_call_type] || pc.port_call_type,
    ata_atd: new Date(pc.ata_atd).toLocaleString(),
    ata_atd_val: pc.ata_atd,
    time_at_port: pc.port_call_type === 'ARRIVAL' ? '-' : human(pc.time_at_port),
    time_at_port_val: pc.time_at_port,
    // operation: pc.port_operation || "-",
    commercial_market: pc.commercial_market,
    in_transit: pc.in_transit ? `${t('portCalls.yes')}` : `${t('portCalls.no')}`
  })), [portCalls]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const resetAll = () => {
    setPage(0);
    setRowsPerPage(10);
    setOrder('desc');
    setOrderBy('ata_atd_val');
    setStartDate(moment().subtract(30, 'day').toDate());
    setEndDate(new Date());
  };


  return <Card variant={'outlined'} sx={{minHeight: '100%'}}>
    <CardHeader
      action={<Box mt={1} sx={{float: 'right'}} display={'flex'}>
        <DatePicker
          locale={i18n.resolvedLanguage}
          maxDate={new Date()}
          minDate={new Date(2022, 4, 1)}
          selectsRange
          monthsShown={2}
          startDate={startDate}
          endDate={endDate}
          onChange={onDateRangeChange}
        />
        <Tooltip title={t('portCalls.resetAll')}><Button startIcon={<Replay/>}
                                                         onClick={resetAll} color={'info'}/></Tooltip>
      </Box>
      }
      title={<Button
        size={'large'} startIcon={<Anchor/>}
        color={'info'}>{t('portCalls.portCalls')}</Button>}
    />
    <CardContent>
      <Box display="flex">
        {loading ? <CircularProgress size={100} sx={{margin: 'auto', marginTop: 10}}/> :
          <TableContainer>
            <Table size={isTablet ? 'small' : 'medium'} aria-label="reports table">
              <TableHead>
                <TableRow sx={{backgroundColor: 'whitesmoke'}}>
                  <TableCell width={20}><THeader>Lp</THeader></TableCell>
                  {[{id: 'name', label: 'vessel'},
                    {id: 'draught_val', label: 'vesselDraught'},
                    {id: 'call_type', label: 'portCallType'},
                    {id: 'ata_atd_val', label: 'ataAtd'},
                    {id: 'time_at_port_val', label: 'timeAtPort'},
                    {id: 'in_transit', label: 'inTransit'}].map(({id, label}) =>
                    <TableCell key={id} sortDirection={orderBy === id ? order : false}
                               align="center">
                      <TableSortLabel
                        active={orderBy === id}
                        direction={orderBy === id ? order : 'asc'}
                        onClick={event => handleRequestSort(event, id)}
                      >
                        <Typography sx={{color: primaryColor[2]}}>
                          {t('portCalls.' + label)}
                        </Typography>
                        {orderBy === id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>

                      {/*<THeader>{t('portCalls.' + cellId)}</THeader>*/}

                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                    ? stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : stableSort(rows, getComparator(order, orderBy))
                ).map(({
                  id,
                  lp,
                  url,
                  name,
                  type,
                  draught,
                  call_type,
                  ata_atd,
                  time_at_port,
                  in_transit,
                }) => (
                  <TableRow
                    key={id}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    <TableCell width={20} align={'center'}><TCell>{lp}</TCell></TableCell>
                    <TableCell align="center"><TCell>
                      <Box display={'flex'} gap={1}>
                        <Box>{type}</Box>
                        <a target={'_blank'} style={{color: primaryColor[2]}}
                           href={url}>{name}</a>
                      </Box>
                    </TCell></TableCell>
                    <TableCell align="center"><TCell>{draught}</TCell></TableCell>
                    <TableCell align="center"><TCell>{call_type}</TCell></TableCell>
                    <TableCell align="center"><TCell>{ata_atd}</TCell></TableCell>
                    <TableCell align="center"><TCell>{time_at_port}</TCell></TableCell>
                    <TableCell align="center"><TCell>{in_transit}</TCell></TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    sx={{border: 'none'}}
                    translate={'yes'}
                    rowsPerPageOptions={[5, 10, 25, 50, {label: t('general.all'), value: -1}]}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={t('general.rowsPerPage')}
                    labelDisplayedRows={({
                      from,
                      to,
                      count
                    }) => `${from}–${to} z ${count !== -1 ? count : `${t('general.moreThan')} ${to}`}`}
                    // labelDisplayedRows={({ from, to, count }) =>`${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        }
      </Box>
    </CardContent>
  </Card>;
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function TablePaginationActions(props) {
  const {count, page, rowsPerPage, onPageChange} = props;

  const handleFirstPageButtonClick = (
    event,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{flexShrink: 0, ml: 2.5}}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage/>
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft/>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight/>
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage/>
      </IconButton>
    </Box>
  );
}

export const THeader = ({children}) => <Typography sx={{color: primaryColor[2]}}>
  {children}
</Typography>;

export const TCell = ({children}) => <Typography
  color={['ARRIVAL', 'WPŁYNIĘCIE'].includes(children) ? successColor[0] : ['DEPARTURE', 'WYPŁYNIĘCIE'].includes(children) ? dangerColor[0] : grayColor[1]}>
  {children}
</Typography>;
