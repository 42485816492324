import React from 'react';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {grayColor} from '../../assets/jss/nextjs-material-dashboard';
import {useDockEventContext} from '../../context/dockEvent/dockEventContext';


const countryNames = new Intl.DisplayNames(
  ['en'], {type: 'region'}
);


export default function VesselParticulars() {
  const {t} = useTranslation();
  const {vessel} = useDockEventContext().state;
  const {
    lengthOverall,
    teu,
    financialOwner,
    insurer,
    depth,
    grossTonnage,
    breadthMoulded,
    summerDwt,
    name,
    grain,
    breadthExtreme,
    flag,
    liquidOil,
    callsign,
    vesselType,
    yardNumber,
    placeOfBuild,
    owner,
    technicalManager,
    liquidGas,
    build,
    builder,
    speedMax,
    manager,
    speedService,
    timestamp,
    imo,
    mmsi,
    draught,
    netTonnage,
    displacementSummer,
    lengthBWPerpendiculars,
    fuelConsumption,
    eni
  } = vessel.allParticulars;

  const subtitle = {
    color: grayColor[16],
    marginBottom: '0.5em',
    textAlign: 'left',
    fontSize: '1.25rem'
  };

  return <Box>
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-around',
    }}>
      <Box >
        <Typography
          style={subtitle}><b>{t('vesselParticulars.dimensions')}</b></Typography>
        <Typography>{t('vesselParticulars.draught')}: <b>{draught} m</b></Typography>
        <Typography>{t('vesselParticulars.lengthOverall')}: <b>{lengthOverall} m</b></Typography>
        <Typography>{t('vesselParticulars.depth')}: <b>{depth} m</b></Typography>
        <Typography>{t('vesselParticulars.lengthBetweenPerpendiculars')}: <b>{lengthBWPerpendiculars} m</b></Typography>
        <Typography>{t('vesselParticulars.breadthExtreme')}: <b>{breadthExtreme} m</b></Typography>
        <Typography>{t('vesselParticulars.breadthMoulded')}: <b>{breadthMoulded} m</b></Typography>
        <Typography>{t('vesselParticulars.displacementSummerVesselWeight')}:
          <b>{displacementSummer} t</b></Typography>
      </Box>
      <Box >
        <Typography
          style={subtitle}><b>{t('vesselParticulars.tonnageAndCapacity')}</b></Typography>
        <Typography>TEU: <b>{teu}</b></Typography>
        <Typography>{t('vesselParticulars.netTonnage')}: <b>{netTonnage}</b></Typography>
        <Typography>{t('vesselParticulars.breadthMoulded')}: <b>{breadthMoulded} m</b></Typography>
        <Typography>{t('vesselParticulars.grossTonnage')}: <b>{grossTonnage}</b></Typography>
        <Typography>{t('vesselParticulars.summerDwt')}: <b>{summerDwt}</b></Typography>
        {liquidOil &&
        <Typography>{t('vesselParticulars.liquidOil')}: <b>{liquidOil} m³</b></Typography>}
        {liquidGas &&
        <Typography>{t('vesselParticulars.liquidOil')}: <b>{liquidGas} m³</b></Typography>}
        {grain && <Typography>{t('vesselParticulars.grain')}: <b>{grain} m³</b></Typography>}

      </Box>
      <Box >
        <Typography
          style={subtitle}><b>{t('vesselParticulars.vesselInfo')}</b></Typography>
        <Typography>{t('vesselParticulars.placeOfBuild')}: <b>{placeOfBuild}</b></Typography>
        <Typography>{t('vesselParticulars.yearBuild')}: <b>{build}</b></Typography>
        <Typography>{t('vesselParticulars.builder')}: <b>{builder}</b></Typography>
        <Typography>{t('vesselParticulars.yardNumber')}: <b>{yardNumber}</b></Typography>
        <Typography>{t('vesselParticulars.callSign')}: <b>{callsign}</b></Typography>
        <Typography>{t('vesselParticulars.speedService')}: <b>{speedService} kn</b></Typography>
        <Typography>{t('vesselParticulars.maxSpeed')}: <b>{speedMax} kn</b></Typography>
        <Typography>{t('vesselParticulars.fuelConsumption')}: <b>{fuelConsumption}</b></Typography>
      </Box>
      <Box >
        <Typography
          style={subtitle}><b>{t('vesselParticulars.contactAndCommunication')}</b></Typography>
        <Typography>{t('vesselParticulars.owner')}: <b>{owner}</b></Typography>
        <Typography>{t('vesselParticulars.insurer')}: <b>{insurer}</b></Typography>
        <Typography>{t('vesselParticulars.manager')}: <b>{manager}</b></Typography>
        <Typography>{t('vesselParticulars.technicalManager')}: <b>{technicalManager}</b></Typography>
        <Typography>{t('vesselParticulars.financialOwner')}: <b>{financialOwner}</b></Typography>
      </Box>
    </Box>
  </Box>;
}

const exampleData = {
  'teu': '1060',
  'financialOwner': null,
  'depth': '13.7',
  'insurer': 'JAPAN SHIP OWNERS P & I ASSOCIATION',
  'grossTonnage': '12679',
  'breadthMoulded': '25',
  'name': 'DONGJIN AUBE',
  'summerDwt': '15220',
  'grain': null,
  'breadthExtreme': '25',
  'flag': 'KR',
  'lengthOverall': '147',
  'callsign': 'D7OT',
  'liquidOil': null,
  'vesselType': 'CONTAINER SHIP',
  'yardNumber': 'HN. 670',
  'owner': 'DONG JIN SHIPPING CO LTD',
  'placeOfBuild': 'MATSUYAMA JAPAN',
  'technicalManager': 'DONG JIN SHIPPING CO LTD',
  'build': '2005',
  'liquidGas': null,
  'builder': 'HAKATA SHIPBUILDING CO LTD',
  'speedMax': null,
  'manager': 'DONG JIN SHIPPING CO LTD',
  'speedService': '18',
  'timestamp': 1648156961923,
  'imo': '9360283',
  'draught': '8.97',
  'mmsi': '440389000',
  'netTonnage': '5614',
  'displacementSummer': null,
  'lengthBWPerpendiculars': '135',
  'eni': null,
  'fuelConsumption': '36 t/day at 17.50 kn'
};
