import {Accordion, AccordionDetails, AccordionSummary, Chip, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import {useDroneSurveysContext} from '../../context/droneSurveys/droneSurveysContext';


export default function SurveyItem({survey}) {
  const {state, dispatch} = useDroneSurveysContext();
  const {selectedSurvey, selectedMeasurement, hoveredItem} = state;
  const setSelectedMeasurement = m => dispatch({type: 'set-selectedMeasurement', selectedMeasurement: m});
  const isSelected = survey?.sessionName === selectedSurvey?.sessionName;
  const isHovered = survey?.sessionName === hoveredItem?.sessionName;

  const {sessionName, dateStr, measurements} = survey;

  const onAccordionClick = () => {
    if (selectedSurvey?.sessionName === survey.sessionName) {
      dispatch({type: 'set-selectedSurvey', selectedSurvey: null});
      setSelectedMeasurement(null);
    } else {
      dispatch({type: 'set-selectedSurvey', selectedSurvey: survey});
      setSelectedMeasurement(measurements[0]);
    }
  };

  const onChipClick = (m) => {
    setSelectedMeasurement(m);
  };

  return <Accordion
    expanded={isSelected}
    elevation={0}
    sx={{
      mx: 1,
      my: 2,
      backgroundColor: "inherit",
      border: "none",
    }}
    onMouseEnter={() => dispatch({type: 'set-hoveredItem', hoveredItem: survey})}
    onMouseLeave={() => dispatch({type: 'set-hoveredItem', hoveredItem: null})}
  >
    <AccordionSummary
      onClick={onAccordionClick}
      sx={{
        padding: 0, margin: 0, width: '100%',
        backgroundColor: isSelected ? '#00d2ff' : isHovered ? '#9bedff' : 'inherit' ,
        '&:hover': {backgroundColor: isSelected ? '#00d2ff' : '#9bedff'}
      }}
    >
      <Box sx={{display: 'flex', flexDirection: 'column', pl: 1}}>
        <Typography>{sessionName.replaceAll('_', ' ')} </Typography>
        <Typography sx={{fontSize: '11px'}} variant={'caption'}>{dateStr}</Typography>
      </Box>
    </AccordionSummary>
    <AccordionDetails sx={{backgroundColor: "inherit"}}>
      {measurements.map(m => <Chip
        key={m}
        sx={{p: 1, width: '100%', marginTop: '1em'}}
        variant={selectedMeasurement === m ? 'filled' : 'outlined'} label={m}
        onClick={() => onChipClick(m)}/>
      )}
    </AccordionDetails>
  </Accordion>;
}
