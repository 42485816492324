import React, {useMemo, useReducer} from 'react';
import PropTypes from 'prop-types';

const initialState = () => {
  return {
    // { "PM10" : { "ELB_01": [10,1121], [... , ...] ... }, { "ELB_02" : []}, ...
    // }
    data: {},
  };
};


const AnalyticsContext = React.createContext(initialState());
// expose the context via a custom hook.
export const useAnalyticsContext = () => React.useContext(AnalyticsContext);

function reducer(state, action) {
  switch (action.type) {
  case 'set-data':
    return {
      ...state,
      data: action.data
    };
  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function AnalyticsContextProvider({children}) {
  const [state, dispatch] = useReducer(reducer, initialState());
  const userContext = useMemo(() => {
    return {
      state,
      dispatch
    };
  }, [state]);

  return <AnalyticsContext.Provider value={userContext}>
    {children}
  </AnalyticsContext.Provider>;
}

AnalyticsContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};
