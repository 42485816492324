// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiInput from 'components/SuiInput';
import Box from '@mui/material/Box';
import {Input, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';

function FormField({label, suffix, ...rest}) {
  return (
    <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}:
        </SuiTypography>
      </SuiBox>
      <Box display={'flex'} alignItems={'center'} gap={2} justifyContent={'center'}>
        <TextField sx={{width: 80}} size={'small'}  {...rest} />
        {suffix && <Typography variant="body2">
          {suffix}
        </Typography>}
      </Box>
    </SuiBox>
  );
}

// Setting default values for the props of FormField
FormField.defaultProps = {
  label: ' ',
};

// Typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string,
};

export default FormField;
