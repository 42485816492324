import React, {useEffect, useState} from 'react';
import Highcharts from 'highcharts/highstock';
import {HighchartsChart, HighchartsProvider} from 'react-jsx-highstock';
import {getWindRoseStation} from '../../api';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';

export default function WindRoseChart({device, selectedDate}) {
  // const {clientId} = useConfig().state.config;
  const clientId = 'ugw';
  const [windRoseData, setWindRoseData] = useState();
  const [loading, setLoading] = useState(true);

  // Pobranie danych meteo do wind-rose
  useEffect(() => {
    getWindRoseStation(clientId, device.serialNo, selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : '2024-07-12')
      .then(result => setWindRoseData(result))
      .finally(() => setLoading(false));
  }, [device, selectedDate]);

  return <HighchartsProvider Highcharts={Highcharts}>
    {loading ? <CircularProgress size={100} sx={{ marginTop: 10}}
    /> : <HighchartsChart
      chart={{polar: true, type: 'column', width: 500, height: 400}}
      // title={{text: 'Wind Rose'}}
      pane={{size: '85%'}}
      legend={{
        align: 'right',
        verticalAlign: 'top',
        y: 100,
        layout: 'vertical'
      }}
      xAxis={{
        categories: ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'],
        tickmarkPlacement: 'on',
        // lineWidth: 0
      }}
      yAxis={{
        min: 0,
        endOnTick: false,
        showLastLabel: true,
        title: {
          text: 'Frequency (%)'
        },
        labels: {
          formatter: function () {
            return this.value + '%';
          }
        },
        reversedStacks: false
      }}
      tooltip={{valueSuffix: '%'}}
      plotOptions={{
        series: {
          stacking: 'normal',
          shadow: false,
          groupPadding: 0,
          pointPlacement: 'on'
        }
      }}
      series={windRoseData}
    >
    </HighchartsChart>}
  </HighchartsProvider>;
}


//series={[{
//         name: '0-1 m/s',
//         // data: [1.81, 1.78, 1.84, 2.19, 2.85, 2.80, 2.81, 2.38, 1.92, 1.91, 2.27, 2.44, 2.48, 2.43, 2.15, 1.87],
//         data: [1.81, 1.78, 1.84, 2.19, 2.85, 2.80, 2.81, 2.38],
//         color: '#7cb5ec'
//       }, {
//         name: '1-2 m/s',
//         data: [2.96, 2.94, 2.98, 3.32, 4.17, 4.11, 4.10, 3.59],
//         color: '#434348'
//       }, {
//         name: '2-3 m/s',
//         data: [3.50, 3.47, 3.52, 3.90, 4.90, 4.82, 4.80, 4.21],
//         color: '#90ed7d'
//       }, {
//         name: '3-4 m/s',
//         data: [2.42, 2.41, 2.44, 2.71, 3.40, 3.34, 3.32, 2.91],
//         color: '#f7a35c'
//       }, {
//         name: '4-5 m/s',
//         data: [1.20, 1.19, 1.21, 1.35, 1.70, 1.67, 1.66, 1.45],
//         color: '#8085e9'
//       }, {
//         name: '5-6 m/s',
//         data: [0.47, 0.47, 0.48, 0.54, 0.68, 0.67, 0.66, 0.58],
//         color: '#f15c80'
//       }, {
//         name: '6-7 m/s',
//         data: [0.14, 0.14, 0.14, 0.16, 0.20, 0.20, 0.20, 0.17],
//         color: '#e4d354'
//       }, {
//         name: '7-8 m/s',
//         data: [0.05, 0.05, 0.05, 0.06, 0.07, 0.07, 0.07, 0.06],
//         color: '#2b908f'
//       }, {
//         name: '8-9 m/s',
//         data: [0.01, 0.01, 0.01, 0.01, 0.02, 0.02, 0.02, 0.01],
//         color: '#f45b5b'
//       }, {
//         name: '9-10 m/s',
//         data: [0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01],
//         color: '#91e8e1'
//       }]}
