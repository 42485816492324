import React, {useState} from 'react';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';


const QuickDateButtons = ({onClick, inverted, days}) => {
  const [selected, setSelected] = useState(days || 1);

  const setDateRange = days => {
    setSelected(days);
    onClick(days);
  };

  return <Box color={"white"} display={"flex"} justifyContent={'space-around'}>
    <Button
      color={(inverted && !(selected === 1)) ? 'inherit' : 'primary'}
      variant={selected === 1 ? 'contained' : 'outlined'}
      onClick={() => setDateRange(1)}>
      24H
    </Button>
    {' '}
    <Button
      size={'small'}
      color={(inverted && !(selected === 7)) ? 'inherit' : 'primary'}
      variant={selected === 7 ? 'contained' : 'outlined'}
      onClick={() => setDateRange(7)}>
      7D
    </Button>
    {' '}
    <Button
      size={'small'}
      color={(inverted && !(selected === 30)) ? 'inherit' : 'primary'}
      variant={selected === 30 ? 'contained' : 'outlined'}
      onClick={() => setDateRange(30)}>1M</Button>
  </Box>
};

export default QuickDateButtons;

