import {Marker} from '@urbica/react-map-gl';
import React from 'react';
import Box from '@mui/material/Box';
import YachtIcon from '../../assets/images/droneMission/yacht-yellow.png';
import {primaryColor} from '../PortManagement/DockStats/colors';


export default function SurveyMarker(survey, dispatch, onClick) {
  return <Marker
    key={survey.sessionName}
    longitude={survey.location[0]}
    latitude={survey.location[1]}
    anchor="top-left"
    offset={[-11, -11]}
  >
    <Box
      p={1}
      px={1.5}
      sx={{
        zIndex: 10,
        backgroundColor: primaryColor[2],
        borderRadius: '50%'
      }}
      onMouseEnter={() => dispatch({type: 'set-hoveredItem', hoveredItem: survey})}
      onMouseLeave={() => dispatch({type: 'set-hoveredItem', hoveredItem: null})}
      onClick={onClick}>
      <img style={{marginTop: 2}} width={30} height={30} src={YachtIcon}/>
    </Box>
  </Marker>;
}
