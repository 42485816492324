import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Highcharts from 'highcharts/highstock';
import {
  Chart,
  HighchartsProvider,
  HighchartsStockChart,
  Legend,
  LineSeries,
  SplineSeries,
  Title,
  Tooltip,
  XAxis,
  YAxis,
} from 'react-jsx-highstock';

import applyExporting from 'highcharts/modules/exporting';
import applyOffline from 'highcharts/modules/offline-exporting';
import applyNoData from 'highcharts/modules/no-data-to-display';
import Box from '@mui/material/Box';
import {dangerColor, infoColor, MOBILE_SCREEN, primaryColor} from '../../assets/jss/nextjs-material-dashboard';
import {getSurveysChartMeasurements} from '../../api_surveys';
import {IconButton, Typography, useMediaQuery} from "@mui/material";
import {Close} from "@mui/icons-material";
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';


applyExporting(Highcharts);
applyOffline(Highcharts);
// applyNoData(Highcharts);

const NUMBER_OF_BUCKETS = 70;

const titleChartStyle = {
  color: primaryColor[2],
}


const plotHorizontalNoiseLines = [{
  zIndex: 99,
  value: 65,
  color: 'red',
  dashStyle: 'shortdash',
  width: 2
}];

const timeOptions = {
  useUTC: false
};

const options = {};

// Conductivity of Water - TYPICAL RANGES
const drinkWater = [{
  zIndex: 99,
  value: 1500,
  color: 'lightblue',
  dashStyle: 'shortdash',
  width: 2
}];


// MUST READ -> https://www.fao.org/3/ac183e/AC183E00.htm#TOC
export default function SurveyWaterChart({sessionName, dateStr, onDatapointHover, onClose}) {
  const [data, setData] = useState({});
  const {temp, ph, ec, turbidity, orp, o2} = data;
  const title = sessionName.replaceAll('_', ' ') + ' - ' + dateStr;
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);

  const setHoverData = useCallback((e) => {
    const measurementId = e.target.series.userOptions.id;
    const timestamp = e.target.x;
    if (data[measurementId + '_GPS']) {
      const location = data[measurementId + '_GPS'].filter(d => d[0] === timestamp)[0];
      onDatapointHover(location[1]);
    }
  }, [data]);

  const plotOptions = useMemo(() => ({
    column: {
      pointWidth: 6,
      maxPointWidth: 50,
      minPointLength: 3, // By default, 0 values are not shown. To visualize a 0 (or close to zero) point, set the minimal point length to a pixel value like 3
      borderWidth: 0
    },
    series: {
      shadow: true,
      point: {
        events: {
          mouseOver: setHoverData.bind(this)
        }
      }
      // dataGrouping: {
      //   approximation: 'average',
      //   groupAll: true,
      //   groupPixelWidth: 20
      // }
    }
  }), [data]);

  useEffect(() => {
    // chart.showLoading('Loading...');
    getSurveysChartMeasurements(sessionName)
      .then(result => setData(result))
      .then(err => console.error(err));

    // setData(MOCK_DATA);
  }, [sessionName]);

  return (<Box sx={{display: "flex", justifyContent: "center"}}>
      <Box sx={{position: "absolute",
        bottom: isMobile ? -35 : 0,
        left: isMobile ? 20 : 0,
        width: isMobile ? "100vw" :"90vw",
        zIndex: 2}}>
        <HighchartsProvider Highcharts={Highcharts}>
          {isMobile && <Box
            sx={{position: 'absolute', top: 35, zIndex: 1, left: 80, display: "flex",}}>
            <ScreenRotationIcon sx={{mr: 2, width: 20, height: 20}}/>
            <Typography variant={"caption"}> Obróć dla pełnego widoku wykresu</Typography>
          </Box>
          }
          <IconButton
            sx={{
              position: "absolute", top: 10, left: 10, zIndex: 1,
              '@media screen and (max-width: 50em)': {left: 0, top: 0}
            }
            }
              onClick={onClose}>
            <Close/>
          </IconButton>
          <HighchartsStockChart options={options} plotOptions={plotOptions} time={timeOptions}>
            <Chart zooming={{type: 'x'}} panning={{enabled: true}} panKey={'shift'} />
            <Legend align={'left'} verticalAlign={'top'} x={40} y={30} useHTML={true}/>
            <Title
              style={titleChartStyle}
              align={'center'}>{title}
            </Title>
            <Tooltip split valueDecimals={2}/>
            <XAxis
              // range={15 * 24 * 60 * 60 * 1000}
            />

            {/* TEMP LINE CHART */}
            <YAxis
              // min={0}
              // max={40}
              opposite={false}
              // plotLines={plotHorizontalNoiseLines}
              useHTML={true}>
              <YAxis.Title>Temp °C</YAxis.Title>

              <LineSeries
                data={temp}
                id="temp"
                name={'Temp'}
                color={dangerColor[2]}
                tooltip={{valueSuffix: ' [°C]'}}
                zones={[{value: 15, color: 'blue'}, {color: 'red'}]}
              />
            </YAxis>

            {/* PH LINE CHART */}
            <YAxis
              labels={{enabled: false}}
              min={0}
              max={14}
              opposite={true}
              useHTML={true}>
              {/*<YAxis.Title>pH </YAxis.Title>*/}
              <SplineSeries
                data={ph}
                visible={false}
                id="ph"
                name={'Ph'}
                tooltip={{valueSuffix: ''}}
                zones={WATER_PH_LEVELS}
                color={'green'}/>
            </YAxis>

            {/* CONDUCTIVITY / SALINITY / TDS - LINE CHART*/}
            <YAxis
              // min={0}
              // max={4000}
              opposite={true}
              labels={{enabled: false}}
              plotLines={drinkWater}
              useHTML={true}>
              {/*<YAxis.Title>Conductivity [mS]</YAxis.Title>*/}
              <SplineSeries
                data={ec}
                visible={false}
                id="ec"
                name={'Conductivity'}
                color={infoColor[1]}
                dashStyle={'ShortDot'}
                tooltip={{
                  valueSuffix: ' [µS/cm]',
                  valueDecimals: 0
                }}
                zones={WATER_PH_LEVELS}
              />
            </YAxis>

            {/* TURBIDITY */}
            <YAxis useHTML={true} labels={{enabled: false}}>
              <LineSeries
                data={turbidity}
                visible={false}
                id="turbidity"
                name={'Turbidity'}
                color={primaryColor[1]}
                dashStyle={'LongDashDot'}
                tooltip={{
                  valueSuffix: ' [NTU]',
                  valueDecimals: 0
                }}
              />
            </YAxis>

            {/* ORP */}
            <YAxis useHTML={true} labels={{enabled: false}}>
              <LineSeries
                data={orp}
                visible={false}
                id="orp"
                name={'ORP'}
                color={'gray'}
                opacity={0.5}
                dashStyle={'Dot'}
                tooltip={{
                  valueSuffix: ' [mV]',
                  valueDecimals: 0
                }}
              />
            </YAxis>

            {/* Dissolved Oxygen */}
            <YAxis useHTML={true}
                   labels={{enabled: false}}
            >
              {/*<YAxis.Title>Dissolved Oxygen [mg/L]</YAxis.Title>*/}
              <LineSeries
                data={data.do}
                id="do"
                name={'Dissolved Oxygen'}
                color={'#8F00FF'}
                dashStyle={'LongDash'}
                tooltip={{
                  valueSuffix: ' [mg/L]'
                }}
              />
            </YAxis>

            {/* Saturation O2 */}
            <YAxis useHTML={true}>
              <YAxis.Title>Saturation O2 [%]</YAxis.Title>
              <LineSeries
                data={o2}
                id="o2"
                name={'Saturation O2 [%]'}
                color={'#f99bff'}
                dashStyle={'LongDash'}
                tooltip={{
                  valueSuffix: ' [%]'
                }}
              />
            </YAxis>
          </HighchartsStockChart>
        </HighchartsProvider>
      </Box>
    </Box>
  );
}

const WATER_PH_LEVELS = [
  {
    value: 1,
    color: '#da3b33'
  }, {
    value: 2,
    color: '#f0c846'
  }, {
    value: 3,
    color: '#f4ee54'
  }, {
    value: 4,
    color: '#bbd252'
  }, {
    value: 5,
    color: '#92c055'
  }, {
    value: 6,
    color: '#6bb558'
  }, {
    value: 7,
    color: '#57a656'
  }, {
    value: 8,
    color: '#57b272'
  }, {
    value: 9,
    color: '#52b1b3'
  }, {
    value: 10,
    color: '#598fc8'
  }, {
    value: 11,
    color: '#3e539f'
  }, {
    value: 12,
    color: '#59519d'
  }, {
    value: 13,
    color: '#5e4798'
  }, {
    value: 14,
    color: '#422d7f'
  }
];

const MOCK_DATA = {
  'temp': [[1661950800000, 18.0], [1661952600000, 18.0], [1661954400000, 18.0], [1661956200000, 18.0], [1661958000000, 18.0], [1661959800000, 18.0], [1661961600000, 18.0], [1661963400000, 18.0], [1661965200000, 18.0], [1661967000000, 18.0], [1661968800000, 18.0], [1661970600000, 18.06], [1661972400000, 18.0], [1661974200000, 18.06], [1661976000000, 18.06], [1661977800000, 18.06], [1661979600000, 18.06], [1661981400000, 18.06], [1661983200000, 18.06], [1661985000000, 18.06], [1661986800000, 18.06], [1661988600000, 18.06], [1661990400000, 18.06], [1661992200000, 18.06], [1661994000000, 18.06], [1661995800000, 18.06], [1661997600000, 18.13], [1661999400000, 18.13], [1662001200000, 18.06], [1662003000000, 18.13], [1662004800000, 18.13], [1662006600000, 18.13], [1662008400000, 18.13], [1662010200000, 18.13], [1662012000000, 18.13], [1662013800000, 18.13], [1662015600000, 18.13], [1662017400000, 18.13], [1662019200000, 18.13], [1662021000000, 18.13], [1662022800000, 18.13], [1662026400000, 18.13], [1662028200000, 18.13], [1662030000000, 18.13], [1662033600000, 18.13], [1662035400000, 18.19]],
  'temp_GPS': [[1661950800000, 18.0], [1661952600000, 18.0], [1661954400000, 18.0], [1661956200000, 18.0], [1661958000000, 18.0], [1661959800000, 18.0], [1661961600000, 18.0], [1661963400000, 18.0], [1661965200000, 18.0], [1661967000000, 18.0], [1661968800000, 18.0], [1661970600000, 18.06], [1661972400000, 18.0], [1661974200000, 18.06], [1661976000000, 18.06], [1661977800000, 18.06], [1661979600000, 18.06], [1661981400000, 18.06], [1661983200000, 18.06], [1661985000000, 18.06], [1661986800000, 18.06], [1661988600000, 18.06], [1661990400000, 18.06], [1661992200000, 18.06], [1661994000000, 18.06], [1661995800000, 18.06], [1661997600000, 18.13], [1661999400000, 18.13], [1662001200000, 18.06], [1662003000000, 18.13], [1662004800000, 18.13], [1662006600000, 18.13], [1662008400000, 18.13], [1662010200000, 18.13], [1662012000000, 18.13], [1662013800000, 18.13], [1662015600000, 18.13], [1662017400000, 18.13], [1662019200000, 18.13], [1662021000000, 18.13], [1662022800000, 18.13], [1662026400000, 18.13], [1662028200000, 18.13], [1662030000000, 18.13], [1662033600000, 18.13], [1662035400000, 18.19]],
  'ph': [[1661950800000, 7.343], [1661952600000, 7.345], [1661954400000, 7.346], [1661956200000, 7.349], [1661958000000, 7.349], [1661959800000, 7.351], [1661961600000, 7.35], [1661963400000, 7.359], [1661965200000, 7.355], [1661967000000, 7.358], [1661968800000, 7.357], [1661970600000, 7.357], [1661972400000, 7.358], [1661974200000, 7.365], [1661976000000, 7.369], [1661977800000, 7.373], [1661979600000, 7.375], [1661981400000, 7.375], [1661983200000, 7.373], [1661985000000, 7.371], [1661986800000, 7.372], [1661988600000, 7.363], [1661990400000, 7.367], [1661992200000, 7.367], [1661994000000, 7.371], [1661995800000, 7.37], [1661997600000, 7.372], [1661999400000, 7.373], [1662001200000, 7.371], [1662003000000, 7.371], [1662004800000, 7.371], [1662006600000, 7.375], [1662008400000, 7.375], [1662010200000, 7.379], [1662012000000, 7.382], [1662013800000, 7.385], [1662015600000, 7.387], [1662017400000, 7.389], [1662019200000, 7.386], [1662021000000, 7.385], [1662022800000, 7.388], [1662026400000, 7.389], [1662028200000, 7.387], [1662030000000, 7.386], [1662033600000, 7.383], [1662035400000, 7.39]],
  'ec': [[1661950800000, 1339.9], [1661952600000, 1342.0], [1661954400000, 1338.1], [1661956200000, 1346.0], [1661958000000, 1336.0], [1661959800000, 1334.1], [1661961600000, 1342.0], [1661963400000, 1342.2], [1661965200000, 1348.3], [1661967000000, 1345.9], [1661968800000, 1345.8], [1661970600000, 1345.9], [1661972400000, 1348.1], [1661974200000, 1342.1], [1661976000000, 1336.0], [1661977800000, 1333.9], [1661979600000, 1339.7], [1661981400000, 1344.2], [1661983200000, 1346.1], [1661985000000, 1336.4], [1661986800000, 1338.2], [1661988600000, 1337.9], [1661990400000, 1338.1], [1661992200000, 1346.0], [1661994000000, 1340.1], [1661995800000, 1342.1], [1661997600000, 1339.9], [1661999400000, 1334.2], [1662001200000, 1340.3], [1662003000000, 1338.5], [1662004800000, 1334.5], [1662006600000, 1334.0], [1662008400000, 1333.7], [1662010200000, 1338.5], [1662012000000, 1334.2], [1662013800000, 1348.4], [1662015600000, 1338.1], [1662017400000, 1346.6], [1662019200000, 1333.9], [1662021000000, 1342.4], [1662022800000, 1334.6], [1662026400000, 1346.4], [1662028200000, 1338.7], [1662030000000, 1340.6], [1662033600000, 1348.3], [1662035400000, 1342.7]],
  'turbidity': [[1661950800000, 2260.55], [1661952600000, 2262.79], [1661954400000, 2254.12], [1661956200000, 2263.25], [1661958000000, 2251.88], [1661959800000, 2252.86], [1661961600000, 2260.72], [1661963400000, 2274.83], [1661965200000, 2276.45], [1661967000000, 2276.19], [1661968800000, 2274.98], [1661970600000, 2281.47], [1661972400000, 2287.36], [1661974200000, 2283.7], [1661976000000, 2289.2], [1661977800000, 2296.77], [1661979600000, 2298.54], [1661981400000, 2301.54], [1661983200000, 2300.31], [1661985000000, 2307.54], [1661986800000, 2300.14], [1661988600000, 2309.67], [1661990400000, 2314.55], [1661992200000, 2315.12], [1661994000000, 2313.97], [1661995800000, 2318.71], [1661997600000, 2326.41], [1661999400000, 2316.21], [1662001200000, 2324.76], [1662003000000, 2318.8], [1662004800000, 2320.16], [1662006600000, 2331.77], [1662008400000, 2337.33], [1662010200000, 2345.48], [1662012000000, 2339.81], [1662013800000, 2342.94], [1662015600000, 2344.96], [1662017400000, 2349.96], [1662019200000, 2352.5], [1662021000000, 2357.68], [1662022800000, 2350.05], [1662026400000, 2350.79], [1662028200000, 2357.69], [1662030000000, 2371.47], [1662033600000, 2366.73], [1662035400000, 2362.02]],
  'o2': [[1661950800000, 90.1], [1661952600000, 90.0], [1661954400000, 89.6], [1661956200000, 89.3], [1661958000000, 89.5], [1661959800000, 89.7], [1661961600000, 89.7], [1661963400000, 89.6], [1661965200000, 89.6], [1661967000000, 89.6], [1661968800000, 89.6], [1661970600000, 89.6], [1661972400000, 89.6], [1661974200000, 89.6], [1661976000000, 89.6], [1661977800000, 89.6], [1661979600000, 89.6], [1661981400000, 89.6], [1661983200000, 89.6], [1661985000000, 89.6], [1661986800000, 89.6], [1661988600000, 89.6], [1661990400000, 89.6], [1661992200000, 89.6], [1661994000000, 89.6], [1661995800000, 89.6], [1661997600000, 89.6], [1661999400000, 89.6], [1662001200000, 89.6], [1662003000000, 89.7], [1662004800000, 89.7], [1662006600000, 89.7], [1662008400000, 89.6], [1662010200000, 89.7], [1662012000000, 89.7], [1662013800000, 89.7], [1662015600000, 89.7], [1662017400000, 89.8], [1662019200000, 89.8], [1662021000000, 89.8], [1662022800000, 89.8], [1662026400000, 89.9], [1662028200000, 89.9], [1662030000000, 89.9], [1662033600000, 89.9], [1662035400000, 89.9]],
  'orp': [[1661950800000, 282.5], [1661952600000, 283.6], [1661954400000, 284.3], [1661956200000, 284.4], [1661958000000, 285.0], [1661959800000, 285.3], [1661961600000, 285.9], [1661963400000, 286.0], [1661965200000, 286.6], [1661967000000, 286.9], [1661968800000, 287.2], [1661970600000, 287.6], [1661972400000, 287.8], [1661974200000, 287.9], [1661976000000, 288.1], [1661977800000, 288.0], [1661979600000, 288.4], [1661981400000, 288.3], [1661983200000, 288.4], [1661985000000, 288.7], [1661986800000, 289.3], [1661988600000, 289.9], [1661990400000, 290.0], [1661992200000, 290.3], [1661994000000, 290.7], [1661995800000, 291.0], [1661997600000, 291.4], [1661999400000, 291.6], [1662001200000, 292.0], [1662003000000, 292.2], [1662004800000, 292.7], [1662006600000, 292.6], [1662008400000, 292.7], [1662010200000, 293.1], [1662012000000, 293.3], [1662013800000, 293.2], [1662015600000, 293.2], [1662017400000, 293.4], [1662019200000, 293.6], [1662021000000, 293.9], [1662022800000, 293.9], [1662026400000, 294.0], [1662028200000, 294.2], [1662030000000, 294.3], [1662033600000, 294.7], [1662035400000, 294.9]],
  'do': [[1661950800000, 8.52], [1661952600000, 8.51], [1661954400000, 8.47], [1661956200000, 8.45], [1661958000000, 8.46], [1661959800000, 8.49], [1661961600000, 8.48], [1661963400000, 8.48], [1661965200000, 8.47], [1661967000000, 8.47], [1661968800000, 8.47], [1661970600000, 8.47], [1661972400000, 8.47], [1661974200000, 8.47], [1661976000000, 8.47], [1661977800000, 8.47], [1661979600000, 8.47], [1661981400000, 8.47], [1661983200000, 8.47], [1661985000000, 8.47], [1661986800000, 8.48], [1661988600000, 8.47], [1661990400000, 8.47], [1661992200000, 8.48], [1661994000000, 8.48], [1661995800000, 8.48], [1661997600000, 8.48], [1661999400000, 8.48], [1662001200000, 8.48], [1662003000000, 8.48], [1662004800000, 8.48], [1662006600000, 8.48], [1662008400000, 8.48], [1662010200000, 8.48], [1662012000000, 8.48], [1662013800000, 8.48], [1662015600000, 8.48], [1662017400000, 8.49], [1662019200000, 8.49], [1662021000000, 8.49], [1662022800000, 8.5], [1662026400000, 8.5], [1662028200000, 8.5], [1662030000000, 8.5], [1662033600000, 8.5], [1662035400000, 8.5]]
};
