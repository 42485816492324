import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {dangerColor, successColor, warningColor} from '../../assets/jss/nextjs-material-dashboard';

export const PM10_COLORS = [
    successColor[2], successColor[0], '#FFD911', warningColor[0], dangerColor[0]
  ];

export default function MapLegend() {
  const {t} = useTranslation();
  const labels = t('general.mapLegendLevels', {returnObjects: true});

  return <Box sx={{
    px: 1,
    pb: 2,
    borderRadius: 2,
    color: 'white',
    backgroundColor: 'rgba(6,47,79,0.8)',
    cursor: 'pointer',
    textAlign: 'center',
  }}>
    <Typography textTransform={'uppercase'} variant={'caption'} fontSize={10}>
      {t('general.mapLegendTitle')}
    </Typography>

    <Box sx={{display: 'flex', gap: 0.4}}>
      {[0, 1, 2, 3, 4].map(lvl => ({lvl, color: PM10_COLORS[lvl], label: labels[lvl]}))
        .map(({lvl, label, color}) =>
            <Box key={lvl} sx={{
              backgroundColor: color,
              width: 100,
              borderRadius: 1,
              lineHeight: 1,
              position: 'relative'
            }}>

              <Typography textTransform={'uppercase'} variant={'caption'} fontSize={10}>{label}</Typography>
            </Box>
        )}
    </Box>
  </Box>;
}
