import React, {useEffect, useState} from 'react';
import {Slider, Typography, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import {makeStyles} from '@mui/styles';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import {MOBILE_SCREEN, primaryColor} from '../../assets/jss/nextjs-material-dashboard';
import PauseIcon from '@mui/icons-material/Pause';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {useMeasurementsContext} from '../../context/measurements/measurementsContext';
import moment from 'moment';

const TimeLabel = ({children, short}) => <Box>
  <Box width={2} height={short ? 10 : 20}
       sx={{backgroundColor: 'rgba(0,0,0,0.4)', marginLeft: '1.1em', marginTop: -1.3}}/>
  {!short && children}
</Box>;

const INTERVAL_SECONDS = 1;

const useStyles = makeStyles(theme => ({
  margin: {
    height: theme.spacing(3)
  },
  thumb: {
    background: '#ffffff',
    height: 20
  },
  markLabel: {
    color: '#ffffff',
    fontSize: '12px',
  },
  mark: {
    display: 'none',
  },
  rail: {
    backgroundColor: 'rgba(0,0,0,0.9)',
    height: '10px',
  },
  track: {
    display: 'none'
  },
}));


export default function CustomSlider() {
  const {t} = useTranslation();
  const [icon, setIcon] = useState(false);
  const [iconText, setIconText] = useState('Play');
  const classes = useStyles();

  const {state: {sliderPosition, selectedDate}, dispatch: sliderDispatch} = useMeasurementsContext();

  const value = sliderPosition * 1000;
  const setValue = val => sliderDispatch({type: 'set-slider-position', value: Math.round(Math.min(val / 1000, 23))});

  // const [value, setValue] = React.useState(0);
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);
  const once_per_three_hours = useMediaQuery('(max-width:1400px)');
  const once_per_six_hours = useMediaQuery('(max-width:1000px)');
  const denominator = once_per_six_hours ? 6 : once_per_three_hours ? 3 : 1;
  const [isPlaying, setPlaying] = useState(false);

  const startHour = moment(selectedDate).isSame(moment(), 'day') ? new Date().getHours() : 0;
  const norm = val => val % 24;
  const shift = (val, hours) => norm(val + hours + 1);

  const marks = _.range(0, 24).map(i => ({
    value: i * 1000,
    tooltipLabel: shift(i, startHour) > 9 ? shift(i, startHour) + ':00' : '0' + shift(i, startHour) + ':00',
    label: <TimeLabel
      short={(i + 1) % denominator}>{shift(i, startHour) > 9 ? shift(i, startHour) + ':00' : '0' + shift(i, startHour) + ':00'}</TimeLabel>
  }));


  const labelText = (value) => {
    const idx = Math.round((value / 1000) % marks.length);
    // const d2 = new Date();
    // d2.setDate(d2.getDate() - 1);

    // const today = new Date().toLocaleString(window.navigator.language, {weekday: 'short'});
    // const date = moment(selectedDate).subtract(22-idx,'hours').format('DD.MM.YYYY');
    const date = moment(selectedDate).subtract(22-idx,'hours').format('DD.MM.YYYY');
    // const yesterday = d2.toLocaleString(window.navigator.language, {weekday: 'short'});
    // const dayName = shift(value / 1000, startHour) > startHour ? yesterday : today;
    return <span>{date} <b>{marks[idx]?.tooltipLabel}</b></span>;
  };

  const play = () => {
    setIcon(true);
    setValue(0);
    setIconText(t('customSlider.pause'));
    setPlaying(true);
  };

  const pause = () => {
    setIcon(false);
    setIconText(t('customSlider.play'));
    setPlaying(false);
  };

  useEffect(() => {
    if (isPlaying && value < (marks.length - 1) * 1000) {
      setTimeout(() => {
        // fetchMeasurements(value + 1);
        setValue(value + 1000);
      }, 1000 * INTERVAL_SECONDS);
    } else {
      pause();
    }
  }, [value, isPlaying]);

  const onForward = () => {
    value < (marks.length - 1) * 1000 && setValue(value + 1000);
  };

  const onBack = () => {
    value > 0 && setValue(value - 1000);
  };


  return (
    <Box sx={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '90px',
      padding: ' 0.5em 1.6em',
      background: `linear-gradient(to top, rgba(25, 34, 49,.5), rgba(25, 34, 49, 0))`,
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      {!isMobile && <>
        <Box mr={1} mt={-1}>
          <Box sx={{
            cursor: "pointer",
            width: 40, height: 40, marginBottom: 0.7, textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: primaryColor[2],
            backgroundColor: 'white',
            borderRadius: '50%'
          }}
               onClick={icon ? pause : play}>
            {icon ? <PauseIcon p={0} m={0}/> : <PlayArrowRoundedIcon p={0} m={0}/>}
          </Box>
          <Typography sx={{marginLeft: '.3em'}} variant={'caption'} color={'white'}>{iconText}</Typography>
        </Box>
        <Box ml={1} mt={-1}>
          <FastRewindIcon
            onClick={onBack}
            sx={{
              cursor: "pointer",
              width: 40, height: 40, marginLeft: '1em', color: primaryColor[2], backgroundColor: 'white',
              borderRadius: '50%', padding: 0.8
            }}/>
          <Typography sx={{marginLeft: '1em'}} variant={'caption'}
                      color={'white'}>{t('customSlider.previous')}</Typography>
        </Box>
      </>}
      <Slider
        sx={{width: isMobile ? '100%' : '90%'}}
        value={value}
        valueLabelFormat={labelText}
        classes={{
          thumb: classes.thumb,
          rail: classes.rail,
          track: classes.track,
          mark: classes.mark,
          markLabel: classes.markLabel,
        }}
        aria-label={'Meteo Slider'}
        valueLabelDisplay={isMobile ? 'auto' : 'on'}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        onChangeCommitted={(e, val) => {
          const roundedValue = Math.round(val / 1000);
          setValue(roundedValue * 1000);
        }}
        min={-500}
        max={23500}
        marks={marks}
      >
      </Slider>
      {!isMobile && <Box ml={3} mt={-1}>
        <FastForwardIcon
          onClick={onForward}
          sx={{
            cursor: "pointer",
            width: 40, height: 40, color: primaryColor[2], backgroundColor: 'white',
            borderRadius: '50%', padding: 0.8
          }}/>
        <Typography variant={'caption'} color={'white'}>{t('customSlider.next')}</Typography>
      </Box>}

      {isMobile &&
      <Box position={"absolute"} width={"90%"} bottom={70} display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"} flexDirection={"row"}>
          <Box sx={{
            cursor: "pointer",
            width: 40, height: 40, marginBottom: 0.7, textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: primaryColor[2],
            backgroundColor: 'white',
            borderRadius: '50%'
          }}
               onClick={isPlaying ? pause : play}>
            {isPlaying ? <PauseIcon p={0} m={0}/> : <PlayArrowRoundedIcon p={0} m={0}/>}
          </Box>
          <Box>
            <FastForwardIcon
              onClick={onForward}
              sx={{
                cursor: "pointer",
                width: 40, height: 40, color: primaryColor[2], backgroundColor: 'white',
                borderRadius: '50%', padding: 0.8, marginLeft: 3
              }}/>
          </Box>
        </Box>

        <Box position={"absolute"} right={0}>
          <FastRewindIcon
            onClick={onBack}
            sx={{
              cursor: "pointer",
              width: 40, height: 40, marginLeft: '1em', color: primaryColor[2], backgroundColor: 'white',
              borderRadius: '50%', padding: 0.8
            }}/>
        </Box>
      </Box>
      }
    </Box>
  );
}
