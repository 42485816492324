import React, {useState} from 'react';
import {CircularProgress, IconButton, Select, TextField, useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import {Close, Search} from '@mui/icons-material';
import {getVesselData, getVesselImg, searchVessel} from '../../../api';
import Typography from '@mui/material/Typography';
import {primaryColor} from '../../../assets/jss/nextjs-material-dashboard';
import {useConfig} from '../../../context/clientConfig/clientConfigContext';
import MenuItem from '@mui/material/MenuItem';
import Flag from 'react-world-flags';
import {useDockEventContext} from '../../../context/dockEvent/dockEventContext';

const isIMO = strVal => strVal.length === 7 && !isNaN(strVal);
const isMMSI = strVal => strVal.length === 9 && !isNaN(strVal);

export default function VesselSearchForm() {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {config} = useConfig().state;
  const {state: dockEvent, dispatch} = useDockEventContext();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const {favouriteVessels} = config?.portMgmt || [];
  console.table(searchResult);

  const onSearch = () => {
    setLoading(true);
    setError(null);
    console.log('SEARCH', {searchQuery});
    if (isMMSI(searchQuery) || isIMO(searchQuery)) {
      getVesselData(isMMSI(searchQuery) ? searchQuery : null, isIMO(searchQuery) ? searchQuery : null)
        .then(result => {
          result.errors ? setError(result.errors[0]?.detail) : dispatch({
            type: 'update-vessel-data', vessel: {
              mmsi: result.mmsi,
              imo: result.imo,
              name: result.name,
              flag: result.flag,
              type: result.vesselType,
              dwt: result.summerDwt,
              allParticulars: result
            }
          });
          getVesselImg(result.mmsi, result.imo)
            .then(imgId => dispatch({type: 'update-vessel-data', vessel: {imgId}}))
            .catch(err => console.warn(t('dockEventDialog.imgNotFound') + result.mmsi));
        })
        .catch(err => setError(err))
        .finally(() => setLoading(false));
    } else {
      searchVessel(searchQuery)
        .then(result => setSearchResult(result))
        .catch(err => setError(err))
        .finally(() => setLoading(false));
    }
  };

  const onSearchChange = event => {
    setSearchQuery(event.target.value);
    // const filtered = favouriteVessels.filter()
    event.target.value.length > 2 && onSearch();
  };

  const clearResults = () => {
    setSearchQuery('');
    setSearchResult([]);
  };

  const selectVessel = (mmsi) => {
    setLoading(true);
    setError(null);
    setSearchResult([]);
    getVesselData(mmsi, null)
      .then(result => {
        result.errors ? setError(result.errors[0]?.detail) : dispatch({
          type: 'update-vessel-data', vessel: {
            mmsi: result.mmsi,
            imo: result.imo,
            name: result.name,
            flag: result.flag,
            type: result.vesselType,
            dwt: result.summerDwt,
            allParticulars: result
          }
        });
        getVesselImg(result.mmsi, result.imo)
          .then(imgId => dispatch({type: 'update-vessel-data', vessel: {imgId}}))
          .catch(err => console.warn(t('dockEventDialog.imgNotFound') + result.mmsi));
      })
      .catch(err => setError(err))
      .finally(() => setLoading(false));
  };

  return <Box p={2}>
    <Box>
      {error && <Typography sx={{position: 'absolute', top: 50}} variant={'body1'} color={'red'}>
        {error}
      </Typography>}
      <Box>
        <TextField
          label={t('dockEventDialog.quickSearch')}
          value={searchQuery}
          size={'small'}
          sx={{width: 300}}
          onFocus={() => searchResult.length === 0 && setSearchResult(favouriteVessels)}
          onChange={onSearchChange} inputMode={'search'}/>
        {searchResult?.length > 0 && <IconButton
          // sx={{position: 'absolute'}}
          sx={{marginLeft: -5, marginBottom: 1}}
          color={'error'}
          onClick={clearResults}
        >
          <Close/>
        </IconButton>}
        <IconButton
          color={'primary'}
          // disabled={loading}
          onClick={() => onSearch()}
          sx={{marginLeft: 2, paddingTop: 1}}
          size={'large'}>
          {loading ? <CircularProgress size={20}/> : <Search/>}
        </IconButton>
      </Box>

      {searchResult.length > 0 &&
      <Box minWidth={300} border={'1px solid #ADA9B4'}>
        {searchResult.map(({flag, mmsi, name, vesselType}) =>
          <MenuItem sx={{
            '&:hover, &:focus, &:active': {
              backgroundColor: primaryColor[2], color: 'white'
            }
          }} value={mmsi} onClick={() => selectVessel(mmsi)}>
            {<Flag style={{border: '1px solid #ADA9B4'}}
                   width={20}
                   code={flag}/>} &nbsp;&nbsp; {name}, {vesselType} [{flag}]</MenuItem>)}
      </Box>}
    </Box>
  </Box>;
}
