import React, {useState} from 'react';
import Highcharts from 'highcharts/highstock';
import {
  Chart,
  ColumnSeries,
  HighchartsProvider,
  HighchartsStockChart,
  Legend, Navigator,
  Title,
  Tooltip,
  XAxis,
  YAxis
} from 'react-jsx-highstock';

import applyExporting from 'highcharts/modules/exporting';
import applyOffline from 'highcharts/modules/offline-exporting';

import {getChartMeasurements, getNoiseMeasurements} from '../../api';
import Box from '@mui/material/Box';
import AirDateRangePicker from './AirDateRangePicker';
import QuickAirDateButtons from './QuickAirDateButtons';
import {MOBILE_SCREEN, primaryColor} from '../../assets/jss/nextjs-material-dashboard';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {useMediaQuery} from "@mui/material";
import DeviceImage from './DeviceImage';
import {useTranslation} from 'react-i18next';


applyExporting(Highcharts);
applyOffline(Highcharts);

const NUMBER_OF_BUCKETS = 70;

const plotOptions = {
  column: {
    pointWidth: 6,
    maxPointWidth: 50,
    minPointLength: 3, // By default, 0 values are not shown. To visualize a 0 (or close to zero) point, set the minimal point length to a pixel value like 3
    borderWidth: 0
  },
  series: {
    shadow: true,
    // dataGrouping: {
    //   approximation: 'average',
    //   groupAll: true,
    //   groupPixelWidth: 20
    // }
  }
};

const plotHorizontalNoiseLines = [{
  zIndex: 99,
  value: 55,
  color: "green",
  dashStyle: "shortdash",
  width: 2
}, {
  zIndex: 99,
  value: 45,
  color: "orange",
  dashStyle: "shortdash",
  width: 2
}];

const timeOptions = {
  useUTC: false
};

const options = {};


export default function NoiseChart({device}) {
  const {clientId} = useConfig().state.config;
  const {t} = useTranslation();
  const title = device.name;
  const units = '[dBA]';
  const min = 0;
  const max = 100;

  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);

  const afterSetExtremes = (e) => {
    const {serialNo} = device;
    const {chart} = e.target;
    const nowMillis = new Date().getTime();
    const min = e.userMin || (nowMillis - 1000 * 60 * 60 * 24 * 7);
    const max = e.userMax || nowMillis;

    var rangeInHours = null;
    const dateFromHours = rangeInHours ? rangeInHours : Math.max(1, Math.round((nowMillis - min) / (1000 * 60 * 60)));
    const dateToHours = rangeInHours ? 0 : Math.max(0, Math.round((nowMillis - max) / (1000 * 60 * 60)));
    const dateTimeFrom = '-' + dateFromHours + 'h';
    const dateTimeTo = '-' + dateToHours + 'h';
    chart.showLoading(t('general.loading'));
    getNoiseMeasurements(clientId, serialNo, dateTimeFrom, dateTimeTo)
      .then(data => {
        console.log({data});
        if (data[serialNo]) {
          chart.yAxis[0].series[1].setData(data[serialNo].LeqD, false);
          chart.yAxis[0].series[2].setData(data[serialNo].LeqN, false);
          chart.yAxis[0].series[3].setData(data[serialNo].dayAvg, false);
          chart.yAxis[0].series[4].setData(data[serialNo].nightAvg, false);
          if (!e.trigger) {
            chart.navigator.series[1].setData(data[serialNo].LeqD, false);
            chart.navigator.series[2].setData(data[serialNo].LeqN, false);
            chart.navigator.series[3].setData(data[serialNo].dayAvg, false);
            chart.navigator.series[4].setData(data[serialNo].nightAvg, false);
          }
        }
        chart.hideLoading();
        chart.redraw();
      }).catch(error => console.error(error.message));

    getChartMeasurements(clientId, serialNo, 'noise', dateTimeFrom, dateTimeTo, NUMBER_OF_BUCKETS)
      .then(data => {
        if (data[serialNo]) {
          chart.yAxis[0].series[0].setData(data[serialNo].noise, false);
          if (!e.trigger) {
            chart.navigator.series[0].setData(data[serialNo].noise, false);
          }
        }
        // chart.hideLoading();
        // chart.redraw();
      }).catch(error => console.error(error.message));
  };

  const [xAxisEvents, setXAxisEvents] = useState({afterSetExtremes: afterSetExtremes});

  return (<Box position={'relative'}>
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsStockChart options={options} plotOptions={plotOptions} time={timeOptions}>
          <Chart zooming={{type: 'x'}} panning={{enabled: true}} panKey={'shift'} />
          <Legend align={'left'} verticalAlign={'top'} x={40} y={30} useHTML={true}/>
          <Title style={{color: primaryColor[2]}} align={'center'}>{title}</Title>

          <Tooltip valueSuffix={' ' + units} valueDecimals={2}/>
          <XAxis
            events={xAxisEvents}
            range={15 * 24 * 60 * 60 * 1000}
          />

          <YAxis
            min={min}
            max={max}
            plotLines={plotHorizontalNoiseLines}
            useHTML={true}>
            <YAxis.Title>{t('measurements.noise')} {units}</YAxis.Title>
            {/*color={'#058DC7'}*/}
            <ColumnSeries id="series1" name={t('measurements.parameters.noise')} visible={false} />
            {/*<ColumnSeries pointWidth={30} id="AeqD" name="<b>L<sub>AeqD</sub><b>" dashStyle="shortdash" />*/}
            {/*<ColumnSeries pointWidth={30} id="AeqN" name="<b>L<sub>AeqN</sub></b>" dashStyle="shortdash" />*/}
            <ColumnSeries color={'green'} pointWidth={30} id="AeqD" name={t('measurements.noiseDay')}  />
            <ColumnSeries color={'orange'} pointWidth={30} id="AeqN" name={t('measurements.noiseNight')}  />

            <ColumnSeries color={'violet'} pointWidth={30} id="AeqDAvg" name={t('measurements.noiseDayAvg')}  visible={false}  />
            <ColumnSeries color={"purple"} pointWidth={30} id="AeqNAvg" name={t('measurements.noiseNightAvg')}  visible={false}  />


          </YAxis>
          <QuickAirDateButtons width={!isMobile ? 200 : "100%"} days={7}/>
          <AirDateRangePicker/>
          <DeviceImage clientId={clientId} serialNo={device.serialNo} />
          <Navigator adaptToUpdatedData={false}>
            <Navigator.Series seriesId="series1"/>
            <Navigator.Series seriesId="AeqD"/>
            <Navigator.Series seriesId="AeqN"/>

            <Navigator.Series seriesId="AeqDAvg"/>
            <Navigator.Series seriesId="AeqNAvg"/>
          </Navigator>
        </HighchartsStockChart>
      </HighchartsProvider>
    </Box>
  );
}
