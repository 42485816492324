import {Box, Card, CardContent, CardHeader, IconButton, Modal, Typography} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {deleteMaintenance, getMaintenance} from '../../api';
import Button from '@mui/material/Button';
import MaintenanceForm from './MaintenanceForm';
import CircularProgress from '@mui/material/CircularProgress';
import {Add, ChevronLeft, Close, Delete} from '@mui/icons-material';
import {dangerColor} from '../../assets/jss/nextjs-material-dashboard';
import {initialMaintenanceObj, useMaintenance} from '../../context/maintenance/maintenanceContext';
import _ from 'lodash';
import {removeMaintenanceImage, removeNoteImage} from '../../views/Maintenance/S3_Util';

export default function MaintenancePanel({maintenance, serialNo, onClose}) {
  const {clientId, email, config: {devices}} = useConfig().state;
  const {dispatch} = useMaintenance();
  const [maintenanceObject, setMaintenanceObject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  // const name = serialNo
  const isEdit = maintenanceObject?.serialNo;
  const devicesDict = useMemo(() => _.keyBy(devices, 'serialNo'), [devices]);
  const name = isEdit ? devicesDict[serialNo || maintenanceObject?.serialNo].name : '';

  useEffect(() => {
    if (maintenance) {
      setMaintenanceObject(maintenance);
    } else {
      getMaintenance(clientId, serialNo)
        .then(result => {
          setMaintenanceObject(result);
        })
        .catch(err => console.error(err.message));
    }
  }, [maintenance, serialNo]);

  const removeMaintenanceImages = mObj => {
    (mObj.startPhoto || []).map(p => removeMaintenanceImage(p.url));
    (mObj.endPhoto || []).map(p => removeMaintenanceImage(p.url));
    (mObj.notes || []).filter(n => n.imgUrl).map(n => removeMaintenanceImage(n.imgUrl));
  }
  const newMaintenance = () => {
    setMaintenanceObject(initialMaintenanceObj(clientId, email));
  };
  const removeMaintenance = () => {
    setLoading(true);
    setErrorMessage(null);
    deleteMaintenance(maintenanceObject.clientId, maintenanceObject.serialNo, maintenanceObject.createdAt)
      .then(_ => {
        dispatch({type: 'remove-maintenance', maintenance: maintenanceObject});
        removeMaintenanceImages(maintenanceObject);
        setMaintenanceObject(null);
      })
      .catch(err => {
        console.info(err.response);
        setErrorMessage(err.response.data.error);
      })
      .finally(() => setLoading(false));
  };

  const titleComp = <Box display={'flex'} gap={5}>
    <Typography color={'primary'} variant={'h5'}>
      Serwis - {isEdit ? 'EDYCJA' : 'NOWY'} - {name}
      <br/>
      <Typography color={'primary'} variant={'caption'}>
        {serialNo || maintenance.serialNo || 'NOWE ZGŁOSZENIE'}
      </Typography>
    </Typography>

    {!maintenanceObject ?
      <Button onClick={newMaintenance} sx={{ml: 20, height: 40}} variant={'contained'} color={'warning'}
              startIcon={<Add/>}>Otwórz nowy serwis
        urządzenia</Button>
      : <Button onClick={removeMaintenance} sx={{ml: 20, height: 40}} variant={'contained'} color={'error'}
                startIcon={<Delete/>}>Usuń
        serwis i wszystkie
        dane</Button>
    }
    <IconButton color={'error'} size={'large'} onClick={onClose} sx={{position: 'absolute', right: 10, top: 10}}>
      <Close/>
    </IconButton>
  </Box>;

  return <Modal open={true} sx={{overflowY: 'scroll'}}>
    <Card elevation={0} sx={{minHeight: '100vh'}}>
      <CardHeader
        title={titleComp}
      />
      <CardContent>
        {errorMessage && <Typography color={dangerColor[0]}>{errorMessage}</Typography>}
        {loading &&
          <Box>
            <CircularProgress size={100} sx={{marginLeft: 'calc(50% - 50px)', marginTop: 10}}/>
          </Box>}

        {maintenanceObject === null ? <Box>
          <Typography variant={'h4'} color={'primary'}>Brak aktywnego serwisu</Typography>
          <br/>
          <Button startIcon={<ChevronLeft/>} onClick={onClose}>Wróc do listy</Button>
        </Box> : <Box>
          <MaintenanceForm maintenance={maintenanceObject} email={email} onClose={onClose}/>
        </Box>}
      </CardContent>
    </Card>
  </Modal>;
}
