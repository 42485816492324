import {Amplify, Storage} from 'aws-amplify';
import {s3configFilesCoral} from '../../aws-exports';

const S3_FILE_PREFIX = 'https://s3.eu-central-1.amazonaws.com/files.coral.com/maintenance/';
const customPrefix = {
  public: 'maintenance/',
  protected: 'maintenance/',
  private: 'maintenance/'
};


export const removeNoteImage = async note => {
  Amplify.configure(s3configFilesCoral);
  const imgKey = note.imgUrl.replace(S3_FILE_PREFIX, '');
  console.log('REMOVING', imgKey);
  await Storage.remove(imgKey, {
    customPrefix,
    level: 'public',
  });
  if (imgKey.includes('photo/')) {
    console.log('REMOVING', imgKey.replace('photo/', 'thumb/'));
    await Storage.remove(imgKey.replace('photo/', 'thumb/'), {
      customPrefix,
      level: 'public',
    });
  } else {
    console.log('REMOVING', imgKey.replace('thumb/', 'photo/'));
    await Storage.remove(imgKey.replace('thumb/', 'photo/'), {
      customPrefix,
      level: 'public',
    });
  }
}

export const removeMaintenanceImage = async imgUrl => {
  if (!imgUrl) {return}

  Amplify.configure(s3configFilesCoral);
  const imgKey = imgUrl.replace(S3_FILE_PREFIX, '');
  console.log('REMOVING', imgKey);
  await Storage.remove(imgKey, {
    customPrefix,
    level: 'public',
  });
  if (imgKey.includes('photo/')) {
    console.log('REMOVING', imgKey.replace('photo/', 'thumb/'));
    await Storage.remove(imgKey.replace('photo/', 'thumb/'), {
      customPrefix,
      level: 'public',
    });
  } else {
    console.log('REMOVING', imgKey.replace('thumb/', 'photo/'));
    await Storage.remove(imgKey.replace('thumb/', 'photo/'), {
      customPrefix,
      level: 'public',
    });
  }
}
