import React from 'react';
import Box from '@mui/material/Box';

import {
  CardContent,
  Tooltip,
  Typography,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  useMediaQuery
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {grayColor, successColor} from './colors';
import ChartistGraph from 'react-chartist';
import {cargoTotalChart, cargoTypesChart, passengersChart} from './variables/charts';
import './chartist-custom-style.css?v=1.1.0';
import tooltipStyle from './tooltipStyle';
import {Accessibility, ArrowDownward, ArrowUpward, Euro, Flag, Insights, Sailing} from '@mui/icons-material';
import Excel from '../../../assets/images/Excel-icon.png';
import RegularButton from '../../CustomButtons/Button';
import {useTranslation} from 'react-i18next';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import CardBody from '../../Card/CardBody';
import Card from '../../Card/Card';
import CardIcon from '../../Card/CardIcon';
import _ from 'lodash';
import Button from '@mui/material/Button';
import CardHeader from '../../Card/CardHeader';
import {MOBILE_SCREEN, primaryColor} from "../../../assets/jss/nextjs-material-dashboard";

const styles = {
  ...tooltipStyle,
  successText: {
    color: successColor[0],
  },
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px',
  },
};


function DockStats() {
  const {t} = useTranslation();
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);

  return (
    <MuiCard sx={{minHeight: '100%'}} variant={'outlined'} >
      {!isMobile && <MuiCardHeader
        title={<Button size={'large'}
                       startIcon={<Insights/>}
                       color={'info'}>{t("general.statistics")}</Button>}
        subheader={<Typography
          sx={{marginLeft: 2, color: grayColor[3]}}
          variant={'caption'}>{t("dockStats.fromTheOfficialDataOfThePort")}</Typography>}
      />}
      {/*MOBILE CARD TITLE*/}
      <Typography sx={{
        color: primaryColor[2],
        textAlign: "center",
        fontSize: "1.25rem",
        paddingTop: "1em",
        '@media screen and (min-width: 50em)': {display: "none"}
      }}>{t("general.statistics")}</Typography>
      <CardContent>
        {/* CHARTS */}
        <GridContainer>
          {/* ONE VALUE STATS */}
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={12} md={3}>
                <Card>
                  <CardHeader color="primary" stats icon>
                    <CardIcon color="primary">
                      <Sailing/>
                    </CardIcon>
                    <Typography variant={'body2'} className={classes.cardCategory}>
                      {t("dockStats.theLengthOfThePortQuays")}
                    </Typography>
                    <Box mt={1}><Typography variant={'h5'} className={classes.cardTitle}>
                      3,7 km
                    </Typography>
                    </Box>
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={12} md={3}>
                <Card>
                  <CardHeader color="primary" stats icon>
                    <CardIcon color="primary">
                      <Flag/>
                    </CardIcon>
                    <Typography variant={'body2'} className={classes.cardCategory}>
                      {t("dockStats.transhipmentCapacity")}
                    </Typography>
                    <Box mt={1}><Typography variant={'h5'} className={classes.cardTitle}>
                      1 mln t
                    </Typography>
                    </Box>
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={12} md={3}>
                <Card>
                  <CardHeader color="primary" stats icon>
                    <CardIcon color="primary">
                      <Accessibility/>
                    </CardIcon>
                    <Typography variant={'body2'} className={classes.cardCategory}>
                      {t("dockStats.checkedPassengers")}
                    </Typography>
                    <Box mt={1}><Typography variant={'h5'} className={classes.cardTitle}>
                      1245 tyś
                    </Typography>
                    </Box>
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={12} md={3}>
                <Card>
                  <CardHeader color="primary" stats icon>
                    <CardIcon color="primary">
                      <Euro/>
                    </CardIcon>
                    <Typography variant={'body2'} className={classes.cardCategory}>{t("dockStats.completeReloading")}
                    </Typography>
                    <Box mt={1}><Typography variant={'h5'} className={classes.cardTitle}>
                      5 mln t
                    </Typography>
                    </Box>
                  </CardHeader>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
          {/* PASSSNEGERS CHART */}
          <GridItem xs={12}>
            <Card chart>
              <CardHeader color="primary">
                <div id={'chart-id'}>
                  <ChartistGraph
                    className="ct-chart"
                    data={passengersChart.data}
                    type="Line"
                    options={passengersChart.options}
                    listener={passengersChart.animation}
                  />
                </div>
              </CardHeader>
              <CardBody>
                <Typography variant={'body1'} className={classes.cardTitle}>
                  {t("dockStats.numberOfPassengersInTheThousands")}
                </Typography>
                <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory}/> {Math.round((41.560 / 26.600) * 100)} %
                </span>{' '}
                  {t("dockStats.increaseWithThePreviousYear")}
                </p>
                <Box sx={{
                  position: 'absolute',
                  right: 20,
                  top: 10
                }}>
                  <Tooltip
                    id="tooltip-report"
                    title={t('dockStats.createReport')}
                    placement="top"
                  >
                    <RegularButton
                      size={'sm'}
                      justIcon
                      color={'transparent'}
                      onClick={() => {
                        // setType("visitors")
                        // setOpenModal(true);
                      }}>
                      <img width={40} height={25} src={Excel}/>
                    </RegularButton>
                  </Tooltip>
                </Box>
              </CardBody>
            </Card>
          </GridItem>

          {/* CARGO TOTAL CHART */}
          <GridItem xs={10} sm={12} md={6}>
            <Card chart>
              <CardHeader color="primary">
                <div id={'chart-id'}>
                  <ChartistGraph
                    className="ct-chart"
                    data={cargoTotalChart.data}
                    type="Bar"
                    options={cargoTotalChart.options}
                    listener={cargoTotalChart.animation}
                  />
                </div>
              </CardHeader>
              <CardBody>
                <Typography variant={'body1'} className={classes.cardTitle}>
                  {t("dockStats.transhipmentInTotalInThousandsOfTons")}
                </Typography>
                <p className={classes.cardCategory}>
                  {t("dockStats.totalReloadedSince2009")}: {_.sum(cargoTotalChart.data.series[0])} {t("general.thousandsOfTons")}
                </p>
                <Box sx={{
                  position: 'absolute',
                  right: 20,
                  top: 10
                }}>
                  <Tooltip
                    id="tooltip-report"
                    title={t('dockStats.createReport')}
                    placement="top"
                  >
                    <RegularButton
                      size={'sm'}
                      justIcon
                      color={'transparent'}
                      onClick={() => {
                        window.open('https://marina-master-web.s3.eu-central-1.amazonaws.com/charts/reservations-chart.jpg', '_blank', 'noopener,noreferrer');
                      }}>
                      <img width={40} height={25} src={Excel}/>
                    </RegularButton>
                  </Tooltip>
                </Box>
              </CardBody>
            </Card>
          </GridItem>

          {/* CARGO BY TYPE */}
          <GridItem xs={12} sm={12} md={6}>
            <Card chart>
              <CardHeader color="primary">
                <div id={'chart-id'}>
                  <ChartistGraph
                    className="ct-chart"
                    data={cargoTypesChart.data}
                    type="Bar"
                    options={cargoTypesChart.options}
                    listener={cargoTypesChart.animation}
                  />
                </div>
              </CardHeader>
              <CardBody>
                <Typography variant={'body1'} className={classes.cardTitle}>
                  {t("dockStats.cargoGroupsInThousandsOfTons")}
                </Typography>
                <p color={'red'} className={classes.cardCategory}>
                <span>
                  <ArrowDownward color={'error'} className={classes.upArrowCardCategory}/>
                </span>{' '}
                </p>
                <Box sx={{
                  position: 'absolute',
                  right: 20,
                  top: 10
                }}>
                  <Tooltip
                    id="tooltip-report"
                    title={t('dockStats.createReport')}
                    placement="top"
                  >
                    <RegularButton
                      size={'sm'}
                      justIcon
                      color={'transparent'}
                      onClick={() => {
                        // setType("visitors")
                        // setOpenModal(true);
                      }}>
                      <img width={40} height={25} src={Excel}/>
                    </RegularButton>
                  </Tooltip>
                </Box>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </CardContent>
    </MuiCard>
  );
}

export default DockStats;
