const gdanskieWodyStations = [
  {
    'name': 'Nowy Port',
    'location': {lat: 54.3945, lng: 18.6695},
    'humidity': false,
    'serialNo': '35',
    'trendsId': '31',
    'pressure': false,
    'rain': true,
    'sun': false,
    'temp': false,
    'water': false,
    'winddir': false,
    'windlevel': false
  },
  {
    'name': 'Cygańska Góra',
    'location': {lat: 54.3601, lng: 18.6138},
    'humidity': false,
    'serialNo': '18',
    'trendsId': '20',
    'pressure': false,
    'rain': true,
    'sun': false,
    'temp': false,
    'water': false,
    'winddir': false,
    'windlevel': false
  },
  {
    'name': 'Stogi Kaczeńce',
    'location': {lat: 54.364871, lng: 18.7001},
    'humidity': false,
    'serialNo': '310',
    'trendsId': '95',
    'pressure': false,
    'rain': true,
    'sun': false,
    'temp': false,
    'water': false,
    'winddir': false,
    'windlevel': false
  },
  {
    'name': 'Brzeźno',
    'location': {lat: 54.4091, lng: 18.6175},
    'humidity': false,
    'serialNo': '303',
    'trendsId': '91',
    'pressure': false,
    'rain': true,
    'sun': false,
    'temp': false,
    'water': false,
    'winddir': false,
    'windlevel': false
  },
  {
    'name': 'Dolne Miasto',
    'location': {lat: 54.33604, lng: 18.66171},
    'humidity': false,
    'serialNo': '1',
    'trendsId': '1',
    'pressure': false,
    'rain': true,
    'sun': false,
    'temp': false,
    'water': false,
    'winddir': false,
    'windlevel': false
  },
  {
    'name': 'Górki Zachodnie',
    'location': {lat: 54.349406, lng: 18.7837},
    'humidity': false,
    'serialNo': '2',
    'trendsId': '2',
    'pressure': false,
    'rain': true,
    'sun': false,
    'temp': false,
    'water': false,
    'winddir': true,
    'windlevel': true
  },
  {
    'name': 'Śródmieście Góra Gradowa',
    'location': {lat: 54.353223, lng: 18.635321},
    'humidity': true,
    'serialNo': '307',
    'trendsId': '101',
    'pressure': true,
    'rain': true,
    'sun': false,
    'temp': true,
    'water': false,
    'winddir': true,
    'windlevel': true
  }
];

const lipWaterStations = [
  {
    "name": "Vec-Liepāja",
    "serialNo": "457954",
    'location': {lat: 56.5333, lng: 21.0167},
    "trendsId": "457954",
    "humidity": true,
    "pressure": true,
    "rain": true,
    "sun": false,
    "temp": true,
    "water": true,
    "winddir": true,
    "windlevel": true
  }
];

export const waterStations = {
  lip: lipWaterStations,
  rem: gdanskieWodyStations
};

export const isHighLevel = (waterLevel, waterLevelStation) => waterLevel > waterLevelStation.threshold;

export const naOstrowiOuterCoords = [
  [
    18.659977912902832,
    54.38403136638434
  ],
  [
    18.658111095428463,
    54.38278176076423
  ],
  [
    18.650343418121338,
    54.381357163950724
  ],
  [
    18.65025758743286,
    54.381407150640804
  ],
  [
    18.64907741546631,
    54.38111972634152
  ],
  [
    18.646245002746582,
    54.37983878923306
  ],
  [
    18.64555835723877,
    54.379288911285364
  ],
  [
    18.6447536945343,
    54.378495324443506
  ],
  [
    18.64452838897705,
    54.378476578112156
  ],
  [
    18.643637895584103,
    54.377501757085845
  ],
  [
    18.64395171403885,
    54.37742208319025
  ],
  [
    18.643927574157715,
    54.3773330357122
  ],
  [
    18.64354133605957,
    54.37737052941071
  ],
  [
    18.64304780960083,
    54.3764706711956
  ],
  [
    18.643198013305664,
    54.376345689328005
  ],
  [
    18.64257574081421,
    54.37524583248722
  ],
  [
    18.641867637634277,
    54.37515834260977
  ],
  [
    18.643721044063568,
    54.369575790533304
  ],
  [
    18.643718361854553,
    54.36946797559236
  ],
  [
    18.643235564231873,
    54.368277304811905
  ],
  [
    18.643648624420166,
    54.368208551114165
  ],
  [
    18.64404559135437,
    54.36904608831988
  ],
  [
    18.644136786460873,
    54.36903046278211
  ],
  [
    18.643895387649536,
    54.36846793946267
  ],
  [
    18.64433526992798,
    54.368402311240146
  ],
  [
    18.644002676010132,
    54.367533508696226
  ],
  [
    18.644431829452515,
    54.3674397519273
  ],
  [
    18.64452838897705,
    54.367602263524084
  ],
  [
    18.644614219665527,
    54.367577261781825
  ],
  [
    18.644850254058838,
    54.36801479007399
  ],
  [
    18.64508628845215,
    54.36797103745454
  ],
  [
    18.644925355911255,
    54.367571011343856
  ],
  [
    18.64569783210754,
    54.36743350146842
  ],
  [
    18.645998239517212,
    54.364683207270076
  ],
  [
    18.64651322364807,
    54.36470195989956
  ],
  [
    18.64633083343506,
    54.36651467366607
  ],
  [
    18.646620512008667,
    54.3666209337148
  ],
  [
    18.649120330810547,
    54.36540830270831
  ],
  [
    18.650836944580078,
    54.36375806691923
  ],
  [
    18.652467727661133,
    54.36445817504854
  ],
  [
    18.657531738281246,
    54.363458016925016
  ],
  [
    18.66225242614746,
    54.360457396429375
  ],
  [
    18.664913177490234,
    54.360457396429375
  ],
  [
    18.676156997680664,
    54.355055727164306
  ],
  [
    18.677959442138672,
    54.35565594771057
  ],
  [
    18.66650104522705,
    54.36167017360525
  ],
  [
    18.667230606079098,
    54.36558332382727
  ],
  [
    18.666458129882812,
    54.36815854835258
  ],
  [
    18.66281032562256,
    54.37638318392822
  ],
  [
    18.662381172180172,
    54.37728304406091
  ],
  [
    18.6622953414917,
    54.37795792621687
  ],
  [
    18.662080764770508,
    54.37900772085218
  ],
  [
    18.661909103393555,
    54.3798075463652
  ],
  [
    18.661952018737793,
    54.380432399204224
  ],
  [
    18.661952018737793,
    54.38103224898139
  ],
  [
    18.66199493408203,
    54.38148213056181
  ],
  [
    18.6622953414917,
    54.38210695791054
  ],
  [
    18.662853240966797,
    54.38285673817427
  ],
  [
    18.659977912902832,
    54.38403136638434
  ]
];

export const naOstrowiuInnerCoords = [
  [
    18.657467365264893,
    54.37924517067782
  ],
  [
    18.660764694650425,
    54.37188775367198
  ],
  [
    18.65810394330765,
    54.371462766336236
  ],
  [
    18.657739162881626,
    54.372300237173825
  ],
  [
    18.657588959176792,
    54.37228773773466
  ],
  [
    18.657009602029575,
    54.37152526474971
  ],
  [
    18.656730652292026,
    54.371587763068035
  ],
  [
    18.65662336393143,
    54.37170025980135
  ],
  [
    18.65492820783402,
    54.37212524467909
  ],
  [
    18.657546043505135,
    54.375609330036994
  ],
  [
    18.657009601702157,
    54.376724799756126
  ],
  [
    18.656617999185983,
    54.37710598974282
  ],
  [
    18.65668773662037,
    54.37724971619602
  ],
  [
    18.656401635089424,
    54.37726637965508
  ],
  [
    18.655371666827705,
    54.37626653343985
  ],
  [
    18.653397560992744,
    54.373416838133764
  ],
  [
    18.65198135463288,
    54.37384180524523
  ],
  [
    18.655178547123796,
    54.37818496728403
  ],
  [
    18.65506052992714,
    54.37825995309068
  ],
  [
    18.654695749501116,
    54.37894106789554
  ],
  [
    18.65533947966469,
    54.37903479840614
  ],
  [
    18.655210733631975,
    54.37924100477613
  ],
  [
    18.653290271977312,
    54.378922321767746
  ],
  [
    18.65312933943642,
    54.37902230101709
  ],
  [
    18.653161525944597,
    54.37919101544819
  ],
  [
    18.65508198743555,
    54.37949563706575
  ],
  [
    18.65495055919382,
    54.37972683548142
  ],
  [
    18.65560770040247,
    54.37984087205828
  ],
  [
    18.65557551389429,
    54.37989554701703
  ],
  [
    18.65235149865839,
    54.37934723199115
  ],
  [
    18.652127981295052,
    54.37973672904649
  ],
  [
    18.6553788186211,
    54.380249109960346
  ],
  [
    18.655582666506234,
    54.37991168910199
  ],
  [
    18.65566849719471,
    54.37997729893055
  ],
  [
    18.655480742563668,
    54.38034908597834
  ],
  [
    18.652067185321357,
    54.37984087158155
  ],
  [
    18.65172386256745,
    54.38034075420786
  ],
  [
    18.647861481586006,
    54.37889107782099
  ],
  [
    18.646280765970005,
    54.37761215439211
  ],
  [
    18.645336628396763,
    54.376462338828645
  ],
  [
    18.648555279214634,
    54.37505002129658
  ],
  [
    18.648211956460727,
    54.374812547205856
  ],
  [
    18.645315170724643,
    54.37608739200838
  ],
  [
    18.644971849280406,
    54.37612488608166
  ],
  [
    18.644371034461074,
    54.373625155386264
  ],
  [
    18.648061754065566,
    54.37332517747548
  ],
  [
    18.647890092688613,
    54.372675217817
  ],
  [
    18.644542695838027,
    54.372975200476276
  ],
  [
    18.64445686514955,
    54.37237523296609
  ],
  [
    18.645658494788222,
    54.372175241847906
  ],
  [
    18.6472034471808,
    54.37082527632245
  ],
  [
    18.647117616492324,
    54.36917525818712
  ],
  [
    18.650150299072266,
    54.366808450777164
  ],
  [
    18.653240203857422,
    54.36580834988434
  ],
  [
    18.659420013427734,
    54.36425814537213
  ],
  [
    18.663196563720703,
    54.363207973588814
  ],
  [
    18.66405487060547,
    54.368008539702636
  ],
  [
    18.66345405578613,
    54.368308556454636
  ],
  [
    18.66405487060547,
    54.36865857322854
  ],
  [
    18.661329746246338,
    54.37504585535059
  ],
  [
    18.65931272506714,
    54.37952011086532
  ],
  [
    18.657467365264893,
    54.37924517067782
  ]
];

export const portPolnocnyOuterBounds = [
  [
    18.715628385543845,
    54.406598941144715
  ],
  [
    18.719319105148333,
    54.40525010758032
  ],
  [
    18.71949076652529,
    54.405424958878356
  ],
  [
    18.720155954360983,
    54.40517517108153
  ],
  [
    18.721303939819336,
    54.404719304430564
  ],
  [
    18.72296690940859,
    54.40416351494653
  ],
  [
    18.72217297554018,
    54.40365143257562
  ],
  [
    18.719705343246478,
    54.404575674162025
  ],
  [
    18.715070486068743,
    54.40654898513711
  ],
  [
    18.711873292922995,
    54.40448824679574
  ],
  [
    18.709319829940817,
    54.40381380084887
  ],
  [
    18.69996428489687,
    54.40153434126103
  ],
  [
    18.70050072669985,
    54.400828619872264
  ],
  [
    18.700425624847433,
    54.400510104472374
  ],
  [
    18.698998689651507,
    54.400110395373126
  ],
  [
    18.698633909225485,
    54.399979239976226
  ],
  [
    18.699127435684225,
    54.398904999037484
  ],
  [
    18.69892358779909,
    54.398873770682414
  ],
  [
    18.69827985763552,
    54.39977313779101
  ],
  [
    18.697990179061907,
    54.399741910096836
  ],
  [
    18.695898056030295,
    54.39924226375717
  ],
  [
    18.695608377456683,
    54.39961075352181
  ],
  [
    18.690962791442892,
    54.39845530843111
  ],
  [
    18.691831827163718,
    54.39688759846409
  ],
  [
    18.694095611572287,
    54.39724986346018
  ],
  [
    18.694202899932883,
    54.39713743673479
  ],
  [
    18.69645595550539,
    54.39748720775756
  ],
  [
    18.69696021080019,
    54.398174249298826
  ],
  [
    18.697775602340716,
    54.39726860121782
  ],
  [
    18.708515167236346,
    54.39903615786908
  ],
  [
    18.708579540252703,
    54.39894871869461
  ],
  [
    18.697904348373434,
    54.39714368267202
  ],
  [
    18.69817256927492,
    54.39683763062734
  ],
  [
    18.703140020370505,
    54.39765584629234
  ],
  [
    18.704030513763445,
    54.39581327656242
  ],
  [
    18.70422363281252,
    54.39583201497617
  ],
  [
    18.704320192337054,
    54.39580078428179
  ],
  [
    18.704395294189474,
    54.39567586126655
  ],
  [
    18.706423044204733,
    54.39603813696281
  ],
  [
    18.706369400024435,
    54.396181797128925
  ],
  [
    18.707045316696185,
    54.39629422647323
  ],
  [
    18.70715260505678,
    54.39605687527387
  ],
  [
    18.706498146057147,
    54.39591946079395
  ],
  [
    18.70440602302553,
    54.39560715344605
  ],
  [
    18.70448112487795,
    54.395500968406175
  ],
  [
    18.704277276992816,
    54.39538229068344
  ],
  [
    18.705382347106955,
    54.3934459214494
  ],
  [
    18.705629110336325,
    54.393458414447046
  ],
  [
    18.70588660240175,
    54.3931648279969
  ],
  [
    18.705800771713275,
    54.39307112975142
  ],
  [
    18.705629110336325,
    54.39302115726638
  ],
  [
    18.70744228363039,
    54.39067238181336
  ],
  [
    18.715574741363547,
    54.39335847035945
  ],
  [
    18.71591806411745,
    54.39300241756875
  ],
  [
    18.707817792892477,
    54.39036628149288
  ],
  [
    18.714834451675436,
    54.38456868510438
  ],
  [
    18.721733093261737,
    54.389123115907424
  ],
  [
    18.741570711135886,
    54.39931721109611
  ],
  [
    18.71822476387026,
    54.4084597590746
  ],
  [
    18.715628385543845,
    54.406598941144715
  ]
];

export const martwaWislaBounds = [
  [
    18.69375228881836,
    54.35743154882186
  ],
  [
    18.694868087768555,
    54.355555911561765
  ],
  [
    18.695511817932125,
    54.35538084771528
  ],
  [
    18.69624137878418,
    54.35540585688189
  ],
  [
    18.697357177734375,
    54.35420539971409
  ],
  [
    18.70345115661621,
    54.354805632682144
  ],
  [
    18.70448112487793,
    54.35483064219887
  ],
  [
    18.705081939697266,
    54.35520578312289
  ],
  [
    18.708257675170895,
    54.35515576453093
  ],
  [
    18.70997428894043,
    54.35545587516941
  ],
  [
    18.711562156677246,
    54.355180773834505
  ],
  [
    18.713278770446777,
    54.355180773834505
  ],
  [
    18.717613220214844,
    54.3549556895541
  ],
  [
    18.72370719909668,
    54.354030330113396
  ],
  [
    18.7302303314209,
    54.35345509617346
  ],
  [
    18.731346130371094,
    54.35370519887596
  ],
  [
    18.734092712402344,
    54.35325501291539
  ],
  [
    18.734350204467773,
    54.352904864869416
  ],
  [
    18.73692512512207,
    54.352404648199325
  ],
  [
    18.73795509338379,
    54.35180438015734
  ],
  [
    18.73915672302246,
    54.351954447989876
  ],
  [
    18.744220733642578,
    54.351754357424696
  ],
  [
    18.748984336853027,
    54.35062882984157
  ],
  [
    18.754949569702145,
    54.3497033729569
  ],
  [
    18.757352828979492,
    54.3495032714308
  ],
  [
    18.760099411010742,
    54.35000352341927
  ],
  [
    18.7606143951416,
    54.35025364712997
  ],
  [
    18.760957717895504,
    54.350578805677934
  ],
  [
    18.76127958297729,
    54.35066634792435
  ],
  [
    18.762223720550537,
    54.350978997282034
  ],
  [
    18.762781620025635,
    54.35111656224585
  ],
  [
    18.76338243484497,
    54.35141670238663
  ],
  [
    18.76439094543457,
    54.3515542658852
  ],
  [
    18.76462697982788,
    54.351754357424696
  ],
  [
    18.76587152481079,
    54.351891919792926
  ],
  [
    18.766086101531982,
    54.35197945924201
  ],
  [
    18.76735210418701,
    54.35202948170064
  ],
  [
    18.767716884613037,
    54.351754357424696
  ],
  [
    18.7701416015625,
    54.35181688583098
  ],
  [
    18.771514892578125,
    54.35221706537813
  ],
  [
    18.77265214920044,
    54.35211702085671
  ],
  [
    18.773553371429443,
    54.35184189716685
  ],
  [
    18.77488374710083,
    54.352079504098384
  ],
  [
    18.77816677093506,
    54.35039121452195
  ],
  [
    18.778767585754395,
    54.350766395977615
  ],
  [
    18.78140687942505,
    54.350041042072924
  ],
  [
    18.782565593719482,
    54.350166104004394
  ],
  [
    18.782565593719482,
    54.35089145570164
  ],
  [
    18.782029151916504,
    54.35092897354464
  ],
  [
    18.78185749053955,
    54.350841431857724
  ],
  [
    18.78014087677002,
    54.351216609203014
  ],
  [
    18.778724670410156,
    54.35152925437427
  ],
  [
    18.778274059295654,
    54.351604288861424
  ],
  [
    18.778531551361084,
    54.35225458201088
  ],
  [
    18.778939247131348,
    54.35230460413459
  ],
  [
    18.781771659851074,
    54.351791874479886
  ],
  [
    18.78211498260498,
    54.3527047789321
  ],
  [
    18.781921863555905,
    54.35285484347643
  ],
  [
    18.78166437149048,
    54.35287985418054
  ],
  [
    18.77763032913208,
    54.35286734883041
  ],
  [
    18.777437210083008,
    54.35265475729553
  ],
  [
    18.775269985198975,
    54.35289235952689
  ],
  [
    18.774948120117188,
    54.352979896844744
  ],
  [
    18.77241611480713,
    54.35330503382125
  ],
  [
    18.77039909362793,
    54.353442590998384
  ],
  [
    18.768339157104492,
    54.353492611675875
  ],
  [
    18.76486301422119,
    54.3534175806368
  ],
  [
    18.76316785812378,
    54.35304242338617
  ],
  [
    18.76215934753418,
    54.35260473559807
  ],
  [
    18.760228157043457,
    54.35186690848749
  ],
  [
    18.758897781372067,
    54.35141670238663
  ],
  [
    18.75739574432373,
    54.35107904457412
  ],
  [
    18.75638723373413,
    54.35090396165311
  ],
  [
    18.754949569702145,
    54.35081641991291
  ],
  [
    18.75338315963745,
    54.35092897354464
  ],
  [
    18.751215934753418,
    54.35141670238663
  ],
  [
    18.74928474426269,
    54.35172934603554
  ],
  [
    18.74891996383667,
    54.351904425439926
  ],
  [
    18.74537944793701,
    54.352904864869416
  ],
  [
    18.74316930770874,
    54.35358014771499
  ],
  [
    18.742847442626953,
    54.353642673343046
  ],
  [
    18.742547035217285,
    54.354042835109624
  ],
  [
    18.740551471710205,
    54.35439297345852
  ],
  [
    18.73997211456299,
    54.35430543915102
  ],
  [
    18.73896360397339,
    54.35451802214575
  ],
  [
    18.73866319656372,
    54.3547806231502
  ],
  [
    18.732998371124268,
    54.356168629150424
  ],
  [
    18.73143196105957,
    54.35621864650935
  ],
  [
    18.72769832611084,
    54.355680956709726
  ],
  [
    18.72671127319336,
    54.35550589339602
  ],
  [
    18.724801540374756,
    54.35538084771528
  ],
  [
    18.72469425201416,
    54.35549338884509
  ],
  [
    18.723578453063965,
    54.35540585688189
  ],
  [
    18.721539974212646,
    54.35561843418333
  ],
  [
    18.715167045593258,
    54.35679384176071
  ],
  [
    18.71149778366089,
    54.3573815329395
  ],
  [
    18.709309101104736,
    54.35741904485699
  ],
  [
    18.708107471466064,
    54.357406540888284
  ],
  [
    18.70450258255005,
    54.35770663508648
  ],
  [
    18.701863288879395,
    54.35800672709264
  ],
  [
    18.699545860290524,
    54.358031734660884
  ],
  [
    18.69375228881836,
    54.35743154882186
  ]
];


export const waterLevelStations = {
  'rem': [
    {
      'water': true,
      'name': 'Ujście rz. Strzyża na Ostrowiu',
      'serialNo': '28',
      'trendsId': '29',
      'location': {lat: 54.380406, lng: 18.658602},
      'altLocation': {lat: 54.380406, lng: 18.658602},
      'threshold': 0.0,
      'geometry': {
        'type': 'MultiPolygon',
        'coordinates': [
          [
            naOstrowiOuterCoords,
            naOstrowiuInnerCoords
          ]
        ]
      }
    },
    {
      'water': true,
      'name': 'Martwa Wisła - Stogi',
      'serialNo': '255',
      'trendsId': '88',
      'threshold': 0.0,
      'location': {lat: 54.355824, lng: 18.701196},
      'geometry': {
        'type': 'Polygon',
        'coordinates': [martwaWislaBounds]
      }
    },
    {
      'water': true,
      'name': 'Port Północny',
      'serialNo': '254',
      'trendsId': '87',
      'threshold': 5.167,
      'location': {lat: 54.398342, lng: 18.714280},
      'geometry': {
        'type': 'Polygon',
        'coordinates': [portPolnocnyOuterBounds]
      }
    }
  ],
  'zpg': [],
  'gdy': [],
  'lip': []
};
