import React from 'react';
import {Box, useMediaQuery} from '@mui/material';
import {useAxis} from 'react-jsx-highstock';
import {addDays} from 'date-fns';
import QuickDateButtons from '../QuickDateButtons/QuickDateButtons';
import {MOBILE_SCREEN} from "../../assets/jss/nextjs-material-dashboard";


const QuickAirDateButtons = ({width, days}) => {
  const axis = useAxis('xAxis');
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);

  const setDateRange = days => {
    const now = new Date();
    const start = addDays(now, -days);
    axis.setExtremes(start.valueOf(), now.valueOf());
  };

  return <Box width={width} position={!isMobile && 'absolute'} left={40} top={10}>
    <QuickDateButtons days={days} onClick={setDateRange}/>
  </Box>;
};

export default QuickAirDateButtons;
