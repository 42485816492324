import React, {useEffect, useState} from 'react';
import Highcharts from 'highcharts/highstock';
import applyExporting from 'highcharts/modules/exporting';

import applyAccessibility from 'highcharts/modules/accessibility';
import {Box, Card, CardContent, CardHeader, Modal, Select} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import moment from 'moment/moment';
import {getWaterLevelData} from '../../api';
import {Close} from '@mui/icons-material';
import range from 'lodash/range';
import MenuItem from '@mui/material/MenuItem';
import {waterLevelStations} from './waterStations';
import CircularProgress from '@mui/material/CircularProgress';
import WaterLevelChart from '../../components/WaterLevelChart/WaterLevelChart';

applyExporting(Highcharts);
applyAccessibility(Highcharts);


export default function WaterLevelModal({stationNo, onClose}) {
  const {t} = useTranslation();
  const {clientId, uiSettings} = useConfig().state.config;
  const [year, setYear] = useState(moment().year());
  const [interval, setInterval] = useState('1w');
  const [loading, setLoading] = useState(true);
  const [waterLevelData, setWaterLevelData] = useState(true);
  const stationName = waterLevelStations[clientId] ? waterLevelStations[clientId].filter(s => s.serialNo === stationNo)[0].name : '';


  useEffect(() => {
    setLoading(true);
    getWaterLevelData(clientId, stationNo, year, interval)
      .then(result => setWaterLevelData(result))
      .finally(() => setLoading(false));
  }, [stationNo, year, interval]);

  return <Modal open={true} sx={{left: '10%', right: '10%', top: '5%'}}>
    <Card elevation={0} sx={{p: 2, maxHeight: '92vh'}}>
      <CardHeader
        action={<Close sx={{cursor: 'pointer'}} color={'error'} onClick={onClose}/>}
        title={<Typography color={'primary'} variant={'h4'}>{t('openData.waterLvlIn')} {year}{t('openData.yearSymbol')} - {stationName}</Typography>}
        subheader={<Box mt={3} display={'flex'} gap={2}>
          <Typography color={'primary'} variant={'body1'}>{t('openData.chooseYear')}</Typography>
          <Select
            sx={{minWidth: 100}}
            size={'small'}
            variant={'standard'}
            value={year}
            onChange={e => setYear(e.target.value)}
          >
            {range(uiSettings.reportsYear, moment().year() + 1)
              .map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
          </Select>

          <Typography color={'primary'} variant={'body1'}>{t('openData.chooseInterval')}</Typography>
          <Select
            sx={{minWidth: 100}}
            size={'small'}
            variant={'standard'}
            value={interval}
            onChange={e => setInterval(e.target.value)}
          >
            {['1d', '1w', '1M']
              .map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
          </Select>
        </Box>}
      />

      <CardContent>
        {loading ? <Box><CircularProgress size={100} sx={{marginLeft: 'calc(50% - 50px)', marginTop: 10}}/></Box> :
          <WaterLevelChart
            selectedYear={year}
            data={waterLevelData}
            selectedInterval={interval}
          />
        }
      </CardContent>
    </Card>
  </Modal>;
}
