import React from 'react';
import PropTypes from 'prop-types';

import v1 from '../../assets/images/vesselTypes/vi1.png';
import v2 from '../../assets/images/vesselTypes/vi2.png';
import v3 from '../../assets/images/vesselTypes/vi3.png';
import v4 from '../../assets/images/vesselTypes/vi4.png';
import v6 from '../../assets/images/vesselTypes/vi6.png';
import v7 from '../../assets/images/vesselTypes/vi7.png';
import v8 from '../../assets/images/vesselTypes/vi8.png';
import v9 from '../../assets/images/vesselTypes/vi9.png';

import {Tooltip} from '@mui/material';
import IMG_NOT_AVAILABLE_URL from '../../assets/images/image-not-available.png';


const greenColor = {fill: 'rgb(129,238,126)', stroke: 'rgb(53,104,61)'};
const purpleColor = {fill: 'rgb(251,0,255)', stroke: 'rgb(109,38,134)'};
const tealColor = {fill: 'rgb(33,255,255)', stroke: 'rgb(62,139,148)'};

const specialColors = {
  '30': {fill: 'rgb(253,141,103)', stroke: '#000000'}, // Fishing
  '37': purpleColor, // Pleasure Craft
  '36': purpleColor, // Sailing Vessel
  '90': tealColor, // Tug
  '96': greenColor, // General Cargo
  '99': tealColor, // Motor Hopper
  '115': {fill: 'rgb(245,194,204)', stroke: '#000000'}, // Beacon
  '200': {fill: 'rgb(201,201,201)', stroke: '#000000'},  // Lighthouse
  'RO-RO/PASSENGER SHIP': {fill: '#0000ff', stroke: '#FFFFFF'}, // RO-RO Passenger
};

const generalColors = {
  '0': {fill: 'rgb(201,201,201)', stroke: '#000000'},  // Reserved
  '1': {fill: 'rgb(201,201,201)', stroke: '#000000'},  // Reserved
  '2': {fill: '#0000ff', stroke: '#000000'}, // Wing In Ground
  '3': tealColor, // Special or Tug
  '4': {fill: 'rgb(255,255,11)', stroke: '#FF0000'}, // High-Speed Craft
  '5': tealColor, // Special Category
  '6': {fill: '#0000ff', stroke: '#000000'}, // Passenger
  '7': greenColor, // Cargo
  '8': {fill: 'rgb(251,0,7)', stroke: '#000000'}, // Tanker
  '9': {fill: 'grey', stroke: '#000000'}, //Other
};

const typeImg = {
  'RO-RO/PASSENGER SHIP': v6,
  '1': v1,  // Beacon
  '2': v2, // Wing In Ground
  '3': v3, // Special or Tug
  '4': v4, // High-Speed Craft
  '5': v3, // Special Category
  '6': v6, // Passenger
  '7': v7, // Cargo
  '8': v8, // Tanker
  '9': v9, //Other
  '30': v2, // Fishing
  '37': v9, // Pleasure Craft
  '52': v3, // Tug
};

const defaultImg = v9;

export const TypeIcon = ({shipType, typeName}) => {
  console.log({shipType, typeName});

  const imgSrc = shipType && typeImg[shipType] || typeImg[shipType.slice(0, 1)] || defaultImg;
  return <Tooltip title={typeName}><img src={imgSrc} alt={typeName}/></Tooltip>;
};

TypeIcon.propTypes = {
  shipType: PropTypes.string.isRequired,
  typeName: PropTypes.string.isRequired
};

const unknownVessel = {
  fill: 'grey',
  stroke: '#000080'
};

export function vesselColor(shipType) {
  return shipType && (specialColors[shipType] || generalColors[shipType.slice(0, 1)]) || unknownVessel;
}

export const vesselImg = imgId => imgId ? `https://photos.marinetraffic.com/ais/showphoto.aspx?photoid=${imgId}` : IMG_NOT_AVAILABLE_URL;


const Nab_1_Center = [54.174296, 19.387199]
const Nab_1_R = [2*0.00037100000000123146, 2*0.0001390000000007774]

const Nab_2_Center = [ 54.173561, 19.387671]
const Nab_2_R = [2*0.0003639999999975885,  2*0.00033300000000124896]

export const isNearDock1 = (lat, lon) => Math.abs(Nab_1_Center[0]-lat) <= Nab_1_R[0] && Math.abs(Nab_1_Center[1]-lon) <= Nab_1_R[1]
export const isNearDock2 = (lat, lon) => Math.abs(Nab_2_Center[0]-lat) <= Nab_2_R[0] && Math.abs(Nab_2_Center[1]-lon) <= Nab_2_R[1]
