import WindRoseMarker from '../../components/WindRoseMarker/WindRoseMarker';
import React, {useEffect} from 'react';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {useMapContext} from '../../context/map/mapContext';
import {getWindRoseData} from '../../api';
import {useWindRoseContext} from '../../context/windRose/windRoseContext';

export default function WindMarkers({mapStyleId}) {
  const {config: {clientId, devices, weatherStation}} = useConfig().state;
  const {state: {selectedMarkers}, dispatch: mapDispatch} = useMapContext();
  const {dispatch: windRoseDispatch} = useWindRoseContext();

  useEffect(() => {
    mapDispatch({type: 'set-loading', loading: true});
    const today = new Date().toISOString().slice(0, 'YYYY-MM-DD'.length);
    getWindRoseData(clientId, weatherStation, today, today)
      .then(result => windRoseDispatch({type: 'set-wind-roses-data', widRosesData: result}))
      .catch(err => mapDispatch({type: 'set-error', error: err.response?.data}))
      .finally(() => mapDispatch({type: 'set-loading', loading: false}));
  }, [clientId]);


  return selectedMarkers.includes('WEATHER') && devices
    .filter(device => device.weatherStation)
    .map(device => <WindRoseMarker
      key={'WindRose-' + device.serialNo}
      device={device}
      weatherStation={weatherStation}
      mapStyleId={mapStyleId}
    />);
}
