import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PM1Icon = (props) => <SvgIcon {...props} viewBox="0 0 104 104">
    <g fill={props.color} fillRule="evenodd">
      <path
        d="M35 71.38a10.1 10.1 0 011.64-.24c.587-.04 1.167-.06 1.74-.06.613 0 1.237.06 1.87.18s1.21.34 1.73.66.943.76 1.27 1.32c.327.56.49 1.273.49 2.14 0 .853-.153 1.573-.46 2.16a4.048 4.048 0 01-1.22 1.43 4.937 4.937 0 01-1.74.79c-.653.16-1.327.24-2.02.24h-.33a12.025 12.025 0 01-.97-.04 3.437 3.437 0 01-.34-.04v5.32H35V71.38zm3.42 1.14c-.347 0-.68.007-1 .02-.32.013-.573.047-.76.1v5.8c.067.027.173.043.32.05l.46.02c.16.007.313.01.46.01h.32c.453 0 .903-.043 1.35-.13.447-.087.85-.243 1.21-.47.36-.227.65-.547.87-.96.22-.413.33-.94.33-1.58 0-.547-.103-1.003-.31-1.37a2.478 2.478 0 00-.82-.88 3.456 3.456 0 00-1.15-.47 5.974 5.974 0 00-1.28-.14zm18.52 3.32l.2-2.1h-.1l-.76 1.92-3.64 6.32h-.5l-3.86-6.34-.72-1.9h-.1l.28 2.08v9.42h-1.56v-14h1.24l4.38 7.16.66 1.56h.04l.62-1.6 4.18-7.12h1.3v14h-1.66v-9.4zm5.52 7.92h2.8v-9.68l.2-1.18-.8.94-2.2 1.58-.76-1.02 4.4-3.4h.72v12.76h2.74v1.48h-7.1v-1.48z"
        opacity=".904"></path>
      <g dataId="55296e5f8b" opacity=".9" fillRule="nonzero">
        <path
          d="M49.928 26.964c0-1.085-.88-1.964-1.964-1.964C46.49 25 46 25.88 46 26.964a1.964 1.964 0 103.928 0zM47.964 35C46.879 35 46 35.88 46 36.964s.119 1.964 1.204 1.964c2.28 0 2.724-.88 2.724-1.964 0-1.085-.88-1.964-1.964-1.964zm-8 1.82c1.084 0 1.78-.136 1.964-1.205.316-1.837-.88-2.724-1.964-2.724a1.964 1.964 0 000 3.928zm-10.72-1.965c1.085 0 1.964-.879 1.964-1.964 0-1.084 0-1.963-1.964-1.963a1.964 1.964 0 000 3.927zm-.316 8.109c0-1.085-.396-1.964-1.964-1.964a1.964 1.964 0 101.964 1.964zM28.964 52C27.879 52 27 52.364 27 53.964A1.964 1.964 0 1028.964 52zm11.507-2c-1.084 0-2.721.02-2.439 1.964.285 1.964 1.355 1.964 2.44 1.964a1.964 1.964 0 000-3.928zm5.493-5C44.879 45 44 45.88 44 46.964s.49 1.964 1.964 1.964a1.964 1.964 0 000-3.928zm-7.842-4C36.554 41 36 41.95 36 43.122a2.122 2.122 0 104.244 0c0-1.172-.459-2.122-2.122-2.122zM78.928 31.964c0-1.085-.88-1.964-1.964-1.964C75.49 30 75 30.88 75 31.964a1.964 1.964 0 103.928 0zM72.964 38C71.879 38 71 38.88 71 39.964s.119 1.964 1.204 1.964c2.28 0 2.724-.88 2.724-1.964 0-1.085-.88-1.964-1.964-1.964zm-16 1.928c1.084 0 1.78-.135 1.964-1.204.316-1.837-.88-2.724-1.964-2.724a1.964 1.964 0 000 3.928zm6-6c1.084 0 1.964-.88 1.964-1.964 0-1.085 0-1.964-1.964-1.964a1.964 1.964 0 000 3.928zm3.964 8.036c0-1.085-.396-1.964-1.964-1.964a1.964 1.964 0 101.964 1.964zM54.964 44C53.879 44 53 44.364 53 45.964A1.964 1.964 0 1054.964 44zm15 6C68.879 50 68 50.88 68 51.964s.49 1.964 1.964 1.964a1.964 1.964 0 000-3.928zm-12.842 2C55.554 52 55 52.95 55 54.122a2.122 2.122 0 104.244 0c0-1.172-.459-2.122-2.122-2.122z"
          ></path>
      </g>
    </g>
</SvgIcon>;

export default PM1Icon;
