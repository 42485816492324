import React, {forwardRef, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {
  CardHeader,
  IconButton,
  Table,
  TableContainer,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import moment from 'moment';
import RegularButton from '../CustomButtons/Button';
import {deleteDockReport, generateDockReport, getDockReports} from '../../api';
import CircularProgress from '@mui/material/CircularProgress';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Delete, Download, ListAlt} from '@mui/icons-material';
import {grayColor, MOBILE_SCREEN, primaryColor} from '../../assets/jss/nextjs-material-dashboard';
import {useConfig} from 'context/clientConfig/clientConfigContext';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useTranslation} from 'react-i18next';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';

const MAX_REPORTS = 10;

function DockReports() {
  const [reports, setReports] = useState([]);
  const {clientId} = useConfig().state.config;
  const {email} = useConfig().state;
  const [loading, setLoading] = useState(true);
  const [generating, setGenerating] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month').toDate());
  const [endDate, setEndDate] = useState(new Date());
  const removeAuthor = useMediaQuery('(max-width:1024px)');
  const removeCreated = useMediaQuery('(max-width:780px)');
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);
  const {t} = useTranslation();

  useEffect(() => {
    getDockReports(clientId)
      .then(result => setReports(result))
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  const rows = reports.map((report, idx) => ({
    lp: idx + 1,
    url: report.reportUrl,
    report: report.reportUrl.split('/')[5],
    author: report.createdBy,
    start: new Date(report.dateFrom).toLocaleDateString(),
    end: new Date(report.dateTo).toLocaleDateString(),
    created: new Date(report.timestamp).toLocaleString(),
    dockId: report.dockId,
    timestamp: report.timestamp
  }));

  const deleteReport = ({dockId, timestamp}) =>
    Swal.fire({
      title: `${t('swal.areYouSureYouWantToDeleteTheReport')}?`,
      icon: 'question',
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: t('general.yes'),
      denyButtonText: t('general.no'),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDockReport(dockId, timestamp)
          .then(res => {
            setReports(reports.filter(r => r.timestamp !== timestamp));
            Swal.fire(t('swal.reportDeleted'), '', 'success');
          })
          .catch(err => Swal.fire(t('swal.errorMessage'), err.response?.data, 'error'));
      }
    });


  const validate = () => {
    const isValid = reports.every(event => !moment(startDate).isSame(moment(event.start), 'day') && !moment(endDate).isSame(moment(event.end), 'day'));
    console.log({isValid});
    return isValid;
  };

  const generateReport = () => {
    setGenerating(true);
    if (validate()) {
      generateDockReport(clientId, {
        dateFrom: startDate.getTime(),
        dateTo: endDate.getTime(),
        docks: ['elb-4', 'elb-5'],
        createdBy: email,
        lang: 'pl'
      })
        .then(res => {
          console.log(res);
          Swal.fire(t('swal.successfullyGeneratedReport'), '', 'success');
          setReports([res, ...reports]);
        })
        .catch(err => Swal.fire(t('swal.errorMessage'), err.response?.data, 'error'))
        .finally(() => setGenerating(false));
    } else {
      setGenerating(false);
      Swal.fire(`${t('swal.reportExists')}!`, `${t('swal.reportForDates')} ${startDate.toLocaleDateString()} i ${endDate.toLocaleDateString()} ${t('swal.isAlreadyGenerated')}.`, 'warning');
    }
  };

  const DateCustomInput = forwardRef(({value, onClick}, ref) => (
    <TextField size={'small'} margin={'normal'} onClick={onClick} ref={ref} value={value}/>
  ));

  return (<Card variant={'outlined'} sx={{minHeight: '100%'}}>
      {!isMobile && <CardHeader
        title={<Button size={'large'}
                       startIcon={<ListAlt/>}
                       color={'info'}>{t('dockReports.reports')}</Button>}
      />}
      {/*MOBILE CARD TITLE*/}
      <Typography sx={{
        color: primaryColor[2],
        textAlign: 'center',
        fontSize: '1.25rem',
        paddingTop: '1em',
        '@media screen and (min-width: 50em)': {display: 'none'}
      }}>{t("dockReports.reports")} </Typography>
      {loading ? <CircularProgress size={100} sx={{marginLeft: '40%', marginTop: 10}}/> :
        <><Typography variant={'body1'} fontWeight={'bold'}
                      sx={{marginLeft: 2, color: primaryColor[2]}}>{t('dockReports.createNewReport')}:</Typography>
          <Box ml={3} display={'flex'} flexDirection={isMobile ? 'column' : 'row'} gap={2} alignItems={'center'}>
            <Box display={'flex'} alignItems={'center'}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                customInput={<DateCustomInput/>}
              />
              <Box sx={{mx: 2}}><Typography>to</Typography></Box>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                customInput={<DateCustomInput/>}
              />
            </Box>
            {/*<Select*/}
            {/*  defaultValue={'all'}*/}
            {/*  value={selectedDock}*/}
            {/*  onChange={setSelectDock}*/}
            {/*  variant={'outlined'}*/}
            {/*  size={'small'}*/}
            {/*  sx={{marginBottom: 2, minWidth: 194}}*/}
            {/*>*/}
            {/*  <MenuItem value={'choose'} disabled>{t("newEventDialog.found")}{vesselsOptions.length}</MenuItem>*/}
            {/*  {vesselsOptions.map(({flag, mmsi, name}) => <MenuItem value={mmsi}>*/}
            {/*    {<Flag style={{border: '1px solid #ADA9B4'}}*/}
            {/*           width={20}*/}
            {/*           code={flag}/>} &nbsp;&nbsp; {name}</MenuItem>)}*/}
            {/*</Select>*/}
            <Tooltip placement={"top"} title={t('dockReports.disabledMessage')}><Box mt={1}>
              {generating ? <LoadingButton
                  size={'small'}
                  color={'success'}
                  variant={'contained'}
                  loading={generating}
                  sx={{width: 150}}>
                  GENERATING</LoadingButton> :
                <RegularButton
                  disabled={reports.length >= MAX_REPORTS}
                  color={'success'}
                  size={'sm'}
                  // onClick={generateReport}
                >
                  {reports.length >= MAX_REPORTS ? t('dockReports.maxReportsReached') : t('dockReports.generateReport')}
                </RegularButton>
              }
            </Box></Tooltip>
          </Box>
          <Box display="flex" mt={4}>
            <TableContainer>
              <Table size="small" aria-label="reports table">
                <TableHead>
                  <TableRow sx={{backgroundColor: 'whitesmoke'}}>
                    {!isMobile && <TableCell width={20}><THeader>Lp</THeader></TableCell>}
                    {!removeCreated &&
                    <TableCell height={60} align="center"><THeader>{t('dockReports.created')}</THeader></TableCell>}
                    <TableCell align="center"><THeader>{t('dockReports.dateFrom')}</THeader></TableCell>
                    <TableCell align="center"><THeader>{t('dockReports.dateTo')}</THeader></TableCell>
                    {!removeAuthor &&
                    <TableCell align="center"><THeader>{t('dockReports.author')}</THeader></TableCell>}
                    <TableCell align="center"><THeader>{t('dockReports.actions')}</THeader></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(({lp, report, url, created, author, dockId, timestamp, start, end}) => (
                    <TableRow
                      key={report}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                      {!isMobile && <TableCell width={20} align={'center'}><TCell>{lp}</TCell></TableCell>}
                      {!removeCreated && <TableCell align="center"><TCell>
                        <a style={{color: primaryColor[2]}} href={url}>{created}</a>
                      </TCell></TableCell>}
                      <TableCell align="center"><TCell>{start}</TCell></TableCell>
                      <TableCell align="center"><TCell>{end}</TCell></TableCell>
                      {!removeAuthor && <TableCell align="center"><TCell>{author}</TCell></TableCell>}
                      <TableCell align="center">
                        <IconButton>
                          <a href={url}><Download color={'success'}/></a>
                        </IconButton>
                        <IconButton onClick={() => deleteReport({dockId, timestamp})}>
                          <Delete color={'error'}/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box mr={4} sx={{float: 'right'}} color={grayColor[1]}>
                <Typography fontWeight={'bold'}
                            color={reports.length >= MAX_REPORTS ? 'red' : 'inherit'}>{reports.length} /
                  {MAX_REPORTS}</Typography>
              </Box>
            </TableContainer>
          </Box>
        </>}
    </Card>
  );
}

export default DockReports;

const THeader = ({children}) => <Typography sx={{color: primaryColor[2]}}>
  {children}
</Typography>;

const TCell = ({children}) => <Typography
  color={grayColor[1]}>
  {children}
</Typography>;
