import {useDockEventContext} from '../../../context/dockEvent/dockEventContext';
import {Box, IconButton, Table, TableContainer, Typography, useMediaQuery} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {Storage} from 'aws-amplify';
import CircularProgress from '@mui/material/CircularProgress';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {Delete, Image, Theaters} from '@mui/icons-material';
import {
  grayColor,
  MOBILE_SCREEN,
  primaryColor,
  roseColor,
} from '../../../assets/jss/nextjs-material-dashboard';
import Excel from 'assets/images/Excel-icon.png';
import Word from 'assets/images/word-icon.png';
import PDF from 'assets/images/PDF-icon.png';
import TextIcon from 'assets/images/txt-icon.png';
import Swal from 'sweetalert2';
import {useTranslation} from "react-i18next";


export default function Documents() {
  const [loading, setLoading] = useState(true);
  const [s3Keys, setS3Keys] = useState([]);
  const {state, dispatch} = useDockEventContext();
  const {files} = state;
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);
  const {t} = useTranslation();

  useEffect(() => Promise.all(files.map(f => Storage.get(f.s3Key)))
      .then(result => setS3Keys(result))
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
    , [files]);


  const onDelete = ({name, s3Key}) => {
    Swal.fire({
      title: `${t("swal.areYouSureYouWantToDeleteTheFile")} ${name}?`,
      icon: 'question',
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: t("general.yes"),
      denyButtonText: t("general.no"),
    }).then((result) => {
      if (result.isConfirmed) {
        Storage.remove(s3Key)
          .then(() => {
            Swal.fire(t("swal.fileRemoved"), '', 'success');
            dispatch({type: 'delete-dock-event-file', s3Key});
          })
          .catch(err => Swal.fire(t("swal.errorMessage"), err.response?.data, 'error'));
      }
    });
  };

  const rows = useMemo(() => s3Keys ? files.map(({name, size, type, uploadedDate, addedBy, s3Key}, idx) => ({
      lp: idx + 1,
      name,
      date: new Date(uploadedDate).toLocaleString(),
      size,
      author: addedBy,
      url: s3Keys.filter(s3k => s3k.includes(s3Key))[0],
      s3Key,
      type
    })) : []
    , [files, s3Keys]);

  console.table(rows);

  return <Box display="flex" mt={2}>
    {loading ? <CircularProgress size={100} sx={{margin: 'auto', marginTop: 10}}/> :
      <TableContainer>
        {rows.length > 0 && <Table size="small" aria-label="reports table">
          <TableHead>
            <TableRow>
              {!isMobile && <TableCell width={10}><THeader>Lp</THeader></TableCell>}
              <TableCell align="center"><THeader>{t("documents.file")}</THeader></TableCell>
              <TableCell align="center"><THeader>{t("documents.added")}</THeader></TableCell>
              <TableCell align="center"><THeader>{t("documents.author")}</THeader></TableCell>
              <TableCell align="center"><THeader></THeader></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(({lp, name, date, author, url, s3Key, type}) => (
              <TableRow
                key={name}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                {!isMobile && <TableCell width={10} align={'center'}><TCell>{lp}</TCell></TableCell>}
                <TableCell align="center">
                  <TCell>
                    <Box sx={{display: "flex", alignItems: "center"}}>
                      <Box>
                        <Typography
                          sx={{"@media screen and (max-width: 60em)": {fontSize: ".80rem"}}}>
                          <a href={url}>{name}</a></Typography>
                      </Box>
                      <Box sx={{"@media screen and (max-width: 60em)": {display: "none"}}}>
                        <FileIcon type={type} name={name}/>
                      </Box>
                    </Box>
                  </TCell>
                </TableCell>
                <TableCell align="center"><TCell
                  sx={{"@media screen and (max-width: 60em)": {fontSize: ".80rem"}}}>{date}</TCell></TableCell>
                <TableCell align="center"><TCell
                  sx={{"@media screen and (max-width: 60em)": {fontSize: ".80rem"}}}>{author}</TCell></TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => onDelete({name, s3Key})}>
                    <Delete sx={{"@media screen and (max-width: 60em)": {width: "20px"}}} color={'error'}/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>}
      </TableContainer>
    }
  </Box>;
}

const THeader = ({children}) => <Typography fontWeight={'bold'}>
  {children}
</Typography>;

const TCell = ({children, sx}) => <Typography sx={sx} color={grayColor[1]}>
  {children}
</Typography>;

const FileIcon = ({type, name}) => {
  if (type.includes('pdf')) {
    return <img width={30} height={30} src={PDF} style={{float: 'right'}}/>;
  }
  if (type.includes('video') || name.includes('.gif')) {
    return <Theaters sx={{float: 'right', color: roseColor[2]}}/>;
  }
  if (type.includes('image')) {
    return <Image sx={{float: 'right', color: primaryColor[2]}}/>;
  }
  if (name.includes('.txt') || name.includes('.rtf')) {
    return <img width={30} height={30} src={TextIcon} style={{float: 'right'}}/>;
  }
  if (name.includes('.xlsx') || name.includes('.xls')) {
    return <img width={30} height={25} src={Excel} style={{float: 'right'}}/>;
  }
  if (name.includes('.doc') || name.includes('.docx')) {
    return <img width={50} height={30} src={Word} style={{float: 'right'}}/>;
  }
  return <></>;
};
