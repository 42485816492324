import React, {useMemo, useReducer} from 'react';
import PropTypes from 'prop-types';

const initialState = () => {
  return {
    satelliteData: null,
    satelliteDataByParam: {},
    currentSatelliteParam: null,
    currentDate: null,
    // map with key: param-YYYY-MM-DD, and value: {
    //
    // }
    cache: {}
  };
};


const SatelliteDataContext = React.createContext(initialState());
// expose the context via a custom hook.
export const useSatelliteDataContext = () => React.useContext(SatelliteDataContext);

function reducer(state, action) {
  switch (action.type) {
  case 'set-satellite-data':
    return {
      ...state,
      satelliteData: action.satelliteData,
      satelliteDataByParam: action.satelliteData.reduce((acc, item) => ({...acc, ...item}), {})
    };
  case 'set-current-satellite-param':
    return {
      ...state,
      currentSatelliteParam: state.currentSatelliteParam === action.satelliteParam ? null : action.satelliteParam
    };
  case 'set-current-satellite-date':
    return {
      ...state,
      currentDate: action.date,
    };
  case 'add-satellite-layer':
    return {
      ...state,
      cache: {
        ...state.cache,
        [action.param + '-' + action.date]: action.layer
      },
    };
  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function SatelliteDataContextProvider({children}) {
  const [state, dispatch] = useReducer(reducer, initialState());
  const userContext = useMemo(() => {
    return {state, dispatch};
  }, [state]);

  return <SatelliteDataContext.Provider value={userContext}>
    {children}
  </SatelliteDataContext.Provider>;
}

SatelliteDataContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};
