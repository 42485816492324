import React, {useEffect, useState} from 'react';
import Highcharts from 'highcharts/highstock';
import {
  AreaSplineSeries,
  Chart,
  HighchartsProvider,
  HighchartsStockChart,
  Legend,
  LineSeries,
  Title,
  Tooltip,
  XAxis,
  YAxis,
  Navigator
} from 'react-jsx-highstock';

import applyExporting from 'highcharts/modules/exporting';
import applyOffline from 'highcharts/modules/offline-exporting';

import {getChartMeasurements, getEpisodesForChart} from '../../api';
import Box from '@mui/material/Box';
import AirDateRangePicker from './AirDateRangePicker';
import QuickAirDateButtons from './QuickAirDateButtons';
import {
  dangerColor,
  MOBILE_SCREEN,
  primaryColor,
  successColor,
  warningColor
} from '../../assets/jss/nextjs-material-dashboard';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {useMediaQuery} from '@mui/material';
import DeviceImage from './DeviceImage';
import {useTranslation} from 'react-i18next';
import moment from 'moment/moment';
import CameraOnDevice from './CameraOnDevice';
import CameraDeviceModal from '../CameraDevice/CameraDeviceModal';
import {unitsByParams} from '../../utils';

applyExporting(Highcharts);
applyOffline(Highcharts);

const NUMBER_OF_BUCKETS = 70;

const plotOptions = {
  column: {
    pointWidth: 6,
    maxPointWidth: 50,
    minPointLength: 3, // By default, 0 values are not shown. To visualize a 0 (or close to zero) point, set the minimal point length to a pixel value like 3
    borderWidth: 0
  },
  series: {
    shadow: true,
    // dataGrouping: {
    //   approximation: 'average',
    //   groupAll: true,
    //   groupPixelWidth: 20
    // }
  }
};


const plotVerticalLines = episodes => episodes.map(e => ({
  color: '#FF0000', // Red
  width: 2,
  // acrossPanes: true,
  dashStyle: 'shortdash',
  value: e.timestamp // Position, you'll have to translate this to the values on your x axis
}));

const timeOptions = {
  useUTC: false
};
const ONE_HOUR_MS = 3600000

const OIL_IN_WATER_MEASUREMENTS = 'temp,oil';

// MUST READ -> https://www.fao.org/3/ac183e/AC183E00.htm#TOC
export default function OilInWaterChart({device}) {
  const {clientId} = useConfig().state.config;
  const userEmail = useConfig().state.email;
  const {t} = useTranslation();
  const [episodes, setEpisodes] = useState([]);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [showCameraModal, setShowCameraModal] = useState(false);
  const title = device.name;

  useEffect(() => {
    if (clientId && device && dateFrom && dateTo) {
      getEpisodesForChart(clientId, device.serialNo, dateFrom - ONE_HOUR_MS, dateTo + ONE_HOUR_MS)
        .then(result => setEpisodes(result));
    }
  }, [clientId, device, dateFrom, dateTo]);

  const episodesData = episodes.map(e => ({
    x: e.timestamp,
    date: moment(e.timestamp).format('LL'),
    y: e.value || e.PM10,
    threshold: e.threshold,
    value: e.value || e.PM10,
    episodeType: e.episode_type || e.level, // TODO levle check
    episodeTitle: `${t('episodes.exceededLevel')} ${t('episodes.severity', {returnObjects: true})[e.level]}`,
    episodeLabel: `</br>${t('episodes.measuredLevel')}: <b>${e.value} ${unitsByParams(e.substance)}</b> </br>${t('episodes.thresholdLevel')}: <b>${e.threshold} ${unitsByParams(e.substance)}</b> </br> ${t('episodes.period')}: <b>${t('episodes', {returnObjects: true})[e.period] || e.period}</b>`
  }));

  const afterSetExtremes = (e) => {
    const {serialNo} = device;
    const {chart} = e.target;
    const nowMillis = new Date().getTime();
    const min = e.userMin || (nowMillis - 1000 * 60 * 60 * 24);
    const max = e.userMax || nowMillis;

    // Zabezpieczenie przed ponownym pobraniem danych po narysowaniu vertical Lines
    if (!!e.max && e.type === "afterSetExtremes") return;

    var rangeInHours = null;
    const dateFromHours = rangeInHours ? rangeInHours : Math.max(1, Math.round((nowMillis - min) / (1000 * 60 * 60)));
    const dateToHours = rangeInHours ? 0 : Math.max(0, Math.round((nowMillis - max) / (1000 * 60 * 60)));
    const dateTimeFrom = '-' + dateFromHours + 'h';
    const dateTimeTo = '-' + dateToHours + 'h';
    chart.showLoading(t('general.loading'));
    getChartMeasurements(clientId, serialNo, OIL_IN_WATER_MEASUREMENTS, dateTimeFrom, dateTimeTo, NUMBER_OF_BUCKETS)
      .then(data => {
        setDateFrom(min);
        setDateTo(max);
        if (data[serialNo]) {
          chart.series[0].setData(data[serialNo].oil, false);
          chart.yAxis[0].series[0].setData(data[serialNo].temp, false);
          chart.yAxis[1].series[0].setData(data[serialNo].oil, false);
          if (!e.trigger) {
            chart.navigator.series[0].setData(data[serialNo].temp, false);
            chart.navigator.series[1].setData(data[serialNo].oil, false);
          }
        }
        chart.redraw();
        chart.hideLoading();
      }).catch(error => console.error(error.message));
  };

  const [xAxisEvents, setXAxisEvents] = useState({afterSetExtremes: afterSetExtremes});
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);

  const plotHorizontalLines = [{
    // zIndex: 99,
    zIndex: -1,
    value: 20,
    color: 'green',
    dashStyle: 'shortdash',
    width: 2,
    label: {
      text: t("oilInWaterChart.withinTheNorm")
    }
  },
    {
      // zIndex: 99,
      zIndex: -1,
      value: 50,
      color: 'red',
      dashStyle: 'shortdash',
      width: 2,
      label: {
        text: t("oilInWaterChart.stateOfEmergency")
      }
    }
  ];

  return (<Box position={'relative'}>
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsStockChart plotOptions={plotOptions} time={timeOptions}>
          <Chart zooming={{type: 'x'}} panning={{enabled: true}} panKey={'shift'}/>

          <Legend align={'left'} verticalAlign={'top'} x={40} y={30} useHTML={true}/>
          <Title style={{color: primaryColor[2]}} align={'center'}>{title}</Title>

          <Tooltip split valueDecimals={2} backgroundColor={'white'}/>
          <XAxis
            events={xAxisEvents}
            range={15 * 24 * 60 * 60 * 1000}
            plotLines={plotVerticalLines(episodes)}
          />

          {/* TEMP LINE CHART */}
          <YAxis
            min={0}
            max={30}
            opposite={true}
            useHTML={true}>
            <YAxis.Title>Temp [°C]</YAxis.Title>

            <LineSeries
              id="temp"
              name={t("measurements.parameters.temp")}
              color={dangerColor[2]}
              tooltip={{valueSuffix: ' [°C]'}}
              zones={[{value: 15, color: 'blue'}, {color: 'red'}]}
            />
          </YAxis>

          {/* OIL IN WATER LINE CHART */}
          <YAxis
            min={0}
            max={100}
            opposite={false}
            plotLines={plotHorizontalLines}
            useHTML={true}>
            <YAxis.Title>Oil [ppm]</YAxis.Title>

            <AreaSplineSeries
              id="oil"
              name={t("measurements.parameters.oil")}
              fillColor={{
                linearGradient: [0, 0, 0, 200],
                stops: [
                  [0, 'red'],
                  [0.7, Highcharts.color(warningColor[0]).setOpacity(0.5).get('rgba')],
                  [0.95, Highcharts.color(successColor[0]).setOpacity(0.3).get('rgba')],
                  [1, Highcharts.color(successColor[0]).setOpacity(0.1).get('rgba')]
                ]
              }}
              lineColor={{
                linearGradient: [0, 0, 0, 200],
                stops: [
                  [0, 'red'],
                  [0.7, Highcharts.color(warningColor[0]).setOpacity(0.5).get('rgba')],
                  [0.95, Highcharts.color(successColor[0]).setOpacity(0.3).get('rgba')],
                  [1, Highcharts.color(successColor[0]).setOpacity(0.1).get('rgba')]
                ]
              }}
              color={successColor[2]}
              tooltip={{valueSuffix: ' [ppm]'}}
              zones={[{value: 20, color: 'green'}, {value: 30, color: 'orange'}, {color: 'red'}]}
            />

            <LineSeries
              id="Episodes"
              visible={false}
              color={'red'}

              lineWidth={0}
              shadow={false}
              marker={{
                enabled: true,
                symbol: 'triangle-down',
                color: 'red',
                radius: 13
              }}
              states={{hover: {lineWidthPlus: 0}}}
              data={episodesData}
              name={t("reports.episodes")}
              tooltip={{
                pointFormat: '<span style="color:{point.color}"><b>▼{point.episodeTitle} </b></span> <br/> {point.episodeLabel}'
              }}
            >
              {/*<Tooltip></Tooltip>*/}
            </LineSeries>
          </YAxis>
          <QuickAirDateButtons width={!isMobile ? 200 : '100%'}/>
          <AirDateRangePicker/>

          {/*{device.camera === "photo" && <CameraOnDevice clientId={clientId} serialNo={device.serialNo}/>}*/}
          {device.camera === 'photo' &&
          <CameraOnDevice
            clientId={clientId}
            serialNo={device.serialNo}
            onClick={() => setShowCameraModal(true)}
          />}
          {showCameraModal && <CameraDeviceModal cameraStation={device} onClose={() => setShowCameraModal(false)}/>}
          <DeviceImage clientId={clientId} serialNo={device.serialNo}/>

          <Navigator adaptToUpdatedData={false} series={{
            fillColor: null,
            opacity: 0.5,
          }}>
            <Navigator.Series seriesId="temp"/>
            <Navigator.Series seriesId="oil"/>
          </Navigator>

        </HighchartsStockChart>
      </HighchartsProvider>
    </Box>
  );
}
