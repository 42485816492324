import React, {useState} from 'react';
// Dropzone components
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import './styles.css';


import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {AddAPhoto} from '@mui/icons-material';
import Swal from 'sweetalert2';
import {primaryColor} from '../../assets/jss/nextjs-material-dashboard';
import PreviewWrapper from '../FilesDropzone/PreviewWrapper';
import {useMaintenance} from '../../context/maintenance/maintenanceContext';
import {Amplify, Storage} from 'aws-amplify';
import {s3configFilesCoral} from '../../aws-exports';
import moment from 'moment';

const MAX_FILES = 1;
const MAX_SIZE_BYTES = 10 * 1024 * 1024; // 10 * 1MB
const S3_URL = 'https://s3.eu-central-1.amazonaws.com';
const BUCKET_NAME = 'files.coral.com';
const customPrefix = {
  public: 'maintenance/',
  protected: 'maintenance/',
  private: 'maintenance/'
};

export default function PhotoDropzone({onUploadDone, isNote, startPhoto}) {
  const {t} = useTranslation();
  const {state: {formData}} = useMaintenance();
  const [files, setFiles] = useState([]);

  const [filesProgress, setFilesProgress] = useState({});
  const [maxFilesReached, setMaxFilesReached] = useState(false);

  const handleChangeStatus = async (event, status) => {
    const {meta, file, remove, restart} = event;
    const fileName = isNote ? `${formData.createdAt}-note-${moment().valueOf()}.jpg`
      :
      `${formData.createdAt}-${startPhoto ? 'before' : 'after'}.jpg`;
    const fileKey = `photo/${formData.clientId}/${formData.serialNo}/${fileName}`;
    switch (status) {
    case 'rejected_max_files':
      setMaxFilesReached(true);
      return;
    case 'preparing':
      if (files.length > MAX_FILES - 1) {
        Swal.fire(`${t('swal.maxFiles')}: ${MAX_FILES}!`, '', 'error');
        remove();
        return;
      }
      if (files.filter(f => fileName === f.name).length > 0) {
        Swal.fire(`${t('swal.fileAlreadyExists')}!`, '', 'error');
        remove();
        return;
      }
      Amplify.configure(s3configFilesCoral);
      await Storage.put(fileKey, file, {
        acl: 'public-read',
        customPrefix,
        level: 'public',
        progressCallback: (progress) => {
          setFilesProgress({...filesProgress, [file.name]: progress});
        },
      });
      const imgUrl = `${S3_URL}/${BUCKET_NAME}/${customPrefix.public}${fileKey.replace('/photo/', '/thumb/')}`;
      onUploadDone(imgUrl);
      // remove();
      return;
      // case 'remove':
      // await Storage.remove(fileKey);
      // dispatch({type: 'update-dock-event', event: {files: files.filter(f => f.fileUrl.includes(fileKey))}});
      // return;
    default:
      return;
    }
  };

  return <Box>
    <Dropzone
      multiple={false}
      autoUpload
      initialFiles={[]}
      accept="image/*"
      maxFiles={MAX_FILES}
      maxSizeBytes={MAX_SIZE_BYTES}
      onChangeStatus={handleChangeStatus}
      PreviewComponent={props => <PreviewWrapper {...props} filesProgress={filesProgress}/>}
      inputWithFilesContent={
        <Typography variant={'button'} color={maxFilesReached ? 'error' : 'primary'}>
          {maxFilesReached ? t('fileDropzone.tooManyFiles') : t('fileDropzone.addFile')}
        </Typography>
      }
      inputContent={<Box
        display="flex"
        gap={1}
        alignItems={'center'}
        height="100%"
      >
        <AddAPhoto sx={{color: primaryColor[2]}}/>
        <Typography variant={'caption'} color="primary">
          Kliknij aby dodać lub upuść zdjęcie tutaj
          {/* Przeciągnij pliki lub kliknij, aby dodać */}
        </Typography>
      </Box>}
    />
  </Box>;
}
