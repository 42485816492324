import createTheme from '@mui/material/styles/createTheme';
import pxToRem from "./assets/theme/functions/pxToRem";

const theme = mode => createTheme({
  palette: {
    mode: mode ? mode : 'light',
    light: {
      main: "#e9ecef",
    },
    dark: {
      main: "#344767",
      focus: "#344767",
    },
    primary: {
      main: '#1f6487',
    },
    secondary: {
      main: '#EA9A27'
    }
  },
  typography: {
    size: {
      xs: pxToRem(12),
      sm: pxToRem(14),
      regular: pxToRem(16),
      lg: pxToRem(18),
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar': {
          width: '0.2em'
        },
        '*::-webkit-scrollbar-track': {
          'WebkitBoxShadow': 'inset 0 0 2px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '0px solid slategrey'
        }
      }
    }
  },
  zIndex: {
    modal: 1500
  },
});

export default theme;
