import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import {IconButton} from '@mui/material';
import Box from '@mui/material/Box';
import {HighlightOff} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {useDockEventContext} from '../../context/dockEvent/dockEventContext';
import {useConfig} from '../../context/clientConfig/clientConfigContext';

const filter = createFilterOptions();

const EN_TRANSLATION = {
  'Drobnica różna': 'Break Bulk',
  'Węgiel': 'Coal',
  'Konstrukcje stalowe': 'Steel',
  'Tłuczeń': 'Broken Stone',
  'Cegła': 'Brick',
  'Zboże/soja': 'Grain',
  'Drewno': 'Wood',
  'Piasek': 'Sand',
  'Żwir': 'Gravel'
};

export default function CargoTypeRow({idx, unloading}) {
  const {state, dispatch} = useDockEventContext();
  const {favouriteVessels, cargoTypes} = useConfig().state.config?.portMgmt;
  const {unloadingInfo, loadingInfo, vessel} = state;
  const favVessel = favouriteVessels.filter(f => f.mmsi === vessel.mmsi);
  const loadInfo = unloading ? unloadingInfo : loadingInfo;
  const value = loadInfo.cargoTypes[idx] || favVessel[0]?.cargoType;
  const description = loadInfo.cargoTypes[idx]?.description || '';
  const amount = loadInfo.cargoTypes[idx]?.amount;
  const unit = loadInfo.cargoTypes[idx]?.unit || favVessel[0]?.cargoUnit;

  const {t, i18n} = useTranslation();

  const cargoTypesOptions = cargoTypes.map(a => {
    const e = Object.entries(a);
    return {cargoName: e[0][0], units: e[0][1]};
  });

  const setValue = (cargoName) => {
    dispatch({type: 'update-cargo-type', idx, unloading: unloading, cargoType: {cargoName}});
  };

  const setAmount = event => {
    const a = event.target.value < 0
      ? (event.target.value = 0)
      : event.target.value;
    dispatch({
      type: 'update-cargo-type',
      idx,
      unloading: unloading,
      cargoType: {amount: a}
    });
  };

  const setUnit = unitValue => dispatch({
    type: 'update-cargo-type',
    idx,
    unloading: unloading,
    cargoType: {unit: unitValue}
  });

  const onValueChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setValue(newValue);
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setValue(newValue.inputValue);
    } else {
      setValue(newValue.cargoName);
    }
  };

  const onUnitChange = (event, newUnitValue) => {
    if (typeof newUnitValue === 'string') {
      setUnit(newUnitValue);
    } else if (newUnitValue && newUnitValue.inputValue) {
      // Create a new value from the user input
      setUnit(newUnitValue.inputValue);
    } else {
      setUnit(newUnitValue);
    }
  };

  const onRemoveRow = () => dispatch({type: 'remove-cargo-type', idx, unloading: unloading});

  const onDescriptionChange = event => dispatch({
    type: 'update-cargo-type',
    idx,
    unloading: unloading,
    cargoType: {description: event.target.value}
  });

  return (
    <Box>
      <Box>
        <Box display={'flex'} gap={2} sx={{marginBottom: 1}}>
          <Autocomplete
            sx={{maxWidth: 400}}
            freeSolo
            fullWidth
            size={'small'}
            id={`cargo-type-${idx}-${unloading}`}
            value={value}
            onChange={onValueChange}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const {inputValue} = params;
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option.cargoName);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  cargoName: `${t('cargoTypeRow.add')} "${inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={cargoTypesOptions}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return (i18n.resolvedLanguage === "pl" ? option.cargoName : EN_TRANSLATION[option.cargoName]) || option.cargoName;
            }}
            renderOption={(props, option) => <li {...props}>{(i18n.resolvedLanguage === "pl" ? option.cargoName : EN_TRANSLATION[option.cargoName]) || option.cargoName}</li>}
            renderInput={(params) => (
              <TextField {...params} label={t('cargoTypeRow.cargoType') + (idx + 1)}/>
            )}
          />
          <TextField
            sx={{width: 100}}
            id="amount"
            size={'small'}
            type={'number'}
            label={t('cargoTypeRow.amount')}
            // placeholder={0}
            onChange={setAmount}
            value={amount}
          />
          <Autocomplete
            sx={{width: 150}}
            freeSolo
            size={'small'}
            id={`cargo-unit-${idx}-${unloading}`}
            value={unit}
            onChange={onUnitChange}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const {inputValue} = params;
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  cargoName: `${t('cargoTypeRow.add')} "${inputValue}"`,
                });
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={defaultTypesOptions}
            renderInput={(params) => (
              <TextField {...params} label={t('cargoTypeRow.unit')}/>
            )}
          />
          <IconButton onClick={onRemoveRow}>
            <HighlightOff/>
          </IconButton>
        </Box>
        <Box sx={{maxWidth: 682}} mb={2}>
          <TextField
            fullWidth
            size={'small'}
            value={description}
            onChange={onDescriptionChange}
            placeholder={`${t('cargoTypeRow.descriptionOf')} ${unloading ? t('cargoTypeRow.unloading') : t('cargoTypeRow.loading')} ${t('cargoTypeRow.cargo')}`}/>
        </Box>
      </Box>
    </Box>
  );
}

const defaultTypesOptions = ['t', 'm³'];
// const defaultTypesOptions = ['t', 'm³', 'TEU', 'pcs'];


// const generalTypes = [
//   {label: 'BREAK BULK', code: 'BREAK_BULK', units: ['t', 'm³', 'metric tons', 'cubic tons', 'TEU', 'pcs']},
//   {label: 'Neo Bulk', code: 'NEO_BULK', units: ['t', 'm³', 'metric tons', 'cubic tons', 'TEU', 'pcs']},
//   {label: 'Containerized', code: 'CONTAINERIZED', units: ['TEU', 'pcs', 'm³', 'cubic tons', 'TEU']},
//
//   {label: 'Liquid bulk', code: 'LIQUID_BULK', units: ['m³', 't', 'TEU', 'pcs']},
//   {label: 'Dry Bulk', code: 'Dry Bulk', units: ['m³', 't', 'metric tons', 'cubic tons', 'TEU', 'pcs']},
// ];
