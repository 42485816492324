import Box from '@mui/material/Box';
import {AirTwoTone, InfoOutlined, InvertColorsTwoTone, ThermostatTwoTone, Thunderstorm} from '@mui/icons-material';
import {Divider, Typography} from '@mui/material';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import './RiskInfoTooltip.css';
import {useTranslation} from 'react-i18next';
import PressureIcon from '../../components/icons/PressureIcon';
import {primaryColor} from '../../assets/jss/nextjs-material-dashboard';
import {RISK_COLOR} from '../../utils';
import {useRiskContext} from '../../context/risk/riskContext';


export default function RiskInfoTooltip() {
  const {t} = useTranslation();
  const {state: {riskAreas, riskSliderValue}} = useRiskContext();
  const {
    risk_pollution: pollutionRiskLvl,
    temp,
    windSpeed,
    humidity,
    pressure,
    precipProb
  } = (riskAreas.length === 0 || riskAreas[0].values.length <= riskSliderValue) ? {} : riskAreas[0].values[riskSliderValue];

  const dividerColor = RISK_COLOR[pollutionRiskLvl] || 'grey';

  const titleComp = <Box>
    <Typography>{t('risk.riskTooltipTitle')}</Typography>
    <br/>
    {/* WIND */}
    <Box>
      <Box float={'left'} display={'flex'} gap={1} mb={1}>
        <AirTwoTone/>
        <Typography>{' '}{t('measurements.windSpeed')}: {windSpeed}m/s</Typography>
      </Box>
      <Box ml={3}>
        <Typography variant={'caption'}>{t('risk.riskTooltipWind1')}</Typography>
        <br/>
        <Typography variant={'caption'}>{t('risk.riskTooltipWind2')}</Typography>
        <br/>
        <Typography variant={'caption'}>{t('risk.riskTooltipWind3')}</Typography>
      </Box>
    </Box>
    <Divider sx={{my: 1}} color={dividerColor}/>

    {/* TEMP */}
    <Box>
      <Box float={'left'} display={'flex'} gap={1} mb={1}>
        <ThermostatTwoTone/>
        <Typography> {' '}{t('measurements.temperature')}: {temp}°C</Typography>
      </Box>
      <Box ml={3}>
        <Typography variant={'caption'}>{t('risk.riskTooltipTempLine1')}</Typography>
        <br/>
        <Typography variant={'caption'}>{t('risk.riskTooltipTempLine2')}</Typography>
      </Box>
    </Box>
    <Divider sx={{my: 1}} color={dividerColor}/>
    {/* HUMIDITY */}
    <Box>
      <Box float={'left'} display={'flex'} gap={1} mb={1}>
        <InvertColorsTwoTone/>
        <Typography> {' '} {t('measurements.humidity')}: {Math.round(humidity)}%</Typography>
      </Box>
      <Box ml={3}>
        <Typography variant={'caption'}>{t('risk.riskTooltipHumid')}</Typography>
      </Box>
    </Box>
    <Divider sx={{my: 1}} color={dividerColor}/>

    {/* PRESSURE */}
    <Box>
      <Box float={'left'} display={'flex'} gap={1} mb={1}>
        <Box sx={{
          borderRadius: '50%',
          display: 'inline-block',
          width: '20px',
          height: '20px',
          backgroundColor: 'whitesmoke',
        }}>
          <PressureIcon color={primaryColor[0]} sx={{width: 20, height: 20}}/>
        </Box>

        <Typography>{' '}{t('measurements.pressure')}: {pressure} hPa</Typography>
      </Box>
      <Box ml={3}>
        <Typography variant={'caption'}>{t('risk.riskTooltipPressure1')}</Typography>
        <br/>
        <Typography variant={'caption'}>{t('risk.riskTooltipPressure2')}</Typography>
        <br/>
        <Typography variant={'caption'}>{t('risk.riskTooltipPressure3')}</Typography>
      </Box>
    </Box>
    <Divider sx={{my: 1}} color={dividerColor}/>

    {/* RAIN */}
    <Box>
      <Box float={'left'} display={'flex'} gap={1} mb={1}>
        <Thunderstorm />
        <Typography> {' '} {t('risk.rainProb')}: {precipProb} % {' '} </Typography>
      </Box>
      <Box ml={3}>
        <Typography variant={'caption'}>{t('risk.riskTooltipRain')}</Typography>
      </Box>
    </Box>
  </Box>;

  return <Tooltip TransitionComponent={Fade}
                  classes={{popper: 'risk-popper', tooltip: 'risk-tooltip'}}
                  TransitionProps={{timeout: 600}}
                  placement={'right'}
                  title={titleComp}>
    <InfoOutlined sx={{width: 18}}/>
  </Tooltip>;
}
