import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

// Source: https://uxwing.com/day-cloudy-icon/
const VesselIcon = (props) => <SvgIcon {...props}
  viewBox="0 0 5.7923918 12.373498"
>
  <g transform="translate(-19.30897,-69.802537)">
    <path
      style={{
        opacity: 1,
        fillOpacity: 1,
        strokeWidth: 0.69999999,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeMiterlimit: 4,
        strokeDasharray: 'none',
        strokeDashoffset: 0
      }}
      d="m 19.66307,74.829397 2.574573,-4.676898 2.513719,4.693602 -0.0041,6.963273 -2.464078,-2.281676 -2.624214,2.29838 z"
    />
  </g>
</SvgIcon>;

export default VesselIcon;
