import React, {useState} from 'react';
import Box from "@mui/material/Box";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';


export default function CustomImgDeviceCarousel({images}) {
  const [currentImage, setCurrentImage] = useState(0);

  const nextImage = () => {
    setCurrentImage((prevImage) =>
      prevImage === images.length - 1 ? 0 : prevImage + 1
    );
  };

  const prevImage = () => {
    setCurrentImage((prevImage) =>
      prevImage === 0 ? images.length - 1 : prevImage - 1
    );
  };

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <img style={{borderRadius: 5}}
           src={images[currentImage]}
           alt={`Slide ${currentImage}`}/>
      {images.length > 1 && <Box sx={{
        position: 'absolute',
        top: 0,
        left: -10,
        right: -10,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <ArrowBackIosNewOutlinedIcon sx={{color: "white", cursor: "pointer", width: 40, height: 40}}
                                     onClick={prevImage}/>
        <ArrowForwardIosOutlinedIcon sx={{color: "white", cursor: "pointer", width: 40, height: 40}}
                                     onClick={nextImage}/>
      </Box>}
    </Box>
  );
};


