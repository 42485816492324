import Box from '@mui/material/Box';
import React, {useEffect, useState} from 'react';
import {Modal} from '@mui/material';
import {getDevicePhoto} from '../../api';
import CustomImgDeviceCarousel from '../CustomImgDeviceCarousel/CustomImgDeviceCarousel';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '60%',
  transform: 'translate(-50%, -50%)',
};


export default function DevicePhoto({clientId, serialNo}) {
  const [imgUrls, setImgUrls] = useState([]);
  const [openModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    getDevicePhoto(clientId, serialNo).then(result => setImgUrls(result));
  }, [clientId, serialNo]);
  const handleOpen = () => setIsOpenModal(true);
  const handleClose = () => setIsOpenModal(false);

  return imgUrls.length === 0 ? null : [<Box key={'first'} position={'absolute'} right={20} top={105}
               onClick={handleOpen}
               sx={{
                 width: 100,
                 maxHeight: 100,
                 cursor: 'pointer',
                 overflow: 'hidden',
                 transition: '0.6s ease-in-out',
                 '&:hover ': {
                   overflow: 'visible',
                   cursor: 'zoom-in',
                   width: "90%",
                   zIndex: 1,
                 }
               }}>
    <img alt={'device Photo'}
         style={{
           height: '100%',
           width: '100%',
           borderRadius: 5,
           border: clientId === 'ugw' ? 'none' : ' 1px solid whitesmoke',
         }}
         src={imgUrls[0]}
    />
  </Box>, <Modal
    key='second'
    open={openModal}
    onClose={handleClose}>
    <Box sx={modalStyle}>
      <CustomImgDeviceCarousel images={imgUrls} />
    </Box>
  </Modal>];
}
