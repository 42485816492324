import React, {useState} from 'react';
import Highcharts from 'highcharts/highstock';
import {
  AreaSeries,
  Chart,
  ColumnSeries,
  HighchartsProvider,
  HighchartsStockChart,
  Legend,
  LineSeries,
  Title,
  Tooltip,
  XAxis,
  YAxis,
} from 'react-jsx-highstock';

import applyExporting from 'highcharts/modules/exporting';
import applyOffline from 'highcharts/modules/offline-exporting';

import {getChartMeasurements} from '../../api';
import Box from '@mui/material/Box';
import AirDateRangePicker from './AirDateRangePicker';
import QuickAirDateButtons from './QuickAirDateButtons';
import {
  dangerColor,
  MOBILE_SCREEN,
  primaryColor,
  successColor,
  warningColor
} from '../../assets/jss/nextjs-material-dashboard';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {useMediaQuery} from '@mui/material';
import DeviceImage from './DeviceImage';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import DiagBottomWidget from './DiagBottomWidget';

applyExporting(Highcharts);
applyOffline(Highcharts);

const NUMBER_OF_BUCKETS = 70;

const plotOptions = {
  column: {
    pointWidth: 6,
    maxPointWidth: 50,
    minPointLength: 3, // By default, 0 values are not shown. To visualize a 0 (or close to zero) point, set the minimal point length to a pixel value like 3
    borderWidth: 0
  },
  series: {
    shadow: true,
  }
};

const timeOptions = {
  useUTC: false
};


const SOLAR_DATA_CLIENT_ID = 'sol';

export default function DiagnosticChart({device}) {
  const {t} = useTranslation();
  const {clientId} = useConfig().state.config;
  const {name: title, serialNo, diagMeasurements} = device;
  console.log({diagMeasurements});

  if (!diagMeasurements || diagMeasurements.length === 0) return null;

  const afterSetExtremes = (e) => {
    const {chart} = e.target;
    const nowMillis = new Date().getTime();

    const min = e.userMin || (nowMillis - 1000 * 60 * 60 * 24 * 7);
    const max = e.userMax || nowMillis;

    var rangeInHours = null;

    const dateFromHours = rangeInHours ? rangeInHours : Math.max(1, Math.round((nowMillis - min) / (1000 * 60 * 60)));
    const dateToHours = rangeInHours ? 0 : Math.max(0, Math.round((nowMillis - max) / (1000 * 60 * 60)));
    const dateTimeFrom = '-' + dateFromHours + 'h';
    const dateTimeTo = '-' + dateToHours + 'h';
    chart.showLoading(t('general.loading'));

    getChartMeasurements(SOLAR_DATA_CLIENT_ID, serialNo, diagMeasurements, dateTimeFrom, dateTimeTo, NUMBER_OF_BUCKETS)
      .then(data => {
        console.log({data});
        if (data[serialNo]) {
          diagMeasurements.forEach((m, index) => {
            if(chart.series[index]) chart.series[index].setData(data[serialNo][m]);
          });
        }
        chart.hideLoading();
      }).catch(error => console.error(error.message));
  };
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);
  const [xAxisEvents, setXAxisEvents] = useState({afterSetExtremes: afterSetExtremes});

  return (<Box position={'relative'}>
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsStockChart plotOptions={plotOptions} time={timeOptions}>
          <Chart zooming={{type: 'x'}} panning={{enabled: true}} panKey={'shift'} />
          <Legend align={'left'} verticalAlign={'top'} x={40} y={30} useHTML={true}/>
          <Title style={{color: primaryColor[2]}} align={'center'}>{title}</Title>

          <Tooltip split valueDecimals={2}/>
          <XAxis
            events={xAxisEvents}
            range={15 * 24 * 60 * 60 * 1000}
          />

          {/* relativeCharge LINE CHART */}
          {diagMeasurements.includes('batRemainCapacity') &&
          <YAxis
            // min={0}
            // max={100}
            opposite={false}
            useHTML={true}>
            <YAxis.Title>[%]</YAxis.Title>
            {/*<YAxis.Title>Poziom naładowania [%]</YAxis.Title>*/}

            <LineSeries
              id="batRemainCapacity"
              name={'Poziom naładowania Bat.'}
              color={successColor[0]}
              tooltip={{valueSuffix: ' [%]'}}
              zones={[{value: 30, color: 'red'}, {color: 'green'}]}
            />
          </YAxis>}

          {/* Battery TEMP LINE CHART */}
          {diagMeasurements.includes('tempEnv') &&
          <YAxis
            // min={0}
            // max={50}
            opposite={true}
            useHTML={true}>
            <YAxis.Title>Temp [°C]</YAxis.Title>

            <LineSeries
              id="tempEnv"
              name={'Temp Aku'}
              color={dangerColor[2]}
              tooltip={{valueSuffix: ' [°C]'}}

              // zones={[{value: 15, color: 'blue'}, {color: 'red'}]}
            />
            <LineSeries
              id="tempInternal"
              name={'Temp Wewn.'}
              color={primaryColor[2]}
              tooltip={{valueSuffix: ' [°C]'}}
              // zones={[{value: 15, color: 'blue'}, {color: 'red'}]}
            />
          </YAxis>}

          {/*/!* Battery TEMP LINE CHART *!/*/}
          {/*{diagMeasurements.includes('tempInternal') &&*/}
          {/*  <YAxis*/}
          {/*    // min={0}*/}
          {/*    // max={50}*/}
          {/*    opposite={true}*/}
          {/*    useHTML={true}>*/}
          {/*    <YAxis.Title>Temp [°C]</YAxis.Title>*/}

          {/*    */}
          {/*  </YAxis>}*/}

          {/* BAT Voltage */}
          {diagMeasurements.includes('batVoltage') &&
          <YAxis visible={false}>
            <LineSeries
              visible={false}
              id="batVoltage"
              name={'Aku Napięcie'}
              // color={'#8F00FF'}
              tooltip={{valueSuffix: ' [V]'}}
            />
          </YAxis>}

          {/* BAT CURRENT */}
          {diagMeasurements.includes('batCurrent') &&
          <YAxis  visible={false}>
            <LineSeries
              visible={false}
              id="batCurrent"
              name={'Aku Natężenie'}
              // color={'#8F00FF'}
              tooltip={{valueSuffix: ' [mA]'}}
            />
          </YAxis>}

          {/* BAT POWER */}
          {diagMeasurements.includes('batPower') &&
            <YAxis  visible={false}>
              <LineSeries
                visible={false}
                id="chargingCurrent"
                name={'Aku Moc dostarczana'}
                // color={'#8F00FF'}
                tooltip={{valueSuffix: ' [W]'}}
              />
            </YAxis>}

          {/* LOAD Voltage */}
          {diagMeasurements.includes('loadVoltage') &&
            <YAxis visible={false}>
              <LineSeries
                visible={false}
                id="loadVoltage"
                name={'Box Napięcie'}
                // color={'#8F00FF'}
                tooltip={{valueSuffix: ' [V]'}}
              />
            </YAxis>}

          {/* LOAD CURRENT */}
          {diagMeasurements.includes('loadCurrent') &&
            <YAxis visible={false}>
              <LineSeries
                visible={false}
                id="loadCurrent"
                name={'Box Natężenie'}
                // color={'#8F00FF'}
                tooltip={{valueSuffix: ' [mA]'}}
              />
            </YAxis>}

          {/* LOAD POWER */}
          {diagMeasurements.includes('loadPower') &&
            <YAxis  visible={false}>
              <LineSeries
                visible={false}
                id="loadPower"
                name={'Box Moc Pobierana'}
                // color={'#8F00FF'}
                tooltip={{valueSuffix: ' [W]'}}
              />
            </YAxis>}


          {/* SOLAR Voltage */}
          {diagMeasurements.includes('solarVoltage') &&
            <YAxis  visible={false}>
              <LineSeries
                visible={false}
                id="solarVoltage"
                name={'Solar Napięcie'}
                // color={'#8F00FF'}
                tooltip={{valueSuffix: ' [V]'}}
              />
            </YAxis>}

          {/* SOLAR CURRENT */}
          {diagMeasurements.includes('solarCurrent') &&
            <YAxis  visible={false}>
              <LineSeries
                visible={false}
                id="solarCurrent"
                name={'Solar Natężenie'}
                // color={'#8F00FF'}
                tooltip={{valueSuffix: ' [mA]'}}
              />
            </YAxis>}

          {/* SOLAR POWER */}
          {diagMeasurements.includes('solarPower') &&
            <YAxis  visible={false}>
              <LineSeries
                visible={false}
                id="solarPower"
                name={'Solar Moc Generowana'}
                // color={'#8F00FF'}
                tooltip={{valueSuffix: ' [W]'}}
              />
            </YAxis>}

          {/* Energy generated per day */}
          {diagMeasurements.includes('eGeneratedDay') &&
            <YAxis >
              <LineSeries
                // visible={false}
                id="eGeneratedDay"
                name={'E. wygenerowana dziennie'}
                color={'lightgreen'}
                tooltip={{valueSuffix: ' [Wh]'}}
              />
            </YAxis>}

          {/* Energy generated per month */}
          {diagMeasurements.includes('eGeneratedMonth') &&
            <YAxis visible={false}>
              <YAxis.Title>Dzienna energia - Wh</YAxis.Title>
              <LineSeries
                visible={false}
                id="eGeneratedMonth"
                name={'E. wygenerowana miesiąc'}
                color={successColor[0]}
                tooltip={{valueSuffix: ' [Wh]'}}
              />
            </YAxis>}

          {/* Energy generated TOTAL */}
          {diagMeasurements.includes('eGeneratedTotal') &&
            <YAxis  visible={false}>
              <LineSeries
                // visible={false}
                id="eGeneratedTotal"
                name={'E. wygenerowana całkowita'}
                color={'green'}
                tooltip={{valueSuffix: ' [Wh]'}}
              />
            </YAxis>}

          {/* Energy consumed per day */}
          {diagMeasurements.includes('eConsumedDay') &&
            <YAxis  visible={false}>
              <LineSeries
                // visible={false}
                id="eConsumedDay"
                name={'E. zużyta dziennie'}
                color={warningColor[0]}
                tooltip={{valueSuffix: ' [Wh]'}}
              />
            </YAxis>}

          {/* Energy consumed per month */}
          {diagMeasurements.includes('eConsumedMonth') &&
            <YAxis  visible={false}>
              <LineSeries
                visible={false}
                id="eConsumedMonth"
                name={'E. zużyta miesiąc'}
                color={'orange'}
                tooltip={{valueSuffix: ' [Wh]'}}
              />
            </YAxis>}



          {/* Energy consumed per day */}
          {diagMeasurements.includes('eConsumedTotal') &&
            <YAxis  opposite={false}>
              <YAxis.Title>Całkowita energia - Wh</YAxis.Title>
              <LineSeries
                // visible={false}
                id="eConsumedTotal"
                name={'E. zużyta całkowita'}
                color={'brown'}
                tooltip={{valueSuffix: ' [Wh]'}}
              />
            </YAxis>}

          {/* LIGHT TIME PER DAY */}
          {diagMeasurements.includes('lightTimeDay') &&
            <YAxis  visible={false}>
              <LineSeries
                // visible={false}
                id="lightTimeDay"
                name={'Ilość słońca'}
                // color={'#8F00FF'}
                tooltip={{valueSuffix: ' [h]'}}
              />
            </YAxis>}

          <QuickAirDateButtons days={7} width={!isMobile ? 200 : '100%'}/>
          <AirDateRangePicker/>
          <DeviceImage clientId={clientId} serialNo={device.serialNo} />
        </HighchartsStockChart>
      </HighchartsProvider>
    </Box>
  );
}
