import React, {useState} from 'react';
import Highcharts from 'highcharts/highstock';
import {
  Chart,
  HighchartsProvider,
  HighchartsStockChart,
  Legend,
  LineSeries,
  SplineSeries,
  Title,
  Tooltip,
  XAxis,
  YAxis,
  Navigator, ColumnSeries
} from 'react-jsx-highstock';

import applyExporting from 'highcharts/modules/exporting';
import applyOffline from 'highcharts/modules/offline-exporting';

import {getChartMeasurements, getMockedWaterChartMeasurements} from '../../api';
import Box from '@mui/material/Box';
import AirDateRangePicker from './AirDateRangePicker';
import QuickAirDateButtons from './QuickAirDateButtons';
import {
  dangerColor,
  infoColor,
  MOBILE_SCREEN,
  primaryColor,
  successColor
} from '../../assets/jss/nextjs-material-dashboard';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {useMediaQuery} from '@mui/material';
import DeviceImage from './DeviceImage';
import {useTranslation} from 'react-i18next';


applyExporting(Highcharts);
applyOffline(Highcharts);

const NUMBER_OF_BUCKETS = 70;

const plotOptions = {
  column: {
    pointWidth: 6,
    maxPointWidth: 50,
    minPointLength: 3, // By default, 0 values are not shown. To visualize a 0 (or close to zero) point, set the minimal point length to a pixel value like 3
    borderWidth: 0
  },
  series: {
    shadow: true,
    // dataGrouping: {
    //   approximation: 'average',
    //   groupAll: true,
    //   groupPixelWidth: 20
    // }
  }
};

const timeOptions = {
  useUTC: false
};

const options = {};

// Conductivity of Water - TYPICAL RANGES
const drinkWater = [{
  zIndex: 99,
  value: 50,
  color: 'lightblue',
  dashStyle: 'shortdash',
  width: 2
}];


const AGRO_MEASUREMENTS = 'soilSurfTemp,soilTemp,leafTemp,soilHumidity,leafWetnessYMSD';
const BLE_AGRO_MEASUREMENTS = "soilK,soilN,soilP,soilPH,soilTemp";

// MUST READ -> https://www.fao.org/3/ac183e/AC183E00.htm#TOC
export default function AgroChart({device}) {
  const {t} = useTranslation();
  const {clientId} = useConfig().state.config;
  const title = device.name;

  const afterSetExtremes = (e) => {
    const {serialNo} = device;

    const {chart} = e.target;
    const nowMillis = new Date().getTime();

    const min = e.userMin || (nowMillis - 1000 * 60 * 60 * 24);
    const max = e.userMax || nowMillis;

    var rangeInHours = null;

    const dateFromHours = rangeInHours ? rangeInHours : Math.max(1, Math.round((nowMillis - min) / (1000 * 60 * 60)));
    const dateToHours = rangeInHours ? 0 : Math.max(0, Math.round((nowMillis - max) / (1000 * 60 * 60)));
    const dateTimeFrom = '-' + dateFromHours + 'h';
    const dateTimeTo = '-' + dateToHours + 'h';
    chart.showLoading(t('general.loading'));
    getChartMeasurements(clientId, serialNo, ["3408", "3409", "3410"].includes(serialNo) ? BLE_AGRO_MEASUREMENTS : AGRO_MEASUREMENTS, dateTimeFrom, dateTimeTo, NUMBER_OF_BUCKETS)
      .then(data => {
        if (data[serialNo]) {
          let i = 0;

          if (device.measurements.includes('soilK')) {
            chart.series[i].setData(data[serialNo].soilK);
            i++;
          }
          if (device.measurements.includes('soilN')) {
            chart.series[i].setData(data[serialNo].soilN);
            i++;
          }
          if (device.measurements.includes('soilP')) {
            chart.series[i].setData(data[serialNo].soilP);
            i++;
          }
          if (device.measurements.includes('soilPh')) {
            chart.series[i].setData(data[serialNo].soilPh);
            i++;
          }

          if (device.measurements.includes('soilSurfTemp')) {
            chart.series[i].setData(data[serialNo].soilSurfTemp);
            i++;
          }
          if (device.measurements.includes('soilTemp')) {
            chart.series[i].setData(data[serialNo].soilTemp);
            i++;
          }
          if (device.measurements.includes('leafTemp')) {
            chart.series[i].setData(data[serialNo].leafTemp);
            i++;
          }
          if (device.measurements.includes('soilHumidity')) {
            chart.series[i].setData(data[serialNo].soilHumidity);
            i++;
          }
          if (device.measurements.includes('leafWetnessYMSD')) {
            chart.series[i].setData(data[serialNo].leafWetnessYMSD);
            i++;
          }
        }
        chart.hideLoading();
      }).catch(error => console.error(error.message));
  };

  const [xAxisEvents, setXAxisEvents] = useState({afterSetExtremes: afterSetExtremes});
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);

  return (<Box position={'relative'}>
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsStockChart options={options} plotOptions={plotOptions} time={timeOptions}>
          <Chart zooming={{type: 'x'}} panning={{enabled: true}} panKey={'shift'} />
          <Legend align={'left'} verticalAlign={'top'} x={40} y={30} useHTML={true}/>
          <Title style={{color: primaryColor[2]}} align={'center'}>{title}</Title>

          <Tooltip split valueDecimals={2}/>
          <XAxis
            events={xAxisEvents}
            range={15 * 24 * 60 * 60 * 1000}
          />
          {/* soilSurfTemp, soilTemp, soilHumidity, leafTemp, leafWetnessYMSD */}
          {/* TEMP LINE CHART */}
          <YAxis
            min={0}
            max={40}
            opposite={false}
            // plotLines={plotHorizontalNoiseLines}
            useHTML={true}>
            <YAxis.Title>Temp °C</YAxis.Title>

            {device.measurements.includes('soilK') && <LineSeries
              id="soilK"
              name={t('measurements.parameters.soilK')}
              // color={dangerColor[2]}
              tooltip={{valueSuffix: ' '}}
            />}
            {device.measurements.includes('soilN') && <LineSeries
              id="soilK"
              name={t('measurements.parameters.soilN')}
              // color={dangerColor[2]}
              tooltip={{valueSuffix: ' '}}
            />}
            {device.measurements.includes('soilP') && <LineSeries
              id="soilK"
              name={t('measurements.parameters.soilP')}
              // color={dangerColor[2]}
              tooltip={{valueSuffix: ' '}}
            />}
            {device.measurements.includes('soilPh') && <LineSeries
              id="soilK"
              name={t('measurements.parameters.soilPh')}
              // color={dangerColor[2]}
              tooltip={{valueSuffix: ' '}}
              zones={WATER_PH_LEVELS}
            />}

            {device.measurements.includes('soilSurfTemp') && <LineSeries
              id="soilSurfTemp"
              name={t('measurements.parameters.soilSurfTemp')}
              color={dangerColor[2]}
              tooltip={{valueSuffix: ' [°C]'}}
              // zones={[{value: 15, color: 'blue'}, {color: 'red'}]}
              zones={TEMP_LEVELS}
            />}

            {device.measurements.includes('soilTemp') && <SplineSeries
              visible={true}
              id="soilTemp"
              name={t('measurements.parameters.soilTemp')}
              tooltip={{valueSuffix: ' [°C]'}}
              color={'green'}/>}
            {device.measurements.includes('leafTemp') && <LineSeries
              visible={false}
              id="leafTemp"
              name={t('measurements.parameters.leafTemp')}
              color={primaryColor[2]}
              dashStyle={'LongDashDot'}
              tooltip={{valueSuffix: ' [°C]'}}
            />}

            {/*<LineSeries color='green' id="AeqD" name="<b>L<sub>AeqD</sub><b>" dashStyle="shortdash" />*/}
            {/*<LineSeries color='red' id="AeqN" name="<p>L<sub>AeqN</sub></p>" dashStyle="shortdash" />*/}
          </YAxis>

          {/* soilTemp LINE CHART          */}
          {/*{device.measurements.includes('soilTemp') && <YAxis*/}
          {/*  labels={{enabled: false}}*/}
          {/*  min={0}*/}
          {/*  max={40}*/}
          {/*  opposite={false}*/}
          {/*  useHTML={true}>*/}
          {/*  /!*<YAxis.Title>pH </YAxis.Title>*!/*/}
          {/*  */}
          {/*</YAxis>}*/}

          {/* soilHumidity - LINE CHART*/}
          <YAxis
            min={0}
            max={100}
            opposite={true}
            // labels={{enabled: false}}
            // plotLines={drinkWater}
            useHTML={true}>
            <YAxis.Title>Wilgotność %</YAxis.Title>
            {device.measurements.includes('soilHumidity') && <SplineSeries
              visible={true}
              id="soilHumidity"
              name={t('measurements.parameters.soilHumidity')}
              color={infoColor[1]}
              dashStyle={'ShortDot'}
              tooltip={{
                valueSuffix: ' [%]',
                valueDecimals: 0
              }}
            />}

            {device.measurements.includes('leafWetnessYMSD') && <LineSeries
              visible={true}
              id="leafWetnessYMSD"
              name={t('measurements.parameters.leafWetnessYMSD')}
              color={successColor[2]}
              opacity={0.5}
              dashStyle={'Dot'}
              tooltip={{
                valueSuffix: ' [%]',
                valueDecimals: 0
              }}
            />}

          </YAxis>

          {/* leafTemp */}
          {/*{device.measurements.includes('leafTemp') && <YAxis useHTML={true} labels={{enabled: false}}>*/}
          {/*  */}
          {/*</YAxis>}*/}

          {/* leafWetnessYMSD */}
          {/*{device.measurements.includes('leafWetnessYMSD') && <YAxis opposite={true} useHTML={true} labels={{enabled: false}}>*/}
          {/*  */}
          {/*</YAxis>}*/}

          <QuickAirDateButtons width={!isMobile ? 200 : '100%'}/>
          <AirDateRangePicker/>

          <DeviceImage clientId={clientId} serialNo={device.serialNo}/>

          {/*<Navigator adaptToUpdatedData={false}>*/}
          {/*  <Navigator.Series seriesId="leafTemp"/>*/}
          {/*  <Navigator.Series seriesId="leafWetnessYMSD"/>*/}
          {/*</Navigator>*/}

        </HighchartsStockChart>
      </HighchartsProvider>
    </Box>
  );
}

const TEMP_LEVELS = [
  {color: 'blue'},
  {
    value: 1,
    color: '#422d7f'
  },
  {
    value: 2,
    color: '#5e4798'
  },
  {
    value: 3,
    color: '#59519d'
  },
  {
    value: 4,
    color: '#3e539f'
  },
  {
    value: 5,
    color: '#598fc8'
  },
  {
    value: 6,
    color: '#52b1b3'
  },
  {
    value: 7,
    color: '#57b272'
  },
  {
    value: 8,
    color: '#57a656'
  },

  {
    value: 9,
    color: '#6bb558'
  },
  {
    value: 10,
    color: '#6bb558'
  },
  {
    value: 11,
    color: '#6bb558'
  },
  {
    value: 12,
    color: '#6bb558'
  },

  {
    value: 13,
    color: '#92c055'
  },
  {
    value: 14,
    color: '#92c055'
  },
  {
    value: 15,
    color: '#bbd252'
  }, {
    value: 16,
    color: '#bbd252'
  }, {
    value: 17,
    color: '#bbd252'
  },
  {
    value: 18,
    color: '#f4ee54'
  },
  {
    value: 19,
    color: '#f4ee54'
  },
  {
    value: 20,
    color: '#f4ee54'
  },

  {
    value: 21,
    color: '#f0c846'
  }, {
    value: 22,
    color: '#f0c846'
  }, {
    value: 23,
    color: '#f0c846'
  },
  {
    value: 24,
    color: '#da3b33'
  },
  {
    value: 25,
    color: '#da3b33'
  },
  {color: 'red'}
];

const WATER_PH_LEVELS = [
  {
    value: 1,
    color: '#da3b33'
  }, {
    value: 2,
    color: '#f0c846'
  }, {
    value: 3,
    color: '#f4ee54'
  }, {
    value: 4,
    color: '#bbd252'
  }, {
    value: 5,
    color: '#92c055'
  }, {
    value: 6,
    color: '#6bb558'
  }, {
    value: 7,
    color: '#57a656'
  }, {
    value: 8,
    color: '#57b272'
  }, {
    value: 9,
    color: '#52b1b3'
  }, {
    value: 10,
    color: '#598fc8'
  }, {
    value: 11,
    color: '#3e539f'
  }, {
    value: 12,
    color: '#59519d'
  }, {
    value: 13,
    color: '#5e4798'
  }, {
    value: 14,
    color: '#422d7f'
  }
];
