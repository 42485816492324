import {Box, Chip, TextField} from '@mui/material';
import {useNotificationsContext} from '../../context/notifications/notificationsContext';
import React, {useRef} from 'react';
import {validateEmail} from '../../utils';
import {useTranslation} from 'react-i18next';

export default function ContactEmailInput() {
  const {t} = useTranslation();
  const {state: {currentConfig}, dispatch} = useNotificationsContext();
  const emails = currentConfig.emailRecipients;

  const tagRef = useRef();

  const handleDelete = (email) => {
    dispatch({type: 'update-currentConfig', name: 'emailRecipients', value: emails.filter((val) => val !== email)});
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch({type: 'update-currentConfig', name: 'emailRecipients', value: [...emails, tagRef.current.value]});
    tagRef.current.value = '';
  };

  return <TextField
      inputRef={tagRef}
      variant="standard"
      size="small"
      sx={{margin: '1rem 0'}}
      margin="none"
      placeholder={emails.length < 5 ? t('risk.addEmail') : ''}
      inputProps={{
        style: {
          flex: 1,
          minWidth: 300,
          alignSelf: 'flex-end'
        }
      }}
      onKeyDown={event => event.key === 'Enter' && handleOnSubmit(event)}
      InputProps={{
        endAdornment: (
          <Box flex={3} marginBottom={1} width={'60vw'}  sx={{display: 'flex', flexWrap: 'wrap', gap: 2}}>
            {emails.map((email, index) => {
              return (
                <Tags email={email} key={index} onDelete={() => handleDelete(email)}/>
              );
            })}
          </Box>
        ),
      }}
    />;
}

export const Tags = ({isNew, email, onDelete}) => {
  return (
    <Chip
      sx={{paddingTop: 2, paddingBottom: 2}}
      label={email}
      color={validateEmail(email) ? (isNew ? 'success' : 'primary') : 'error'}
      onDelete={onDelete}
    />);
};
