import {Box, IconButton, Slider} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './swal_custom_style.css';
import ImageGallery from 'react-image-gallery';
import './image-gallery-custom.css';
import moment from 'moment/moment';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PauseIcon from '@mui/icons-material/Pause';
import './play-pause-fade.css';
import {Launch, PlayArrow} from '@mui/icons-material';


// const imgUrlIR = "https://s3.eu-central-1.amazonaws.com/files.coral.com/zpg/camera/ZPG_OAKD_TEST/photo/1712866536563-ir.jpg";

export default function ReplayView({images}) {
  const {t, i18n} = useTranslation();
  const imageGalleryRef = useRef(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [showIcon, setShowIcon] = useState(null);
  const [showIR, setShowIR] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIcon(false); // Ukrywa ikonę po 3 sekundach
    }, 3000); // 0.5s animacji + 2.5s opóźnienia

    return () => clearTimeout(timer);
  }, [showIcon]);

  const toggleSpeed = () => {
    setShowIcon('speed');
    setSpeed(speed === 1 ? 2 : 1);
  }
  const toggleShowIR = () => {
    setShowIR(!showIR);
  };

  const items = images.slice().reverse().map(({temp, oil, thumbUrl, imgUrl, imgUrlIR, timestamp,  type}) => ({
    timestamp,
    original: imgUrl || imgUrlIR,
    irImage: showIR ? imgUrl: imgUrlIR,
    // thumbnail: thumbUrl,
    description: <Box textAlign={'left'} >
      <Typography>{moment(timestamp).format('LLL')}</Typography>
      {oil && <Typography>Subst. ropopochodne: {oil?.toFixed(1)} ppm</Typography>}
      {temp && <Typography>Temp. wody: {temp?.toFixed(1)} °C</Typography>}
    </Box>,
  }));

  console.log({items});

  const handleSlideChange = (event, value) => {
    setCurrentImageIndex(value);
  };

  const sliderLabelFormat = index => {
    return items[index]?.timestamp ? <span>{moment(items[index]?.timestamp).format('HH:mm')} </span> : '';
  };

  return <Box sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    mt: -5,
    cursor: 'pointer'
  }}>
    <Box>
      <Box position={'relative'}>
        <ImageGallery
          ref={imageGalleryRef}
          onPause={(currentIndex) => {
            setShowIcon('pause');
            setIsPlaying(false);
          }}
          onPlay={(currentIndex) => {
            setShowIcon('play');
            setIsPlaying(true);
          }}
          onClick={(event) => {
            isPlaying ? imageGalleryRef.current.pause() : imageGalleryRef.current.play();
          }}
          items={items}
          startIndex={currentImageIndex}
          onSlide={index => setCurrentImageIndex(index)}
          showThumbnails={false}
          showPlayButton={true}
          showFullscreenButton={false}
          useTranslate3D={false}
          showBullets={false}
          slideDuration={0} //default = 450
          slideInterval={1000 / speed} //default = 3000
          infinite={false}
        />

        {/*{items[currentImageIndex].irImage && <Box position={'absolute'} top={10} right={10}>*/}
        {/*  <IconButton size={'small'}*/}
        {/*              style={{position: 'absolute'}}*/}
        {/*              onClick={toggleShowIR}>*/}
        {/*    <Launch style={{color: 'white', transform: 'scaleX(-1)'}}/>*/}
        {/*  </IconButton>*/}
        {/*  <img src={items[currentImageIndex].irImage} alt={'IR Image'} width={300} style={{borderRadius: 10}}/>*/}
        {/*</Box>}*/}

        {showIcon && <Box className="icon" borderRadius={100} color={'white'} sx={{
          position: 'absolute',
          left: 'calc(50% - 50px)',
          bottom: 'calc(50% - 50px)',
          backgroundColor: 'rgba(0, 0, 0, .1)'
        }}>
          {showIcon === 'play' ? <PlayArrow sx={{width: 200, height: 200}}/> :
            (showIcon === 'pause' ? <PauseIcon sx={{width: 200, height: 200}}/> : <Typography fontSize={200}>x{speed}</Typography>)
          }
        </Box>}
        <Button
          sx={{position: 'absolute', bottom: 10, right: 10, backgroundColor: 'rgba(0, 0, 0, .2)'}}
          onClick={toggleSpeed}
        >
          <Typography textTransform={'lowercase'} fontWeight={'bold'} fontSize={25} variant={'body1'}
                      sx={{color: 'white'}}>
            x{speed}
          </Typography>
        </Button>
      </Box>

      <Slider
        value={currentImageIndex}
        onChange={handleSlideChange}
        min={0}
        max={items.length - 1}
        step={1}
        marks
        valueLabelDisplay="auto"
        valueLabelFormat={sliderLabelFormat}
        aria-labelledby="image-slider"
      />
    </Box>
  </Box>;
}



