import React from 'react';
import ChartistGraph from 'react-chartist';
import {useTranslation} from 'react-i18next';
import {useMeasurementsContext} from '../../context/measurements/measurementsContext';
import moment from 'moment';


var delays2 = 80,
  durations2 = 500;




const MockDataToday = (t, hours, halfDayBack) => ({
  labels: [
    `${t('general.yesterday')}, ${hours - 1}:00`,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    `${hours < 12 ? t('general.yesterday') : t('general.today')}, ${halfDayBack}:00`,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    `${t('general.today')}, ${hours}:00`,
  ],
  series: [
    [
      10, 20, 30, 40, 50, 60, 80, 70, 40, 30, 20, 50,
      10, 20, 30, 40, 50, 60, 80, 70, 55, 40, 30, 20,
    ]
  ],
});

const MockData = (selectedDate, hours, halfDayBack) => ({
  labels: [
    `${selectedDate.subtract(1, 'day').format('DD.MM.YYYY')}, ${hours - 1}:00`,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    `${hours < 12 ? selectedDate.subtract(1, 'day').format('DD.MM.YYYY') : selectedDate.format('DD.MM.YYYY')}, ${halfDayBack}:00`,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    `${selectedDate.format('DD.MM.YYYY')}, ${hours}:00`,
  ],
  series: [
    [
      10, 20, 30, 40, 50, 60, 80, 70, 40, 30, 20, 50,
      10, 20, 30, 40, 50, 60, 80, 70, 55, 40, 30, 20,
    ]
  ],
});

const options = {
  axisX: {
    showGrid: false,
  },
  // low: 0,
  // high: 100,
  chartPadding: {
    top: 20,
    right: 10,
    bottom: 0,
    left: 0,
  },
};

const responsiveOptions = [
  [
    'screen and (max-width: 640px)',
    {
      // seriesBarDistance: 5,
      // axisX: {
      //   labelInterpolationFnc: function (value) {
      //     return new Date(value[0]).toLocaleString();
      //   },
      // },
    },
  ],
];

const animation = {
  draw: function (data) {
    if (data.type === 'line') {
      data.element.animate({
        opacity: {
          begin: (data.index + 1) * delays2,
          dur: durations2,
          from: 0,
          to: 1,
          easing: 'ease',
        },
      });
    }
  },
};

export default function WaterMiniChart({data}) {
  const {t} = useTranslation();
  const {state: {selectedDate}} = useMeasurementsContext();
  const isToday = moment(selectedDate).isSame(moment(), 'day');

  const hours = new Date().getHours();
  const halfDayBack = hours < 12 ? (12 - hours) : (hours - 12);

  return <ChartistGraph
    data={{
      ...(isToday ? MockDataToday(t, hours, halfDayBack) : MockData(moment(selectedDate), hours, halfDayBack)),
      series: [data || []]
    }}
    type="Line"
    options={options}
    responsiveOptions={responsiveOptions}
    listener={animation}
  />;
}
