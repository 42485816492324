import React from 'react';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {MenuItem} from '../../components/MapSidebar/MapSideBar';
import {useRiskContext} from '../../context/risk/riskContext';

export default function RiskSideBar({dark}) {
  const {config: {modules}} = useConfig().state;
  const {t} = useTranslation();
  const {state: {riskLayers}, dispatch} = useRiskContext();

  const toggleMarkers = layerId => dispatch({type: 'toggle-riskLayer', layerId})

  return <Box p={2} sx={{
    zIndex:999,
    overflow: 'visible',
    borderRadius: 5,
    position: 'absolute',
    top: 100,
    // '@media screen and (max-width: 45em)': {display: 'none'}
  }}>

    {modules.RISK_OIL && <MenuItem
      layerId={'RISK_OIL'}
      label={t('mapSideBar.riskOil')}
      onClick={() => toggleMarkers('RISK_OIL')}
      dark={dark}
      selected={riskLayers.includes('RISK_OIL')}/>}
    {modules.RISK_SAND && <MenuItem
      layerId={'RISK_SAND'}
      label={t('mapSideBar.riskSand')}
      onClick={() => toggleMarkers('RISK_SAND')}
      dark={dark}
      selected={riskLayers.includes('RISK_SAND')}/>}
    {modules.RISK_SAND && <MenuItem
      layerId={'RISK_SHORE'}
      label={t('mapSideBar.riskShore')}
      onClick={() => toggleMarkers('RISK_SHORE')}
      dark={dark}
      selected={riskLayers.includes('RISK_SHORE')}/>}
    {/*{modules.RISK_SAND && <MenuItem*/}
    {/*  layerId={'RISK_SOUND'}*/}
    {/*  label={"Hałas"}*/}
    {/*  onClick={() => toggleMarkers('RISK_SHORE')}*/}
    {/*  dark={dark}*/}
    {/*  selected={riskLayers.includes('RISK_SHORE')}/>}*/}
  </Box>;
}
