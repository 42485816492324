import Box from '@mui/material/Box';
import React, {useEffect, useState} from 'react';
import {CardHeader, Checkbox, Chip, Divider, InputLabel, ListItemIcon, ListItemText, Select} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import {BarChart, Refresh} from '@mui/icons-material';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import {useTranslation} from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import {waterLevelStations, waterStations} from './waterStations';
import {colorByStation, oDataColor, oDataIcons, stationIcon, stationName} from './openDataUtils';


const MEASUREMENTS = ['rain', 'windlevel', 'winddir', 'temp', 'humidity', 'pressure', 'water'];
const INTERVALS = ['1h', '1d', '1M'];

export default function CreateReportSection({children, actionHandler}) {
  const {t} = useTranslation();
  const intervals = INTERVALS;
  const {config: {clientId}, email} = useConfig().state;

  const [measurements, setMeasurements] = useState(['rain']);
  const [stations, setStations] = useState([]);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [interval, setInterval] = useState('1h');

  const onActionButtonClick = () => actionHandler(stations, measurements, dateFrom, dateTo, interval);

  //  set Nowy Port as default
  useEffect(() => clientId === 'rem' && setStations([waterStations.rem[0]]), [clientId]);

  const setDateRange = (from, to) => {
    setDateFrom(from);
    setDateTo(to);
  };

  const deviceItems = [...(waterStations[clientId] || []), ...waterLevelStations[clientId]];

  const resetAll = () => {
    setDateFrom(new Date());
    setDateTo(new Date());
    setStations([waterStations.rem[0]]);
    setMeasurements(['rain']);
  };


  const handleDeleteParam = (paramToDelete) => {
    setMeasurements(measurements.filter(m => m !== paramToDelete));
  };

  const handleDeleteStations = (toDelete) => {
    const newStations = stations.filter(({serialNo}) => serialNo !== toDelete);
    setStations(newStations);
  };

  const handleChangeStations = e => {
    const serialNoList = e.target.value;
    const newStations = deviceItems.filter(({serialNo}) => serialNoList.includes(serialNo));
    setStations(newStations);
  };

  const handleChangeMeasurements = e => {
    const mList = e.target.value;
    const newMeasurements = MEASUREMENTS.filter(m => mList.includes(m));
    setMeasurements(newMeasurements);
  };


  return <Card
    variant={'outlined'}
    sx={{minHeight: '100%'}}
  >
    <CardHeader
      action={
        <Button sx={{float: 'right'}} startIcon={<Refresh/>}
                onClick={resetAll} color={'info'}>{t('general.reset')}</Button>
      }
      title={<Button size={'large'}
                     startIcon={<BarChart/>}
                     color={'info'}>{t('openData.reportsTitle')}</Button>}
    />
    <CardContent>
      {/* SEARCH FORM */}
      <Box display={'flex'} gap={4} flexWrap={'wrap'}>

        {/* MEASUREMENTS */}
        <FormControl>
          <InputLabel sx={{marginTop: -1,}}
                      id="measurement-select-label">{t('openData.chooseMeasurement')}</InputLabel>
          <Select
            labelId={'measurement-select-label'}
            multiple
            sx={{minWidth: 200}}
            size={'small'}
            variant="outlined"
            label={t('openData.chooseMeasurement')}
            value={measurements}
            renderValue={(selected) => selected.length > 1 ? t('measurements.selected') + ': ' + selected.length : t('openData.MEASUREMENTS.' + selected[0])}
            onChange={handleChangeMeasurements}
          >
            {MEASUREMENTS.map(m =>
              <MenuItem key={m} value={m}>
                <Checkbox checked={measurements.indexOf(m) > -1}/>
                <ListItemText primary={t('openData.MEASUREMENTS.' + m)}/>
                <ListItemIcon>
                  {/* TODO w zależności od tego czy rain czy full meteo itp. */}
                  {/*<BubbleChartOutlined sx={{background: 'transparent'}} color={'primary'}/>*/}
                  {oDataIcons[m]}
                </ListItemIcon>
              </MenuItem>)}
          </Select>
        </FormControl>
        {/* STATIONS */}
        <FormControl>
          <InputLabel sx={{marginTop: -1,}}
                      id="station-select-label">{t('openData.chooseStation')}</InputLabel>
          <Select
            labelId={'station-select-label'}
            multiple
            sx={{minWidth: 250}}
            size={'small'}
            variant="outlined"
            label={t('openData.chooseStation')}
            value={stations.map(({serialNo}) => serialNo)}
            renderValue={(selected) => selected.length > 1 ? t('measurements.selected') + ': ' + selected.length : stations.filter(s => s.serialNo === selected[0])[0]?.name}
            onChange={handleChangeStations}
          >
            {deviceItems.map(({serialNo, name}) =>
              <MenuItem key={serialNo} value={serialNo}>
                <Checkbox checked={stations.map(({serialNo}) => serialNo).indexOf(serialNo) > -1}/>
                <ListItemText primary={stationName(name, serialNo, t)}/>
                <ListItemIcon>
                  {stationIcon(serialNo)}
                </ListItemIcon>
              </MenuItem>)}
          </Select>
        </FormControl>

        {/* DateRangePicker */}
        <Box sx={{width: 300}}>
          <DateRangePicker
            inputStyle={'mui'}
            dateFrom={dateFrom}
            dateTo={dateTo}
            buttonColor={'white'}
            onChange={setDateRange}/>
        </Box>

        {/* INTERVAL */}
        <FormControl>
          <InputLabel id="interval-select-label">{t('reports.interval')}</InputLabel>
          <Select
            sx={{maxWidth: 100}}
            labelId={'interval-select-label'}
            size={'small'}
            value={interval}
            label={t('reports.interval')}
            onChange={(e) => setInterval(e.target.value)}
          >
            {intervals.map(param => <MenuItem key={param} value={param}>{param.toUpperCase()}</MenuItem>)}
          </Select>
        </FormControl>

        {/* ACTION BUTTON */}
        <Box>
          <Button
            sx={{width: 170}}
            onClick={onActionButtonClick}
            disabled={measurements.length === 0 || stations.length === 0}
            color={'success'}
            variant={'contained'}>
            {t('reports.generateReport')}
          </Button>
        </Box>
      </Box>
      {/* DEVICES CHIPS */}
      <Box mt={2} flexWrap={'wrap'} display={'flex'} gap={1}>
        {stations.map(({name, serialNo}) =>
          <Chip
            sx={{paddingTop: 2, paddingBottom: 2}}
            variant={'outlined'}
            key={serialNo}
            label={stationName(name, serialNo, t)}
            color={colorByStation(serialNo)}
            onDelete={() => handleDeleteStations(serialNo)}
            // avatar={
            //   <Avatar sx={{background: 'white'}}><Tornado /></Avatar>
            // }
          />)}
      </Box>
      <Divider sx={{paddingTop: 2, height: 1}}/>

      {/* MEASUREMENTS CHIPS */}
      {measurements.length > 0 && <Box mt={2} flexWrap={'wrap'} display={'flex'} gap={1}>
        {measurements.map(m =>
          <Chip
            sx={{paddingTop: 2, paddingBottom: 2}}
            variant={'outlined'}
            key={m}
            label={t('openData.MEASUREMENTS.' + m)}
            color={oDataColor[m] || 'default'}
            onDelete={() => handleDeleteParam(m)}
            avatar={<Avatar sx={{background: 'white'}}>{oDataIcons[m]}</Avatar>}/>)}
      </Box>}

      {/* Content: Charts or Reports table */}
      {children}
    </CardContent>
  </Card>;
}
