import React, {useEffect, useState} from 'react';
import Highcharts from 'highcharts/highstock';
import {
  Chart,
  ColumnSeries,
  LineSeries,
  HighchartsProvider,
  HighchartsStockChart,
  Legend, Navigator,
  Title,
  Tooltip,
  XAxis,
  YAxis
} from 'react-jsx-highstock';

import applyExporting from 'highcharts/modules/exporting';
import applyOffline from 'highcharts/modules/offline-exporting';

import {getChartMeasurements, getEpisodesForChart} from '../../api';
import Box from '@mui/material/Box';
import AirDateRangePicker from './AirDateRangePicker';
import QuickAirDateButtons from './QuickAirDateButtons';
import {MOBILE_SCREEN, primaryColor} from '../../assets/jss/nextjs-material-dashboard';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {useMediaQuery} from '@mui/material';
import DeviceImage from './DeviceImage';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {unitsByParams} from '../../utils';


applyExporting(Highcharts);
applyOffline(Highcharts);

const NUMBER_OF_BUCKETS = 70;

const plotOptions = {
  column: {
    pointWidth: 6,
    maxPointWidth: 50,
    minPointLength: 3, // By default, 0 values are not shown. To visualize a 0 (or close to zero) point, set the minimal point length to a pixel value like 3
    borderWidth: 0
  },
  series: {
    shadow: true,
    // dataGrouping: {
    //   approximation: 'average',
    //   groupAll: true,
    //   groupPixelWidth: 20
    // }
  }
};

const plotVerticalLines = episodes => episodes.map( e => ({
  color: '#FF0000', // Red
  width: 2,
  // acrossPanes: true,
  dashStyle: 'shortdash',
  value: e.timestamp // Position, you'll have to translate this to the values on your x axis
}));



const timeOptions = {
  useUTC: false
};

const options = {};
const ONE_HOUR_MS = 3600000

export default function AirChart({device}) {
  const {clientId} = useConfig().state.config;
  const {measurements} = device;
  const {t} = useTranslation();
  const title = device.name;
  const units = '[µg/m3]';
  const min = 0;
  const max = measurements.includes('PM100') ? null : 150;
  const [episodes, setEpisodes] = useState([]);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();

  useEffect(() => {
    if (clientId && device && dateFrom && dateTo) {
      getEpisodesForChart(clientId, device.serialNo, dateFrom - ONE_HOUR_MS, dateTo + ONE_HOUR_MS)
        .then(result => setEpisodes(result));
    }
  }, [clientId, device, dateFrom, dateTo]);

  const episodesData = episodes.map(e => ({
    x: e.timestamp,
    date: moment(e.timestamp).format("LL"),
    y: e.value || e.PM10,
    // y: data[serialNo].PM25[1],
    threshold: e.threshold,
    value: e.value || e.PM10,
    episodeTitle: `${t('episodes.exceededLevel')} ${t('episodes.severity', {returnObjects: true})[e.level]}`,
    episodeLabel: e.norm === 'EU_2020' ? `<p style="text-align: center; color: navy;"><b>${t('episodes.EU_2020_norm')}</b></p></br></br>${t('episodes.measuredLevel')}: <b>${e.value} ${unitsByParams(e.substance)}</b> </br>${t('episodes.thresholdLevel')}: <b>${e.threshold} ${unitsByParams(e.substance)}</b> </br> ${t('episodes.period')}: <b>${t('episodes',{returnObjects: true})[e.period] || e.period}</b>` :
      `</br>${t('episodes.measuredLevel')}: <b>${e.value} ${unitsByParams(e.substance)}</b> </br>${t('episodes.thresholdLevel')}: <b>${e.threshold} ${unitsByParams(e.substance)}</b> </br> ${t('episodes.period')}: <b>${t('episodes',{returnObjects: true})[e.period] || e.period}</b>`
    // marker: {
    //   symbol: 'triangle',
    //   color: 'red',
    //   radius: 10
    //   // 'url(https://www.highcharts.com/samples/graphics/sun.png)'
    // },
  }))
  // console.log(episodes);

  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);

  const afterSetExtremes = (e) => {
    const {serialNo} = device;
    const {chart} = e.target;
    const nowMillis = new Date().getTime();
    const min = e.userMin || (nowMillis - 1000 * 60 * 60 * 24);
    const max = e.userMax || nowMillis;

    // Zabezpieczenie przed ponownym pobraniem danych po narysowaniu vertical Lines
    if (!!e.max && e.type === "afterSetExtremes") return;

    var rangeInHours = null;
    const dateFromHours = rangeInHours ? rangeInHours : Math.max(1, Math.round((nowMillis - min) / (1000 * 60 * 60)));
    const dateToHours = rangeInHours ? 0 : Math.max(0, Math.round((nowMillis - max) / (1000 * 60 * 60)));
    const dateTimeFrom = '-' + dateFromHours + 'h';
    const dateTimeTo = '-' + dateToHours + 'h';
    chart.showLoading(t('general.loading'));
    getChartMeasurements(clientId, serialNo, 'PM100,PM10,PM25', dateTimeFrom, dateTimeTo, NUMBER_OF_BUCKETS)
      .then(data => {
        let i = 0;
        setDateFrom(min);
        setDateTo(max);
        if (data[serialNo]) {
          if (device.measurements.includes('PM100')) {
            chart.yAxis[0].series[i].setData(data[serialNo].PM100, false);
            i++;
          }
          if (device.measurements.includes('PM10')) {
            chart.series[0].setData(data[serialNo].PM10, false);
            chart.yAxis[0].series[i].setData(data[serialNo].PM10, false);
            i++;
          }
          if (device.measurements.includes('PM25')) {
            chart.yAxis[0].series[i].setData(data[serialNo].PM25, false);
            i++;
          }
          if (!e.trigger) {
            i = 0;
            if (device.measurements.includes('PM100')) {
              chart.navigator.series[i].setData(data[serialNo].PM100, false);
              i++;
            }
            if (device.measurements.includes('PM10')) {
              chart.navigator.series[i].setData(data[serialNo].PM10, false);
              i++;
            }
            if (device.measurements.includes('PM25')) {
              chart.navigator.series[i].setData(data[serialNo].PM25, false);
              i++;
            }
          }
        }
        chart.hideLoading();
        chart.redraw();
      }).catch(error => console.error(error.message));
  };

  const [xAxisEvents, setXAxisEvents] = useState({afterSetExtremes: afterSetExtremes});

  return (<Box position={'relative'}>
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsStockChart options={options} plotOptions={plotOptions} time={timeOptions}>
          <Chart zooming={{type: 'x'}} panKey={'shift'}/>
          <Legend align={'left'} verticalAlign={'top'} x={40} y={30} useHTML={true}/>
          <Title style={{color: primaryColor[2]}} align={'center'}>{title}</Title>

          <Tooltip shared valueSuffix={' ' + units} valueDecimals={2}/>
          <XAxis
            events={xAxisEvents}
            range={15 * 24 * 60 * 60 * 1000}
            plotLines={plotVerticalLines(episodes)}
          />

          <YAxis
            min={min}
            softMax={max}
            // plotLines={plotHorizontalNoiseLines}
            useHTML={true}>
            <YAxis.Title>{(device.measurements.includes('PM100') ? 'PM100 ' : 'PM10 ')+units}</YAxis.Title>
            {device.measurements.includes('PM100') && <ColumnSeries color={primaryColor[2]} id="series0" name={'PM100'}/>}
            {device.measurements.includes('PM10') && <ColumnSeries color={'#058DC7'} id="series1" name={'PM10'} />}
            {device.measurements.includes('PM25') && <ColumnSeries color={'#e91e63'} id="series2" name={'PM25'}/>}
            <LineSeries
              id="Episodes"
              visible={false}
              color={'red'}
              lineWidth={0}
              shadow={false}
              marker={{
                enabled: true,
                symbol: 'triangle-down',
                color: 'red',
                radius: 13
              }}
              states={{
                hover: {
                  lineWidthPlus: 0
                }
              }}
              data={episodesData}
              name={t('episodes.episodesName')}
              tooltip={{
                pointFormat: '<span style="color:{point.color}"><b>▼{point.episodeTitle} </b></span> <br/> {point.episodeLabel}'
                  // 'Przekroczenie wartości {point.episode_type}: <b>{point.threshold}</b> {point.value}ug/m3<br/>'
                // pointFormatter: () => '<span style=\"color:{point.color}\">●adasdad</span> {series.name}: <b>{point.y}</b><br/>'
                }
              }
            />
          </YAxis>
          <QuickAirDateButtons width={!isMobile ? 200 : "100%"}/>
          <AirDateRangePicker/>

          <DeviceImage clientId={clientId} serialNo={device.serialNo} />
          <Navigator adaptToUpdatedData={false}>
            {device.measurements.includes('PM100') && <Navigator.Series seriesId="series0"/>}
            {device.measurements.includes('PM10') && <Navigator.Series seriesId="series1"/>}
            {device.measurements.includes('PM25') && <Navigator.Series seriesId="series2"/>}
          </Navigator>

        </HighchartsStockChart>
      </HighchartsProvider>
    </Box>
  );
}
