import Box from '@mui/material/Box';
import React, {useEffect, useState} from 'react';
import {CircularProgress, Table, TableContainer, TableHead, TableRow, Tooltip, Typography} from '@mui/material';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import Button from '@mui/material/Button';
import {Archive, Delete} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {createWaterReports, deleteWaterReport, getWaterReports} from '../../api';
import Swal from 'sweetalert2';
import moment from 'moment';
import CreateReportSection from './CreateReportSection';
import {getFilename, TCell, THeader} from '../../components/Table/tableUtills';
import {waterLevelStations, waterStations} from './waterStations';

const STATIONS = [...waterLevelStations.rem, ...waterStations.rem];
export default function OpenDataReports() {
  const {t} = useTranslation();
  const {config: {clientId}, email} = useConfig().state;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setLoading(true);
    getWaterReports(clientId)
      .then(result => setRows(result))
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [clientId]);

  const deleteReport = (clientId, createdAt, createdBy) =>
    Swal.fire({
      title: `${t('swal.areYouSureYouWantToDeleteTheReport')}?`,
      html: `${t('swal.reportCreatedAt')} ${createdAt.replace('T', ' ').slice(0,"2023-08-24T16:49:30.165Z".length - 5)} ${t('swal.by')} ${createdBy}.`,
      icon: 'question',
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: t('general.yes'),
      denyButtonText: t('general.no'),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        deleteWaterReport(clientId, createdAt)
          .then(res => {
            setRows(rows.filter(r => r.createdAt !== createdAt));
            Swal.fire(t('swal.reportDeleted'), '', 'success');
          })
          .catch(err => Swal.fire(t('swal.errorMessage'), err.response?.data, 'error'))
          .finally(() => setLoading(false));
      }
    });

  const generateReport = (stations, measurements, dateFrom, dateTo, interval) => {
    setLoading(true);
    createWaterReports(clientId, email, new Date().toISOString(), moment(dateFrom).format("YYYY-MM-DD"), moment(dateTo).format("YYYY-MM-DD"), stations.map(s => s.serialNo), measurements, interval)
      .then(result => setRows([result,...rows]))
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  };

  return <CreateReportSection
    actionHandler={generateReport}
  >
    {error && <Typography color={'error'}>{error}</Typography>}
    {/* REPORTS TABLE */}
    {loading && <CircularProgress size={100} sx={{position: 'absolute', top: '50%', left: '50%'}}/>}
    <Box mt={2}>
      <TableContainer>
        <Table aria-label="reports table">
          <TableHead>
            <TableRow sx={{backgroundColor: 'whitesmoke'}}>
              <TableCell width={20}><THeader>#</THeader></TableCell>
              <TableCell><THeader>{t('measurements.reports.creationDate')}</THeader></TableCell>
              <TableCell><THeader>{t('measurements.reports.data')}</THeader></TableCell>
              <TableCell><THeader>{t('measurements.reports.stations')}</THeader></TableCell>
              <TableCell><THeader>{t('measurements.reports.interval')}</THeader></TableCell>
              <TableCell><THeader>{t('measurements.reports.author')}</THeader></TableCell>
              <TableCell><THeader>{t('measurements.reports.period')}</THeader></TableCell>
              <TableCell width={20}><THeader>{t('measurements.reports.actions')}</THeader></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(({
              clientId,
              createdAt,
              measurements,
              stations,
              s3Url,
              interval,
              createdBy,
              dateFrom,
              dateTo
            }, idx) => (
              <TableRow
                key={idx}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell width={20} align={'center'}><TCell>{idx + 1}</TCell></TableCell>
                <TableCell><TCell>{new Date(createdAt).toLocaleString()}</TCell></TableCell>
                <TableCell
                  align={'center'}><TCell>{measurements.map(m => t('openData.MEASUREMENTS', {returnObjects: true})[m] || m).join(', ')}</TCell></TableCell>
                <TableCell align={'center'}><TCell><Tooltip placement={'top'}
                                                            title={'Zobacz lokalizację stacji w Google Maps'}><Box>{stations.map((stationNo, idx, lis) => {
                  const station = STATIONS.filter(s => s.serialNo === stationNo)
                  return station.length > 0 ?
                    <a target={'_blank'} href={`http://www.google.com/maps/place/${station[0].location.lat},${station[0].location.lng}`}
                       key={idx}>
                      {station[0].name} {idx < lis.length - 1 ? ', ' : ''}
                    </a> : stationNo;
                })}</Box></Tooltip></TCell></TableCell>

                <TableCell align={'center'}><TCell>{interval}</TCell></TableCell>
                <TableCell align={'center'}><TCell>{createdBy}</TCell></TableCell>

                <TableCell align={'center'}><TCell>
                  <Tooltip placement={'top'} title={'Pobierz Raport'}><a target={'_blank'} href={s3Url} key={idx}>
                    {' '}{getFilename(s3Url).substring(0, 21).replace('_', ' ')}{' '}
                  </a></Tooltip>
                </TCell></TableCell>

                <TableCell align="center">
                  <Box display={"flex"}>
                  <Tooltip placement={'top'} title={'Pobierz plik xlsx'}>
                    <a href={s3Url}>
                      <Button
                        color={'success'}
                      >
                        <Archive/>
                      </Button>
                    </a>
                  </Tooltip>

                  {/*  TODO */}
                  {/*<Tooltip placement={'top'} title={'Wyślij plik e-mailem - TODO, funkcja w trakcie implementacji'}>*/}
                  {/*  /!*<a href={s3Url}>*!/*/}
                  {/*    <Button*/}
                  {/*      color={'primary'}*/}
                  {/*    >*/}
                  {/*      <Share/>*/}
                  {/*    </Button>*/}
                  {/*  /!*</a>*!/*/}
                  {/*</Tooltip>*/}

                  <Tooltip placement={'bottom'} title={'Usuń raport'}>
                    <Button
                      color={'error'}
                      onClick={() => deleteReport(clientId, createdAt, createdBy)}
                    >
                      <Delete/>
                    </Button>
                  </Tooltip>
                  </Box>
              </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>

  </CreateReportSection>;
}
