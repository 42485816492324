import ReactDOM from 'react-dom';
import React from 'react';
import {StyledEngineProvider} from '@mui/material/styles';

import './i18n';
import {Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
import ClientConfigContextProvider from 'context/clientConfig/clientConfigContext';
// import MarineTrafficContextProvider from './context/marineTraffic/marineTrafficContext';
// import {PusherProvider} from '@harelpls/use-pusher';
// import DockMgmtContextProvider from './context/dockMgmt/dockMgmtContext';
// import DockEventContextProvider from './context/dockEvent/dockEventContext';
import SurveysContextProvider from './context/surveys/surveysContext';
import MeasurementsContextProvider from './context/measurements/measurementsContext';
import WindRoseContextProvider from './context/windRose/windRoseContext';
import SatelliteDataContextProvider from './context/sateliteData/satelliteDataContext';
import AnalyticsContextProvider from './context/analytics/analyticsContext';
import DroneSurveysContextProvider from './context/droneSurveys/droneSurveysContext';
import PlaybackContextProvider from './context/marineTrafficPlayback/playbackContext';
import ILayersContextProvider from './context/iLayers/iLayersContext';
import App from './App';
import MapContextProvider from './context/map/mapContext';
import RiskContextProvider from './context/risk/riskContext';
import NotificationsContextProvider from './context/notifications/notificationsContext';
import CameraContextProvider from './context/camera/cameraContext';
import MaintenanceContextProvider from './context/maintenance/maintenanceContext';

Amplify.configure(awsconfig);
// const pusherConfig = {
//   clientKey: 'd46ed9e833a78cde8b57',
//   cluster: 'eu'
// };

const AppIndex = () =>
  <StyledEngineProvider injectFirst>
    <ClientConfigContextProvider>
      {/*<MarineTrafficContextProvider> NOT USED NOW */}
      <MapContextProvider>
        <RiskContextProvider>
          <PlaybackContextProvider>
            {/*<DockMgmtContextProvider>*/}
            {/*  <DockEventContextProvider>*/}
            {/*<PusherProvider {...pusherConfig}>  NOT USED */}
            <NotificationsContextProvider>
              <MaintenanceContextProvider>
                <SurveysContextProvider>
                  <SatelliteDataContextProvider>
                    <MeasurementsContextProvider>
                      <WindRoseContextProvider>
                        <AnalyticsContextProvider>
                          <DroneSurveysContextProvider>
                            <ILayersContextProvider>
                              <CameraContextProvider>
                                <App/>
                              </CameraContextProvider>
                            </ILayersContextProvider>
                          </DroneSurveysContextProvider>
                        </AnalyticsContextProvider>
                      </WindRoseContextProvider>
                    </MeasurementsContextProvider>
                  </SatelliteDataContextProvider>
                </SurveysContextProvider>
              </MaintenanceContextProvider>
            </NotificationsContextProvider>
            {/*</PusherProvider>*/}
            {/*</DockEventContextProvider>*/}
            {/*</DockMgmtContextProvider>*/}
          </PlaybackContextProvider>
        </RiskContextProvider>
      </MapContextProvider>
      {/*</MarineTrafficContextProvider>*/}
    </ClientConfigContextProvider>
  </StyledEngineProvider>;

const target = document.getElementById('root');
if (target) ReactDOM.render(<AppIndex/>, target);
