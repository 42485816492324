import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableContainer,
  TableFooter,
  TablePagination,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import Button from '@mui/material/Button';
import {Anchor, Replay} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import React, {useEffect, useMemo, useState} from 'react';
import {
  dangerColor,
  grayColor,
  MOBILE_SCREEN,
  primaryColor,
  successColor,
  TABLET_SCREEN
} from '../../assets/jss/nextjs-material-dashboard';
import {getBerthCalls} from '../../api';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {getComparator, human, stableSort, TablePaginationActions, TCell, THeader, TypeIcon} from './PortCalls';
import {useTranslation} from 'react-i18next';
import TableSortLabel from '@mui/material/TableSortLabel';
import {visuallyHidden} from '@mui/utils';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import BerthCallsMobile from "./BerthCallsMobile";


export default function BerthCalls() {
  const {t, i18n} = useTranslation();
  const {clientId} = useConfig().state.config;
  const [startDate, setStartDate] = useState(moment().subtract(30, 'day').toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [berthCalls, setBerthCalls] = useState([]);
  const isTablet = useMediaQuery(`(max-width:${TABLET_SCREEN})`);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('dock_time_val');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);


  useEffect(() => {
    setLoading(true);
    console.log("FETCH PORT CALLS for:", moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
    getBerthCalls(clientId, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
      .then(result => setBerthCalls(result))
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, [clientId, startDate, endDate]);

  const onDateRangeChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };


  //{
  //  "clientId": "elb",
  //  "dock_timestamp": 1651333920000,
  //  "commercial_market": "DRY BREAKBULK",
  //  "dock_timestamp_str": "2022-04-30 15:52:00",
  //  "draught": 1,
  //  "mmsi": "261186044",
  //  "port_operation": "N/A",
  //  "recordId": "elb-261186044-1651333920000",
  //  "time_at_berth": 760,
  //  "time_at_port": 760,
  //  "undock_timestamp": 1651379520000,
  //  "undock_timestamp_str": "2022-05-01 04:32:00",
  //  "vessel_name": "BM 5280",
  //  "vessel_type": "Inland Cargo"
  // }
  const rows = useMemo(() => berthCalls.map((pc, idx) => ({
    id: pc.recordId,
    lp: idx + 1,
    name: pc.vessel_name,
    type: TypeIcon(pc.vessel_type, t),
    url: `https://www.marinetraffic.com/pl/ais/details/ships/shipid:0/mmsi:${pc.mmsi}/imo:0`,
    dock_time: pc.dock_timestamp ? new Date(pc.dock_timestamp).toLocaleString() : '-',
    dock_time_val: pc.dock_timestamp,
    undock_time: pc.undock_timestamp ? new Date(pc.undock_timestamp).toLocaleString() : '-',
    undock_time_val: pc.undock_timestamp,
    draught: pc.draught.toFixed(1) + 'm',
    draught_val: pc.draught,
    time_at_port: human(pc.time_at_port),
    time_at_port_val: pc.time_at_port,
    time_at_berth: human(pc.time_at_berth),
    // operation: pc.port_operation,
    commercial_market: t('berthCalls.commercialMarkets', {returnObjects: true})[pc.commercial_market] || pc.commercial_market,
  })), [berthCalls]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const resetAll = () => {
    setPage(0);
    setRowsPerPage(10);
    setOrder('desc');
    setOrderBy('dock_time_val');
    setStartDate(moment().subtract(30, 'day').toDate());
    setEndDate(new Date());
  };

  return <Card variant={'outlined'}>
      <CardHeader
        action={<Box mt={1} sx={{float: 'right'}} display={'flex'}>
          <DatePicker
            locale={i18n.resolvedLanguage}
            maxDate={new Date()}
            minDate={new Date(2022, 4, 1)}
            selectsRange
            monthsShown={2}
            startDate={startDate}
            endDate={endDate}
            onChange={onDateRangeChange}
          />
          <Tooltip title={t('portCalls.resetAll')}>
            <Button sx={{float: 'right'}} startIcon={<Replay/>} onClick={resetAll} color={'info'}/>
          </Tooltip>
        </Box>
        }
        title={<Button size={'large'} startIcon={<Anchor/>}
                       color={'info'}>{t('berthCalls.berthCalls')}</Button>}
      />
      <CardContent>
        <Box display="flex">
          {loading ? <CircularProgress size={100} sx={{margin: 'auto', marginTop: 10}}/> :
            <TableContainer>
              <Table size={isTablet ? 'small' : 'medium'} aria-label="reports table">
                <TableHead>
                  <TableRow sx={{backgroundColor: 'whitesmoke'}}>
                    <TableCell width={20}><THeader>Lp</THeader></TableCell>
                    {[{id: 'name', label: 'vessel'},
                      {id: 'dock_time_val', label: 'dockTime'},
                      {id: 'undock_time_val', label: 'undockTime'},
                      {id: 'time_at_port_val', label: 'timeAtPort'},
                      {id: 'draught_val', label: 'draught'},
                      // {id: 'portOperation', label: 'portOperation'}
                    ].map(({id, label}) =>
                      <TableCell key={id} sortDirection={orderBy === id ? order : false}
                                 align="center">
                        <TableSortLabel
                          active={orderBy === id}
                          direction={orderBy === id ? order : 'asc'}
                          onClick={event => handleRequestSort(event, id)}
                        >
                          <Typography sx={{color: primaryColor[2]}}>
                            {t('berthCalls.' + label)}
                          </Typography>
                          {orderBy === id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    )}
                    <TableCell sortDirection={orderBy === 'commercial_market' ? order : false} align="center">
                      <TableSortLabel
                        active={orderBy === 'commercial_market'}
                        direction={orderBy === 'commercial_market' ? order : 'asc'}
                        onClick={event => handleRequestSort(event, 'commercial_market')}
                      >
                        <Typography sx={{color: primaryColor[2]}}>
                          {t('berthCalls.commercialMarket')}
                        </Typography>
                        {orderBy === 'commercial_market' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                      ? stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : stableSort(rows, getComparator(order, orderBy))
                  ).map(({
                           id,
                           lp,
                           dock_time,
                           undock_time,
                           url,
                           name,
                           type,
                           time_at_port,
                           // time_at_berth,
                           // operation,
                           draught,
                           commercial_market
                         }) => (
                    <TableRow
                      key={id}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                      <TableCell width={20} align={'center'}><TCell>{lp}</TCell></TableCell>
                      <TableCell align="center"><TCell>
                        <Box display={'flex'} gap={1}>
                          <Box>{type}</Box>
                          <a target={'_blank'} style={{color: primaryColor[2]}}
                             href={url}>{name}</a>
                        </Box>
                      </TCell></TableCell>
                      <TableCell align="center"><TCell>{dock_time}</TCell></TableCell>
                      <TableCell align="center"><TCell>{undock_time}</TCell></TableCell>
                      {/*{clientId !== 'elb' && <TableCell align="center"><TCell>{berth_name}</TCell></TableCell>}*/}
                      {/*{clientId !== 'elb' && <TableCell align="center"><TCell>{time_at_berth} h</TCell></TableCell>}*/}
                      <TableCell align="center"><TCell>{time_at_port}</TCell></TableCell>
                      <TableCell align="center"><TCell>{draught}</TCell></TableCell>
                      {/*<TableCell align="center"><TCell>{operation}</TCell></TableCell>*/}
                      <TableCell align="center"><TCell>{commercial_market}</TCell></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={{border: 'none'}}
                      translate={'yes'}
                      rowsPerPageOptions={[5, 10, 25, 50, {label: t("berthCalls.all"), value: -1}]}
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage={t("general.rowsPerPage")}
                      labelDisplayedRows={({
                                             from,
                                             to,
                                             count
                                           }) => `${from}–${to} z ${count !== -1 ? count : `${t("berthCalls.moreThan")}${to}`}`}
                      // labelDisplayedRows={({ from, to, count }) =>`${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          }
        </Box>
      </CardContent>
    </Card>;
}
