import {Chip, Typography} from '@mui/material';
import React from 'react';
import {Popup} from '@urbica/react-map-gl';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import {grayColor} from '../PortManagement/DockStats/colors';


export default function SurveyInfoPopup({survey}) {
  const {t} = useTranslation();

  return <Popup
    longitude={survey.location[0]}
    latitude={survey.location[1]}
    anchor={'bottom'}
    offset={[15, -12]}
    closeButton={false}
    closeOnClick={false}
    maxWidth={'300px'}

  >
    <Typography>{survey.sessionName.replaceAll('_', ' ')} </Typography>
    <Typography sx={{fontSize: '11px'}} variant={'caption'}>{survey.dateStr}</Typography>
    <Box mt={2} display={'flex'} flexWrap={'wrap'} gap={1}>{survey.measurements.map(m => <Chip
      size={'small'}
      variant={'outlined'}
      // color={'success'}
      key={m}
      label={<Typography variant={'caption'} color={grayColor[5]}>{t('measurements.parameters', {returnObjects: true})[m] || m}</Typography>}
    />)}
    </Box>
  </Popup>;
};
