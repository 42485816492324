import {dangerColor, successColor, warningColor} from './assets/jss/nextjs-material-dashboard';
import moment from 'moment';

export function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`; // np. 2023-03-03
}

export function formatDateTime(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hour = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day} ${hour}:${minutes}`; // np. 2023-03-03 04:19
}

export const RISK_COLOR = {
  1: successColor[0],
  2: '#B0DD10',
  3: warningColor[0],
  4: dangerColor[0]
};

export function average(numbers) {
  return _.sum(numbers) / (numbers.length || 1);
}


export function getColorBetweenGreenAndRed(value, reversed=false) {
  // upewniamy się, że wartość znajduje się w przedziale od 0 do 100
  value = Math.max(0, Math.min(100, value));

  if(reversed) {
    value = 100 - value;
  }

  // wyznaczamy wartości kolorów RGB dla zielonego i czerwonego koloru
  var red = value < 50 ? 255 : Math.round(256 - (value - 50) * 5.12);
  var green = value > 50 ? 255 : Math.round(value * 5.12);
  var blue = 0;

  // łączymy wartości RGB w jeden kolor w formacie #RRGGBB
  return "#" + componentToHex(red) + componentToHex(green) + componentToHex(blue);
}

// pomocnicza funkcja zamieniająca wartość liczbową na heksadecymalną wartość składowej koloru RGB
function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export function generateRandom(min = 0, max = 100) {
  // find diff
  let difference = max - min;
  // generate random number
  let rand = Math.random();
  // multiply with difference
  rand = Math.floor( rand * difference);
  // add with min value
  return rand + min;
}

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const UNITS = {
  temp: '°C',
  oil: 'ppm',
  dhtHumidity: '%',
  dhtTemp: '°C',
  noise: 'dBA',
  do: 'mg/L',
  o2: '%, ',
  ph: '',
  ec: 'µS/cm',
  orp: 'mV',
  turbidity: 'NTU',
  chlorophyll: 'μg/L',
  waterLevel: 'cm',
};

export const unitsByParams = (param) => UNITS[param] || 'µg/m3';

export function validateEmail(input) {
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return input.match(validRegex);
}

export function validatePhone(phoneNumber) {
  const regex = /^(\+)?\d{7,13}$/; // Wymagane od 7 do 11 cyfr po ewentualnym prefiksie +48
  return regex.test(phoneNumber);
}

export function formatPhoneNumber(phoneNumber) {
  const regex = /^(\d{3})(\d{3})(\d{3})$/;
  return phoneNumber.replace(regex, '$1-$2-$3');
}

export const isMobileMonitoring = () => window.location.href.includes('mobilemonitoring.pl') || window.location.href.includes('localhost');


const REPORTS_BUCKET_URL = "https://yeti-reports.s3.eu-central-1.amazonaws.com/";
const CORAL_REPORTS_BUCKET_URL = "https://s3.eu-central-1.amazonaws.com/files.coral.com/";

export function pdfReportS3Link (clientId, datetime) {
  const risk = clientId === "lip" ? "Risks" : "Ryzyko";
  if ( clientId === 'zpg' && moment(datetime).isSameOrAfter(moment("2022-12-01", "YYYY-MM-DD"), 'day')) {
    return CORAL_REPORTS_BUCKET_URL + clientId + `/risk-pdf/${risk}-${datetime.format("YYYY-MM-DD")}.pdf`;
  } else if (moment(datetime).isSameOrAfter(moment("2022-12-02", "YYYY-MM-DD"), 'day')) {
    return CORAL_REPORTS_BUCKET_URL + clientId + `/risk-pdf/${risk}-${datetime.format("YYYY-MM-DD")}.pdf`;
  } else {
    return REPORTS_BUCKET_URL + clientId + `/risk-pdf/${risk}-${datetime.format("YYYY-MM-DD")}.pdf`;
  }
}

export function xlsxReportS3Link (clientId, datetime) {
  const risk = clientId === "lip" ? "Risks" : "Ryzyko";
  if ( clientId === 'zpg' && moment(datetime).isSameOrAfter(moment("2022-12-01", "YYYY-MM-DD"), 'day')) {
    return CORAL_REPORTS_BUCKET_URL + clientId + `/risk/${risk}-${datetime.format("YYYY-MM-DD")}.xlsx`;
  } else if (moment(datetime).isSameOrAfter(moment("2022-12-02", "YYYY-MM-DD"), 'day')) {
    return CORAL_REPORTS_BUCKET_URL + clientId + `/risk/${risk}-${datetime.format("YYYY-MM-DD")}.xlsx`;
  } else {
    return REPORTS_BUCKET_URL + clientId + `/risk/${risk}-${datetime.format("YYYY-MM-DD")}.xlsx`;
  }
}

export const isAdmin = email => email && (email.includes('seadata.pl') || email.includes('dawid.camdrone') || email.includes('woot6091') || email.includes('mateuszblazejak'));
