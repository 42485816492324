import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import {useDockMgmtContext} from '../../context/dockMgmt/dockMgmtContext';
import {IconButton} from '@mui/material';
import Box from '@mui/material/Box';
import {HighlightOff} from '@mui/icons-material';
import {addDockAgent, getDockAgents} from '../../api';
import {useConfig} from 'context/clientConfig/clientConfigContext';
import {useDockEventContext} from '../../context/dockEvent/dockEventContext';

const filter = createFilterOptions();


export default function AgentComboBox({idx, unloading}) {
  const {state, dispatch} = useDockEventContext();
  const {clientId} = useConfig().state.config;
  const [agentsOptions, setAgentsOptions] = useState([]);
  const {unloadingInfo, loadingInfo} = state;
  const loadInfo = unloading ? unloadingInfo : loadingInfo;
  const agentName = loadInfo.agents[idx];

  useEffect(() => {
    getDockAgents(clientId)
      .then(result => {
        setAgentsOptions(result);
      }).catch(err => console.error(err));
  }, [clientId]);

  const setAgentName = (option) => {
    dispatch({type: 'update-cargo-agent', idx, unloading: unloading, agent: option});
  };

  const addNewAgentNameToDictionary = (newAgentName) => addDockAgent(clientId, newAgentName);

  const onValueChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setAgentName(newValue);
    } else if (newValue && newValue.inputValue) {
      addNewAgentNameToDictionary(newValue.inputValue);
      setAgentName(newValue.inputValue);
    } else {
      setAgentName(newValue);
    }
  };

  const onRemove = () => dispatch({type: 'remove-cargo-agent', idx, unloading: unloading});

  return (
    <Box key={`${'' + idx + unloading}`} mb={2} display={'flex'} gap={1}>
      <Box>
        <Autocomplete
          freeSolo
          fullWidth
          size={'small'}
          id={`cargo-agent-${idx}-${unloading}`}
          value={agentName}
          onChange={onValueChange}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const {inputValue} = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                label: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={agentsOptions}
          // getOptionLabel={(option) => {
          //   // Value selected with enter, right from the input
          //   if (typeof option === 'string') {
          //     return option;
          //   }
          //   // Add "xxx" option created dynamically
          //   if (option.inputValue) {
          //     return option.inputValue;
          //   }
          //   // Regular option
          //   return option.label;
          // }}
          // renderOption={(props, option) => <li {...props}>{option.label}</li>}
          sx={{width: 300}}
          renderInput={(params) => (
            <TextField {...params} label={'Operator ' + (idx + 1)}/>
          )}
        />
      </Box>
      <Box>
        <IconButton onClick={onRemove}>
          <HighlightOff/>
        </IconButton>
      </Box>
    </Box>
  );
}
