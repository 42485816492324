// @mui material components
import { makeStyles } from "@mui/styles";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";
import palette from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";
import rgba from "assets/theme/functions/rgba";


export default makeStyles(({ }) => {
  const { dark, white, transparent: transparentColor } = palette;
  const { regular } = boxShadows;
  const { borderRadius } = borders;

  return {
    defaultNavbar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: ({ transparent }) =>
        transparent ? transparentColor.main : rgba(white.main, 0.8),
      color: ({ light }) => (light ? white.main : dark.main),
      boxShadow: ({ transparent }) => (transparent ? "none" : regular),
      backdropFilter: ({ transparent }) =>
        transparent ? "none" : `saturate(200%) blur(${pxToRem(30)})`,
      borderRadius: borderRadius.section,
      margin: `${pxToRem(16)} ${pxToRem(24)}`,
      width: `calc(100% - ${pxToRem(48)})`,
      position: "absolute",
      left: 0,
      zIndex: 3,
    },
  };
});
