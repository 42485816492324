import SuiBox from '../SuiBox';
import SuiTypography from '../SuiTypography';
import Select from '@mui/material/Select';
import React from 'react';

export default function CustomSelect({value, onChange, label, minWidth, children}) {
  return <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
    <SuiBox minWidth={minWidth || 200} mb={1} ml={0.5} lineHeight={0} display="inline-block">
      <SuiTypography
        component="label"
        variant="caption"
        fontWeight="bold"
        textTransform="capitalize"
      >
        {label}:
      </SuiTypography>
    </SuiBox>
    <Select
      fullWidth
      size={'small'}
      value={value}
      onChange={onChange}
      // variant={'standard'}
    >
      {children}
    </Select>
  </SuiBox>
}
