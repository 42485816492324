import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {useEffect} from 'react';
import SuiTypography from '../SuiTypography';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from '@mui/material';
import {TABLET_SCREEN} from '../../assets/jss/nextjs-material-dashboard';

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 100,
    label: '',
  },
  {
    value: 200,
    label: '200 ug/L',
  },
  {
    value: 300,
    label: '',
  },
  {
    value: 400,
    label: '400 ug/L',
  },
];

const marksSmall = [marks[0], marks[4]];

function valuetext(value) {
  return `${value} ug/L`;
}

const DEFAULT_VALUES = [10, 50];

export default function ChlorophyllSlider({onChange, min, max}) {
  const {t} = useTranslation();
  const isTablet = useMediaQuery(`(max-width:${TABLET_SCREEN})`);
  const [value, setValue] = React.useState(min !== null ? [min, max] : DEFAULT_VALUES);

  useEffect(() => min === null && onChange(DEFAULT_VALUES), []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Box mt={2} mb={-2} maxWidth={500} minWidth={100} width={"100%"}>
      <SuiTypography
        component="label"
        variant="caption"
        fontWeight="bold"
        textTransform="capitalize"
      >
        {t('episodes.thresholdRange')}:
      </SuiTypography>

      <Slider
        color={'success'}
        max={400}
        getAriaLabel={valuetext}
        getAriaValueText={valuetext}
        marks={isTablet ? marksSmall : marks}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
      />
    </Box>
  );
}
