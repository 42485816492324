import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const TemperatureIcon = (props) => <SvgIcon {...props} viewBox="0 0 104 104">
  <g opacity=".9" fillRule="nonzero" fill={props.color}>
    <path
      d="M58.565 55.154V29.892a7.891 7.891 0 10-15.783 0v25.25c-6.82 4.352-8.822 13.41-4.47 20.23a14.65 14.65 0 0012.323 6.771l.078.011c8.091-.015 14.638-6.586 14.623-14.677a14.65 14.65 0 00-6.77-12.323zm-14.055 23.1c-5.94-3.428-7.975-11.023-4.545-16.962a12.418 12.418 0 014.545-4.546c.348-.2.562-.573.56-.975V29.89a5.648 5.648 0 0111.297 0v25.88c-.002.402.212.774.56.975 5.94 3.43 7.975 11.024 4.546 16.964-3.43 5.939-11.024 7.974-16.963 4.545z"
      ></path>
    <path
      d="M51.835 59.439V42.422a1.122 1.122 0 00-2.244 0v17.017a7.751 7.751 0 001.122 15.424 7.752 7.752 0 001.122-15.424zm-1.122 13.17a5.508 5.508 0 01-5.508-5.509 5.519 5.519 0 015.508-5.508 5.508 5.508 0 110 11.016zM69.503 29.712h-7.011a1.122 1.122 0 000 2.244h7.01a1.122 1.122 0 000-2.244zM68.01 35.32h-4.116a1.122 1.122 0 000 2.244h4.117a1.122 1.122 0 000-2.243zM69.503 40.93h-7.011a1.122 1.122 0 000 2.243h7.01a1.122 1.122 0 000-2.243zM68.01 46.538h-4.116a1.122 1.122 0 000 2.244h4.117a1.122 1.122 0 000-2.244zM69.503 52.147h-7.011a1.122 1.122 0 000 2.244h7.01a1.122 1.122 0 000-2.244z"
      ></path>
  </g>
</SvgIcon>;

export default TemperatureIcon;
