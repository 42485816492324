import {AppBar, Card, CardContent, CardHeader, Tab, Tabs, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import {Add, EmailOutlined, Settings, Warning} from '@mui/icons-material';
import React, {useState} from 'react';
import {primaryColor} from '../../assets/jss/nextjs-material-dashboard';
import {useTranslation} from 'react-i18next';
import NotifsConfigTable from './NotifsConfigTable';
import NewConfig from './NewConfig';
import {useNotificationsContext} from '../../context/notifications/notificationsContext';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import RiskRecipients from './RiskRecipients';


export default function NotifConfig() {
  const {t} = useTranslation();
  const {email} = useConfig().state;
  const {state: {currentConfig}, dispatch} = useNotificationsContext();
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const createNewConfig = () => dispatch({type: 'new-config', email});

  return <Card variant={'outlined'} sx={{
      minHeight: '100%',
      maxWidth: 'calc(100vw - 160px)',
      '@media screen and (max-width: 50em)': {maxWidth: '100vh'}
    }}>
      <CardHeader
        title={<AppBar elevation={0} color={'transparent'} position="static">
          <Tabs orientation={'horizontal'} value={tabValue} onChange={handleSetTabValue}>
            <Tab icon={<Settings/>} iconPosition={'start'}
                 label={<Typography color={'primary'}>{t('episodes.headingConfig')}</Typography>}/>

            <Tab icon={<EmailOutlined/>} iconPosition={'start'}
                 label={<Typography color={'primary'}>{t('risk.riskRecipients')}</Typography>}/>

            <Tab disabled icon={<Warning/>} iconPosition={'start'}
                 label={<Typography color={'darkgray'}>{t('episodes.enableAnomaly')}</Typography>}/>
            {/*<Tab disabled icon={<Schedule />} iconPosition={'start'}*/}
            {/*     label={<Typography color={'darkgray'}>Powiadomienia Cykliczne</Typography>}/>*/}
          </Tabs>
        </AppBar>}
        // title={<Button sx={{'@media screen and (max-width: 50em)': {display: 'none'}}}
        //                size={'large'} startIcon={<Settings />}
        //                color={'error'}>{t('episodes.headingConfig')}</Button>}
        // subheader={<Typography
        //   sx={{marginLeft: 2, color: grayColor[3], '@media screen and (max-width: 50em)': {display: 'none'}}}
        //   variant={'caption'}>{t('episodes.subheadingConfig')}</Typography>}
      />
      {/*MOBILE CARD TITLE*/}
      <Typography sx={{
        color: primaryColor[2],
        textAlign: 'center',
        fontSize: '1.25rem',
        '@media screen and (min-width: 50em)': {display: 'none'}
      }}>{t('episodes.headingConfig')}</Typography>


    {tabValue === 1 ? <CardContent>
        <RiskRecipients onClose={() => setTabValue(0)} />
      </CardContent>:<>
      {!currentConfig && <Button sx={{ml:5}} startIcon={<Add />} size={'small'} variant={'outlined'}
                                 onClick={createNewConfig} color={'success'}>{t('episodes.addNew')}</Button>}
    <CardContent>
        {currentConfig && <NewConfig />}
        <NotifsConfigTable />
      </CardContent></>}
    </Card>;
}
