import React, {useCallback, useEffect, useState} from 'react';
import {useConfig} from '../context/clientConfig/clientConfigContext';
import Header from '../components/Header/Header';
import Box from '@mui/material/Box';
import {Typography, useMediaQuery} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import DatePicker from 'react-datepicker';
import DeviceSidebar from '../components/DeviceSidebar/DeviceSidebar';
import MapGL, {Marker} from '@urbica/react-map-gl';
import {DARK_MAPSTYLES, INITIAL_MAPSTYLE, MAPBOX_TOKEN} from '../config';
import MapSideBar from '../components/MapSidebar/MapSideBar';
import BaseMapSelector from '../components/BaseMapSelector/BaseMapSelector';
import {useMeasurementsContext} from '../context/measurements/measurementsContext';
import {MOBILE_SCREEN} from '../assets/jss/nextjs-material-dashboard';
import DevicesMarkers from './Map/DevicesMarkers';
import {useMapContext} from '../context/map/mapContext';
import WindMarkers from './Map/WindMarkers';
import WindPmMarkers from './Map/WindPmMarkers';
import DeviceInfoPopup from './Map/DeviceInfoPopup';
import MapLegend from './Map/MapLegend';
import {usePlaybackContext} from '../context/marineTrafficPlayback/playbackContext';
import _ from 'lodash';
import {getMarineTrafficPlayback} from '../api';
import moment from 'moment/moment';
import NewPlaybackSlider from '../components/MarineTraffic/NewPlaybackSlider';
import VesselPlaybackIcon from '../components/MarineTraffic/VesselPlaybackIcon';

const ONE_MINUTES_MILLIS = 60 * 1000;

export default function NewMap() {
  const {config} = useConfig().state;
  const {clientId, modules} = config ? config : {};
  const {state: {selectedDate, sliderPosition}, dispatch: measurementsDispatch} = useMeasurementsContext();
  const {
    state: {selectedDevice, loading, error, selectedMarkers, hoveredDevice},
    dispatch: mapDispatch
  } = useMapContext();

  const [mapStyleId, setMapStyleId] = useState(INITIAL_MAPSTYLE);
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);
  const [viewport, setViewport] = useState({
    // latitude: 54.390069,
    // longitude: 18.663377,
    latitude: config && config.uiSettings && config.uiSettings.mapCenter[1],
    longitude: config && config.uiSettings && config.uiSettings.mapCenter[0],
    zoom: config && config.uiSettings && config.uiSettings.mapZoom
  });

  useEffect(() => {
    setViewport({
      latitude: config && config.uiSettings && config.uiSettings.mapCenter[1],
      longitude: config && config.uiSettings && config.uiSettings.mapCenter[0],
      zoom: config && config.uiSettings && config.uiSettings.mapZoom
    });
  }, [config]);

  // MARINE TRAFFIC - PLAYBACK -- START
  const {
    state: {dateFrom, dateTo, state, timestamp, vesselPositionsByTimestamp},
    dispatch: playbackDispatch
  } = usePlaybackContext();
  const [displayedVessels, setDisplayedVessels] = useState({});

  const [selectedVessel, setSelectedVessel] = useState(null);
  // when selectedDate changes change playback dates
  useEffect(() => {
    playbackDispatch({
      type: 'set-dates',
      dateFrom: moment(selectedDate).set('hours', 0).set('minute', 0),
      dateTo: moment(selectedDate)
    });
    playbackDispatch({type: 'set-state', state: 'READY'});
  }, [selectedDate])
  // LOAD DATA WHEN READY
  useEffect(() => {
    // console.log({state});
    if (state !== 'READY') return;
    // console.log('LOADING DATA for dates', {dateFrom, dateTo});
    mapDispatch({type: 'set-loading', loading: true});

    getMarineTrafficPlayback(clientId, dateFrom.format('YYYY-MM-DD')).then(result => {
      const flattened = _
        .values(result)
        .flatMap(v => v.positions)
        .map(pos => ({
          ...pos,
          //round to the nearest minute
          timestamp: Math.round(pos.timestamp / ONE_MINUTES_MILLIS) * ONE_MINUTES_MILLIS
        }));
      const byTimestamp = _.groupBy(flattened, 'timestamp');
      playbackDispatch({type: 'update-vessels-position', vessels: result, vesselPositionsByTimestamp: byTimestamp});
      playbackDispatch({type: 'set-timestamp', timestamp: dateFrom.valueOf()});
    }).catch(err => console.error(err))
      .finally(() => mapDispatch({type: 'set-loading', loading: false}));

  }, [state, dateFrom, dateTo]);

  const updateDisplayedVessels = useCallback((newDisplayedVessels) => {
    setDisplayedVessels({...displayedVessels, ..._.keyBy(newDisplayedVessels, 'mmsi')});
  }, [displayedVessels]);


  useEffect(() => {
    const matchedVessels = vesselPositionsByTimestamp[Math.round(timestamp / ONE_MINUTES_MILLIS) * ONE_MINUTES_MILLIS];
    if (matchedVessels) {
      updateDisplayedVessels(matchedVessels);
    }

  }, [vesselPositionsByTimestamp, timestamp]);


  const vesselsArray = _.values(displayedVessels);
  const vesselsMarkers = vesselsArray
    .map(({heading, latitude, longitude, mmsi, speed, status, timestamp}) => <Marker
      key={mmsi}
      longitude={longitude}
      latitude={latitude}
      anchor="top-left"
      offset={[-11, -11]}
    >
      <VesselPlaybackIcon mapBearing={viewport.bearing} {...{heading, mmsi, speed, status}} />
    </Marker>);

// MARINE TRAFFIC -- END

  return <>
    <Header/>
    {error && <Box p={2} sx={{
      position: 'absolute',
      top: '10%',
      left: '40%',
      zIndex: 1400,
      backgroundColor: 'rgba(255,255,255, .5)'
    }}>
      <Typography color={'error'} variant={'subtitle2'}>{error}</Typography>
    </Box>}
    {loading && <Box sx={{position: 'absolute', top: '40%', left: '40%', zIndex: 1400}}>
      <CircularProgress variant={'indeterminate'} size={200}/>
    </Box>}
    <Box sx={{
      position: 'absolute',
      top: 100,
      right: 10,
      zIndex: 1,
      '@media screen and (max-width: 60em)': {top: '4em'},
      '@media screen and (max-width: 70em)': {top: '6em'},
      '@media screen and (max-width: 65em)': {top: '6.3em'},
      '@media screen and (max-width: 55em)': {top: '4.5em'}
    }}>
      <DatePicker
        selected={selectedDate}
        onChange={date => measurementsDispatch({type: 'set-selectedDate', selectedDate: date})}
      />
    </Box>

    {/* LEGEND */}
    {clientId !== 'atm' && <Box
      sx={{
        position: 'absolute',
        top: 95,
        '@media screen and (max-width: 50em)': {
          top: 65,
        },
        left: 'calc(50vw - 152px)',
        zIndex: 999
      }}>
      <MapLegend/>
    </Box>}

    <Box sx={{
      height: 'calc(100vh - 88px)',
      '@media screen and (max-width: 50em)': {
        height: 'calc(100vh - 60px)'
      }
    }}>
      {selectedDevice && <DeviceSidebar device={selectedDevice} onClose={() => {
        mapDispatch({type: 'set-selectedDevice', device: null});
        // TODO what is this?
        window.location.href = window.location.href.replace('?serialNo=ELB_01', '');
      }}/>}
      <MapGL
        style={{height: '100%'}}
        {...viewport}
        mapStyle={mapStyleId}
        onViewportChange={setViewport}
        accessToken={MAPBOX_TOKEN}
      >
        <MapSideBar dark={DARK_MAPSTYLES.includes(mapStyleId)}/>

        {!isMobile && <BaseMapSelector
          selectedStyleId={mapStyleId}
          onStyleChange={(id) => setMapStyleId(id)}
        />}

        {(modules.AIR || modules.WATER || modules.AGRO || modules.NOISE) && <DevicesMarkers/>}
        {modules.WEATHER && <WindMarkers mapStyleId={mapStyleId}/>}
        {modules.WIND_PM && <WindPmMarkers mapStyleId={mapStyleId} setViewport={setViewport}/>}
        {selectedMarkers.includes('MARINE_TRAFFIC') && vesselsMarkers}
        {/*{selectedMarkers.includes('MARINE_TRAFFIC') && vesselPopup}*/}
        {selectedMarkers.includes('MARINE_TRAFFIC') && <NewPlaybackSlider />}
        {hoveredDevice && <DeviceInfoPopup device={hoveredDevice}/>}
      </MapGL>
    </Box>
  </>;
}


const MARCO_POLO_ZACUMOWANY = {
  'teu': '60',
  'financialOwner': null,
  'insurer': 'STANDARD P & I CLUB',
  'depth': '13.4',
  'grossTonnage': '15955',
  'breadthMoulded': '23.4',
  'summerDwt': '7330',
  'name': 'MARCO POLO',
  'grain': null,
  'breadthExtreme': '23.43',
  'flag': 'CY',
  'lengthOverall': '150.38',
  'liquidOil': null,
  'callsign': '5BJX5',
  'vesselType': 'RO-RO/PASSENGER SHIP',
  'yardNumber': null,
  'placeOfBuild': 'KRIMPEN AAN DEN IJSSEL NETHERLANDS',
  'owner': 'TIRRENIA DI NAVIGAZIONE SOCIETA PER AZIONI',
  'technicalManager': null,
  'liquidGas': null,
  'build': '1993',
  'builder': 'TILLE FRISIAN',
  'speedMax': null,
  'manager': 'TIRRENIA DI NAVIGAZIONE SOCIETA PER AZIONI',
  'speedService': '20.6',
  'timestamp': 1699696800000,
  'imo': '9019080',
  'mmsi': '209764000',
  'draught': '6.02',
  'netTonnage': '4786',
  'displacementSummer': null,
  'lengthBWPerpendiculars': '137.33',
  'fuelConsumption': '42 t/day at avg. speed',
  'eni': null,
  'heading': 345.5,
  'latitude': 54.37297439575195,
  'longitude': 18.66010570526123,
  'speed': 0,
  'course': 259.5,
  'status': '0'
};
