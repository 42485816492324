import React, {useMemo, useReducer} from 'react';
import {Auth} from 'aws-amplify';
import PropTypes from 'prop-types';
import _ from 'lodash';

const initialState = () => {
  return {
    surveys: [],
    selectedSurvey: null,
    selectedMeasurement: null,
    hoveredItem: null,
  };
};


const DroneSurveysContext = React.createContext(initialState());
// expose the context via a custom hook.
export const useDroneSurveysContext = () => React.useContext(DroneSurveysContext);

function reducer(state, action) {
  switch (action.type) {
  case 'set-surveys':
    return {
      ...state,
      surveys: action.surveys
    };
  case 'set-selectedSurvey':
    return {
      ...state,
      selectedSurvey: action.selectedSurvey
    };
  case 'set-selectedMeasurement':
    return {
      ...state,
      selectedMeasurement: action.selectedMeasurement
    };
  case 'set-hoveredItem':
    return {
      ...state,
      hoveredItem: action.hoveredItem
    };
  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function DroneSurveysContextProvider({children}) {
  const [surveysReducerState, userDispatch] = useReducer(reducer, initialState());
  const userContext = useMemo(() => {
    return {
      state: surveysReducerState,
      dispatch: userDispatch
    };
  }, [surveysReducerState]);

  return <DroneSurveysContext.Provider value={userContext}>
    {children}
  </DroneSurveysContext.Provider>;
}

DroneSurveysContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};
