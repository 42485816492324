import React, {useMemo, useReducer} from 'react';
import moment from 'moment';

const initialState = () => {
  return {
    dockEvents: {},
    selectedDock: null,
    selectedVessel: null,
  };
};


const DockMgmtContext = React.createContext(initialState());
// expose the context via a custom hook.
export const useDockMgmtContext = () => React.useContext(DockMgmtContext);

function reducer(state, action) {
  switch (action.type) {
  case 'update-dock-event':
    const events = state.dockEvents[action.dockId];
    const idx = events.findIndex(e => e.dockId === action.dockId && e.timestamp === action.dockEvent.timestamp);
    events[idx] = {...events[idx], ...action.dockEvent};
    return {
      ...state,
      dockEvents: {...state.dockEvents, [action.dockId]: events.slice()},
      // dockEvent: state.dockEvent ? {...state.dockEvent, ...action.dockEvent} : state.dockEvent
    };
  case 'set-dock-events':
    return {
      ...state,
      dockEvents: {...state.dockEvents, [action.dockId]: action.dockEvents}
    };
  case 'delete-dock-event':
    return {
      ...state,
      dockEvents: {
        ...state.dockEvents,
        [action.dockEvent.dockId]: state.dockEvents[action.dockEvent.dockId].filter(evt => evt.timestamp !== action.dockEvent.timestamp)
      },
    };
  case 'add-dock-event':
    return {
      ...state,
      dockEvents: {...state.dockEvents, [action.dockId]: [...state.dockEvents[action.dockId], action.dockEvent]},
      selectedVessel: null,
    };
  case 'select-dock':
    return {
      ...state,
      selectedDock: action.selectedDock,
      selectedVessel: action.selectedVessel
    };

  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function DockMgmtContextProvider(props) {

  const [state, dispatch] = useReducer(reducer, initialState());
  const context = useMemo(() => {
    return {
      state,
      dispatch
    };
  }, [state]);

  return <DockMgmtContext.Provider value={context}>
    {props.children}
  </DockMgmtContext.Provider>;
}
