import React from 'react';
import Highcharts from 'highcharts';
import {HighchartsChart, HighchartsProvider, LineSeries, Pane, Tooltip, XAxis, YAxis} from 'react-jsx-highstock';
import applyExporting from 'highcharts/modules/exporting';
import applyOffline from 'highcharts/modules/offline-exporting';
import applyHighchartsMore from 'highcharts/highcharts-more';
import {useTranslation} from 'react-i18next';
import WindChartControlPanel from './WindChartControlPanel';
import {windDir} from '../measurementsUtil';


applyExporting(Highcharts);
applyHighchartsMore(Highcharts);
applyOffline(Highcharts);

const plotOptions = (pointInterval) => {
  return {
    series: {
      pointStart: 0,
      pointInterval: pointInterval,
      shadow: true,
    },
    column: {
      pointPadding: 0,
      groupPadding: 0
    },
  };
};

const chartOptions = (width, height, onClick) => {
  return {
    backgroundColor: 'transparent',
    width: width,
    height: height,
    events: {
      click: onClick
    }
  };
};

const yLabelFormatter = units => function () {
  return this.value + units;
};

const tooltipFormatter = function () {
  return `<span style="font-size: 10px">${window.t('windRose.windDirection')}: ${this.x}°</span> <b>` + windDir(this.x) + `</b><br/><br/>` +
    `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${this.y} ${window.polarChartUnits}</b><br/>`;
};

const chartLinesColor = dark => dark ? 'rgb(0,0,128, 1)' : 'whitesmoke';
const lineColor = 'rgb(200, 40, 0, 0.7)';
const secondaryLineColor = dark => dark ? 'rgb(0, 0, 0, 0.7)' : 'whitesmoke';

export default function PolarChart({
  data,
  secondSeriesName,
  secondData,
  width,
  height,
  name,
  serialNo,
  seriesName,
  max,
  units,
  exportChartTitle,
  onClick,
  dark
}) {
  const {t} = useTranslation();
  const pointInterval = 360 / data.length;
  const detailedView = height > 400;
  window.polarChartUnits = units;
  window.t = t;

  return (
    // This force rerender all children and allow changes in the chart inside
    <div style={{width: width + 40}} key={width + '-' + height}>
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsChart
          polar
          chart={chartOptions(width, height, onClick)}
          exporting={{
            enabled: detailedView,
            filename: name + '-windRose',
            chartOptions: {
              backgroundColor: '#FFF',
              title: {text: exportChartTitle, useHtml: true},
              chart: {
                backgroundColor: '#FFF'
              }
            },
            buttons: {
              contextButton: {
                symbol: `menu`,
                symbolSize: 28,
                // symbolFill: 'rgba(0,0,0,0)',
                // symbolX: 35.0,
                // symbolY: 40.0,
                x: -80.5,
                y: 59.5,
                menuItems: ['downloadPNG', 'downloadPDF', 'downloadSVG'],
                // menuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
              }
            }
          }}
          plotOptions={plotOptions(pointInterval)}
        >
          {/* Custom Control Panel for that chart */}
          <WindChartControlPanel serialNo={serialNo} name={name} onClick={onClick}/>

          <Pane startAngle={0} endAngle={360}/>
          <XAxis
            lineColor={chartLinesColor(dark)}
            tickColor={chartLinesColor(dark)}
            labels={{format: '{value}°', style: {color: dark ? '#000000' : '#FFFFFF'}}}
            gridLineColor={chartLinesColor(dark)}
            tickInterval={45}
            min={0}
            max={360}
          />

          <Tooltip valueDecimals={1} formatter={tooltipFormatter}/>

          <YAxis min={0} max={max}
                 lineColor={chartLinesColor(dark)}
                 gridLineColor={chartLinesColor(dark)}
                 labels={{formatter: yLabelFormatter(units), style: {color: dark ? '#000000' : '#FFFFFF'}}}
          >
            <LineSeries
              color={lineColor}
              name={seriesName}
              data={data}
            />

            {secondData &&
            <LineSeries
              color={secondaryLineColor(dark)}
              name={secondSeriesName}
              data={secondData}
            />
            }

          </YAxis>
        </HighchartsChart>
      </HighchartsProvider>
    </div>
  );
}
