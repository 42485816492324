import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import {Delete} from '@mui/icons-material';
import {DateTimePicker} from '@mui/lab';
import moment from 'moment/moment';
import {dangerColor, grayColor} from '../../assets/jss/nextjs-material-dashboard';
import React from 'react';
import {useMaintenance} from '../../context/maintenance/maintenanceContext';
import PhotoDropzone from './PhotoDropzone';
import Button from '@mui/material/Button';
import {removeNoteImage} from '../../views/Maintenance/S3_Util';


export default function MaintenanceNote({idx, note}) {
  const {state: {formData}, dispatch} = useMaintenance();
  const setFormData = newFormData => dispatch({type: 'set-formData', formData: newFormData});

  // console.log({note});

  const handleNoteChange = (idx, name, value) => {
    console.log('handleNoteChange', {idx, name, value});

    formData.notes[idx][name] = value;
    setFormData({...formData});
  };

  const removeImg = async (deleteNote) => {
    if (!note.imgUrl) {
      return;
    }
    removeNoteImage(note);
    if (deleteNote !== true) {
      handleNoteChange(idx, 'imgUrl', null);
    }
  };

  const removeNote = () => {
    removeImg(true);
    setFormData({
      ...formData,
      notes: formData.notes.filter((_, index) => index !== idx)
    });
  };

  return <Card elevation={4}>
    <Box sx={{display: 'flex', flexDirection: 'row'}}>
      {note.imgUrl && <CardMedia
        component="img"
        image={note.imgUrl.replace('/thumb/', '/photo/')}
        alt="Start Photo"
        sx={{maxWidth: 300}}
      />}
      <CardContent>
        <Box width={300} display={'flex'} flexDirection={'column'} gap={2} position={'relative'}>
          <Typography>#{idx + 1}</Typography>
          <IconButton onClick={removeNote} sx={{position: 'absolute', right: -10, top: -10}}
                      color={'error'}>
            <Delete/>
          </IconButton>
          <TextField
            label="Notka"
            name="text"
            value={note.text}
            onChange={(e) => handleNoteChange(idx, 'text', e.target.value)}
            multiline
            rows={4}
            fullWidth
          />
          <DateTimePicker
            label="createdAt"
            value={moment(note.createdAt)}
            onChange={(newDate) => handleNoteChange(idx, 'createdAt', newDate.toISOString())}
            renderInput={(params) => <TextField {...params} fullWidth/>}
          />
          <TextField
            label="createdBy"
            name="createdBy"
            value={note.createdBy}
            onChange={(e) => handleNoteChange(idx, 'createdBy', e.target.value)}
            fullWidth
          />
          <FormControlLabel
            control={<Checkbox color={'error'} checked={note.private}
                               onChange={() => handleNoteChange(idx, 'private', !note.private)} size={'small'}/>}
            label={<Typography color={note.private ? dangerColor[0] : grayColor[2]} variant={'caption'}>Notka
              prywatna</Typography>}
          />
        </Box>
        {note.imgUrl ?
          <Button color={'error'} startIcon={<Delete/>} onClick={removeImg}>
            Usuń zdjęcie
          </Button> :
          <PhotoDropzone isNote onUploadDone={imgUrl => handleNoteChange(idx, 'imgUrl', imgUrl)}/>}
      </CardContent>
    </Box>
  </Card>;
}
