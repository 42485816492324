import React, {useMemo, useReducer} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const initialState = () => {
  return {
    gisLayers: [],
    wmsLayers: [],
    satLayers: [],
    selectedLayers: [],
    selectedMarkers: [],   // AIR, WATER, WIND_PM, WEATHER
    selectedDevice: null,
    hoveredDevice: null,
    currentAgroParam: null,
    loading: false,
    error: null,
  };
};


const MapContext = React.createContext(initialState());
// expose the context via a custom hook.
export const useMapContext = () => React.useContext(MapContext);

function reducer(state, action) {
  switch (action.type) {
  case 'toggle-current-agro-param':
    return {
      ...state,
      currentAgroParam: state.currentAgroParam === action.agroParam ? null : action.agroParam
    };

  case 'set-hoveredDevice':
    return {
      ...state,
      hoveredDevice: action.device
    };
  case 'set-selectedDevice':
    return {
      ...state,
      selectedDevice: action?.device?.serialNo === state?.selectedDevice?.serialNo ? null : action.device
    };
  case 'toggle-layer':
    return {
      ...state,
      selectedLayers: state.selectedLayers.includes(action.layerId) ?
        state.selectedLayers.filter(l => l !== action.layerId)
        : [...state.selectedLayers, action.layerId]
    };
  case 'toggle-satLayer':
    return {
      ...state,
      satLayers: state.satLayers.includes(action.layerId) ?
        state.satLayers.filter(l => l !== action.layerId)
        : [...state.satLayers, action.layerId]
    };

  case 'add-markers':
    return {
      ...state,
      selectedMarkers: _.uniq([...state.selectedMarkers, action.id])
    };

  case 'toggle-markers':
    let newSelectedMarkers;
    if ('WEATHER' === action.id) {
      newSelectedMarkers = state.selectedMarkers.includes(action.id) ? state.selectedMarkers.filter(l => l !== action.id)
        : [...state.selectedMarkers.filter(l => l !== 'WIND_PM'), action.id];
    } else if ('WIND_PM' === action.id) {
      newSelectedMarkers = state.selectedMarkers.includes(action.id) ? state.selectedMarkers.filter(l => l !== action.id)
      : [...state.selectedMarkers.filter(l => l !== 'WEATHER'), action.id];
    } else {
      newSelectedMarkers = state.selectedMarkers.includes(action.id) ?
        state.selectedMarkers.filter(l => l !== action.id)
        : [...state.selectedMarkers, action.id];
    }
    return {
      ...state,
      selectedMarkers: newSelectedMarkers,
      selectedDevice: null
    };

  case 'set-loading':
    return {
      ...state,
      loading: action.loading
    };
  case 'set-error':
    return {
      ...state,
      error: action.error
    };
  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function MapContextProvider({children}) {
  const [state, dispatch] = useReducer(reducer, initialState());
  const userContext = useMemo(() => {
    return {
      state,
      dispatch
    };
  }, [state]);

  return <MapContext.Provider value={userContext}>
    {children}
  </MapContext.Provider>;
}

MapContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};
