import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {useTranslation} from 'react-i18next';
import {useMatch, useNavigate} from 'react-router-dom';
import Flag from 'react-world-flags';
// import './LanguagePicker.css';


const LanguagePicker = () => {
  const {i18n} = useTranslation();
  const relPath = useMatch(':lang/*')?.params['*'];
  const navigate = useNavigate();

  const handleLangChange = (event) => {
    const targetLang = event.target.value;
    navigate(`/${targetLang}/${relPath}`);
  };

  return <>
    <FormControl>
      <Select
        sx={{textAlign: 'center', margin: '1em 0 0 1em', '@media screen and (max-width: 66.5em)': {display: 'none'}}}
        IconComponent={() => null}
        disableUnderline
        value={i18n.resolvedLanguage}
        onChange={handleLangChange}
        variant={'standard'}
      >
        <MenuItem value={'en'}>{<Flag width={20} code={'gb'}/>}</MenuItem>
        <MenuItem value={'pl'}>{<Flag width={20} code={'pl'}/>}</MenuItem>
        <MenuItem value={'lv'}>{<Flag width={20} code={'lv'}/>}</MenuItem>
        {/*<MenuItem value={'es'}>{<Flag width={20} code={'es'}/>}</MenuItem>*/}
        {/*<MenuItem value={'fr'}>{<Flag width={20} code={'fr'}/>}</MenuItem>*/}
      </Select>
    </FormControl>
  </>;
};

export default LanguagePicker;
