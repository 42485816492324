import Box from '@mui/material/Box';
import {IconButton, Typography} from '@mui/material';
import {Archive, FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from '@mui/icons-material';
import {dangerColor, grayColor, primaryColor, successColor} from '../../assets/jss/nextjs-material-dashboard';
import React from 'react';
import {REGION} from '../../aws-exports';
import Button from '@mui/material/Button';


export const BUCKET = 'yeti-reports';

const makeS3Url = (path, clientId) => `https://s3.${REGION}.amazonaws.com/${BUCKET}/${clientId}${path}`;

export const getFilename = path => path.substring(path.lastIndexOf('/') + 1);

const buttons = (link, clientId) => {
  return <a href={makeS3Url(link, clientId)}>
    <Button
      simple
      color={'success'}
    >
      <Archive/>
    </Button>
  </a>;
};

export const createTableData = (links, clientId) => {
  return links.map((s3link, idx) => [
    idx + 1,
    <a
      style={{color: '#3C4858'}}
      href={makeS3Url(s3link, clientId)}
      key={s3link}
    >
      {' '}
      {getFilename(s3link)}{' '}
    </a>,
    buttons(s3link, clientId)
  ]);
};


// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function TablePaginationActions(props) {
  const {count, page, rowsPerPage, onPageChange} = props;

  const handleFirstPageButtonClick = (
    event,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{flexShrink: 0, ml: 2.5}}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage/>
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft/>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight/>
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage/>
      </IconButton>
    </Box>
  );
}

export const THeader = ({children}) => <Typography sx={{color: primaryColor[2]}}>
  {children}
</Typography>;

export const TCell = ({children}) => <Typography
  color={['ARRIVAL', 'WPŁYNIĘCIE'].includes(children) ? successColor[0] : ['DEPARTURE', 'WYPŁYNIĘCIE'].includes(children) ? dangerColor[0] : grayColor[1]}>
  {children}
</Typography>;
