import React, {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Header from '../components/Header/Header';
import {useConfig} from '../context/clientConfig/clientConfigContext';
import './Risk/windy-custom.css';
import {Search} from '@mui/icons-material';
import {MAPBOX_TOKEN} from '../config';
import RiskLegend from './Risk/RiskLegend';
import {useRiskContext} from '../context/risk/riskContext';
import {fetchRiskMapData} from '../api';
import {useMeasurementsContext} from '../context/measurements/measurementsContext';
import DatePicker, {registerLocale} from 'react-datepicker';
import {formatDate, RISK_COLOR} from '../utils';
import RiskSideBar from './Risk/RiskSideBar';
import {OIL_IN_WATER_DATA} from './Risk/OilInWaterData';
import {useTranslation} from 'react-i18next';
import RiskPureHtmlPopup from './Risk/RiskPureHtmlPopup';
import lv from 'date-fns/locale/lv';
import pl from 'date-fns/locale/pl';

registerLocale('lv', lv);
registerLocale('pl', pl);

const WINDY_JS_API_KEY = 'ysO9H6MEdnkklyZG2AV6bsKxJr0TNdNp';

export default function Risk() {
  const {config} = useConfig().state;
  const {t, i18n} = useTranslation();
  const {clientId, modules, uiSettings} = config ? config : {};
  const [picker, setPicker] = useState();
  const {state: {riskLayers}} = useRiskContext();
  const [leafletMap, setLeafletMap] = useState();
  const [windyStore, setWindyStore] = useState();
  const [oilSimulationLayers, setOilSimulationLayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pickerVisible, setPickerVisible] = useState(true);
  const {state: {riskAreas, riskSliderValue, riskCalculation}, dispatch} = useRiskContext();
  const {state: {selectedDate}, dispatch: measurementsDispatch} = useMeasurementsContext();
  const labels = t('risk.riskLevelLabels', {returnObjects: true});
  const currentAddedRiskAreas = useRef([]);

  const openPicker = () => {
    picker.open({lat: uiSettings.mapCenter[1], lon: uiSettings.mapCenter[0]});
    setPickerVisible(true);
  }

  useEffect(() => {
    console.log("CHANGE LANGUAGE");
    if (windyStore && i18n){
      windyStore.set('lang', i18n.language === 'lv' ? 'en': i18n.language );
    }
  }, [windyStore, i18n.language])

  // ON RISK LAYER CHANGE
  useEffect(() => {
    if (windyStore) {
      if(riskLayers.length > 0) {
        windyStore.set('overlay', 'currents');
        picker.open({lat: uiSettings.mapCenter[1]+0.2, lon: uiSettings.mapCenter[0]+0.2});
      } else {
        windyStore.set('overlay', 'wind');
      }
    }
  }, [riskLayers, windyStore, picker, uiSettings, leafletMap]);

  // FETCH RISK DATA
  useEffect(() => {
    setLoading(true);
    setError(null);
    fetchRiskMapData(clientId, selectedDate ? formatDate(selectedDate): undefined)
      .then(result =>  dispatch({type: 'set-risk-areas', riskAreas: result, clientId}))
      .catch(err => setError(err.response.data))
      .finally(() => setLoading(false))
    ;
  } , [clientId, selectedDate]);

  // Add Oil Spill Simulation layers to the map
  useEffect(() => {
    if (!leafletMap) return;

    let numberOfLayersToShow = riskSliderValue % oilSimulationLayers.length;
    const reverse = riskSliderValue >= oilSimulationLayers.length;
    numberOfLayersToShow = reverse ? (oilSimulationLayers.length - numberOfLayersToShow) : numberOfLayersToShow;

    for (let i = 0; i < oilSimulationLayers.length; i++) {leafletMap.removeLayer(oilSimulationLayers[i]);}

    if(riskLayers.includes('RISK_OIL')){
      for (let i = 0; i < oilSimulationLayers.length; i++) {
        const l = oilSimulationLayers[i];
        leafletMap.removeLayer(l);
        if (i <= numberOfLayersToShow) {
          l.addTo(leafletMap);
        }
      }
    }

  }, [oilSimulationLayers, riskSliderValue, leafletMap, riskLayers ]);

  // Add Risk Areas as Geojson layer to the map
  useEffect(() => {
    if (riskAreas.length === 0 || !leafletMap) return;
    // DODAWANIE GEOJSONA DO MAPY
    var myStyle = {
      "color": "black",
      "weight": 1,
      "opacity": 0.65
    };
    // Remove previous layers
    currentAddedRiskAreas.current.forEach(riskAreaLayer => leafletMap.removeLayer(riskAreaLayer));

    const areas = [];
    riskAreas.forEach(rArea => {
      const l = L.geoJSON({type: "Feature", geometry: rArea.geometry, properties: rArea}, {
        style: () => ({...myStyle, color: RISK_COLOR[rArea.values[riskSliderValue]?.risk]}),
        onEachFeature: (feature, layer) => onEachFeature(feature, layer, riskSliderValue)
      });
      l.addTo(leafletMap);
      areas.push(l);
    })
    currentAddedRiskAreas.current = areas;
  }, [clientId, riskAreas, leafletMap, riskSliderValue]);

  function onEachFeature(feature, layer, riskSliderValue) {
    const {values} = feature.properties;
    const riskValue = values[riskSliderValue]?.risk;
    layer.bindPopup(RiskPureHtmlPopup(riskSliderValue, feature.properties, labels[riskValue], t));
  }

  // CONFIG AND CREATE WINDY MAP
  useEffect(() => {
    if (!uiSettings) {
      return;
    }

    const options = {
      // Required: API key
      key: WINDY_JS_API_KEY,
      // Put additional console output
      verbose: false,
      expertMode: false,
      // acTime: 'next3d', // 'next12h', 'next24h', 'next3d', 'next5d', 'next10d'
      // visibleProducts: ['gfs', 'ecmwf'],
      latlon: true, // Display lat/lon values on the weather picker
      lat: uiSettings.mapCenter[1] + (clientId === 'mbm' ? 0.2 : 0.0), // 54.390069,
      lon: uiSettings.mapCenter[0] + (clientId === 'mbm' ? 0.2 : 0.0), // 18.663377,
      zoom: uiSettings.mapZoom
    };
    // Initialize Windy API
    windyInit(options, windyAPI => {
      // windyAPI is ready, and contain 'map', 'store', 'picker' and other usefull stuff
      const {map, picker, utils, broadcast, store} = windyAPI;
      setPicker(picker);
      setLeafletMap(map);
      setWindyStore(store);
      store.set('metric_wind', 'm/s');
      map.options.minZoom = 4;
      map.options.maxZoom = 18;
      map.setView([options.lat, options.lon], options.zoom + 1);
      // store.getAll();
      const timePath = store.get('path'); // format YYYYMMDDHH
      const sliderValue = parseInt(timePath.slice(8)) % 24;
      dispatch({type: 'set-riskSliderValue', riskSliderValue: sliderValue, clientId: config.clientId})
      const gl = L.mapboxGL({
        accessToken: MAPBOX_TOKEN,
        style: 'mapbox://styles/mapbox/light-v10'
      }).addTo(map);
      // TODO ustaw visibility tej mapy tak zeby nie zakrywała Satelite

      if(config.clientId === 'mbm') {
        const oilLayers = [];
        // AD OIL in WATER SIMULATION DATA
        OIL_IN_WATER_DATA.forEach(data => {
          const oilLayer = L.polygon(data, {
            smoothFactor: 0.10,
            color: 'black',
            weight: 0.5,
            opacity: 0.1
          });
          oilLayers.push(oilLayer);
          setOilSimulationLayers(oilLayers);
        });
      }

      // Once you have created the leaflet layer, the mapbox-gl map object can be accessed using
      // console.log({mapBoxGL: gl.getMapboxMap()});
      // add a source to the mapbox-gl layer
      // gl.getMapboxMap().addSource({...})
      // gl.getMapboxMap().addLayer({...})

      // Wywoływane gdy zmieni się godzina -> rysowanie markerow urządzeń i pobranie danych
      store.on('path', function (timePath) {
        // console.log('ON Path', {timePath}); // format YYYYMMDDHH
        const sliderValue = parseInt(timePath.slice(8)) % 24;
        dispatch({type: 'set-riskSliderValue', riskSliderValue: sliderValue})
      });

      // const reactString = '<div>Hello, world!</div>';
      // const htmlString = React.createElement('div', { dangerouslySetInnerHTML: { __html: reactString } }));
      // L.popup()
      //   .setLatLng([50.4, 14.3])
      //   .setContent(ReactDOMServer.renderToString(<div>Hello World</div>))
      //   .openOn(map);

      // picker.on('pickerOpened', ({lat, lon, values, overlay}) => {
      //   // console.log('opened', lat, lon, values, overlay);
      //   const windObject = utils.wind2obj(values);
      //   console.log(windObject);
      // });

      // picker.on('pickerMoved', ({lat, lon, values, overlay}) => {
        // picker was dragged by user to latLon coords
        // console.log('moved', lat, lon, values, overlay);
      // });

      picker.on('pickerClosed', () => {
        // picker was closed
        setPickerVisible(false);
      });

      // store.on('pickerLocation', ({lat, lon}) => {
      //   const {values, overlay} = picker.getParams();
      // });

      // Wait since wather is rendered
      broadcast.once('redrawFinished', () => {
        // Opening of a picker (async)
        picker.open({lat: uiSettings.mapCenter[1], lon: uiSettings.mapCenter[0]});
        // map.options.zoom = 16;
        // map.zoom = 16;
        // map.setAttribute('zoom', 16);
      });

    });
  }, [config, i18n]);

  return <>
    <Header/>
    {/* DATE PICKER */}
    <Box sx={{
      position: 'absolute',
      top: 95,
      right: 100,
      zIndex: 999,
      '@media screen and (max-width: 55em)': {display: 'none'}
    }}>
      <DatePicker
        locale={i18n.resolvedLanguage}
        selected={selectedDate}
        onChange={date => measurementsDispatch({type: 'set-selectedDate', selectedDate: date})}
      />
    </Box>
    <RiskSideBar dark={'false'}/>
    {/* WEATHER PICKER */}
    {!pickerVisible && <Box
      onClick={openPicker}
      sx={{
        position: 'absolute',
        bottom: 50,
        right: 10,
        zIndex: 999,
        px: 1,
        pt: 1,
        borderRadius: '50%',
        color: 'white',
        backgroundColor: 'rgba(68,65,65,0.84)',
        cursor: 'pointer',
      }}>
      <Search color={'white'}/>
    </Box>}

    {/* LEGEND */}
    <Box
      sx={{
        position: 'absolute',
        top: 95,
        '@media screen and (max-width: 50em)': {
          top: 65,
        },
        left: 'calc(50vw - 152px)',
        zIndex: 999
      }}>
      <RiskLegend onWater={clientId === 'mbm'}/>
    </Box>

    {/* WINDY MAP */}
    <Box id="windy" sx={{
      height: 'calc(100vh - 88px)',
      '@media screen and (max-width: 50em)': {
        height: 'calc(100vh - 60px)'
      }
    }}>
    </Box>
  </>;
}
