import React, {useEffect, useState} from 'react';
import {Chip, Slider, Tooltip, Typography, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import {MOBILE_SCREEN, primaryColor} from '../../assets/jss/nextjs-material-dashboard';
import PauseIcon from '@mui/icons-material/Pause';
import {useTranslation} from 'react-i18next';
import {Done, History, Settings, Speed} from '@mui/icons-material';
import {usePlaybackContext, VESSEL_LABELS} from '../../context/marineTrafficPlayback/playbackContext';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pl';
import 'moment/locale/fr';

const ONE_MINUTES_STEP = 60 * 1000;


export default function PlaybackSlider() {
  const {t, i18n} = useTranslation();
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);
  moment.locale(i18n.language);
  const {
    state: {reversed, speed, timestamp, dateFrom, dateTo, state, visibleLabels},
    dispatch: dispatch
  } = usePlaybackContext();
  const [showSpeed, setShowSpeed] = useState(false);

  const showConfigWindow = () => dispatch({type: 'set-state', state: 'CONFIG'});

  const onReverse = () => {
    dispatch({type: 'set-reversed', reversed: !reversed});
  };

  const labelText = (value) => {
    const date = moment.unix(value / 1000).format('LLLL');
    return <span>{date}</span>;
  };

  const onPlay = () => {
    dispatch({type: 'set-state', state: 'PLAYING'});
  };

  const onPause = () => {
    dispatch({type: 'set-state', state: 'PAUSED'});
  };


  const isPlaying = state === 'PLAYING';

  const onSliderChange = (event, newValue) => {
    onPause();
    dispatch({type: 'set-timestamp', timestamp: newValue});
  };

  const onSpeedSliderChange = (event, speed) => {
    dispatch({type: 'set-speed', speed});
  };

  const toggleVesselLabel = label => {
    if (visibleLabels.includes(label)) {
      dispatch({type: 'set-visibleLabels', visibleLabels: visibleLabels.filter(l => l !== label)});
    } else {
      dispatch({type: 'set-visibleLabels', visibleLabels: [label, ...visibleLabels]});
    }
  };

  // console.log({vessels})

  useEffect(() => {
    if (state === 'PLAYING') {
      setTimeout(() => {
        // fetchVesselsPosition(value + 1);
        dispatch({type: 'set-timestamp', timestamp: timestamp + (reversed ? -1 : 1) * ONE_MINUTES_STEP});
      }, 1000 / speed);
    } else {
      console.log('PAUSED');
    }
  }, [timestamp, state, speed, reversed]);

  return (
    <Box sx={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '90px',
      padding: '0.5em 1.6em',
      background: `linear-gradient(to top, rgba(25, 34, 49,.2), rgba(25, 34, 49, 0))`,
      display: 'flex',
      zIndex: 3
      // justifyContent: 'space-between'
    }}>

      <Box mr={1}>
        <Box sx={{
          cursor: 'pointer',
          width: 40,
          height: 40,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: primaryColor[2], backgroundColor: 'white',
          borderRadius: '50%',
        }}
             onClick={isPlaying ? onPause : onPlay}>
          {isPlaying ? <Tooltip placement={'top'} title={t('playback.pause')}><PauseIcon/></Tooltip> :
            <Tooltip placement={'top'} title={t('playback.play')}><PlayArrowRoundedIcon/></Tooltip>}
        </Box>
      </Box>
      <Box>
        <Tooltip placement={'top'} title={t('playback.reverse')}>
          <History
            onClick={onReverse}
            sx={{
              cursor: 'pointer',
              mr: 1,
              width: 40, height: 40,
              color: reversed ? 'white' : primaryColor[2],
              backgroundColor: reversed ? primaryColor[2] : 'white',
              borderRadius: '50%', padding: 0.6
            }}/></Tooltip>
      </Box>

      <Box>
        {showSpeed && <Box
          py={1.5}
          sx={{backgroundColor: 'rgba(255,255,255, 0.7)'}} borderRadius={5} height={100} marginLeft={0.5}
          marginBottom={5.5} position={'absolute'} bottom={40}>
          <Slider
            max={100}
            min={1}
            size={'small'}
            valueLabelFormat={x => 'x' + x}
            aria-label="Speed"
            orientation="vertical"
            onChange={onSpeedSliderChange}
            valueLabelDisplay="auto"
            defaultValue={10}
          />
        </Box>}

        <Tooltip placement={'top'} title={t('playback.speed')}>
          <Speed
            onClick={() => setShowSpeed(!showSpeed)}
            sx={{
              cursor: 'pointer',
              mr: 3,
              width: 40, height: 40,
              color: showSpeed ? 'white' : primaryColor[2],
              backgroundColor: showSpeed ? primaryColor[2] : 'white',
              borderRadius: '50%', padding: 0.6
            }}/>
        </Tooltip>
        <Typography sx={{pl: 0.5}} color={'primary'} fontWeight={'bold'}>x{speed}</Typography>
      </Box>
      <Box width={'100%'} position={'absolute'} left={200} bottom={30} display={'flex'} gap={4}>
        <Box width={300} position={isMobile && 'absolute'} bottom={isMobile ? -12 : 5} left={-8}>
          <Typography color={'black'} fontWeight={'bold'} sx={{fontSize: '15px'}}
                      variant={'subtitle1'}>{moment.unix(timestamp / 1000).format(isMobile ? 'LLL' : 'LLLL')}</Typography>
        </Box>
        <Box display={'flex'}
             position={isMobile ? 'absolute' : 'relative'}
             gap={1}
             flexDirection={isMobile ? 'column' : 'row'}
             justifySelf={'center'}
             bottom={isMobile ? 70 : 0}
             left={isMobile ? -175 : 0}
        >
          {VESSEL_LABELS.map(label =>
            <Chip
              key={label}
              size={'small'}
              onClick={() => toggleVesselLabel(label)}
              color={'info'}
              variant={visibleLabels.includes(label) ? 'filled' : 'outlined'}
              icon={visibleLabels.includes(label) ? <Done/> : null}
              label={t('playback.' + label)}
            />)}
        </Box>
      </Box>
      <Slider
        sx={{width: '90%'}}
        value={timestamp}
        valueLabelFormat={labelText}
        aria-label={'Vessels Replay Slider'}
        valueLabelDisplay={'auto'}
        onChange={onSliderChange}
        min={dateFrom.valueOf()}
        max={dateTo.valueOf()}
        step={ONE_MINUTES_STEP}
      >
      </Slider>
      <Box zIndex={isMobile ? 0 : 100}>
        <Tooltip placement={'top'} title={t('playback.settings')}><Settings
          onClick={showConfigWindow}
          sx={{
            cursor: 'pointer',
            width: 40, height: 40, marginLeft: '1em', color: primaryColor[2], backgroundColor: 'white',
            borderRadius: '50%', padding: 0.8
          }}/>
        </Tooltip>
      </Box>
    </Box>
  );
}
