import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Agriculture,
  Air,
  BorderStyle,
  BubbleChart,
  GpsFixed,
  Grain,
  Grass,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Landslide,
  Layers,
  LayersOutlined,
  LocationOn,
  Map,
  Nature,
  NewReleases,
  OilBarrel,
  Polyline,
  PrecisionManufacturing,
  SatelliteAlt,
  Straighten,
  VolumeUp,
  Warehouse,
  Water,
  WbSunny
} from '@mui/icons-material';
import Button from '@mui/material/Button';
import {primaryColor, warningColor, whiteColor} from '../../assets/jss/nextjs-material-dashboard';
import _ from 'lodash';
import {useSurveysContext} from '../../context/surveys/surveysContext';
import {useTranslation} from 'react-i18next';
import {useSatelliteDataContext} from '../../context/sateliteData/satelliteDataContext';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {useILayersContext} from '../../context/iLayers/iLayersContext';
import {useMapContext} from '../../context/map/mapContext';
import {Tooltip} from '@mui/material';
import {AGRO_PARAMS, AgroGroupIcons, AgroIcons, METEO_PARAMS} from '../measurementsUtil';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';

const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
  return p.toString() === '[object SafariRemoteNotification]';
})(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
const bgColor = {false: 'rgba(6,47,79,0.5)', true: 'rgba(0,125,193,0.5)'};
const txtColor = {false: whiteColor, true: whiteColor};
const iconStyle = (selected) => ({
  padding: 0.5,
  borderRadius: 10,
  position: 'absolute',
  left: '-8px',
  top: 0,
  width: 30,
  height: 30,
  backgroundColor: selected ? warningColor[2] : 'white',
  color: selected ? 'white' : primaryColor[2],
  boxShadow: isSafari ? 'none' : '0 5px 20px rgba(0, 0, 0, .25)',
});


const icons = (selected) => ({
  'GPS_TRACE': <GpsFixed sx={iconStyle(selected)}/>,
  'RISK_OIL': <OilBarrel sx={iconStyle(selected)}/>,
  'RISK_SAND': <Grain sx={iconStyle(selected)}/>,
  'RISK_SHORE': <Landslide sx={iconStyle(selected)}/>,

  'AIR': <BubbleChart sx={iconStyle(selected)}/>,
  'NOISE': <VolumeUp sx={iconStyle(selected)}/>,
  //Agriculture, Grass
  'AGRO': <Agriculture sx={iconStyle(selected)}/>,
  'METEO': <WbSunny sx={iconStyle(selected)}/>,
  'WIND_PM': <BubbleChart sx={iconStyle(selected)}/>,
  'WEATHER': <Air sx={iconStyle(selected)}/>,
  'WATER': <Water sx={iconStyle(selected)}/>,
  'MARINE_TRAFFIC': <DirectionsBoatIcon sx={iconStyle(selected)}/>,
  'SURVEYS': <LayersOutlined sx={iconStyle(selected)}/>,
  'GIS': <Map sx={iconStyle(selected)}/>,
  'SATELLITE': <SatelliteAlt sx={iconStyle(selected)}/>,

  'Mapa hydrograficzna': <Map sx={iconStyle(selected)}/>,
  'Ewidencja gruntów': <Layers sx={iconStyle(selected)}/>,
  'Ortofotomapa': <Map sx={iconStyle(selected)}/>,
  // "fr hydro": <Public sx={iconStyle}/>,
  // "FR Parcel": <Public sx={iconStyle}/>,
  // "FR Orto": <Public sx={iconStyle}/>,

  'BORDER POINT': <LocationOn sx={iconStyle(selected)}/>,
  'ZONE': <Polyline sx={iconStyle(selected)}/>,
  'KM': <Straighten sx={iconStyle(selected)}/>,
  'DOCK': <PrecisionManufacturing sx={iconStyle(selected)}/>,
  'BUILDING': <Warehouse sx={iconStyle(selected)}/>,
  'BORDER': <BorderStyle sx={iconStyle(selected)}/>,

  'Batymetria': <SatelliteAlt sx={iconStyle(selected)}/>,
  'Mętność wody': <SatelliteAlt sx={iconStyle(selected)}/>,

  // 'GIS': <Public sx={iconStyle}/>,
  // 'MEASUREMENTS': <BubbleChart sx={iconStyle}/>,
  // 'SATELLITE': <SatelliteAlt sx={iconStyle}/>,
  // 'WMS': <Layers sx={iconStyle}/>,
  // "fr hydro": <Public sx={iconStyle}/>,
  // "FR Parcel": <Public sx={iconStyle}/>,
  // "FR Orto": <Public sx={iconStyle}/>,
});

function TextIcon({text, selected}) {
  return (
    <Box sx={iconStyle(selected)}>
      <Typography fontWeight={'bold'} color={selected ? 'white' : primaryColor[2]}>{text}</Typography>
    </Box>
  );
}


export default function MapSideBar({dark}) {
  const {email, clientId, config: {modules}} = useConfig().state;
  const {state: {currentAgroParam, selectedMarkers, gisLayers, wmsLayers, selectedLayers}, dispatch: mapDispatch} = useMapContext();
  const {state: {surveysByParam, currentParam}, dispatch: surveysDispatch} = useSurveysContext();
  const {state: {currentSatelliteParam}, dispatch: satelliteDataDispatch} = useSatelliteDataContext();
  const [toggleSurveys, setToggleSurveys] = useState(false);
  const [toggleGIS, setToggleGIS] = useState(false);
  const [toggleSatellite, setToggleSatellite] = useState(false);
  const {t} = useTranslation();
  const {state: {iLayersActive}, dispatch: iLayersDispatch} = useILayersContext();

  const toggleMarkers = id => mapDispatch({type: 'toggle-markers', id});
  const toggleLayer = layerId => mapDispatch({type: 'toggle-layer', layerId});
  const toggleSatelliteLayer = layerId => mapDispatch({type: 'toggle-satLayer', layerId});
  const selectSurvey = parameter => surveysDispatch({type: 'set-current-survey-param', surveyParam: parameter});
  const toggleAgroParam = parameter => mapDispatch({type: 'toggle-current-agro-param', agroParam: parameter});

  const selectSatData = parameter => {
    toggleSatelliteLayer(parameter);
    satelliteDataDispatch({type: 'set-current-satellite-param', satelliteParam: parameter});
  };

  const gisItems = gisLayers
    .map(({layerId, label}) => <Box key={layerId} display="flex" flexDirection={'column'}>
        <MenuItem
          inset
          key={layerId}
          layerId={layerId}
          label={t('mapSideBar.' + layerId, label)}
          onClick={() => toggleLayer(layerId)}
          dark={dark}
          selected={selectedLayers.includes(layerId)}/>
      </Box>
    );

  const satelliteItems = wmsLayers
    .map(({layerName, layers}) => <Box key={layerName} display="flex" flexDirection={'column'}>
      <MenuItem
        inset
        key={layerName}
        layerId={layerName}
        label={layerName}
        onClick={() => toggleLayer(layerName)}
        dark={dark}
        selected={selectedLayers.includes(layerName)}/>
    </Box>);

  // TURBIDITY is DISABLED - NO coral.bgeo.es server
  // const turbidityMenuItem = toggleSatellite && <SurveyMenuItem
  //   paramName={t('mapSideBar.turbidity')}
  //   key={'turbidity'}
  //   no={3}
  //   onClick={() => selectSatData('turbidity')}
  //   dark={dark}
  //   selected={currentSatelliteParam === 'turbidity'}
  // />;


  const surveysItems = _.map(surveysByParam, (v, parameter) => <SurveyMenuItem
    paramName={t('mapSideBar.' + parameter, parameter)}
    key={parameter}
    no={v.length}
    onClick={() => selectSurvey(parameter)}
    dark={dark}
    selected={currentParam === parameter}
  />);

  const agroItems = selectedMarkers.includes('AGRO') ? AGRO_PARAMS.map(parameter => <AgroMenuItem
    paramName={t('measurements.parameters.' + parameter, parameter)}
    param={parameter}
    key={parameter}
    onClick={() => toggleAgroParam(parameter)}
    dark={dark}
    selected={currentAgroParam === parameter}
  />) : [];

  const meteoItems = selectedMarkers.includes('METEO') ? METEO_PARAMS.map(parameter => <MeteoMenuItem
    paramName={t('measurements.parameters.' + parameter, parameter)}
    param={parameter}
    key={parameter}
    onClick={() => toggleAgroParam(parameter)}
    dark={dark}
    selected={currentAgroParam === parameter}
  />) : [];


  return <Box p={2} sx={{
    overflow: 'visible',
    borderRadius: 5,
    position: 'absolute',
    left: 5,
    top: '10px',
    '@media screen and (max-width: 45em)': {display: 'none'}
  }}>

    {modules.AGRO && <MenuItem
      layerId={'AGRO'}
      label={t('mapSideBar.agriculture')}
      onClick={() => toggleMarkers('AGRO')}
      dark={dark}
      selected={selectedMarkers.includes('AGRO')}/>}
    {agroItems}

    {modules.AGRO && <MenuItem
      layerId={'METEO'}
      label={t('mapSideBar.meteo')}
      onClick={() => toggleMarkers('METEO')}
      dark={dark}
      selected={selectedMarkers.includes('METEO')}/>}
    {meteoItems}

    {modules.WATER ? <MenuItem
      layerId={'WATER'}
      label={t('mapSideBar.waterQuality')}
      onClick={() => toggleMarkers('WATER')}
      dark={dark}
      selected={selectedMarkers.includes('WATER')}/> : <Box sx={{width:130, height: 32}}/>}

    {clientId === "anw" ? <MenuItem
      layerId={'GPS_TRACE'}
      label={t('mapSideBar.gpsTrace')}
      onClick={() => toggleMarkers('GPS_TRACE')}
      dark={dark}
      selected={selectedMarkers.includes('GPS_TRACE')}/> : <Box sx={{width:130, height: 32}}/>}

    {modules.MARINE_TRAFFIC ? <MenuItem
      layerId={'MARINE_TRAFFIC'}
      label={t('mapSideBar.marineTraffic')}
      onClick={() => toggleMarkers('MARINE_TRAFFIC')}
      dark={dark}
      selected={selectedMarkers.includes('MARINE_TRAFFIC')}/> : <Box sx={{width:130, height: 32}}/>}

    {modules.AIR ? <MenuItem
      layerId={'AIR'}
      label={t('mapSideBar.airQuality')}
      onClick={() => toggleMarkers('AIR')}
      dark={dark}
      selected={selectedMarkers.includes('AIR')}/> : <Box sx={{width:130, height: 32}}/>}

    {modules.NOISE ? <MenuItem
      layerId={'NOISE'}
      label={t('mapSideBar.noise')}
      onClick={() => toggleMarkers('NOISE')}
      dark={dark}
      selected={selectedMarkers.includes('NOISE')}/> : <Box sx={{width:130, height: 32}}/>}
    {modules.WEATHER ? <MenuItem
      layerId={'WEATHER'}
      label={t('mapSideBar.airDirection')}
      onClick={() => toggleMarkers('WEATHER')}
      dark={dark}
      selected={selectedMarkers.includes('WEATHER')}/> : <Box sx={{width:130, height: 32}}/>}

    {modules.WIND_PM ? <MenuItem
      layerId={'WIND_PM'}
      label={t('mapSideBar.airWithPm')}
      onClick={() => toggleMarkers('WIND_PM')}
      dark={dark}
      selected={selectedMarkers.includes('WIND_PM')}/> : <Box sx={{width:130, height: 32}}/>}

    {(modules.SURVEYS && email !== 'rafal.gluszewski@ug.edu.pl') ? <MenuItem
      layerId={'SURVEYS'}
      label={t('mapSideBar.droneMeasurements')}
      arrow
      expanded={toggleSurveys}
      onClick={() => setToggleSurveys(!toggleSurveys)}
      dark={dark}
    /> : <Box sx={{width:130, height: 32}}/>}
    {toggleSurveys ? surveysItems : []}
    {modules.GIS ? <MenuItem
      layerId={'GIS'}
      label={'GIS'}
      arrow
      expanded={toggleGIS}
      onClick={() => setToggleGIS(!toggleGIS)}
      dark={dark}
    /> : <Box sx={{width:130, height: 32}}/>}
    {toggleGIS && modules.ILAYERS && <Box display="flex" flexDirection={'column'}>
      <MenuItem
        inset
        // key={layerId}
        layerId={'iLayers'}
        label={t('mapSideBar.iLayers',)}
        onClick={() => iLayersDispatch({type: 'set-iLayersActive', iLayersActive: !iLayersActive})}
        dark={dark}
        selected={iLayersActive}/>
    </Box>}
    {toggleGIS ? gisItems : []}

    {modules.SATELLITE ?
    <MenuItem
      layerId={'SATELLITE'}
      label={t('mapSideBar.SATELLITE')}
      arrow
      expanded={toggleSatellite}
      onClick={() => setToggleSatellite(!toggleSatellite)}
      dark={dark}
    /> : <Box sx={{width:130, height: 32}}/>}
    {/*{toggleSatellite ? turbidityMenuItem : []}*/}
    {toggleSatellite ? satelliteItems : []}
  </Box>;
}

export const MenuItem = ({inset, layerId, label, onClick, dark, selected, isSafari, arrow, expanded}) => <Box mt={0.5}>
  <Button
    startIcon={icons(selected)[layerId] || <NewReleases sx={iconStyle(selected)}/>}
    endIcon={arrow && (expanded ? <KeyboardArrowUp/> : <KeyboardArrowDown/>)}
    key={layerId}
    onClick={onClick}
    sx={{
      textTransform: 'none',
      paddingRight: 2,
      paddingLeft: 3,
      marginBottom: 1,
      color: txtColor[dark],
      background: selected ? warningColor[2] : bgColor[dark],
      '&:hover': {
        backgroundColor: warningColor[0],
        color: dark ? 'black' : 'white'
      },
      marginLeft: inset ? 3 : 1,
      borderRadius: 10,
      boxShadow: isSafari ? 'none' : '0 5px 20px rgba(0, 0, 0, .25)',
    }}
    variant={'text'}>
    {["RISK_OIL","RISK_SAND", "RISK_SHORE"].includes(layerId) ? <Tooltip placement={'right'} title={layerId === 'RISK_OIL' ? "Symulacja wycieków oleju. Kliknij PLAY": "W fazie R&D"} >
      <Typography fontSize={'small'} fontWeight={'bold'} variant={'body2'}>{label}</Typography>
    </Tooltip> : <Typography fontSize={'small'} fontWeight={'bold'} variant={'body2'}>{label}</Typography>}

  </Button>
</Box>;

const SurveyMenuItem = ({paramName, no, onClick, dark, selected}) => <Box ml={2} mt={0.5}>
  <Button
    startIcon={<TextIcon text={no} selected={selected}/>}
    key={paramName}
    onClick={onClick}
    sx={{
      textTransform: 'none',
      paddingRight: 2,
      paddingLeft: 3,
      color: txtColor[dark],
      background: selected ? warningColor[2] : bgColor[dark],
      '&:hover': {
        backgroundColor: warningColor[2],
        color: dark ? 'black' : 'white'
      },
      marginLeft: 1,
      borderRadius: 10
    }}
    variant={'text'}>
    <Typography fontSize={'small'} fontWeight={'bold'} variant={'body2'}>{paramName}</Typography>
  </Button>
</Box>;


const AgroMenuItem = ({param, paramName, onClick, dark, selected}) => <Box ml={2} mt={0.5}>
  <Button
    startIcon={AgroGroupIcons[param] || <Grass/>}
    endIcon={AgroIcons[param] || <Nature/>}
    key={paramName}
    onClick={onClick}
    sx={{
      textTransform: 'none',
      paddingRight: 2,
      paddingLeft: 3,
      color: txtColor[dark],
      background: selected ? warningColor[2] : bgColor[dark],
      '&:hover': {
        backgroundColor: warningColor[2],
        color: dark ? 'black' : 'white'
      },
      marginLeft: 1,
      borderRadius: 10
    }}
    variant={'text'}>
    <Typography fontSize={'small'} fontWeight={'bold'} variant={'body2'}>{paramName}</Typography>
  </Button>
</Box>;


const MeteoMenuItem = ({param, paramName, onClick, dark, selected}) => <Box ml={2} mt={0.5}>
  <Button
    startIcon={AgroIcons[param] || <Nature/>}
    key={paramName}
    onClick={onClick}
    sx={{
      textTransform: 'none',
      paddingRight: 2,
      paddingLeft: 3,
      color: txtColor[dark],
      background: selected ? warningColor[2] : bgColor[dark],
      '&:hover': {
        backgroundColor: warningColor[2],
        color: dark ? 'black' : 'white'
      },
      marginLeft: 1,
      borderRadius: 10
    }}
    variant={'text'}>
    <Typography fontSize={'small'} fontWeight={'bold'} variant={'body2'}>{paramName}</Typography>
  </Button>
</Box>;
