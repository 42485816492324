import React, {useState} from 'react';
import Box from '@mui/material/Box';
import {Button, Typography} from '@mui/material';
import {DONE, NEW} from './MaintenanceStatus';
import moment from 'moment';
import {ExpandLess, ExpandMore, LocationOn} from '@mui/icons-material';
import {
  dangerColor,
  grayColor,
  primaryColor,
  successColor,
  warningColor
} from '../../assets/jss/nextjs-material-dashboard';

export default function DescriptionCell({status, description, notes, t}) {
  const [expand, setExpand] = useState(false);
  const {createdAt, createdBy, text} = description || {};

  return <Box display={'flex'} flexWrap={'wrap'} gap={1} flexDirection={'column'} alignItems={'flex-start'}
              justifyContent={'flex-start'}>
    <Typography fontWeight={'bold'}
                color={status === NEW ? warningColor[0] : (status === DONE ? grayColor[1] : successColor[0])}>
      {t('maintenance.statusLabels', {returnObjects: true})[status]}
    </Typography>
    {text && <>
      <Typography sx={{width: '100%', color: grayColor[1]}}>
        {text}
      </Typography>
      <Box>
        <Typography sx={{color: grayColor[0]}} variant={'caption'}>
          Dodana: {moment(createdAt).format('lll')},
        </Typography>
        <Typography sx={{marginLeft: 1, color: grayColor[0]}} variant={'caption'}>
          Przez:{' '}</Typography>
        <Typography sx={{color: primaryColor[2]}} variant={'caption'}>
          {createdBy}
        </Typography>
      </Box>

    </>}

    {notes && <Box width={'100%'}>
      <Button
        sx={{textDecoration: !expand ? 'underline' : 'none', mb: 2}}
        // size={'small'}
        variant={'text'}
        onClick={() => setExpand(!expand)}>
        <Typography variant={'button'} color={primaryColor[2]}>Notatki ({notes.length}):</Typography>
        {expand ? <ExpandLess/> : <ExpandMore/>}
      </Button>

      {expand && notes.map(({createdAt, createdBy, text, imgUrl, private: isPrivate}, idx) =>
        <Box key={idx} width={'100%'} display={'flex'} flexDirection={'column'}
             borderBottom={'1px solid ' + grayColor[3]} mb={3}>
          <Typography variant={'caption'}
                      color={isPrivate ? dangerColor[2] : primaryColor[2]}>Nr {idx + 1}. {isPrivate ? ' - Notatka Poufna' : ''}</Typography>
          <Box>
            <Typography color={grayColor[1]}>{text}</Typography>
            {imgUrl && <NotePhoto idx={idx} imgUrl={imgUrl}/>}
          </Box>

          <Box display={'flex'} gap={1}>
            <Typography color={grayColor[1]} fontSize={10}
                        variant={'caption'}>Dodana: {createdAt.slice(0, 16).replace('T', ', ')}</Typography>
            <Typography color={grayColor[1]} fontSize={10} variant={'caption'}>Przez: {createdBy}</Typography>
          </Box>
        </Box>
      )}
      {expand && <Button onClick={() => setExpand(false)}>Zwiń Notatki<ExpandLess/></Button>}

    </Box>}
  </Box>;
};

const NotePhoto = ({imgUrl}) => {
  const [expanded, setExpanded] = useState(false);
  const togglePhoto = () => setExpanded(!expanded);

  return <Box
    display={'flex'}
    flexDirection={'column'}
    alignItems={'flex-start'}
    onClick={togglePhoto}
    sx={{
      // position: 'relative',
      width: expanded ? '80%' : 100,
      height: expanded ? '80%' : 100,
      cursor: expanded ? 'zoom-out' : 'pointer',
      overflow: expanded ? 'visible' : 'hidden',
    }}>
    <img alt={'device Photo'}
         style={{
           height: '100%',
           width: '100%',
           borderRadius: 5,
           border: ' 1px solid whitesmoke',
         }}
         src={imgUrl}
    />
  </Box>;
};
