import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useWindRoseContext} from '../../context/windRose/windRoseContext';
import {Marker} from '@urbica/react-map-gl';
import PolarChart from '../PolarChart/PolarChart';
import {MAPSTYLES} from '../../config';

const MAX_SIZE = 800; // Try with 800

export default function WindRoseMarker({device, mapStyleId, weatherStation}) {
  const {t} = useTranslation();
  const {state, dispatch} = useWindRoseContext();
  const {widRosesData, selectedStation, dateFrom, dateTo} = state;
  const {name, serialNo, location} = device;

  const dark = mapStyleId === MAPSTYLES[1].id;

  const data = useMemo(() => widRosesData[weatherStation], [widRosesData, weatherStation]);
  const length = useMemo(() => selectedStation === serialNo ? MAX_SIZE : 280, [selectedStation, serialNo]);
  const onMarkerClick = useCallback(() => {
    dispatch({type: 'toggle-selectedStation', serialNo});
  }, [serialNo]);

  if (data === undefined || data.length === 0) {
    return null;
  }

  return <Marker
    longitude={location[0]}
    latitude={location[1]}
    offset={[25, 25]}
  >
    <PolarChart
      dark={dark}
      exportChartTitle={`<div><br/>${t('windRose.station')}: <b>${name}</b><br/>${t('windRose.avgWind')} [m/s] <br/>${t('windRose.periodFrom')} ${dateFrom} ${t('windRose.to')} ${dateTo}</div>`}
      serialNo={serialNo}
      name={name}
      seriesName={t('windRose.windSpeed')}
      data={data}
      width={length}
      height={length}
      max={15}
      units={'m/s'}
      onClick={onMarkerClick}
    />
  </Marker>;
}
