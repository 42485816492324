import Box from '@mui/material/Box';
import React, {useState} from 'react';
import {CircularProgress, Typography} from '@mui/material';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {useTranslation} from 'react-i18next';
import AnalyticChart from '../AnalyticChart/AnalyticChart';
import {keyBy, map, mapValues} from 'lodash';
import {useAnalyticsContext} from '../../context/analytics/analyticsContext';
import {getAnalyticsData} from '../../api';
import DataSelectionForm, {iconByGroup, specificIcons} from '../DataSelectionForm/DataSelectionForm';
import {BlurOn, Thermostat} from '@mui/icons-material';


export default function DataAnalytics() {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isWeatherSelected, setIsWeatherSelected] = useState(false);
  const [error, setError] = useState('');
  const {state: {data}, dispatch: analyticsDispatch} = useAnalyticsContext();
  const {clientId, config: {devices}} = useConfig().state;

  const devicesNames = mapValues(keyBy(devices, 'serialNo'), 'name');
  const seriesData = (measurementData) => map(measurementData, (data, serialNo) => {
    return ({
      name: devicesNames[serialNo],
      data: data,
    });
  });

  const fetchData = (stations, selectedParams, dateFrom, dateTo) => {
    const now = new Date();
    const daysFrom = Math.ceil((dateFrom.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
    const daysFromTxt = (daysFrom > 0 ? '+' : '-') + Math.abs(daysFrom).toString() + 'd/d';
    const daysTo = Math.ceil((dateTo.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
    const daysToTxt = (daysTo > 0 ? '+' : '-') + Math.abs(daysTo).toString() + 'd/d';
    const serials = stations.map(({serialNo}) => serialNo);
    const interval = '1h';
    const measurements = selectedParams.map(p => p.value).join(',');
    setIsWeatherSelected(selectedParams.filter(({group}) => group === 'WEATHER').length > 0);
    setLoading(true);
    setError('');

    getAnalyticsData(clientId, measurements, serials, daysFromTxt, daysToTxt, interval)
      .then(result => analyticsDispatch({type: 'set-data', data: result}))
      .catch(err => setError(err.response.data))
      .finally(() => setLoading(false));
  };
  const literals = t('measurements.parameters', {returnObjects: true});

  return <DataSelectionForm
    actionHandler={fetchData}
    actionButtonLabel={t('measurements.fetchData')}
  >
    {error && <Typography color={'error'}>{error}</Typography>}

    {loading ? <CircularProgress size={100} sx={{marginLeft: '40%', marginTop: 10}}/> :
      <Box>
        {/*soilHumidity,soilTemp,soilSurfTemp,leafTemp,leafWetnessYMSD*/}
        {data.soilHumidity && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.agro') + ': ' + literals['soilHumidity']}
          units={'[%]'}
          seriesList={seriesData(data.soilHumidity)}
          weatherData={data}
          icon={iconByGroup['AGRO']}
        />}
        {data.soilTemp && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.agro') + ': ' + literals['soilTemp']}
          units={'[°C]'}
          seriesList={seriesData(data.soilTemp)}
          weatherData={data}
          icon={iconByGroup['AGRO']}
        />}
        {data.soilSurfTemp && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.agro') + ': ' + literals['soilSurfTemp']}
          units={'[%]'}
          seriesList={seriesData(data.soilSurfTemp)}
          weatherData={data}
          icon={iconByGroup['AGRO']}
        />}
        {data.leafTemp && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.agro') + ': ' + literals['leafTemp']}
          units={'[°C]'}
          seriesList={seriesData(data.leafTemp)}
          weatherData={data}
          icon={iconByGroup['AGRO']}
        />}
        {data.leafWetnessYMSD && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.agro') + ': ' + literals['leafWetnessYMSD']}
          units={'[%]'}
          seriesList={seriesData(data.leafWetnessYMSD)}
          weatherData={data}
          icon={iconByGroup['AGRO']}
        />}

        {data.PM100 && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.airQuality') + ': ' + t('measurements.parameters', {returnObjects: true})['PM100']}
          units={'[µg/m3]'}
          seriesList={seriesData(data.PM100)}
          weatherData={data}
          icon={iconByGroup['AIR']}
        />}

        {data.PM10 && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.airQuality') + ': ' + t('measurements.parameters', {returnObjects: true})['PM10']}
          units={'[µg/m3]'}
          seriesList={seriesData(data.PM10)}
          weatherData={data}
          icon={iconByGroup['AIR']}
        />}
        {data.PM25 && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.airQuality') + ': ' + t('measurements.parameters', {returnObjects: true})['PM25']}
          units={'[µg/m3]'}
          seriesList={seriesData(data.PM25)}
          weatherData={data}
          icon={iconByGroup['AIR']}
        />}

        {data.noise && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.noise') + ': ' + t('measurements.parameters', {returnObjects: true})['noise']}
          units={'[dBA]'}
          seriesList={seriesData(data.noise)}
          weatherData={data}
          icon={iconByGroup['NOISE']}
        />}

        {data.chlorophyll && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.waterQuality') + ': ' + t('measurements.parameters', {returnObjects: true})['chlorophyll']}
          units={'[µg/L]'}
          seriesList={seriesData(data.chlorophyll)}
          weatherData={data}
          icon={<BlurOn color={'success'}/>}
        />}

        {data.temp && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.waterQuality') + ': ' + t('measurements.parameters', {returnObjects: true})['temp']}
          units={'[°C]'}
          seriesList={seriesData(data.temp)}
          weatherData={data}
          icon={<Thermostat color={'error'}/>}
        />}

        {data.oil && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.waterQuality') + ': ' + t('measurements.parameters', {returnObjects: true})['oil']}
          units={'[ppm]'}
          seriesList={seriesData(data.oil)}
          weatherData={data}
          icon={specificIcons['oil']}
        />}

        {data.do && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.waterQuality') + ': ' + t('measurements.parameters', {returnObjects: true})['do']}
          units={'[mg/L]'}
          seriesList={seriesData(data.do)}
          weatherData={data}
          icon={specificIcons['do']}
        />}

        {data.o2 && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.waterQuality') + ': ' + t('measurements.parameters', {returnObjects: true})['o2']}
          units={'[%]'}
          seriesList={seriesData(data.o2)}
          weatherData={data}
          icon={specificIcons['o2']}
        />}

        {data.ph && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.waterQuality') + ': ' + t('measurements.parameters', {returnObjects: true})['ph']}
          units={''}
          seriesList={seriesData(data.ph)}
          weatherData={data}
          icon={specificIcons['ph']}
        />}

        {data.ec && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.waterQuality') + ': ' + t('measurements.parameters', {returnObjects: true})['ec']}
          units={'[µS/cm]'}
          seriesList={seriesData(data.ec)}
          weatherData={data}
          icon={specificIcons['ec']}
        />}

        {data.orp && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.waterQuality') + ': ' + t('measurements.parameters', {returnObjects: true})['orp']}
          units={'[mV]'}
          seriesList={seriesData(data.orp)}
          weatherData={data}
          icon={iconByGroup['WATER']}
        />}

        {data.turbidity && <AnalyticChart
          isWeather={isWeatherSelected}
          title={t('measurements.waterQuality') + ': ' + t('measurements.parameters', {returnObjects: true})['turbidity']}
          units={'[NTU]'}
          seriesList={seriesData(data.turbidity)}
          weatherData={data}
          icon={specificIcons['turbidity']}
        />}
      </Box>}
  </DataSelectionForm>;
}
