import React from 'react';
import {HashRouter, Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import Measurements from '../views/Measurements';
import PortManagement from '../views/PortManagement';
import {useTranslation} from 'react-i18next';
import DroneSurveys from '../views/DroneSurveys';
import Login from '../views/Login';
import NewMap from '../views/NewMap';
import Risk from '../views/Risk';
import Diagnostics from '../views/Diagnostics';
import Notifications from '../views/Notifications';
import ChangePassword from '../views/ChangePassword';
import Dashboards from '../views/Dashboards';
import OpenData from '../views/OpenData';
import MaintenanceTable from 'views/Maintenance/MaintenanceTable';

export const LangSetter = () => {
  const {i18n} = useTranslation();
  const {lang} = useParams();
  if (i18n.resolvedLanguage !== lang) {
    i18n.changeLanguage(lang);
  }
  return <Outlet/>;
};

const AppRoutes = () => {
  const {i18n} = useTranslation();
  return <HashRouter>
    <Routes>
      <Route path=":lang" element={<LangSetter/>}>
        <Route exact path="" element={<Navigate to="map"/>}/>
        <Route exact path="login" element={<Login/>}/>
        <Route exact path="change-passwd" element={<ChangePassword />}/>
        {/*<Route exact path="map" element={<PrivateRoute><Map/></PrivateRoute>}/>*/}
        <Route exact path="dashboards" element={<PrivateRoute><Dashboards/></PrivateRoute>}/>
        <Route exact path="map" element={<PrivateRoute><NewMap/></PrivateRoute>}/>
        <Route exact path="measurements" element={<PrivateRoute><Measurements/></PrivateRoute>}/>
        <Route exact path="open-data" element={<PrivateRoute><OpenData/></PrivateRoute>}/>
        <Route exact path="notifications" element={<PrivateRoute><Notifications/></PrivateRoute>}/>
        <Route exact path="risk" element={<PrivateRoute><Risk /></PrivateRoute>}/>
        <Route exact path="surveys" element={<PrivateRoute><DroneSurveys/></PrivateRoute>}/>
        <Route exact path="port-mgmt" element={<PrivateRoute><PortManagement/></PrivateRoute>}/>
        <Route exact path="diag" element={<PrivateRoute><Diagnostics/></PrivateRoute>}/>
        <Route exact path="maintenance" element={<PrivateRoute><MaintenanceTable/></PrivateRoute>}/>
        <Route path="*" element={<>404</>}/>
      </Route>
      <Route path="*" element={<Navigate to={i18n.resolvedLanguage}/>}/>
    </Routes>
  </HashRouter>;
};

export default AppRoutes;
