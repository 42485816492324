import React, {useCallback, useEffect, useMemo, useState} from 'react';

import 'mapbox-gl/dist/mapbox-gl.css';
import MapGL, {Layer, Marker, Source} from '@urbica/react-map-gl';
// import Cluster from '@urbica/react-map-gl-cluster';
import {getDockInfo, getMarineTraffic} from '../api';
import {useMarineTrafficContext} from '../context/marineTraffic/marineTrafficContext';
import {useConfig} from 'context/clientConfig/clientConfigContext';
import Box from '@mui/material/Box';
import {Chip, Drawer, Tooltip, Typography, useMediaQuery} from '@mui/material';
import Vessel from '../components/MarineTraffic/Vessel';
import VesselPopup from '../components/MarineTraffic/VesselPopup';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import DockPopup from '../components/PortManagement/DockPopup';
import {useDockMgmtContext} from '../context/dockMgmt/dockMgmtContext';
import DockInfoPopup from '../components/PortManagement/DockInfoPopup';
import Header from '../components/Header/Header';
import BaseMapSelector from '../components/BaseMapSelector/BaseMapSelector';
import {INITIAL_MAPSTYLE, MAPBOX_TOKEN} from '../config';
import {MOBILE_SCREEN, primaryCardHeader, warningColor} from '../assets/jss/nextjs-material-dashboard';
import Button from '@mui/material/Button';
import {
  Anchor,
  CloseFullscreen,
  DirectionsBoat,
  Insights,
  ListAlt,
  Map,
  OpenInFull,
  PlayArrow
} from '@mui/icons-material';
import PortCalls from '../components/PortManagement/PortCalls';
import BerthCalls from '../components/PortManagement/BerthCalls';
import DockReports from '../components/PortManagement/DockReports';
import DockStats from '../components/PortManagement/DockStats/DockStats';
import AnchorIcon from '@mui/icons-material/Anchor';
import {grayColor, primaryColor} from '../components/PortManagement/DockStats/colors';
import DatePicker from 'react-datepicker';
import VesselsSlider from '../components/MarineTraffic/VesselsSlider';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import {isNearDock1, isNearDock2} from '../components/MarineTraffic/util';
import {identity} from '@fullcalendar/react';
import Playback from '../components/PortManagement/Playback';
import PortCallsMobile from '../components/PortManagement/PortCallsMobile';
import BerthCallsMobile from '../components/PortManagement/BerthCallsMobile';
import {usePlaybackContext} from '../context/marineTrafficPlayback/playbackContext';
import Badge from '@mui/material/Badge';
import SailingIcon from '@mui/icons-material/Sailing';
import fnBrowserDetect from '../browserDetect';


const SHOW_VIEWPORT = false;

const anchorIconStyle = {
  position: 'fixed',
  bottom: '2%',
  left: '2%',
  border: '2px solid #fb8c00',
  borderRadius: '50%',
  width: '55px',
  height: '55px',
  zIndex: 1000,
  boxShadow: '0 5px 20px rgba(0, 0, 0, .50)'
};

const MENU_ITEMS = [
  {id: 'portMap', item: 'map', icon: <Map/>},
  {id: 'inOut', item: 'port-calls', icon: <DirectionsBoat/>},
  {id: 'docking', item: 'berth-calls', icon: <Anchor/>},
  {id: 'reports', item: 'reports', icon: <ListAlt/>},
  {id: 'statistics', item: 'stats', icon: <Insights/>},
  {id: 'playback', item: 'playback', icon: <PlayArrow/>},
];

const PortManagement = () => {
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(true);
  const {state: dockState, dispatch: dockDispatch} = useDockMgmtContext();
  const {selectedDock} = dockState;
  const [selectedItem, setSelectedItem] = useState('map');
  const [mapStyleId, setMapStyleId] = useState(INITIAL_MAPSTYLE);
  const [dockLayers, setDockLayers] = useState([]);
  const [dockSources, setDockSources] = useState([]);
  const [hoveredDockId, setHoveredDockId] = useState(null);
  const [selectedVessel, setSelectedVessel] = useState(null);
  const {config} = useConfig().state;
  const {clientId, portMgmt, docks} = config;
  const [viewport, setViewport] = useState({
    latitude: portMgmt.mapCenter[1],
    longitude: portMgmt.mapCenter[0],
    zoom: portMgmt.mapZoom
  });
  const {state, dispatch} = useMarineTrafficContext();
  const {dispatch: playbackDispatch} = usePlaybackContext();
  const {vessels, selectedDate, sliderPosition} = state;
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [reservedDocks, setReservedDocks] = useState([]);
  const [fullSideBar, setFullSideBar] = useState(true);

  useEffect(() => setFullSideBar(!isMobile), [isMobile]);

  function getInheritBgColorSideBar(selectedItem) {
    if (selectedItem === 'map') {
      return 'inherit';
    } else if (selectedItem === 'playback') {
      return 'inherit';
    } else {
      return 'white';
    }
  }

  function getPositionSideBar(selectedItem) {
    if (selectedItem === 'map') {
      return 'absolute';
    } else if (selectedItem === 'playback') {
      return 'absolute';
    } else {
      return 'inline-block';
    }
  }

  function getzIndexSideBar(selectedItem) {
    if (selectedItem === 'map') {
      return 2;
    } else if (selectedItem === 'playback') {
      return 2;
    } else {
      return null;
    }
  }

  function toggleFullSideBar() {
    setFullSideBar(!fullSideBar);
  }

  function toggleDrawer() {
    setOpenDrawer(openDrawer => !openDrawer);
  }

  // GET last known vessels data & positions
  useEffect(async () => {
    setLoading(true);
    const vesselsPositions = await getMarineTraffic(clientId, '-24h');
    // console.log({vesselsPositions});
    setLoading(false);
    dispatch({type: 'set-vessels-position', vessels: vesselsPositions});
  }, []);

  useEffect(async () => {
    setLoading(true);
    if (moment(selectedDate).isSame(moment(), 'day')) {
      // console.log("FETCH MARINE TRAFFIC FOR LAST 24h")
      const vesselsPositions = await getMarineTraffic(clientId, '-24h');
      dispatch({type: 'set-vessels-position', vessels: vesselsPositions});
    } else {
      // console.log("FETCH MARINE TRAFFIC FOR DATE " + moment(selectedDate).format('YYYY-MM-DD'))
      const vesselsPositions = await getMarineTraffic(clientId, moment(selectedDate).format('YYYY-MM-DD'));
      dispatch({type: 'set-vessels-position', vessels: vesselsPositions});
    }
    setLoading(false);
  }, [clientId, selectedDate]);

  // DOCK INOF - DISABLE FOR NOW - Triggered when client is changed
  // useEffect(async () => {
  //   config && setViewport({
  //       latitude: config.portMgmt.mapCenter[1],
  //       longitude: config.portMgmt.mapCenter[0],
  //       zoom: config.portMgmt.mapZoom
  //     }
  //   );
  //   const elb4 = await getDockInfo('elb-4');
  //   const elb5 = await getDockInfo('elb-5');
  //   const docksList = [{id: 'elb-4', occupied: elb4.occupied}, {id: 'elb-5', occupied: elb5.occupied}];
  //   setReservedDocks(docksList.filter(({occupied}) => occupied).map(({id}) => id));
  // }, [config]);


  const vesselsArray = _.values(vessels);

  // console.log({sliderPosition, vessels, vesselsArray});

  console.log({sliderPosition,vesselsArray});

  const vesselsMarkers = vesselsArray.map(vessels24hList => vessels24hList[sliderPosition][1])
    // .filter(identity) - Filtruje nully ktore tertaz zamienie da domyślnego MARCO POLO
    .map(v => v ? v : MARCO_POLO_ZACUMOWANY)
    .map(vessel => <Marker
      key={vessel.mmsi}
      longitude={vessel.longitude}
      latitude={vessel.latitude}
      anchor="top-left"
      offset={[-11, -11]}
    >
      <Vessel mapBearing={viewport.bearing} shipInfo={vessel} selectedDate={selectedDate}
              onClick={() => setSelectedVessel(vessel)}/>
    </Marker>);

  const vesselsNearDock1 = vesselsArray.map(vessels24hList => vessels24hList[sliderPosition][1])
    .filter(identity).map(v => isNearDock1(v.latitude, v.longitude) ? v : null).filter(identity);

  const vesselsNearDock2 = vesselsArray.map(vessels24hList => vessels24hList[sliderPosition][1])
    .filter(identity).map(v => isNearDock2(v.latitude, v.longitude) ? v : null).filter(identity);

  const vesselNearby = {'elb-4': vesselsNearDock1, 'elb-5': vesselsNearDock2};

  const vesselPopup = useMemo(() => selectedVessel &&
    <VesselPopup vessel={selectedVessel} onClose={() => setSelectedVessel(null)}/>
    , [selectedVessel]);

  // DOCK MGMT - INACTIVE NOW
  // useEffect(async () => {
  //   if (docks) {
  //     const layers = _.map(docks, dock => <Layer
  //       onHover={(e) => {
  //         if (hoveredDockId !== dock.id) {
  //           setHoveredDockId(dock.id);
  //         }
  //       }}
  //       onLeave={() => setHoveredDockId(null)}
  //       onClick={() => onDockClick(dock.id)}
  //       key={'dock-layer-' + dock.id}
  //       id={'dock-layer-' + dock.id}
  //       type="fill"
  //       source={'dock-source-' + dock.id}
  //       paint={{
  //         'fill-color': reservedDocks.includes(dock.id) ? 'red' : 'green',
  //         'fill-outline-color': vesselNearby[dock.id].length > 0 ? 'red' : 'green',
  //         'fill-opacity': 0.7
  //       }}
  //     />);
  //
  //     const sources = _.map(docks, dock => <Source
  //       key={'dock-source-' + dock.id}
  //       id={'dock-source-' + dock.id}
  //       type={'geojson'}
  //       data={{
  //         'type': 'FeatureCollection',
  //         'features': [
  //           {
  //             'type': 'Feature',
  //             'geometry': dock.geometry
  //           }
  //         ]
  //       }}
  //     />);
  //
  //     setDockLayers(layers);
  //     setDockSources(sources);
  //
  //     // const elb4 = await getDockInfo('elb-4');
  //     // const elb5 = await getDockInfo('elb-5');
  //     // const docksList = [{id: 'elb-4', occupied: elb4.occupied}, {id: 'elb-5', occupied: elb5.occupied}];
  //     // setOccupiedDocks(docksList.filter(({occupied}) => occupied).map(({id}) => id));
  //   }
  // }, [docks, hoveredDockId, reservedDocks]);
  //
  // useEffect(async () => {
  //   const elb4 = await getDockInfo('elb-4');
  //   const elb5 = await getDockInfo('elb-5');
  //   const docksList = [{id: 'elb-4', occupied: elb4.occupied}, {id: 'elb-5', occupied: elb5.occupied}];
  //   setReservedDocks(docksList.filter(({occupied}) => occupied).map(({id}) => id));
  // }, [hoveredDockId]);
  //
  // const onDockClick = useCallback((dockId) => {
  //   dockDispatch({type: 'select-dock', selectedDock: docks[dockId]});
  // }, [docks]);
  // const dockCalendarPopup = useMemo(() => selectedDock &&
  //   <DockPopup onClose={() => dockDispatch({type: 'select-dock', selectedDock: null})}/>, [selectedDock]);

  return <>
    <Header/>
    <Box display={'flex'}>
      {/* SIDEBAR */}
      <Box
        display={'flex'}
        pl={1}
        pt={2}
        width={fullSideBar ? 210 : 80}
        height={{height: 'calc(80vh - 88px)'}}
        flexDirection={'column'}
        alignItems={'flex-start'}
        position={getPositionSideBar(selectedItem)}
        sx={{
          backgroundColor: getInheritBgColorSideBar(selectedItem),
          zIndex: getzIndexSideBar(selectedItem),
          // '@media screen and (max-width: 50em)': {
          //   display: "none"
          // }
        }}>
        {!isMobile && <Button
          href={'https://www.marinetraffic.com/en/ais/details/ports/23060?name=ELBLAG&country=Poland'}
          target={'_blank'}
          fullWidth
          variant={'contained'}
          color={'primary'}
          sx={{
            ...primaryCardHeader,
            marginBottom: 2,
            fontWeight: 'bold',
            width: fullSideBar ? 170 : 50,
            fontSize: fullSideBar ? '1rem' : '.8rem'
          }}>
          RUCH STATKÓW
        </Button>}
        {!isMobile && <Box mb={4} sx={{borderRadius: 13}}>
          {fullSideBar ? <Chip sx={{display: selectedItem === 'map' ? 'flex' : 'none'}}
                               label={<Typography variant={'caption'}
                                                  color={'primary'}>{t('portManagement.shipsInThePort') + ': ' + Math.max(0, Object.keys(vessels).length - 1)}</Typography>}/> :
            isMobile && <Tooltip title={t('portManagement.shipsInThePort')}>
              <Badge sx={{
                backgroundColor: 'inherit',
                display: selectedItem === 'map' ? 'inline-block' : 'none'
              }}
                     badgeContent={<Typography variant={'caption'}
                                               color={'primary'}>{Math.max(0, Object.keys(vessels).length - 1)}</Typography>}>
                <SailingIcon sx={{marginLeft: 2}} color={'success'}/>
              </Badge>
            </Tooltip>
          }
        </Box>}

        {MENU_ITEMS.map(({id, item, icon}) =>
          <Box mb={1} sx={{backgroundColor: 'rgba(256,256,256,0.4)', borderRadius: 13}}>
            <Tooltip
              title={t('portManagement.' + id)}
              placement={'bottom'}>
              <Button sx={{color: selectedItem === item ? warningColor[2] : 'none'}}
                      onClick={() => {
                        setSelectedItem(item);
                        selectedItem === 'playback' && playbackDispatch({type: 'set-state', state: 'CONFIG'});
                      }} startIcon={icon}>
                {fullSideBar && t('portManagement.' + id)}
              </Button>
            </Tooltip>
          </Box>)}

        <Tooltip
          title={fullSideBar ? t('portManagement.sideBarTooltipMinimal') : t('portManagement.sideBarTooltipExpand')}
          placement={'bottom'}>
          <Button
            startIcon={fullSideBar ? <CloseFullscreen
                sx={{
                  width: 20, height: 20,
                }}/> :
              <OpenInFull sx={{width: 20, height: 20}}/>}
            onClick={toggleFullSideBar}/>
        </Tooltip>
      </Box>

      {loading && <Box sx={{position: 'absolute', top: '40%', left: '40%', zIndex: 1400}}>
        <CircularProgress variant={'indeterminate'} size={200}/>
      </Box>}

      {/* CONTENT */}
      <Box sx={{
        width: '100vw',
        height: 'calc(100vh - 88px)',
        '@media screen and (max-width: 50em)': {
          height: fnBrowserDetect() === 'safari' ? 'calc(100vh - 120px)' : 'calc(100vh - 60px)'
        }
      }}>

        {selectedItem === 'port-calls' && (isMobile ? <PortCallsMobile/> : <PortCalls/>)}
        {selectedItem === 'berth-calls' && (isMobile ? <BerthCallsMobile/> : <BerthCalls/>)}
        {selectedItem === 'reports' && <DockReports/>}
        {selectedItem === 'stats' && <DockStats/>}
        {selectedItem === 'playback' && <Playback/>}

        {selectedItem === 'map' && <>
          <Box sx={{
            position: 'absolute',
            top: 100,
            right: 10,
            zIndex: 2,
            '@media screen and (max-width: 60em)': {top: '4em'},
            '@media screen and (max-width: 70em)': {top: '6em'},
            '@media screen and (max-width: 65em)': {top: '6.3em'},
            '@media screen and (max-width: 55em)': {top: '4.5em'}
          }}>
            <DatePicker
              maxDate={new Date()}
              minDate={new Date(2022, 8, 9)}
              selected={selectedDate}
              onChange={date => dispatch({type: 'set-selectedDate', selectedDate: date})}
            />

          </Box>
          {/* DISABLE LAsT REFRESH DATE - for now */}
          {false && !isMobile &&
          <Box sx={{background: 'rgba(255,255,255,0.7)'}} p={1} borderRadius={1} display={'flex'} gap={4}
               alignItems={'center'} position="absolute" right={10} top={90} zIndex={1}>
            <Typography color={'primary'}
                        variant={'code'}>{t('vessels.lastUpdate')}: {vesselsArray.length > 0 ? new Date(vesselsArray[0].timestamp).toLocaleString(i18n.resolvedLanguage) : new Date().toLocaleString(i18n.resolvedLanguage)}</Typography>
            {SHOW_VIEWPORT &&
            <Box p={2} sx={{position: 'absolute', top: 200, right: 10, zIndex: 1400, backgroundColor: 'lightblue'}}>
              {viewport.longitude},{viewport.latitude}, ZOOM: {viewport.zoom}
            </Box>}
          </Box>}

          <MapGL
            style={{height: '100%'}}
            {...viewport}
            onViewportChange={setViewport}
            mapStyle={mapStyleId}
            accessToken={MAPBOX_TOKEN}
            onClick={(e) => e.originalEvent.target.nodeName === 'CANVAS' && selectedVessel && setSelectedVessel(null)}
          >
            {dockSources}
            {dockLayers}
            {vesselsMarkers}
            {vesselPopup}
            {/*{dockCalendarPopup}*/}
            {/*{hoveredDockId && <DockInfoPopup dockId={hoveredDockId} vesselNearby={vesselNearby[hoveredDockId]}/>}*/}
            {!isMobile && <BaseMapSelector
              selectedStyleId={mapStyleId}
              onStyleChange={(id) => setMapStyleId(id)}
            />}
            <VesselsSlider/>
          </MapGL>
        </>}
      </Box>
    </Box>

    {/*MOBILE SIDEBAR*/}
    {/*{isMobile && <Box style={anchorIconStyle}>*/}
    {/*  <AnchorIcon sx={{cursor: 'pointer', width: '50px', height: '50px', color: '#007dc1'}} onClick={toggleDrawer}/>*/}
    {/*</Box>}*/}
    {/*<Drawer anchor={'left'}*/}
    {/*        open={openDrawer}*/}
    {/*        onClose={toggleDrawer}>*/}
    {/*  <Box mr={1} mb={5} display={'flex'} flexDirection={'column'} gap={1} p={2} position={"relative"}>*/}
    {/*    <Button*/}
    {/*      href={'https://www.marinetraffic.com/en/ais/details/ports/23060?name=ELBLAG&country=Poland'}*/}
    {/*      target={'_blank'}*/}
    {/*      fullWidth*/}
    {/*      variant={'contained'}*/}
    {/*      color={'primary'} sx={{...primaryCardHeader, marginBottom: 2, fontWeight: 'bold'}}>*/}
    {/*      ELBLĄG*/}
    {/*    </Button>*/}

    {/*    <Typography variant={'body1'}>*/}
    {/*      <a style={{color: primaryColor[2], fontWeight: 'bold'}} target={'_blank'}*/}
    {/*         href={'https://www.marinetraffic.com/en/ais/home/centerx:19.38967/centery:54.16746/zoom:14'}>54.16746°/19.38967°</a>*/}
    {/*    </Typography>*/}
    {/*    <Typography fontWeight={'bold'} variant={'body1'}*/}
    {/*                sx={{color: grayColor[0]}}>{t('portManagement.shipsInThePort')}: {' '}*/}
    {/*      <a style={{color: primaryColor[2], fontWeight: 'bold'}}*/}
    {/*         href={'https://www.marinetraffic.com/en/data/?asset_type=vessels&current_port_in|begins|ELBLAG|current_port_in=23060&time_of_latest_position_between|gte|time_of_latest_position_between=1440,525600'}>{Math.max(0, Object.keys(vessels).length - 1)}</a>*/}
    {/*    </Typography>*/}
    {/*  </Box>*/}

    {/*  <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '16px'}}>*/}
    {/*    {MENU_ITEMS.map(({id, item, icon}) =>*/}
    {/*      <Button sx={{marginBottom: 1, color: selectedItem === item ? warningColor[2] : 'none'}}*/}
    {/*              onClick={() => {*/}
    {/*                setSelectedItem(item);*/}
    {/*                toggleDrawer();*/}
    {/*              }} startIcon={icon}>*/}
    {/*        {t('portManagement.' + id)}*/}
    {/*      </Button>)}*/}
    {/*      <Button position={"absolute"} sx={{bottom: 0, top: 100, left: 40}} variant={"contained"} onClick={toggleDrawer}>Back</Button>*/}
    {/*  </Box>*/}
    {/*</Drawer>*/}
  </>;
};

export default PortManagement;


const MARCO_POLO_ZACUMOWANY = {
  "teu": "60",
  "financialOwner": null,
  "insurer": "STANDARD P & I CLUB",
  "depth": "13.4",
  "grossTonnage": "15955",
  "breadthMoulded": "23.4",
  "summerDwt": "7330",
  "name": "MARCO POLO",
  "grain": null,
  "breadthExtreme": "23.43",
  "flag": "CY",
  "lengthOverall": "150.38",
  "liquidOil": null,
  "callsign": "5BJX5",
  "vesselType": "RO-RO/PASSENGER SHIP",
  "yardNumber": null,
  "placeOfBuild": "KRIMPEN AAN DEN IJSSEL NETHERLANDS",
  "owner": "TIRRENIA DI NAVIGAZIONE SOCIETA PER AZIONI",
  "technicalManager": null,
  "liquidGas": null,
  "build": "1993",
  "builder": "TILLE FRISIAN",
  "speedMax": null,
  "manager": "TIRRENIA DI NAVIGAZIONE SOCIETA PER AZIONI",
  "speedService": "20.6",
  "timestamp": 1699696800000,
  "imo": "9019080",
  "mmsi": "209764000",
  "draught": "6.02",
  "netTonnage": "4786",
  "displacementSummer": null,
  "lengthBWPerpendiculars": "137.33",
  "fuelConsumption": "42 t/day at avg. speed",
  "eni": null,
  "heading": 345.5,
  "latitude": 54.37297439575195,
  "longitude": 18.66010570526123,
  "speed": 0,
  "course": 259.5,
  "status": "0"
};
