import React, {useEffect, useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import Header from '../components/Header/Header';
import Button from '@mui/material/Button';
import {MOBILE_SCREEN} from '../assets/jss/nextjs-material-dashboard';
import {
  Agriculture,
  ArrowBackIos,
  BarChart,
  BubbleChart,
  CalendarMonth,
  CalendarViewWeek,
  FilterDrama,
  History,
  ListAlt,
  Looks3,
  MoreHoriz,
  Tune,
  VolumeUp,
  Water
} from '@mui/icons-material';
import {useConfig} from '../context/clientConfig/clientConfigContext';
import AirChart from '../components/AirChart/AirChart';
import {useTranslation} from 'react-i18next';
import CustomReports from '../components/Reports/CustomReports';
import {useNavigate} from 'react-router-dom';
import DataAnalytics from '../components/DataAnalytics/DataAnalytics';
import WeatherCharts from '../components/WeatherCharts/WeatherCharts';
import WaterChart from '../components/AirChart/WaterChart';
import {Drawer, useMediaQuery} from '@mui/material';
import OilInWaterChart from '../components/AirChart/OilInWaterChart';
import PeriodicReports from '../components/Reports/PeriodicReports';
import NoiseChart from '../components/AirChart/NoiseChart';
import AgroChart from '../components/AirChart/AgroChart';
import Highcharts from 'highcharts/highstock';
import ENosePMChart from '../components/AirChart/ENosePMChart';
import ENoseChart from '../components/AirChart/ENoseChart';


const drawerIconStyle = {
  position: 'fixed',
  bottom: '2%',
  left: '2%',
  border: '2px solid #fb8c00',
  borderRadius: '50%',
  width: '55px',
  height: '55px',
  zIndex: 1000,
  textAlign: 'center',
  boxShadow: '0 5px 20px rgba(0, 0, 0, .50)'
};


const Measurements = () => {
  const {t} = useTranslation();
  const {config: {devices, modules, defaultSelectedItem, lang}, email, clientId} = useConfig().state;
  const splitted = window.location.href.split('#');
  const selectedByUrl = splitted[splitted.length - 1];
  const [selectedItem, setSelectedItemState] = useState(selectedByUrl || defaultSelectedItem || 'air');
  const navigate = useNavigate();
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);
  const [openDrawer, setOpenDrawer] = useState(false);

  function toggleDrawer() {
    setOpenDrawer(openDrawer => !openDrawer);
  }

  const setSelectedItem = item => {
    navigate('#' + item);
    setSelectedItemState(item);
  };

  // Set language for Charts
  useEffect(() => {
    if (lang !== 'en') {
      Highcharts.setOptions({
        lang: t('highcharts', {returnObjects: true})
      });
    }
  }, [lang]);

  useEffect(() => {
    if (window.location.href.includes("reports.reports")) {
      navigate('#reports.custom');
      setSelectedItemState("reports.custom");
    }
  }, [window.location.href]);

  const leftMenuItems = useMemo(() => {
    const items = [];
    modules.AGRO && items.push(['agro', <Agriculture/>]);
    modules.AIR && items.push(['air', <BubbleChart/>]);
    modules.WATER && items.push(['water', <Water/>]);
    modules.NOISE && items.push(['noise', <VolumeUp />]);
    // modules.WEATHER && items.push(['weather', <FilterDrama/>]);
    items.push(['weather', <FilterDrama/>]);
    items.push(['analytics', <BarChart/>]);
    return items;
  }, [modules]);

  return <Box>
    <Header/>
    <Box display={'flex'}>
      {/* SIDEBAR */}
      <Box pt={10} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} sx={{
        pl: 1,
        gap: 3,
        width: 250,
        height: 'calc(100vh - 88px)',
        '@media screen and (max-width: 50em)': {
          height: 'calc(100vh - 60px)', display: 'none'
        }
      }}>
        {leftMenuItems.map(([id, icon]) =>
          <Button
            sx={{fontSize: "14px", fontWeight: selectedItem === id ?  "bold" : "normal"}}
            variant={selectedItem === id ? 'contained' : 'text'}
            onClick={() => setSelectedItem(id)} startIcon={icon}>
            {t('measurements.' + id)}
          </Button>
        )}

        {/* IF REPORTS SELECTED - RENDER SUBMENU ITEMS */}
        {[['reports.reports', <ListAlt/>]].map(([id, icon]) => <>
          <Button
            variant={selectedItem === id ? 'contained' : 'text'}
            onClick={() => setSelectedItem(id)} startIcon={icon}>
            {t('measurements.' + id)}
          </Button>
        </>)}
        {selectedItem.includes('reports') && [
          ['reports.custom', <Tune/>],
          ['reports.weekly', <CalendarViewWeek/>],
          ['reports.monthly', <CalendarMonth/>],
          ['reports.quarterly', <Looks3/>],
          ['reports.yearly', <History/>]
        ].map(([id, icon]) => <Button
          color={'info'}
          variant={selectedItem === id ? 'contained' : 'text'}
          sx={{pl: 2, fontSize: '12px'}}
          onClick={() => setSelectedItem(id)} startIcon={icon}>
          {t('measurements.' + id)}
        </Button>)}

      </Box>
      {/* CONTENT */}
      <Box sx={{
        width: '100vw',
        height: 'calc(100vh - 88px)',
        '@media screen and (max-width: 50em)': {
          height: 'calc(100vh - 60px)'
        }
      }}>
        {selectedItem === 'agro' && devices && devices
          .filter(d => d.type === 'agro')
          .map(device => <Box p={2}>
            <AgroChart device={device}/>
          </Box>)
        }

        {selectedItem === 'air' && devices && devices
          .filter(d => d.type === 'air')
          .filter(d => !d.serialNo.includes('NOISE'))
          .map(device => <Box p={2}>
            {device.measurements.includes('voc') ? [<ENoseChart device={device}/>,<ENosePMChart device={device}/>] : <AirChart device={device}/>}
          </Box>)
        }

        {selectedItem === 'water' && devices && devices
          .filter(d => d.type === 'buoy')
          .map(device => <Box p={2}>
            {device.measurements.includes('oil') ? <OilInWaterChart device={device}/> : <WaterChart device={device}/>}
          </Box>)
        }
        {selectedItem === 'noise' && devices && devices
          .filter(d => d.measurements.includes('noise'))
          .map(device => <Box key={device.serialNo} p={2}>
            <NoiseChart device={device}/>
          </Box>)
        }
        {selectedItem === 'weather' && <WeatherCharts/>}
        {selectedItem === 'analytics' && <DataAnalytics/>}
        {selectedItem === 'reports.reports' && <CustomReports/>}
        {selectedItem === 'reports.custom' && <CustomReports/>}
        {selectedItem === 'reports.weekly' && <PeriodicReports interval={'weekly'}/>}
        {selectedItem === 'reports.monthly' && <PeriodicReports interval={'monthly'}/>}
        {selectedItem === 'reports.quarterly' && <PeriodicReports interval={'quarterly'}/>}
        {selectedItem === 'reports.yearly' && <PeriodicReports interval={'yearly'}/>}
      </Box>
    </Box>

    {/* MOBILE SIDEBAR */}
    {isMobile && selectedItem.includes('reports') && <Box style={drawerIconStyle}>
      <MoreHoriz sx={{
        cursor: 'pointer',
        width: '50px',
        height: '50px',
        color: '#007dc1',
        display: 'flex',
        justifyContent: 'center',
      }} onClick={toggleDrawer}/>
    </Box>}
    <Drawer anchor={'left'}
            open={openDrawer}
            onClose={toggleDrawer}>
      <Box justifyContent={'flex-end'} alignItems={'flex-start'} height={'80vh'} mr={1} mb={5} display={'flex'}
           position={'relative'} flexDirection={'column'} gap={1} p={2}>
        {[
          ['reports.custom', <Tune/>],
          ['reports.weekly', <CalendarViewWeek/>],
          ['reports.monthly', <CalendarMonth/>],
          ['reports.quarterly', <Looks3/>],
          ['reports.yearly', <History/>]
        ].map(([id, icon]) => <Button
          color={'info'}
          variant={selectedItem === id ? 'contained' : 'text'}
          sx={{pl: 2, fontSize: '12px'}}
          onClick={() => {
            setSelectedItem(id);
            toggleDrawer();
          }} startIcon={icon}>
          {t('measurements.' + id)}
        </Button>)}

        <Button sx={{mt: 10}}
                fullWidth variant={'contained'}
                startIcon={<ArrowBackIos/>}
                onClick={toggleDrawer}
        />
      </Box>
    </Drawer>
  </Box>;
};

export default Measurements;
