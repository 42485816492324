import {API} from 'aws-amplify';
import {s3List} from './awsLib';

const ANALYTICS_NUMBER_OF_BUCKETS = 150;
const CORAL_API_NAME = 'coral';
const WATER_API_NAME = 'water';
const EPISODES_API_NAME = 'episodes';
const OPEN_DATA_API_NAME = 'openData'; // Gdańskie wody
const REPORTS_API_NAME = 'reports';
const INTERACTIVE_LAYERS_API_NAME = 'iLayers';
const MAP_DATA_API_NAME = 'map';
const RISK_DATA_API_NAME = 'risk';
const IOT_DATA_API_NAME = 'iot';
const CAMERA_DATA_API_NAME = 'camera';
const MARINE_TRAFFIC_INGEST_DATA_API_NAME = 'marine-traffic';
const MAINTENANCE_API_NAME = 'maintenance';

export const getGISLayers = clientId => API.get(MAP_DATA_API_NAME, `/gis/layers/${clientId}`, {});
export const getSatelliteLayers = clientId => API.get(MAP_DATA_API_NAME, `/satellite/layers/${clientId}`, {});
export const getWMSLayers = clientId => API.get(MAP_DATA_API_NAME, `/wms/layers/${clientId}`, {});

export const fetchVesselData = (mmsi, imo) => API.post(MARINE_TRAFFIC_INGEST_DATA_API_NAME, `/vessel/fetch-data`, {
  body: {mmsi, imo},
  headers: {'Content-Type': 'application/json'}
});

export const getClientConfig = (clientId, roles) => API.get(CORAL_API_NAME, `/settings/${clientId}${roles ? '?r=' + roles : ''}`, {});
export const getDevicePhoto = (clientId, serialNo) => API.get(CORAL_API_NAME, `/photos/${clientId}/${serialNo}`, {});


export const getIotDiag = (clientId, serialNo, m) => API.get(IOT_DATA_API_NAME, `/config/${clientId}/${serialNo}`, {});
export const setIoTConfig = (clientId, serialNo, configData) => API.post(IOT_DATA_API_NAME, `/config/${clientId}/${serialNo}`, {
  body: configData,
  headers: {'Content-Type': 'application/json'}
});

// CAMERA API
export const getCameraMeasurements = (clientId, serialNo, measurements, from, to) => API.get(CAMERA_DATA_API_NAME, `/measurements/${clientId}/${serialNo}?m=${measurements}&from=${from}&to=${to}`, {});
export const getCameraDeviceImages = (clientId, serialNo) => API.get(CAMERA_DATA_API_NAME, `/img/${clientId}/${serialNo}`, {});
export const getCameraDeviceImage = (clientId, serialNo, timestamp) => API.get(CAMERA_DATA_API_NAME, `/img/${clientId}/${serialNo}?timestamp=${timestamp}`, {});
export const deleteCameraDeviceImage = (clientId, serialNo, timestamp) => API.del(CAMERA_DATA_API_NAME, `/img/${clientId}/${serialNo}/${timestamp}`, {});
export const executeCameraDeviceImage = (clientId, serialNo) => API.post(IOT_DATA_API_NAME, `/cmd/${clientId}/${serialNo}`, {
  body: {'commandId': 'takePhoto', 'type': 'custom'},
  headers: {'Content-Type': 'application/json'}
});

export const getMapSurveys = clientId => API.get(CORAL_API_NAME, `/map/surveys/${clientId}`, {});
export const getMapSurveyLayer = (clientId, param, date) => API.get(CORAL_API_NAME, `/map/surveys/${clientId}/${param}/${date}`, {});

export const getMeasurements = (clientId, devices, measurements, from, to, interval) => API.get(CORAL_API_NAME, `/measurements/${clientId}?devices=${devices}&parameters=${measurements}&from=${from}&to=${to}&interval=${interval}`, {});
export const getWaterMeasurements = (clientId, devices, measurements, from, to, interval) => API.get(WATER_API_NAME, `/measurements/${clientId}?devices=${devices}&parameters=${measurements}&from=${from}&to=${to}&interval=${interval}`, {});
export const getMockedWaterMeasurements = (clientId, devices, measurements, from, to, interval) => API.get(WATER_API_NAME, `/measurements/${clientId}?devices=${devices}&parameters=${measurements}&from=${from}&to=${to}&interval=${interval}&water_mock=true`, {});
export const getChartMeasurements = (clientId, devices, measurements, from, to, number_of_buckets) => API.get(CORAL_API_NAME, `/measurements/${clientId}/chart/${devices}?from=${from}&to=${to}&n=${number_of_buckets}&m=${measurements}`, {});
export const getNoiseMeasurements = (clientId, devices, from, to) => API.get(CORAL_API_NAME, `/measurements/${clientId}/noise/chart/${devices}?from=${from}&to=${to}`, {});
export const getMockedWaterChartMeasurements = (clientId, devices, measurements, from, to, number_of_buckets) => API.get(CORAL_API_NAME, `/measurements/${clientId}/chart/${devices}?from=${from}&to=${to}&n=${number_of_buckets}&m=${measurements}&water_mock=true`, {});

export const getWeather = (clientId, date) => API.get(CORAL_API_NAME, `/weather/${clientId}?date=${date}`, {});
export const getWeatherStation = (clientId, serialNo, date) => API.get(CORAL_API_NAME, `/weather/${clientId}/${serialNo}?date=${date}`, {});
export const getWindRoseStation = (clientId, serialNo, date) => API.get(CORAL_API_NAME, `/weather/wind-rose/${clientId}/${serialNo}?date=${date}`, {});
export const getForecast = (clientId, calculationDate, parameters) => API.get(CORAL_API_NAME, `/forecast/${clientId}?${parameters ? `parameters=${parameters.join(',')}&` : ''}${calculationDate ? `calculationDate=${calculationDate}` : ''}`, {});
export const getCurrentWeather = clientId => API.get(CORAL_API_NAME, `/weather/${clientId}/current`, {});

export const getMarineTraffic = (clientId, dateString) => API.get(CORAL_API_NAME, `/marine-traffic/${clientId}?d=${dateString}`, {});
export const getMarineTrafficPlayback = (clientId, dateString) => API.get(CORAL_API_NAME, `/marine-traffic/${clientId}/playback?start=${dateString}`, {});
export const getVesselData = (mmsi, imo) => {
  return mmsi ? API.get(CORAL_API_NAME, `/vessel/${mmsi}`, {}) : API.get(CORAL_API_NAME, `/vessel/imo?imo=${imo}`, {});
};

export const getVesselImg = (mmsi, imo) => API.get(CORAL_API_NAME, `/vessel/img/${mmsi}?imo=${imo}`, {});

export const searchVessel = (vesselName) => API.get(CORAL_API_NAME, `/vessel/search/${vesselName}`, {});
export const upsertDockEvent = (dockEvent, dockId) => API.post(CORAL_API_NAME, `/dock/${dockId}`, {
  body: dockEvent,
  headers: {'Content-Type': 'application/json'}
});
export const deleteDockEvent = (dockId, eventId) => API.del(CORAL_API_NAME, `/dock/${dockId}/event/${eventId}`, {});

export const getDockInfo = (dockId) => API.get(CORAL_API_NAME, `/dock/${dockId}`, {});
export const getDockEvents = (dockId) => API.get(CORAL_API_NAME, `/dock/${dockId}/events`, {});

export const getDockAgents = clientId => API.get(CORAL_API_NAME, `/dock-agents/${clientId}`, {});
export const addDockAgent = (clientId, newAgentName) => API.post(CORAL_API_NAME, `/dock-agents/${clientId}`, {
  body: {add: [newAgentName]},
  headers: {'Content-Type': 'application/json'}
});

export const getDockReports = (dockId) => API.get(CORAL_API_NAME, `/dock/${dockId}/reports`, {});
export const generateDockReport = (dockId, data) => API.post(CORAL_API_NAME, `/dock/${dockId}/reports`, {
  body: data,
  headers: {'Content-Type': 'application/json'}
});
export const deleteDockReport = (dockId, timestamp) => API.del(CORAL_API_NAME, `/dock/${dockId}/reports/${timestamp}`, {});

export const getPortCalls = (clientId, startDate, endDate) => API.get(CORAL_API_NAME, `/marine-traffic/${clientId}/port-calls?from=${startDate}&to=${endDate}`, {});
export const getBerthCalls = (clientId, startDate, endDate) => API.get(CORAL_API_NAME, `/marine-traffic/${clientId}/berth-calls?from=${startDate}&to=${endDate}`, {});

export const getWindRoseData = (clientId, serialNo, dateFrom, dateTo) => API.get(CORAL_API_NAME, `/wind-rose/${clientId}/${serialNo}?dateFrom=${dateFrom}&dateTo=${dateTo}`, []);
export const getWindWithPmData = (clientId, serialNo, dateFrom, dateTo) => API.get(CORAL_API_NAME, `/wind-pm/${clientId}/${serialNo}?dateFrom=${dateFrom}&dateTo=${dateTo}`, []);

export const getAnalyticsData = (clientId, measurement, serials, from, to, interval) => API.get(CORAL_API_NAME, `/analytics/${clientId}/${serials.join(',')}?from=${from}&to=${to}&n=${ANALYTICS_NUMBER_OF_BUCKETS}&m=${measurement}&interval=${interval}`, []);


// REPORTS API
const processS3Data = (s3BucketInfos, interval, year) => s3BucketInfos
  .map(bucketInfo => bucketInfo.key)
  .filter(s3Path => (s3Path.includes('/yearly/') && s3Path.includes('.xlsx')) || s3Path.includes(year) && s3Path.includes(interval))
  .reverse();

export const fetchReportsLinks = async (clientId, year, interval) => {
  const reportsS3List = await s3List(clientId, '/' + interval);
  return processS3Data(reportsS3List, interval, year);
};

export const generateCustomReport = (clientId, createdBy, createdAt, dateFrom, dateTo, devices, measurements, interval) => API.post(REPORTS_API_NAME, `/${clientId}`, {
  body: {createdBy, createdAt, dateFrom, dateTo, devices, measurements, interval},
  headers: {'Content-Type': 'application/json'}
});

export const getCustomReports = clientId => API.get(REPORTS_API_NAME, `/${clientId}`, {});
export const deleteCustomReports = (clientId, createdAt) => API.del(REPORTS_API_NAME, `/${clientId}/${createdAt}`, {});

// Interactive Layers - AKA iLayers
export const fetchILayers = clientId => API.get(INTERACTIVE_LAYERS_API_NAME, `/${clientId}`, {});
export const getILayer = (clientId, layerId) => API.get(INTERACTIVE_LAYERS_API_NAME, `/${clientId}/${layerId}`, {});
export const getILayerHistory = (clientId, layerId) => API.get(INTERACTIVE_LAYERS_API_NAME, `/${clientId}/${layerId}/history`, {});
export const createILayer = (clientId, iLayer) => API.put(INTERACTIVE_LAYERS_API_NAME, `/${clientId}`, {
  body: iLayer,
  headers: {'Content-Type': 'application/json'}
});
export const updateILayer = (clientId, iLayer) => API.put(INTERACTIVE_LAYERS_API_NAME, `/${clientId}`, {
  body: iLayer,
  headers: {'Content-Type': 'application/json'}
});
export const deleteILayer = (clientId, iLayerId) => API.del(INTERACTIVE_LAYERS_API_NAME, `/${clientId}/${iLayerId}`, {});


export const getSignedURL = async (filename, path, clientId) => {
  const url = `/${clientId}/sign_s3_url`;
  return path ?
    API.get(INTERACTIVE_LAYERS_API_NAME, url + `?file=${filename}&path=${path}&public=true`, {})
    :
    API.get(INTERACTIVE_LAYERS_API_NAME, url + '?file=' + filename + '&path=tmp', {});
};

// RISK
export const fetchRiskMapData = (clientId, date) => API.get(RISK_DATA_API_NAME, `/${clientId}?forDate=${date}`, {});
export const getRiskRecipients = (clientId) => API.get(RISK_DATA_API_NAME, `/${clientId}/recipients`, {});
export const saveRiskRecipients = (clientId, recipients) => API.post(RISK_DATA_API_NAME, `/${clientId}/recipients`, {
  body: {recipients},
  headers: {'Content-Type': 'application/json'}
});

// EPISODES
export const getEpisodesForChart = (clientId, serialNo, dateFrom, dateTo) => API.get(EPISODES_API_NAME, `/${clientId}/chart/${serialNo}?dateFrom=${dateFrom}&dateTo=${dateTo}`, {});
export const getEpisodesList = (clientId, year) => API.get(REPORTS_API_NAME, `/episodes/${clientId}?year=${year || 'all'}`, {});
export const getEpisodesStats = (clientId, year) => API.get(EPISODES_API_NAME, `/${clientId}?year=${year}`, {});
export const getLatestEpisodes = (clientId, hoursBack) => API.get(EPISODES_API_NAME, `/${clientId}?hours=${hoursBack}`, {});
export const getEpisodesConfig = (clientId) => API.get(EPISODES_API_NAME, `/${clientId}/config`, {});
export const saveEpisodeConfig = (clientId, configData) => API.post(EPISODES_API_NAME, `/${clientId}/config`, {
  body: configData,
  headers: {'Content-Type': 'application/json'}
});
export const enableEpisodeConfig = (clientId, timestamp, enabled) => API.post(EPISODES_API_NAME, `/${clientId}/config?enabled=${enabled}`, {
  body: {timestamp, enabled},
  headers: {'Content-Type': 'application/json'}
});
export const delEpisodesConfig = (clientId, timestamp) => API.del(EPISODES_API_NAME, `/${clientId}/config/${timestamp}`, {});

// YETISENSE API - Gdanskie Wody / Open Weather (dla lip)
export const getWaterReports = clientId => API.get(OPEN_DATA_API_NAME, `/reports/${clientId}`, {});
export const createWaterReports = (clientId, createdBy, createdAt, dateFrom, dateTo, stations, measurements, interval) =>
  API.post(OPEN_DATA_API_NAME, `/reports/${clientId}`, {
    body: {
      createdBy,
      createdAt,
      dateFrom,
      dateTo,
      stations,
      measurements,
      interval,
      lang: clientId === 'lip' ? 'lv' : 'pl'
    },
    headers: {'Content-Type': 'application/json'}
  });

export const deleteWaterReport = (clientId, createdAt) => API.del(OPEN_DATA_API_NAME, `/reports/${clientId}/${createdAt}`, {});

export const getOpenDataWeather = (clientId, stations, from, to, measurements) => API.get(OPEN_DATA_API_NAME, `/weather/${clientId}/${stations.join(",")}?from=${from}&to=${to}&m=${measurements}`, []);
export const getWaterMapData = (clientId, stationNo, firstYear, secondYear) => API.get(OPEN_DATA_API_NAME, `/map/${clientId}/${stationNo}?years=${firstYear},${secondYear}`, []);
export const getWaterLevelData = (clientId, stationNo, selectedYear, interval) => API.get(OPEN_DATA_API_NAME, `/map/${clientId}/${stationNo}?date_start=${selectedYear}-01-01&date_stop=${selectedYear}-12-31&interval=${interval}`, []);

// Yetisense Water Level API
export const getWaterLevelTrends = trendsIdList => API.get(WATER_API_NAME, `/trends/${trendsIdList.join(',')}`, []);

// MAINTENANCE - API
export const getAllMaintenance = (clientId, dateFrom, dateTo) => API.get(MAINTENANCE_API_NAME, `/${clientId}?from=${dateFrom}&to=${dateTo}`, {});

export const getActiveMaintenance = (clientId) => API.get(MAINTENANCE_API_NAME, `/${clientId}`, {});
export const getMaintenance = (clientId, serialNo) => API.get(MAINTENANCE_API_NAME, `/${clientId}/${serialNo}`, {});
export const editMaintenance = (clientId, serialNo, payload, inMaintenance) => {
  let inMaintenanceParam = '';
  if (inMaintenance !== null && inMaintenance !== undefined) {
    inMaintenanceParam = '?=' + inMaintenance;
  }
  return API.put(MAINTENANCE_API_NAME, `/${clientId}/${serialNo}${inMaintenanceParam}`, {
    body: payload,
    headers: {'Content-Type': 'application/json'}
  });
}

export const deleteMaintenance = (clientId, serialNo, timestamp) => API.del(MAINTENANCE_API_NAME, `/${clientId}/${serialNo}/${timestamp}`, {});
