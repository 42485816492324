import Box from '@mui/material/Box';
import React, {useEffect, useState} from 'react';
import {CardHeader, CircularProgress} from '@mui/material';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import {BarChart, Refresh} from '@mui/icons-material';
import CardContent from '@mui/material/CardContent';
import {useTranslation} from 'react-i18next';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import Typography from '@mui/material/Typography';
import {addDays} from 'date-fns';
import OpenDataQuickDateButtons from './OpenDataQuickDateButtons';
import moment from 'moment';
import {getOpenDataWeather} from '../../api';
import GridContainer from '../../components/Grid/GridContainer';
import Meteogram from '../../components/Meteogram/Meteogram';
import {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import es from 'date-fns/locale/es';
import pl from 'date-fns/locale/pl';
import MeteoPLSlider from '../../components/MeteoPLSlider/MeteoPLSlider';
import {da} from 'date-fns/locale';
// import fr from 'date-fns/locale/fr';

// registerLocale('es', es);
registerLocale('pl', pl);
// registerLocale('fr', fr);

const MEASUREMENTS = ['wind', 'temp', 'humidity', 'pressure', 'rain'];
const STATION_BY_CLIENT_ID = {'rem': '307', 'lip': '457954'};
const weatherStations = clientId => clientId === "rem" ? ["307"] : clientId === "gdy" ? ["555","501","505","510","503"] : ["457954"];
export default function OpenWeatherCharts() {
  const {t} = useTranslation();
  const {config: {clientId, meteogram, lang}} = useConfig().state;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [measurements, setMeasurements] = useState(MEASUREMENTS);
  const [dateFrom, setDateFrom] = useState(addDays(new Date(), -1));
  const [dateTo, setDateTo] = useState(new Date());
  const [quickDays, setQuickDays] = useState(1);
  const [weatherData, setWeatherData] = useState(null);

  const fetchWeatherData = () => {
    if (measurements.length === 0) {
      return;
    }
    // replace common wind param to windlevel and winddir
    const mList = measurements.flatMap(mm => mm === 'wind' ? ['windlevel', 'windbearing'] : [mm]);
    setLoading(true);
    const now = new Date();
    const hoursFrom = Math.floor((dateFrom.getTime() - now.getTime()) / 36e5);
    const hoursFromTxt = (hoursFrom > 0 ? "+" : "-") + Math.abs(hoursFrom).toString() + "h";
    const hoursTo = Math.floor((dateTo.getTime() - now.getTime()) / 36e5);
    const hoursToTxt = (hoursTo > 0 ? "+" : "-") + Math.abs(hoursTo).toString() + "h";
    const stations = weatherStations(clientId);

    getOpenDataWeather(clientId, stations, hoursFromTxt, hoursToTxt, mList)
      .then(result => {
        let waterWeatherData = {};
        stations.forEach(serialNo => {
          waterWeatherData[serialNo] = {};
          mList.forEach(m => {
            waterWeatherData[serialNo][mapping[m]] = result[m][serialNo]
          })
        })
        // console.log({result, waterWeatherData});
        setWeatherData(waterWeatherData);
      })
      .catch(err => setError(err.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => fetchWeatherData(), [clientId]);

  const setDateRange = (from, to) => {
    // console.log({from, to, days: moment(to).diff(moment(from), 'days')});
    setDateFrom(from);
    setDateTo(to);
    setQuickDays(moment(to).diff(moment(from), 'days'));
  };

  const setQuickDateRange  = days => {
    const now = new Date();
    const start = addDays(now, -days);
    setDateRange(start, now);
    fetchWeatherData();
  }

  const resetAll = () => {
    setDateFrom(new Date());
    setDateTo(new Date());
    setMeasurements(['rain']);
    setQuickDays(1);
  };

  const handleDeleteParam = (paramToDelete) => {
    setMeasurements(measurements.filter(m => m !== paramToDelete));
  };

  const handleChangeMeasurements = e => {
    const mList = e.target.value;
    const newMeasurements = MEASUREMENTS.filter(m => mList.includes(m));
    setMeasurements(newMeasurements);
  };

  return <Card
    variant={'outlined'}
    sx={{minHeight: '100%'}}
  >
    <CardHeader
      action={
        <Button sx={{float: 'right'}} startIcon={<Refresh/>}
                onClick={resetAll} color={'info'}>{t('general.reset')}</Button>
      }
      title={<Button size={'large'}
                     startIcon={<BarChart/>}
                     color={'info'}>{t('openData.chartsTitle')}</Button>}
    />

    <CardContent>
      {/* SEARCH FORM */}
      <Box display={'flex'} gap={4} flexWrap={'wrap'}>
        {/* MEASUREMENTS */}
        {/*<FormControl>*/}
        {/*  <InputLabel sx={{marginTop: -1,}}*/}
        {/*              id="measurement-select-label">{t('openData.chooseWeatherMeasurement')}</InputLabel>*/}
        {/*  <Select*/}
        {/*    labelId={'measurement-select-label'}*/}
        {/*    multiple*/}
        {/*    sx={{minWidth: 200}}*/}
        {/*    size={'small'}*/}
        {/*    variant="outlined"*/}
        {/*    label={t('openData.chooseWeatherMeasurement')}*/}
        {/*    value={measurements}*/}
        {/*    renderValue={(selected) => selected.length > 1 ? t('measurements.selected') + ': ' + selected.length : t('openData.MEASUREMENTS.' + selected[0])}*/}
        {/*    onChange={handleChangeMeasurements}*/}
        {/*  >*/}
        {/*    {MEASUREMENTS.map(m =>*/}
        {/*      <MenuItem key={m} value={m}>*/}
        {/*        <Checkbox checked={measurements.indexOf(m) > -1}/>*/}
        {/*        <ListItemText primary={t('openData.MEASUREMENTS.' + m)}/>*/}
        {/*        <ListItemIcon>*/}
        {/*          /!* TODO w zależności od tego czy rain czy full meteo itp. *!/*/}
        {/*          /!*<BubbleChartOutlined sx={{background: 'transparent'}} color={'primary'}/>*!/*/}
        {/*          {oDataIcons[m]}*/}
        {/*        </ListItemIcon>*/}
        {/*      </MenuItem>)}*/}
        {/*  </Select>*/}
        {/*</FormControl>*/}

        {/* DateRangePicker */}
        <Box sx={{width: 300}}>
          <DateRangePicker
            inputStyle={'mui'}
            dateFrom={dateFrom}
            dateTo={dateTo}
            buttonColor={'white'}
            onChange={setDateRange}/>
        </Box>

        <Box
          width={230}
          position={'relative'}
        >
          <Typography sx={{position: 'absolute', top:-20}} color={'rgba(0, 0, 0, 0.6)'} variant={'caption'}>
            {t('openData.quickDateSelect')}
          </Typography>
          <OpenDataQuickDateButtons days={quickDays} onClick={setQuickDateRange}/>
        </Box>

        {/* ACTION BUTTON */}
        <Box>
          <Button
            sx={{width: 50}}
            onClick={fetchWeatherData}
            disabled={measurements.length === 0}
            color={'success'}
            variant={'contained'}>
            {/*{t('openData.fetchData')}*/}
            <Refresh />
          </Button>
        </Box>
      </Box>
      {/* MEASUREMENTS CHIPS */}
      {/*{measurements.length > 0 && <Box mt={2} flexWrap={'wrap'} display={'flex'} gap={1}>*/}
      {/*  {measurements.map(m =>*/}
      {/*    <Chip*/}
      {/*      sx={{paddingTop: 2, paddingBottom: 2}}*/}
      {/*      variant={'outlined'}*/}
      {/*      key={m}*/}
      {/*      label={t('openData.MEASUREMENTS.' + m)}*/}
      {/*      color={oDataColor[m] || 'default'}*/}
      {/*      onDelete={() => handleDeleteParam(m)}*/}
      {/*      avatar={<Avatar sx={{background: 'white'}}>{oDataIcons[m]}</Avatar>}/>)}*/}
      {/*</Box>}*/}

      {/* Content: Charts or Reports table TODO */}
      {error && <Typography color={'error'}>{error}</Typography>}
      {loading ? <CircularProgress size={100} sx={{marginLeft: '40%', marginTop: 10}}/> :
        <GridContainer alignItems="center" justify="center">
          {weatherData && <Meteogram data={{
            symbols: [],
            humidity: [],
            pressure: [],
            rain: [],
            temperature: [],
            windBearing: [],
            windSpeed: [],
            ...weatherData[STATION_BY_CLIENT_ID[clientId]]
          }}/>}

          <MeteoPLSlider dateFrom={moment(dateFrom)} dateTo={moment(dateTo)} row={meteogram.row} col={meteogram.col} lang={lang}/>
          {/*{weatherData && makeChartsItems(measurements, weatherData, clientId)}*/}
        </GridContainer>
      }
    </CardContent>
  </Card>;
}

const mapping = {
  humidity : 'humidity',
  pressure: 'pressure',
  rain: 'rain',
  snow: 'snow',
  temp: 'temperature',
  windbearing: 'windBearing',
  windlevel: 'windSpeed',
  // cloudCover:
  // isDaylight: 'isDaylight'
  // precipProb: 'precipProb'
};
