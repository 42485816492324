 import React, {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import {CardHeader, Select, Table, TableContainer, TableHead, TableRow} from '@mui/material';
import Button from '@mui/material/Button';
import {CalendarViewWeek} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {grayColor} from '../PortManagement/DockStats/colors';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import {fetchReportsLinks} from '../../api';
import {createTableData, THeader} from '../Table/tableUtills';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {TCell} from '../PortManagement/PortCalls';
import ExcelIcon from '../../assets/images/Excel-icon.png';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import range from 'lodash/range';
import MenuItem from '@mui/material/MenuItem';


export default function PeriodicReports({interval}) {
  const {t} = useTranslation();
  const {clientId, uiSettings} = useConfig().state.config;
  const [loading, setLoading] = useState(true);
  const [reportsLinks, setReportsLinks] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setLoading(true);
    fetchReportsLinks(clientId, selectedYear, interval)
      .then(result => setReportsLinks(result))
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, [clientId, selectedYear]);

  const rows = createTableData(reportsLinks, clientId);

  console.log({reportsLinks, rows});

  return <Card variant={'outlined'} sx={{minHeight: '100%'}}>
    <CardHeader
      action={<Box mt={1} sx={{float: 'right'}} display={'flex'}>
        {interval !== 'yearly' && <Select
          sx={{minWidth: 100}}
          size={'small'}
          value={selectedYear}
          renderValue={(selected) => selected}
          onChange={e => setSelectedYear(e.target.value)}
        >
          {range(uiSettings.reportsYear, new Date().getFullYear() + 1)
            .map(y =>
              <MenuItem key={y} value={y}>
                {y}
              </MenuItem>)}
        </Select>}
      </Box>}
      title={<Button
        size={'large'} startIcon={<CalendarViewWeek/>}
        color={'info'}>{t('measurements.reports.title.' + interval)}</Button>
      }
      subheader={<Typography
        sx={{marginLeft: 2, color: grayColor[3]}}
        variant={'caption'}>{t('measurements.reports.subheader.' + interval)}</Typography>}
    />

    <CardContent>
      <Box display="flex">
        {loading ? <CircularProgress size={100} sx={{margin: 'auto', marginTop: 10}}/> :
          <TableContainer>
            <Table aria-label="reports table">
              <TableHead>
                <TableRow sx={{backgroundColor: 'whitesmoke'}}>
                  {interval === "weekly" && <TableCell width={20}><THeader>{t('general.week')}</THeader></TableCell>}
                  {interval === "monthly" && <TableCell width={20}><THeader>{t('general.month')}</THeader></TableCell>}
                  {interval === "quarterly" && <TableCell width={20}><THeader>{t('general.quarter')}</THeader></TableCell>}
                  <TableCell><THeader>{t('general.report')}</THeader></TableCell>
                  <TableCell width={20}><THeader>{t('general.download')}</THeader></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(([lp, reportLink, downloadButton]) => (
                  <TableRow
                    key={lp}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    {interval === "weekly" && <TableCell width={20} align={'center'}><TCell>{rows.length - lp + 1}</TCell></TableCell>}
                    {interval === "monthly" && <TableCell width={20} align={'center'}><TCell>{t('general.months', {returnObjects: true})[rows.length - lp + 1]}</TCell></TableCell>}
                    {interval === "quarterly" && <TableCell width={20} align={'center'}><TCell>Q{rows.length - lp + 1}</TCell></TableCell>}
                    <TableCell align="center"><TCell>
                      <Box display={'flex'} gap={1}>
                        <Box><img width={30} height={25} src={ExcelIcon}/></Box>
                        {reportLink}
                      </Box>
                    </TCell></TableCell>
                    <TableCell align="center"><TCell>{downloadButton}</TCell></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>}
      </Box>
    </CardContent>
  </Card>;
}
