import React from 'react';
import Box from '@mui/material/Box';
import {useDockMgmtContext} from '../../context/dockMgmt/dockMgmtContext';
import {Button, IconButton, Tooltip, Typography} from '@mui/material';
import CargoTypeRow from './CargoTypeRow';
import {Add, GroupAdd} from '@mui/icons-material';
import AgentComboBox from './AgentComboBox';
import {useTranslation} from 'react-i18next';
import {useDockEventContext} from '../../context/dockEvent/dockEventContext';


export default function CargoInfo({unloading}) {
  const {state, dispatch} = useDockEventContext();
  const {loadingInfo, unloadingInfo} = state;
  const cargoInformation = unloading ? unloadingInfo : loadingInfo;
  const {t} = useTranslation();

  const cargoTypeRows = cargoInformation.cargoTypes.map((elem, idx) =>
    <CargoTypeRow key={'cargo-type-' + idx} idx={idx} unloading={unloading}/>
  );

  const cargoAgentRows = cargoInformation.agents.map((elem, idx) =>
    <AgentComboBox key={'cargo-agent-' + idx} idx={idx} unloading={unloading}/>
  );

  const resetSection = () => dispatch({type: 'reset-cargo-type', unloading: unloading});
  const addNewCargoTypeRow = () => dispatch({type: 'add-cargo-type', unloading});
  const addNewAgentRow = () => dispatch({type: 'add-cargo-agent', unloading});

  return <Box>
      <Button onClick={resetSection} sx={{fontSize: 'small', float: 'right'}} size={'small'}
              variant={'text'}>{t('cargoInfo.resetToDefault')}</Button>

    {cargoTypeRows}
    <Tooltip title={t('cargoInfo.addNewCargo')}><IconButton sx={{marginBottom: 2}} onClick={addNewCargoTypeRow}>
      <Add/>
    </IconButton>
    </Tooltip>
    <Box/>
    {cargoAgentRows}
    {cargoTypeRows.length > 0 && <Tooltip title={t("cargoInfo.addAgent")}>
      <IconButton onClick={addNewAgentRow}>
        <GroupAdd/>
      </IconButton></Tooltip>
    }
  </Box>;
}
