import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Auth} from 'aws-amplify';

import {useConfig} from 'context/clientConfig/clientConfigContext';
import CoverImg from 'assets/images/login/login-cover.png';
import UGCoverImg from 'assets/images/login/ug-borucino-login.jpg';


import CoverLayout from '../pages/CoverLayout';
import SuiInput from '../components/SuiInput';
import SuiBox from '../components/SuiBox';
import SuiTypography from '../components/SuiTypography';
import SuiButton from '../components/SuiButton';
import coralLogo from 'assets/images/logo/coral-logo.png';
import {getClientConfig} from '../api';
import MMLogo from 'assets/images/logo/mobileMonitoring-logo.png';
import {isMobileMonitoring} from '../utils';
import {Visibility, VisibilityOff} from '@mui/icons-material';


export default function Login() {
  const {t, i18n} = useTranslation();
  const isMM = isMobileMonitoring();
  const translatedLabels = {
    username: t('login.username'),
    password: t('login.password'),
    button: t('login.button'),
  };
  const {dispatch} = useConfig();
  const navigate = useNavigate();
  const [login, setLogin] = useState(null);
  const [passwd, setPasswd] = useState(null);
  const [passwdVisible, setPasswdVisible] = useState(false);
  const [error, setError] = useState(false);

  const handleLogin = () => {
    Auth.signIn(login.toLowerCase(), passwd).then(user => {
      const email = user.attributes.email;
      const clientId = user.attributes['custom:clientId'];
      const roles = user.attributes['custom:role'];
      getClientConfig(clientId, roles).then(result => {
        // console.log('got client config:', {roles, result});
        // dispatch({type: 'set-config', config: result});
        if (email === 'rafal.gluszewski@ug.edu.pl') {
          result.devices = result.devices.filter(d => d.serialNo === 'UGW_WATER_BORUCINO');
          if (result?.modules?.SURVEYS) {
            delete result.modules.SURVEYS;
          }
        }
        if (email === 'michalina.bielawska@armaag.gda.pl') {
          result.devices = result.devices.filter(d => !d.externalProvider);
          if (result?.modules?.WATER) {
            delete result.modules.WATER
          }
        }
        if (clientId === 'ugw') {
          if (email.includes('@seadata.pl')) {
            result.devices = result.devices.filter(d => d.serialNo.includes('E_NOSE_'));
            result.modules.AIR = 1;
          }
        }
        dispatch({type: 'set-user', email, clientId, config: result});
        navigate('/', {replace: true});
      }).catch(error => {
        console.log('error signing in', {error});
        setError(t(`loginErrors.${error.code}`));
      });
    }).catch(error => {
      console.log('error signing in', {error});
      setError(t(`loginErrors.${error.code}`));
    });
  };

  return (
    <CoverLayout
      // title="Witaj w Systemie Coral"
      // title={<span>Witaj w Systemie<img height={80} src={coralLogo} /> </span>}
      title={<img width={'80%'} src={isMM ? MMLogo : coralLogo}/>}
      description={isMM ? t('login.subtitle') : t('login.portSubtitle')}
      image={isMM ? UGCoverImg : CoverImg}
    >
      <SuiBox component="form" role="form">
        {error && <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" textColor={'error'} variant="caption">
              {error}
            </SuiTypography>
          </SuiBox>
        </SuiBox>}

        {/* LOGIN - EMAIL */}
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              {t('login.email')}
            </SuiTypography>
          </SuiBox>
          <SuiInput type="email" placeholder={t('login.email') + '...'} error={!!error} onChange={({currentTarget}) => {
            setLogin(currentTarget.value);
            setError(false);
          }}/>
        </SuiBox>

        {/* PASSWORD */}
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              {t('login.password')}
            </SuiTypography>
          </SuiBox>
          <SuiInput placeholder={t('login.password') + '...'}
                    type={passwdVisible ? 'text' : 'password'}
                    withIcon={{
                      icon: !passwdVisible ?
                        <VisibilityOff sx={{cursor: 'pointer'}} onClick={() => setPasswdVisible(true)}/>
                        :
                        <Visibility sx={{cursor: 'pointer'}} onClick={() => setPasswdVisible(false)}/>,
                      direction: 'right',
                    }}
                    error={!!error}
                    onChange={({currentTarget}) => {
                      setPasswd(currentTarget.value);
                      setError(false);
                    }}/>
        </SuiBox>


        <SuiBox sx={{height: 10}} display="flex" alignItems="center">
          {/*<Switch color={'success'} checked={rememberMe} onChange={handleSetRememberMe}/>*/}
          {/*<SuiTypography*/}
          {/*  variant="button"*/}
          {/*  fontWeight="regular"*/}
          {/*  onClick={handleSetRememberMe}*/}
          {/*  customClass="cursor-pointer user-select-none"*/}
          {/*>*/}
          {/*  &nbsp;&nbsp;Zapamiętaj mnie*/}
          {/*</SuiTypography>*/}
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton variant="gradient" buttonColor="primary" fullWidth onClick={handleLogin}>
            {t('login.button')}
          </SuiButton>
        </SuiBox>
        {/*<SuiBox mt={3} textAlign="center">*/}
        {/*  <SuiTypography variant="button" textColor="text" fontWeight="regular">*/}
        {/*    /!*Don&apos;t have an account?{" "}*!/*/}
        {/*    Nie masz konta?{" "}*/}
        {/*    <a href="mailto:kontakt@seadata.pl?subject=Zapytanie o System CORAL">*/}
        {/*      <SuiTypography*/}
        {/*        // component={Link}*/}
        {/*        // to="/authentication/sign-up/cover"*/}
        {/*        variant="button"*/}
        {/*        textColor="info"*/}
        {/*        fontWeight="medium"*/}
        {/*        textGradient*/}
        {/*      >*/}
        {/*        Skontaktuj się z nami*/}
        {/*      </SuiTypography>*/}
        {/*    </a>*/}
        {/*  </SuiTypography>*/}
        {/*</SuiBox>*/}
        <SuiBox mt={3} textAlign="center">
          <SuiTypography variant="button" textColor="text" fontWeight="regular">
            {/*Don&apos;t have an account?{" "}*/}
            <SuiTypography
              component={Link}
              to={`/${i18n.language}/change-passwd`}
              variant="button"
              textColor="info"
              fontWeight="medium"
              textGradient
            >
              {t('login.changePassword')}
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </CoverLayout>
  );
}
