import React, {useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import Header from '../components/Header/Header';
import Button from '@mui/material/Button';
import {FilterDrama, ListAlt} from '@mui/icons-material';
import {useConfig} from '../context/clientConfig/clientConfigContext';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import WeatherCharts from '../components/WeatherCharts/WeatherCharts';
import MapIcon from '@mui/icons-material/Map';
import WODY_GDA_LOGO from 'assets/images/clientLogos/gdanskie-wody.png';
import OpenDataReports from './OpenData/OpenDataReports';
import OpenDataMap from './OpenData/OpenDataMap';
import OpenWeatherCharts from './OpenData/OpenWeatherCharts';


const OpenData = () => {
  const {t} = useTranslation();
  const {config: {defaultSelectedItem}, email, clientId} = useConfig().state;
  const splitted = window.location.href.split('#');
  const selectedByUrl = splitted[splitted.length - 1];
  const [selectedItem, setSelectedItemState] = useState(selectedByUrl || defaultSelectedItem  || 'reports');
  const navigate = useNavigate();

  console.log({clientId});

  const setSelectedItem = item => {
    navigate('#' + item);
    setSelectedItemState(item);
  };

  const leftMenuItems = useMemo(() => [
    ['reports', <ListAlt/>],
    ['map', <MapIcon/>],
    ['weather', <FilterDrama/>]
  ], []);

  return <Box>
    <Header/>
    <Box display={'flex'}>
      {/* SIDEBAR */}
      <Box pt={2} sx={{
        pl: 1,

        width: 210,
        height: 'calc(100vh - 88px)'
      }}>
        {clientId === 'lip' ? <Box height={100} width={2}/> : <a href={"https://pomiary.gdanskiewody.pl/home/rain"} target={'_blank'}>
          <img src={WODY_GDA_LOGO} style={{marginBottom: 50}} height={63}/>
        </a>}
        {leftMenuItems.map(([id, icon]) => <Box key={id}>
          <Button
            variant={selectedItem === id ? 'contained' : 'text'}
            onClick={() => setSelectedItem(id)} startIcon={icon}>
            {t('openData.' + id)}
          </Button>
        </Box>)}
      </Box>

      {/* CONTENT */}
      <Box sx={{
        width: 'calc(100vw - 210px)',
        height: 'calc(100vh - 88px)',
        '@media screen and (max-width: 50em)': {
          height: 'calc(100vh - 60px)'
        },
      }}>
        {selectedItem === 'reports' && <OpenDataReports/>}
        {selectedItem === 'map' && <OpenDataMap/>}
        {selectedItem === 'weather' && <OpenWeatherCharts/>}
      </Box>
    </Box>
  </Box>;
};

export default OpenData;
