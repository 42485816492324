import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import VanillaMeteogram from './VanillaMeteogram';
import {useTranslation} from 'react-i18next';

const sorterByTimestamp = (a, b) => a[0] - b[0];

const Meteogram = ({data}) => {
  const ref = useRef();

  const {i18n} = useTranslation();

  const meteogramData = data ? {
    winds: data.windSpeed ? data.windSpeed.sort(sorterByTimestamp).map(([timestamp, value], index) => ({
      x: timestamp,
      value: Math.round(value * 10) / 10,
      direction: Math.round(data.windBearing[index][1])
    })): [],
    humidity: data.humidity ? data.humidity.sort(sorterByTimestamp).map(([timestamp, value]) => ({
      x: timestamp,
      y: Math.round(value)
    })): [],
    temperatures: data.temperature ? data.temperature.sort(sorterByTimestamp).map(([timestamp, value]) => ({
      x: timestamp,
      y: Math.round(value),
      //to: (timestamp * 1000) + 36e5, // + 1 hour ?
      symbolName: ''
    })): [],
    pressures: data.pressure ? data.pressure.sort(sorterByTimestamp).map(([timestamp, value]) => ({
      x: timestamp,
      y: Math.round(value)
    })) : [],
    precipitations: data.rain ? data.rain.sort(sorterByTimestamp).map(([timestamp, value]) => ({
      x: timestamp,
      y: Math.round(value * 10) / 10
    })): [],
    symbols: data.symbols ? data.symbols.sort(sorterByTimestamp) : []
  } : null;

  useEffect(() => {
    if (meteogramData) {
      const {weather, highcharts} = i18n.getResourceBundle(i18n.resolvedLanguage);
      new VanillaMeteogram(ref.current, meteogramData, weather, {lang: highcharts});
    }
  }, [meteogramData, i18n.resolvedLanguage]);


  useEffect(() => {
    // Update data in chart
  }, [data]);

  return <Box style={{width: '100%', minWidth: '720px'}} ref={ref}/>;
};

Meteogram.propTypes = {
  data: PropTypes.shape({
    cloudCover: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)), // Past weather only
    humidity: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    pressure: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)), // Past weather only
    rain: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    snow: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    temperature: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    windBearing: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    windSpeed: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    isDaylight: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)), // Forecast only
    precipProb: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)) // Forecast only
  }).isRequired
};

export default Meteogram;
