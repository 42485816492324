import Box from '@mui/material/Box';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import React, {useEffect, useMemo, useState} from 'react';
import {
  CardHeader,
  Checkbox,
  Chip,
  Divider,
  Drawer,
  FormControlLabel,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Select, Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import {
  Agriculture,
  Air,
  BarChart, BlurOff, BlurOn,
  BubbleChartOutlined,
  Cloud, Co2, Grade, Grass, LineWeight, NearMe,
  NotificationImportant,
  OilBarrel,
  Opacity, Percent,
  Refresh, SettingsSuggest, SolarPower, SpatialAudio, Speed,
  Thermostat,
  Thunderstorm, VolumeUp, Warning, WarningAmber,
  Water
} from '@mui/icons-material';
import {MOBILE_SCREEN, primaryColor, roseColor, warningColor} from '../../assets/jss/nextjs-material-dashboard';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import {useTranslation} from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PhIcon from 'assets/images/pH-icon.png';
import EcIcon from 'assets/images/waterIcons/conductivity-blue.png';
import TurbiIcon from 'assets/images/waterIcons/turbidity-blue.png';

import _ from 'lodash';
// import {AgroIcons} from '../measurementsUtil';
import {successColor} from '../PortManagement/DockStats/colors';


const PARAMS = [
  {
    group: 'AGRO',
    value: 'lightVisible',
  },  {
    group: 'AGRO',
    value: 'lightIr',
  },  {
    group: 'AGRO',
    value: 'lightUv',
  },  {
    group: 'AGRO',
    value: 'airTemp',
  },  {
    group: 'AGRO',
    value: 'soilHumidity',
  },  {
    group: 'AGRO',
    value: 'soilTemp',
  },  {
    group: 'AGRO',
    value: 'soilSurfTemp',
  },  {
    group: 'AGRO',
    value: 'leafTemp',
  },  {
    group: 'AGRO',
    value: 'leafWetnessYMSD',
  },

  {
    group: 'AIR',
    value: 'aqi',
  }, {
    group: 'AIR',
    value: 'PM100',
  },{
    group: 'AIR',
    value: 'PM10',
  }, {
    group: 'AIR',
    value: 'PM25',
  }, {
    group: 'AIR',
    value: 'noise',
  }, {
    group: 'AIR',
    value: 'NO2',
  }, {
    group: 'AIR',
    value: 'SO2',
  }, {
    group: 'AIR',
    value: 'O3',
  }, {
    group: 'AIR',
    value: 'CO',
  }, {
    group: 'AIR',
    value: 'C6H6',
  }, {
    group: 'WATER',
    value: 'temp',
  }, {
    group: 'WATER',
    value: 'ec',
  }, {
    group: 'WATER',
    value: 'oil',
  }, {
    group: 'WATER',
    value: 'chlorophyll',
  }, {
    group: 'WATER',
    value: 'orp',
  }, {
    group: 'WATER',
    value: 'ph',
  }, {
    group: 'WATER',
    value: 'do',
  }, {
    group: 'WATER',
    value: 'o2',
  }, {
    group: 'WATER',
    value: 'turbidity',
  }, {
    group: 'WEATHER',
    value: 'windSpeed',
  }, {
    group: 'WEATHER',
    value: 'windBearing',
  }, {
    group: 'WEATHER',
    value: 'precipIntensity',
  }, {
    group: 'WEATHER',
    value: 'temperature',
  }, {
    group: 'WEATHER',
    value: 'pressure',
  }, {
    group: 'WEATHER',
    value: 'humidity',
  }, {
    group: 'ALARMS',
    value: 'alarms',
  }, {
    group: 'ALARMS',
    value: 'anomalies',
  }, {
    group: 'ALARMS',
    value: 'episodes',
  }
];

const colorByGroup = {
  'AGRO': 'success',
  'AIR': 'primary',
  'WATER': 'info',
  'WEATHER': 'success',
  'ALARMS': 'error'
};

const colorByParam = {
  'noise': 'warning',
  'chlorophyll': 'success'
};

export const GROUP_BY_TYPE = {
  'agro': 'AGRO',
  'noise': 'NOISE',
  'air': 'AIR',
  'buoy': 'WATER',
  'weather-station': 'WEATHER'
};

export const iconByGroup = {
  'AGRO': <Grass sx={{background: 'white'}} color={'success'}/>,
  'NOISE': <VolumeUp sx={{background: 'white'}} color={'success'}/>,
  'AIR': <BubbleChartOutlined sx={{background: 'white'}} color={'primary'}/>,
  'WATER': <Water sx={{background: 'white'}} color={'info'}/>,
  'WEATHER': <Cloud color={'success'}/>,
  'ALARMS': <Tooltip title={"Np. Niedostępność urządzenia, kradzieź"}><NotificationImportant color={'error'}/></Tooltip>
};

export const specificIcons = {
  'aqi': <Grade sx={{background: 'white'}} color={'success'}/>,
  'noise': <VolumeUp sx={{background: 'white'}} color={'warning'}/>,
  'oil': <OilBarrel color={'info'}/>,
  'chlorophyll': <BlurOn color={'success'}/>,
  'o2': <Percent color={'info'}/>,
  'do': <Typography fontWeight={'bold'} variant={'body1'} sx={{color: primaryColor[2]}} >O<sub>2</sub></Typography>,
  'ph': <img src={PhIcon} width={35} height={35}/>,
  'ec': <img src={EcIcon} width={25} height={25}/>,
  'turbidity': <img src={TurbiIcon} width={25} height={25}/>,
  'windSpeed': <Air color={'success'}/>,
  'windBearing': <NearMe color={'success'}/>,
  'precipIntensity': <Thunderstorm color={'success'}/>,
  'temperature': <Thermostat color={'success'}/>,
  'pressure': <LineWeight color={'success'}/>,
  'humidity': <Opacity color={'success'}/>,
  'anomalies': <SettingsSuggest color={"error"} />,
  'episodes': <Warning color={"error"} />,

  'airTemp': <Thermostat color={'warning'}/>,
  'soilHumidity': <Opacity color={'warning'}/>,
  'soilTemp': <Thermostat color={'warning'}/>,
  'soilSurfTemp': <Thermostat color={'warning'}/>,
  'leafTemp': <Thermostat color={'warning'}/>,
  'leafWetnessYMSD': <Opacity color={'warning'}/>,
}

export default function DataSelectionForm({children, actionHandler, actionButtonLabel, intervals}) {
  const {t} = useTranslation();
  const {config: {devices, clientId}, email} = useConfig().state;
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [stations, setStations] = useState([]);
  const [params, setParams] = useState([]);
  const [paramsGroups, setParamsGroups] = useState([]);
  const [interval, setInterval] = useState('1h');

  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);
  const [openDrawer, setOpenDrawer] = useState(false);

  const onActionButtonClick = () => actionHandler(stations, params, dateFrom, dateTo, interval);

  function toggleDrawer() {
    setOpenDrawer(openDrawer => !openDrawer);
  }

  const setDateRange = (from, to) => {
    setDateFrom(from);
    setDateTo(to);
  };

  const deviceItems = useMemo(() => devices.filter(d=> !d.disabled).map(d => ({
    ...d,
    label: d.name,
    group: GROUP_BY_TYPE[d.type]
  })), [devices]);

  const resetAll = () => {
    setDateFrom(new Date());
    setDateTo(new Date());
    setStations([]);
    setParams([]);
    setParamsGroups([]);
  };

  const handleCheckboxChange = (e) => {
    const newGroup = e.target.name;
    const isChecked = !paramsGroups.includes(newGroup);
    const stationsMeasurements = _.uniq(stations.flatMap(d=> d.measurements));
    isChecked ? setParamsGroups([...paramsGroups, newGroup]) : setParamsGroups(paramsGroups.filter(p => p !== newGroup));
    isChecked ? setParams([...params, ...PARAMS.filter(({group}) => group === newGroup).filter(({group, value}) => ['WEATHER', 'ALARMS'].includes(group) || stationsMeasurements.includes(value))]) : setParams(params.filter(({group}) => group !== newGroup));
  };

  const handleDeleteParam = (paramToDelete) => {
    setParams(params.filter(p => p.value !== paramToDelete));
  };

  const handleDeleteStations = (toDelete) => {
    const newStations = stations.filter(({serialNo}) => serialNo !== toDelete);
    setStations(newStations);
    adjustParamsAndGroups(newStations);
  };

  const handleChangeStations = e => {
    const serialNoList = e.target.value;
    const newStations = deviceItems.filter(({serialNo}) => serialNoList.includes(serialNo))
    setStations(newStations);
    adjustParamsAndGroups(newStations);
  };

  const adjustParamsAndGroups = (newStations) => {
    if (newStations.length === 0) {
      setParamsGroups(paramsGroups.filter(group => ['WEATHER', 'ALARMS'].includes(group)));
      setParams(params.filter(({group}) => ['WEATHER', 'ALARMS'].includes(group)));
    } else {
      const allValidMeasurements = _.uniq(newStations.flatMap(({measurements}) => measurements));
      const weatherAndAlarmsParams = params.filter(({group}) => ["WEATHER", "ALARMS"].includes(group));
      const allValidParams = _.uniq([...weatherAndAlarmsParams, ...PARAMS.filter(({value}) => allValidMeasurements.includes(value))]);
      setParams(allValidParams);
      setParamsGroups(_.uniq(allValidParams.map(({group}) => group)));
    }
  }

  return <Card
    variant={'outlined'}
    sx={{minHeight: '100%'}}
  >
    <CardHeader
      action={
        <Button sx={{float: 'right'}} startIcon={<Refresh />}
                onClick={resetAll} color={'info'}>{t('general.reset')}</Button>
      }
      title={<Button size={'large'}
                     startIcon={<BarChart/>}
                     color={'info'}>{intervals ? t('measurements.reportsPanel') : t('measurements.analyticsPanel')}</Button>}
    />
    {/* MOBILE CARD TITLE */}
    <Typography sx={{
      color: primaryColor[2],
      textAlign: 'center',
      fontSize: '1.25rem',
      paddingTop: '1em',
      '@media screen and (min-width: 50em)': {display: 'none'}
    }}>{t('measurements.analyticsPanel')}</Typography>

    <CardContent>
      {/* SEARCH FORM */}
      <Box display={'flex'} gap={4}>
        <FormControl>
          <InputLabel sx={{marginTop: -1,}}
                      id="station-select-label">{t('measurements.chooseStation')}</InputLabel>
          <Select
            labelId={'station-select-label'}
            multiple
            sx={{minWidth: 250}}
            size={'small'}
            variant="outlined"
            label={t('measurements.chooseStation')}
            value={stations.map(({serialNo}) => serialNo)}
            renderValue={(selected) => t('measurements.selected') + ': ' + selected.length}
            onChange={handleChangeStations}
          >
            {deviceItems.map(({serialNo, label, group}) =>
              <MenuItem key={serialNo} value={serialNo}>
                <Checkbox checked={stations.map(({serialNo}) => serialNo).indexOf(serialNo) > -1}/>
                <ListItemText primary={label}/>
                <ListItemIcon>
                  {group === 'AIR' ?
                    <BubbleChartOutlined sx={{background: 'transparent'}} color={'primary'}/> : iconByGroup[group]}
                </ListItemIcon>
              </MenuItem>)}
          </Select>
        </FormControl>

        {/*Mobile DateRangePicker*/}
        {isMobile ? <CalendarMonthIcon color={'info'} sx={{width: 33, height: 33}} onClick={toggleDrawer}/> : null}
        <Drawer anchor={'bottom'}
                sx={{height: '100%'}}
                open={openDrawer}
                onClose={toggleDrawer}>
          <Box
            sx={{
              height: 550, marginLeft: 5, marginRight: 5, display: 'flex',
              flexDirection: 'column', justifyContent: 'space-around', gap: 4, paddingTop: 5
            }}>
            <DateRangePicker
              style={{width: 200}}
              inputStyle={'mui'}
              dateFrom={dateFrom}
              dateTo={dateTo}
              buttonColor={'white'}
              onChange={setDateRange}/>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
              <Button variant={'contained'} onClick={toggleDrawer}>{t('measurements.confirm')}</Button>
            </Box>

          </Box>
        </Drawer>
        <Box
          sx={{width: 300, '@media screen and (max-width: 50em)': {display: 'none'}}}
        >
          <DateRangePicker
            inputStyle={'mui'}
            dateFrom={dateFrom}
            dateTo={dateTo}
            buttonColor={'white'}
            onChange={setDateRange}/>
        </Box>

        {intervals && <FormControl>
          <InputLabel id="interval-select-label">{t('reports.interval')}</InputLabel>
          <Select
            sx={{maxWidth: 100}}
            labelId={'interval-select-label'}
            size={'small'}
            value={interval}
            label={t('reports.interval')}
            onChange={(e) => setInterval(e.target.value)}
          >
            {intervals.map(param => <MenuItem key={param} value={param}>{param.toUpperCase()}</MenuItem>)}
          </Select>
        </FormControl>}

        <Box>
          <Button
            sx={{fontSize: isMobile && '0.6rem', width: isMobile ? 100 : 170}}
            onClick={onActionButtonClick}
            disabled={
              intervals ?
                (!paramsGroups.includes("WEATHER")) && (params.length === 0 || stations.length === 0)
                :
                (params.length === 0 || stations.length === 0)
            }
            color={'success'}
            variant={'contained'}>
            {actionButtonLabel}
          </Button>
        </Box>
      </Box>
      {/* DEVICES CHIPS */}
      <Box mt={2} flexWrap={'wrap'} display={'flex'} gap={1}>
        {stations.map(({label, serialNo, group}) =>
          <Chip
            sx={{paddingTop: 2, paddingBottom: 2}}
            variant={'outlined'}
            key={serialNo}
            label={label}
            // color={'default'}
            color={'primary'}
            onDelete={() => handleDeleteStations(serialNo)}
            avatar={<Avatar sx={{background: 'white'}}>{iconByGroup[group]}</Avatar>}
          />)}
      </Box>
      <Divider sx={{paddingTop: 2, height: 1}}/>
      {/* CHECKBOXES */}
      <Box mt={2}>
        {clientId === 'atm' && <FormControlLabel
          name={'AGRO'}
          label={t('measurements.agro')}
          control={<Checkbox size="small" sx={{
            color: '#000000',
            '&.Mui-paramsGroups': {
              color: successColor[2],
            },
          }} checked={paramsGroups.includes('AGRO')} onChange={handleCheckboxChange}/>}
        />}

        {clientId !== 'mbm' && <FormControlLabel
          name={'AIR'}
          label={t('measurements.airQuality')}
          control={<Checkbox size="small" sx={{
            color: '#000000',
            '&.Mui-paramsGroups': {
              color: warningColor[2],
            },
          }} checked={paramsGroups.includes('AIR')} onChange={handleCheckboxChange}/>}
        />}
        <FormControlLabel
          name={'WATER'}
          label={t('measurements.waterQuality')}
          control={<Checkbox size="small" sx={{
            color: '#000000',
            '&.Mui-paramsGroups': {
              color: warningColor[2],
            },
          }} checked={paramsGroups.includes('WATER')} onChange={handleCheckboxChange}/>}
        />
        <FormControlLabel
          name={'WEATHER'}
          label={t('measurements.weather')}
          control={<Checkbox size="small" sx={{
            color: '#000000',
            '&.Mui-paramsGroups': {
              color: warningColor[2],
            },
          }} checked={paramsGroups.includes('WEATHER')} onChange={handleCheckboxChange}/>}
        />
        <FormControlLabel
          name={'ALARMS'}
          label={t('measurements.alarms')}
          control={<Checkbox size="small" sx={{
            color: '#000000',
            '&.Mui-paramsGroups': {
              color: warningColor[2],
            },
          }} checked={paramsGroups.includes('ALARMS')} onChange={handleCheckboxChange}/>}
        />
      </Box>

      {/* PARAMS CHIPS */}
      {params.length > 0 && <Box mt={2} flexWrap={'wrap'} display={'flex'} gap={1}>
        {params.map(({value, group}) =>
          <Chip
            sx={{paddingTop: 2, paddingBottom: 2}}
            variant={'outlined'}
            key={value}
            label={t('measurements.parameters', {returnObjects: true})[value]}
            color={colorByParam[value] || colorByGroup[group] || 'default'}
            onDelete={() => handleDeleteParam(value)}
            avatar={<Avatar sx={{background: 'white'}}>{specificIcons[value] || iconByGroup[group]}</Avatar>}/>)}
      </Box>}

      {/* Content: Charts or Reports table */}
      {children}
    </CardContent>
  </Card>;
}
