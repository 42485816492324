import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useWindRoseContext} from '../../context/windRose/windRoseContext';
import {Marker} from '@urbica/react-map-gl';
import PolarChart from '../PolarChart/PolarChart';

const MAX_SIZE = 800; // Try with 800

export default function WindPmMarker({device, setViewport, dark}) {
  const {t} = useTranslation();
  const {state, dispatch} = useWindRoseContext();
  const {widPmData, selectedPmStation, dateFrom, dateTo} = state;
  const {name, serialNo, location} = device;

  const data = useMemo(() => widPmData[serialNo], [widPmData, serialNo]);
  const length = useMemo(() => selectedPmStation === serialNo ? MAX_SIZE : 280, [selectedPmStation, serialNo]);
  const onMarkerClick = useCallback(() => {
    dispatch({type: 'toggle-selectedPmStation', serialNo});
  }, [serialNo]);


  if (data === undefined || data.length === 0) {
    return null;
  }

  const max = (Math.max(...data) * 1.5) || 50;

  return <Marker
    longitude={location[0]}
    latitude={location[1]}
    offset={[25, 25]}
  >
    <PolarChart
      dark={dark}
      exportChartTitle={`<div><br/>${t('windRose.station')}: <b>${name}</b><br/>${t('windRose.avgLevel')} [m/s] <br/>${t('windRose.periodFrom')} ${dateFrom} ${t('windRose.to')} ${dateTo}</div>`}
      serialNo={serialNo}
      name={name}
      seriesName={"PM10"}
      data={data}
      width={length}
      height={length}
      max={max}
      units={"µg/m3"}
      onClick={onMarkerClick}
    />
  </Marker>;
}
