import React, {useMemo, useReducer} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export const VESSEL_LABELS = [/*'tracks',*/ 'name', 'mmsi', 'speed'];

const initialState = () => {
  return {
    dateFrom: moment().subtract(7, 'days'),
    dateTo: moment(),
    state: 'CONFIG', // STOPPED, READY, PLAYING, PAUSED
    reversed: false,
    speed: 10,
    // current slider value which is timestamp
    timestamp: null,
    vesselPositionsByTimestamp: {},
    // key is mmsi, value contains vessel info and positions array []
    vessels: {},
    //  Display config
    visibleLabels: ['name', 'speed']
  };
};

const PlaybackContext = React.createContext(initialState());
// expose the context via a custom hook.
export const usePlaybackContext = () => React.useContext(PlaybackContext);

function reducer(state, action) {
  switch (action.type) {
  case 'set-state':
    return {
      ...state,
      state: action.state
    };
  case 'set-dates':
    return {
      ...state,
      dateFrom: action.dateFrom || state.dateFrom,
      dateTo: action.dateTo || state.dateTo
    };
  case 'update-vessels-position':
    return {
      ...state,
      vessels: {...state.vessels, ...action.vessels},
      vesselPositionsByTimestamp: {...state.vesselPositionsByTimestamp, ...action.vesselPositionsByTimestamp}
    };
  case 'set-timestamp':
    return {
      ...state,
      timestamp: action.timestamp
    };
  case 'set-visibleLabels':
    return {
      ...state,
      visibleLabels: action.visibleLabels
    };

  case 'set-reversed':
    return {
      ...state,
      reversed: action.reversed
    };

  case 'set-speed':
    return {
      ...state,
      speed: action.speed
    };

  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function PlaybackContextProvider({children}) {

  const [playbackReducerState, playbackDispatch] = useReducer(reducer, initialState());
  const playbackContext = useMemo(() => {
    return {
      state: playbackReducerState,
      dispatch: playbackDispatch
    };
  }, [playbackReducerState]);

  return <PlaybackContext.Provider value={playbackContext}>
    {children}
  </PlaybackContext.Provider>;
}

PlaybackContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};
