import React, {useState} from 'react';
import Box from '@mui/material/Box';
import {CircularProgress, IconButton, Typography} from '@mui/material';
import {useWindRoseContext} from '../../context/windRose/windRoseContext';
import {Close} from '@mui/icons-material';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import QuickDateButtons from '../QuickDateButtons/QuickDateButtons';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {getWindRoseData, getWindWithPmData} from '../../api';
import {subDays} from 'date-fns';

const defaultStyle = {
  margin: '0 auto',
  maxWidth: 220
};

const expandedStyle = {
  position: 'absolute',
  left: -300,
  top: 290,
  margin: '0 auto',
  width: 340
};

export default function WindChartControlPanel({name, serialNo, onClick}) {
  const {clientId, weatherStation} = useConfig().state.config;
  const {state, dispatch} = useWindRoseContext();
  const {
    selectedStation,
    selectedPmStation,
    dateFrom,
    dateTo
  } = state;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const windRoseExpanded = selectedStation === serialNo;
  const windPmExpanded = selectedPmStation === serialNo;

  const expanded = windRoseExpanded || windPmExpanded;

  const onClose = () => {
      windRoseExpanded && dispatch({type: 'toggle-selectedStation', serialNo});
      windPmExpanded && dispatch({type: 'toggle-selectedPmStation', serialNo});
  };

  const fetchWindData = (from, to) => {
    console.log("quickFetchWindData", {from, to});
    setError(null);
    setLoading(true);
    const dateFrom = from.toISOString().slice(0, 'YYYY-MM-DD'.length);
    const dateTo = to.toISOString().slice(0, 'YYYY-MM-DD'.length);
    if (windRoseExpanded) {
      getWindRoseData(clientId, weatherStation, dateFrom, dateTo)
        .then(result => dispatch({type: 'set-wind-roses-data', widRosesData: result}))
        .catch(err => setError(err.response.data))
        .finally(() => setLoading(false));
    } else {
      getWindWithPmData(clientId, serialNo, dateFrom, dateTo)
        .then(result => dispatch({type: 'set-wind-pm-data', widPmData: result}))
        .catch(err => setError(err.response.data))
        .finally(() => setLoading(false));
    }
  };

  const quickFetchWindData = days => {
    const now = new Date();
    fetchWindData(subDays(now, days), now);
  };

  return (
    <Box
      px={2}
      py={expanded ? 2 : 0.5}
      borderRadius={5}
      sx={{
        backgroundColor: 'rgba(6,47,79,0.6)',
        border: '1px solid rgba(6,47,79,1)',
        color: 'white',
        ...(expanded ? expandedStyle : defaultStyle)
      }}
      onClick={expanded ? () => {
      } : onClick}
    >
      {loading && <Box
        borderRadius={5}
        sx={{backgroundColor: 'rgba(255,255,255,0.5)'}}
        width={342}
        m={-2.1}
        height={'101%'}
        paddingTop={5}
        zIndex={100} textAlign={'center'} position={'absolute'}>
        <CircularProgress size={100}/>
      </Box>}

      <Box position={'relative'} display={'flex'} flexDirection={'column'} gap={4}>
        {expanded && <Box style={{position: 'absolute', right: -10, top: -15}}>
          <IconButton color={'inherit'} onClick={onClose}>
            <Close/>
          </IconButton>
        </Box>}
        <Typography>{name}
          <br/>
          {windRoseExpanded && <Typography variant={'caption'}>{t('windRose.windAnalysis')}</Typography>}
          {windPmExpanded && <Typography variant={'caption'}>{t('windRose.pm10Analysis')}</Typography>}
        </Typography>
        {expanded && <>
          <QuickDateButtons inverted onClick={quickFetchWindData}/>

          <Box mb={2} display={'flex'} gap={1}>
            <Typography>{t('windRose.pickDate')}:</Typography>
            <DateRangePicker
              sx={{
                position: 'absolute',
                left: 0,
                top: 205,
                background: 'rgba(6,47,79,0.6)',
              }}
              dateFrom={dateFrom}
              dateTo={dateTo}
              buttonColor={'white'}
              onChange={fetchWindData}/>
          </Box>
          {error && <Typography color={'error'}>{error}</Typography>}
        </>}
      </Box>
    </Box>);
}
