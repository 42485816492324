import React, {useEffect, useRef, useState} from 'react';
import FocusTrap from 'focus-trap-react';
import {DayPicker} from 'react-day-picker';
import {usePopper} from 'react-popper';
import 'react-day-picker/dist/style.css';
import './dateRangePicker.css';
import {Box, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import {Done} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';

const DAY_FORMAT = 'y-MM-dd';

const format = date => {
  return date ? date.toLocaleDateString() : 'N/A';
};

const DateRangePicker = ({dateFrom, dateTo, inputStyle, onChange, buttonColor, sx}) => {
  const {t} = useTranslation();
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const popperRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const [selectedRange, setSelectedRange] = useState({from: dateFrom, to: dateTo});

  useEffect(() => setSelectedRange({from: dateFrom, to: dateTo}), [dateFrom, dateTo]);
  const handleRangeSelect = (range) => {
    if (!range) return;
    setSelectedRange(range);
  };

  // POPER WITH CALENDAR
  const popper = usePopper(popperRef.current, popperElement, {placement: 'bottom-start'});

  const closePopper = () => {
    setIsPopperOpen(false);
    onChange(selectedRange.from, selectedRange.to);
  };

  const openPopper = () => {
    setIsPopperOpen(true);
  };


  return (
    <Box>
      <div ref={popperRef} style={{display: 'flex', alignItems: 'center', gap: 10}}>
        {inputStyle ? <TextField
          label={t('dockReports.dateFrom')}
          size={'small'}
          value={format(selectedRange?.from, DAY_FORMAT)}
          placeholder={format(new Date(), DAY_FORMAT)}
          onClick={openPopper}
        /> : <input
          size={10}
          value={format(selectedRange?.from, DAY_FORMAT)}
          placeholder={format(new Date(), DAY_FORMAT)}
          onClick={openPopper}
        />}
        <div>{' – '}</div>
        {inputStyle ? <TextField
          label={t('dockReports.dateTo')}
          size={'small'}
          value={format(selectedRange?.to, DAY_FORMAT)}
          placeholder={format(new Date(), DAY_FORMAT)}
          onClick={openPopper}
        /> : <input
          size={10}
          value={format(selectedRange?.to, DAY_FORMAT)}
          placeholder={format(new Date(), DAY_FORMAT)}
          onClick={openPopper}
        />}
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: () => setIsPopperOpen(false)
          }}
        >
          <div
            tabIndex={-1}
            style={{
              ...sx,
              borderRadius: 4,
              boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.04), 0px 4px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px -1px rgba(0, 0, 0, 0.09)'
            }}
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              style={{position: "absolute", zIndex: 2, backgroundColor: 'rgba(6,47,79,.89)', color: "whitesmoke"}}
              disabled={{after: new Date()}}
              mode="range"
              selected={selectedRange}
              onSelect={handleRangeSelect}
              initialFocus={isPopperOpen}
              footer={<Box textAlign={'center'} textTransform={'uppercase'}>
                <Button sx={{color: buttonColor || 'primary'}} onClick={closePopper}
                        startIcon={<Done/>}>{t('measurements.confirmDates')}</Button>
              </Box>}
            />
          </div>
        </FocusTrap>
      )}
    </Box>
  );
};

export default DateRangePicker;
