import React from 'react';

// Draws white circle around vessel Icon
export default function MarkerCircle({color}) {
  return <div style={{
    position: 'absolute',
    zIndex: 10,
    top: -6,
    left: -6,
    width: 46,
    height: 46,
    border: '1px solid rgba(0,0,0, 0.6)',
    borderRadius: '50%',
    boxShadow: '0 5px 20px rgba(0, 0, 0, .25)'
  }}>
    <div style={{
      width: 44,
      height: 44,
      border: '5px solid rgba(255,255,255, 0.6)',
      borderRadius: '50%'
    }}>
      <div style={{
        width: 34,
        height: 34,
        border: '1px solid rgba(0,0,0, 0.0)',
        borderRadius: '50%'
      }}>
      </div>
    </div>
  </div>;
}
