import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Circle from './circle';
import {BubbleChart, Construction, Grass, VolumeUp, Water} from '@mui/icons-material';
import {useMeasurementsContext} from '../../context/measurements/measurementsContext';
import {useMapContext} from '../../context/map/mapContext';
import {
  // AgroIcon,
  colorByNoise,
  colorByOil, colorByPh,
  colorByPm10,
  // colorBySoilSurfTemp,
  colorsByMeasurements,
  colorsByO2,
  colorsBySerialNo, MeasurementsUnits
} from '../measurementsUtil';
import {Typography} from '@mui/material';
import {grayColor, primaryColor, warningColor} from '../../assets/jss/nextjs-material-dashboard';
import {useMaintenance} from '../../context/maintenance/maintenanceContext';

const iconStyle = (color, isSafari) => ({
  padding: 0.5,
  width: 34,
  height: 34,
  backgroundColor: color,
  color: 'white',
  boxShadow: isSafari ? 'none' : '0 5px 20px rgba(0, 0, 0, .25)',
  border: '2px solid rgba(255,255,255, 0.8)',
  borderRadius: '50%',
});


const DeviceMarker = ({device, onClick, selectedDevice}) => {
  const [circle, setCircle] = useState(false);
  const {activeMaintenances} = useMaintenance().state;
  const {last24, sliderPosition} = useMeasurementsContext().state;
  const {state: {currentAgroParam, selectedMarkers}, dispatch: mapDispatch} = useMapContext();
  const {location, measurements, name, serialNo, type} = device;

  const readValueSafe = m => (last24[serialNo] && last24[serialNo][m] && last24[serialNo][m][sliderPosition] && last24[serialNo][m][sliderPosition][1]) || null;

  const chlorophyllVal = measurements.includes('chlorophyll') ? readValueSafe('chlorophyll') : null;
  const pm100Val = type === 'air' ? readValueSafe('PM100') : null;
  const noiseVal = measurements.includes('noise') ? readValueSafe('noise') : null;
  const pm10Val = type === 'air' ? readValueSafe('PM10') : null;
  const saturationO2Val = type === 'buoy' ? readValueSafe('o2') : null;
  const waterPhVal = type === 'buoy' ? readValueSafe('ph') : null;
  // const soilTempVal = type === 'buoy' ? readValueSafe('soilTemp') : null;
  const oilInWaterVal = type === 'buoy' ? readValueSafe('oil') : null;
  // const currentAgroParamValue = type === 'agro' ?
  //   (currentAgroParam ?
  //       (last24[serialNo][currentAgroParam] ? last24[serialNo][currentAgroParam][sliderPosition][1] : null)
  //       :
  //       (last24[serialNo]?.soilSurfTemp ? last24[serialNo]?.soilSurfTemp[sliderPosition][1] : null)
  //   ) || soilTempVal
  //   : null;

  const onMouseEnter = () => {
    setCircle(true);
    mapDispatch({type: 'set-hoveredDevice', device});
  };
  const onMouseLeave = () => {
    setCircle(false);
    mapDispatch({type: 'set-hoveredDevice', device: null});
  };

  const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

  return (
    <Box
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => onClick(device)}>

      {(circle || selectedDevice?.serialNo === serialNo) && <Circle/>}
      {activeMaintenances[serialNo] ? <>
        <Construction sx={iconStyle(warningColor[0], isSafari)}/>
        <Typography fontWeight={'bold'} variant={'body2'} color={'warning'}>Serwis</Typography>
      </> : <>
        {selectedMarkers.includes('NOISE') && (!pm10Val || serialNo === 'GDY_NOISE_01') ? <>
          {type === 'air' && !!noiseVal && <VolumeUp sx={iconStyle(colorByNoise(noiseVal), isSafari)}/>}
        </> : <>
          {/*{type === 'air' && pm100Val && <BubbleChart sx={iconStyle(primaryColor[2], isSafari)}/>}*/}
          {type === 'air' && !pm100Val && <BubbleChart sx={iconStyle(colorByPm10(pm10Val || -1), isSafari)}/>}
        </>}

        {type === 'air' && pm100Val && <BubbleChart sx={iconStyle(primaryColor[2], isSafari)}/>}

        {type === 'buoy' && !!chlorophyllVal &&
          <Water sx={iconStyle(colorsByMeasurements['chlorophyll'](chlorophyllVal), isSafari)}/>}
        {type === 'buoy' && !!oilInWaterVal && <Water sx={iconStyle(colorByOil(oilInWaterVal), isSafari)}/>}
        {type === 'buoy' && !!saturationO2Val && <Water
          sx={iconStyle(saturationO2Val !== null ? colorsByO2(saturationO2Val) : (oilInWaterVal !== null ? colorByOil(oilInWaterVal) : colorsBySerialNo[serialNo]), isSafari)}/>}

        {type === 'buoy' && !saturationO2Val && !!waterPhVal &&
          <Water sx={iconStyle(colorByPh(waterPhVal), isSafari)}/>}

        {type === 'buoy' && !chlorophyllVal && !saturationO2Val && !waterPhVal && !oilInWaterVal &&
          <Water sx={iconStyle(grayColor[0], isSafari)}/>}
        {type === 'air' && !pm10Val && !pm100Val && !noiseVal && <BubbleChart sx={iconStyle(grayColor[0], isSafari)}/>}
        {/*{type === 'buoy' && !saturationO2Val && soilTempVal && <Water sx={iconStyle(colorBySoilSurfTemp(soilTempVal), isSafari)} />}*/}

        {/*{type === 'agro' && (currentAgroParam ? AgroIcon(currentAgroParam, iconStyle(colorsByMeasurements[currentAgroParam](currentAgroParamValue), isSafari))*/}
        {/*  : <Grass sx={iconStyle(colorBySoilSurfTemp(currentAgroParamValue), isSafari)}/>)}*/}

        {!!saturationO2Val && <Typography fontWeight={'bold'} variant={'body2'}
                                          color={'primary'}>O<sub>2</sub>: {saturationO2Val?.toFixed(1) || '-:-'} {MeasurementsUnits['o2']}
        </Typography>}
        {/*{!!currentAgroParam && <Typography fontWeight={'bold'} variant={'body2'} color={'primary'}>{currentAgroParamValue?.toFixed(1) || '-:-'} {MeasurementsUnits[currentAgroParam]}</Typography>}*/}
        {!!oilInWaterVal && <Typography fontWeight={'bold'} variant={'body2'}
                                        color={'primary'}>{oilInWaterVal?.toFixed(1) || '-:-'} {MeasurementsUnits['oil']}</Typography>}
        {(type === 'noise' || (selectedMarkers.includes('NOISE') && selectedMarkers?.length === 1)) && !!noiseVal &&
          <Typography fontWeight={'bold'} variant={'body2'}
                      color={'primary'}>{noiseVal?.toFixed(1) || '-:-'} {MeasurementsUnits['noise']}</Typography>}

        {/*{pm100Val && <Typography fontWeight={'bold'} variant={'body2'} color={'primary'}>{pm100Val?.toFixed(1) || '-:-'} {MeasurementsUnits['PM100']}</Typography>}*/}
        {/*{pm10Val && <Typography fontWeight={'bold'} variant={'body2'} color={'primary'}>{pm10Val?.toFixed(1) || '-:-'} {MeasurementsUnits['PM100']}</Typography>}*/}
      </>}
    </Box>
  );
};

DeviceMarker.propTypes = {
  device: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

DeviceMarker.defaultProps = {};

export default DeviceMarker;
