import React, {useEffect, useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import Header from 'components/Header/Header';
import {useConfig} from 'context/clientConfig/clientConfigContext';
import {useTranslation} from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableContainer,
  Tooltip,
  Typography
} from '@mui/material';
import DatePicker from 'react-datepicker';
import {Add, BubbleChartOutlined, CalendarMonth, Cloud, Edit, Water} from '@mui/icons-material';
import moment from 'moment/moment';
import {getAllMaintenance} from 'api';
import fnBrowserDetect from 'browserDetect';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {getComparator, stableSort, TCell, THeader} from 'components/Table/tableUtills';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import {grayColor, primaryColor} from 'assets/jss/nextjs-material-dashboard';
import {visuallyHidden} from '@mui/utils';
import TableBody from '@mui/material/TableBody';
import {DevicesCell} from 'components/Notifications/NotifsConfigTable';
import _ from 'lodash';
import {IN_PROGRESS, NEW, STARTED} from './MaintenanceStatus';
import WaterCamera from 'assets/images/water-camera.png';
import ExcelIcon from 'assets/images/Excel-icon.png';
import {initialMaintenanceObj, useMaintenance} from 'context/maintenance/maintenanceContext';
import {UNKNOWN} from './MaintenanceType';
import {isAdmin} from 'utils';
import PhotosCell from './PhotosCell';
import DescriptionCell from './DescriptionCell';
import DurationStats from './DurationStats';

export default function MaintenanceTable() {
  const {t, i18n} = useTranslation();
  const {clientId, config: {devices, retiredDevices}, email} = useConfig().state;
  const devicesDict = useMemo(() => _.keyBy([...devices, ...(retiredDevices || [])], 'serialNo'), [devices, retiredDevices]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const {state: {allMaintenances: maintenanceList}, dispatch: maintenanceDispatch} = useMaintenance();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('timestamp');

  const setMaintenanceList = maintenances => maintenanceDispatch({
    type: 'set-allMaintenances',
    allMaintenances: maintenances
  });
  const setSelectedMaintenance = m => maintenanceDispatch({type: 'set-selectedMaintenance', selectedMaintenance: m});

  moment.locale(i18n.resolvedLanguage);
  const onDateChange = date => setSelectedDate(date);
  const showPanelModal = (m) => setSelectedMaintenance(m);

  const fetchMaintenance = () => {
    setLoading(true);
    getAllMaintenance(clientId,
      moment(selectedDate).startOf('month').format('YYYY-MM-DD'),
      moment(selectedDate).endOf('month').format('YYYY-MM-DD'))
      .then(result => setMaintenanceList(result))
      .finally(() => setLoading(false));
  };
  const closePanelModal = (refresh) => {
    setSelectedMaintenance(null);
    if (refresh) {
      fetchMaintenance();
    }
  };

  useEffect(() => {
    fetchMaintenance();
  }, [clientId, selectedDate]);

  // console.log(maintenanceList);
  const rows = maintenanceList.map(({
    serialNo,
    createdAt,
    createdBy,
    reactedAt,
    updatedAt,
    updatedBy,
    status,
    type,
    initialText,
    endPhoto,
    startPhoto,
    description,
    notes
  }, idx, list) => ({
    maintenanceObj: list[idx],
    createdAt,
    createdAtDate: moment(createdAt).format('ll'),
    createdAtTime: moment(createdAt).format('dddd HH:mm'),
    createdBy,
    reactedAt,
    updatedAt,
    updatedAtDate: moment(updatedAt).format('ll'),
    updatedAtTime: moment(updatedAt).format('dddd HH:mm'),
    timeAgo: moment(updatedAt || createdAt).fromNow(),
    updatedBy,
    serialNo,
    status,
    type: type || UNKNOWN,
    initialText,
    photos: [...(startPhoto || []), ...(endPhoto || [])],
    description,
    notes: isAdmin(email) ? notes : (notes || []).filter(n => !n.private)
  }));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const newMaintenance = () => {
    setSelectedMaintenance(initialMaintenanceObj(clientId, email));
  };

  // TITLE: Name, DatePicker
  const titleComponent = <Box display={'flex'} gap={5} justifyContent={'space-between'} alignItems={'center'}>
    <Box>
      <Typography color={'primary'} variant={'h5'}>
        Prace serwisowe
      </Typography>
    </Box>
    <Box>
      <DatePicker
        customInput={
          <Button variant={'outlined'} endIcon={<CalendarMonth/>}>
            {moment(selectedDate).format('MMMM YYYY')}
          </Button>
        }
        showMonthYearPicker
        locale={i18n.resolvedLanguage}
        onChange={onDateChange}
        selected={selectedDate}
        maxDate={new Date()}
        minDate={moment('2024-08-01').toDate()}
      />
    </Box>
    <Box display={'flex'} gap={5} justifySelf={'flex-end'}>
      <Tooltip title={'Export do Excela - Już w krótce'}>
        <Button style={{color: primaryColor[2]}}>
          <img width={30} src={ExcelIcon}/>
        </Button>
      </Tooltip>
      {email === 'rwolak@seadata.pl' &&
        <Button onClick={newMaintenance} variant={'contained'} color={'warning'} startIcon={<Add/>}>
          Otwórz serwis
        </Button>}
    </Box>
  </Box>;

  return <>
    <Header/>
    <Box sx={{
      padding: 1,
      width: '100vw',
      height: 'calc(100vh - 88px)',
      '@media screen and (max-width: 50em)': {
        height: fnBrowserDetect() === 'safari' ? 'calc(100vh - 120px)' : 'calc(100vh - 60px)'
      }
    }}>
      <Card elevation={0} sx={{minHeight: '100%'}}>
        <CardHeader
          title={titleComponent}
          // EXCEL ICON - GENERATE REPORT action={<Close sx={{cursor: 'pointer'}} color={'error'} onClick={onClose}/>}
          // Moze dodać statystyki ile serwsow i ile czasu czas reakcji: subheader={selectedView === 'photos' ? photosSubheader : replaySubheader}
        />
        <CardContent>
          {loading ? <Box sx={{position: 'absolute', top: '40%', left: '40%', zIndex: 1400}}>
            <CircularProgress variant={'indeterminate'} size={200}/>
          </Box> : <Box><TableContainer>
            <Table aria-label="MaintenanceTable table">
              <TableHead>
                <TableRow sx={{backgroundColor: 'whitesmoke'}}>
                  <TableCell width={20}><THeader>#</THeader></TableCell>
                  {[{id: 'createdAt', label: 'createdAt'},
                    {id: 'stationName', label: 'stationName'},
                    // {id: 'status', label: 'status'},
                    {id: 'photos', label: 'photos'},
                    {id: 'desc', label: 'desc'},
                    {id: 'updatedAt', label: 'updatedAt'},
                    {id: 'type', label: 'type'},
                    {id: 'reactionTime', label: 'reactionTime'}
                  ].map(({id, label}) =>
                    <TableCell key={id} sortDirection={orderBy === id ? order : false}
                               align="left">
                      <TableSortLabel
                        active={orderBy === id}
                        direction={orderBy === id ? order : 'asc'}
                        onClick={event => handleRequestSort(event, id)}
                      >
                        <Typography sx={{color: primaryColor[2]}}>
                          {t('maintenance.' + label)}
                        </Typography>
                        {orderBy === id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .map(({
                    maintenanceObj,
                    createdAt,
                    createdAtDate,
                    createdAtTime,
                    createdBy,
                    reactedAt,
                    updatedAt,
                    updatedAtDate,
                    updatedAtTime,
                    timeAgo,
                    updatedBy,
                    serialNo,
                    status,
                    type,
                    initialText,
                    photos,
                    description,
                    notes,
                  }, idx) => (
                    <TableRow
                      key={idx}
                      selected={[STARTED, IN_PROGRESS].includes(status)}
                      sx={{
                        verticalAlign: 'top',
                        backgroundColor: status === NEW ? 'rgba(255, 165, 0, 0.3)' : 'transparent',
                        '&:last-child td, &:last-child th': {border: 0}
                      }}>
                      <TableCell width={20} align={'center'}>
                        <TCell>{idx + 1}</TCell>
                        {isAdmin(email) && <IconButton
                          color={'secondary'}
                          onClick={() => showPanelModal(maintenanceObj)}
                          aria-label="Edit MaintenanceTable"
                        >
                          <Edit/>
                        </IconButton>}
                      </TableCell>
                      <TableCell width={200} align="left">
                        <TCell>{createdAtDate}</TCell>
                        <TCell>{createdAtTime}</TCell>
                        <br/>
                        {initialText && <Typography sx={{
                          maxWidth: '200px',
                          wordWrap: 'break-word',
                          overflowWrap: 'break-word',
                        }} color={grayColor[1]}>{initialText}</Typography>}
                      </TableCell>
                      <TableCell width={200} align="center">
                        <DevicesCell stations={[serialNo]} devicesDict={devicesDict}/>
                        {iconsByDevice(devicesDict[serialNo])}
                      </TableCell>
                      {/*<TableCell width={120} align="left">*/}
                      {/*  <Typography fontWeight={'bold'} color={status === NEW ? warningColor[0] : grayColor[1]}>*/}
                      {/*    {t('maintenance.statusLabels', {returnObjects: true})[status]}*/}
                      {/*  </Typography>*/}
                      {/*</TableCell>*/}
                      <TableCell width={300} align="center">
                        <PhotosCell photos={photos}/>
                      </TableCell>
                      <TableCell
                        align="left"
                        // sx={{  alignItems: 'space-around'}}
                      >
                        <DescriptionCell status={status} description={description} notes={notes} t={t}/>
                      </TableCell>
                      <TableCell width={200} align="left">
                        <TCell>{updatedAtDate}</TCell>
                        <TCell>{updatedAtTime}</TCell>
                        <Typography variant={'caption'} color={grayColor[0]}>{timeAgo}</Typography>
                        <Box>
                          <Typography sx={{color: grayColor[0]}} variant={'caption'}>
                            Przez:{' '}</Typography>
                          <Typography sx={{color: primaryColor[2]}} variant={'caption'}>
                            {updatedBy}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell width={200} align="left">
                        <Typography sx={{color: primaryColor[0]}} fontWeight={'bold'}
                          // color={type === INCIDENT ? warningColor[0] : (type === UNKNOWN ? grayColor[1] : successColor[0])}
                        >
                          {t('maintenance.typeLabels', {returnObjects: true})[type]}
                        </Typography>
                        <br/>
                        <Typography variant={'caption'} color={grayColor[0]}>
                          {t('maintenance.typeLabelsDesc', {returnObjects: true})[type]}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <DurationStats status={status} createdAt={createdAt} photos={photos}
                                       language={i18n.resolvedLanguage}/>
                      </TableCell>

                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer></Box>}
        </CardContent>
      </Card>
    </Box>
  </>;
};

const iconsByDevice = (device) => {
  const iconsList = [];
  if (device.type === 'water' || device.type === 'buoy') {
    iconsList.push(<Water sx={{backgroundColor: 'white', borderRadius: 1}} color={'info'}/>);
  }
  if (device.type === 'air') {
    iconsList.push(<BubbleChartOutlined sx={{backgroundColor: 'white', borderRadius: 1}} color={'primary'}/>);
  }
  if (device.serialNo.includes('WEATHER')) {
    iconsList.push(<Cloud sx={{backgroundColor: 'white', borderRadius: 1}} color={'success'}/>);
  }
  if (device.camera) {
    iconsList.push(
      <img style={{marginLeft: 10, borderRadius: 5}} height={'25px'} src={WaterCamera} alt={'Camera on Water'}/>
    );
  }

  return <Box ml={2} mt={2} sx={{textAlign: 'left'}}>
    {iconsList}
  </Box>;
};
