import {darkCardHeader, primaryCardHeader} from './cardHeaderStyle';
import {grayColor} from '../PortManagement/DockStats/colors';


const cardIconStyle = {
  cardIcon: {
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader,&$darkCardHeader": {
      borderRadius: "3px",
      backgroundColor: grayColor[0],
      padding: "5px",
      marginTop: "-20px",
      marginRight: "15px",
      float: "left",
    },
  },
  primaryCardHeader,
  darkCardHeader,
};

export default cardIconStyle;
