import React from 'react';
import Highcharts from 'highcharts/highstock';
import {
  AreaRangeSeries,
  Chart,
  HighchartsChart,
  HighchartsProvider,
  LineSeries,
  Tooltip,
  XAxis,
  YAxis
} from 'react-jsx-highstock';
import applyExporting from 'highcharts/modules/exporting';
import applyHighchartsMore from 'highcharts/highcharts-more';
import {useTranslation} from 'react-i18next';

applyExporting(Highcharts);
applyHighchartsMore(Highcharts);

const plotOptions = {
  series: {
    borderWidth: 0,
    grouping: false,
    dataLabels: {
      enabled: false,
      format: '{point.y:.1f}'
    }
  },
};

const timeOptions = {
  useUTC: false
};

const options = {};

export default function WaterLevelChart({data, selectedInterval, selectedYear}) {
  const {t: translation} = useTranslation();
  const t = translation('openData.rainChart', {returnObjects: true});
  const dataKey = selectedInterval + '-' + selectedYear;
  const averages = data.map(array => {
    return [array[0], array[1]];
  });
  const ranges = data.map(array => {
    return [array[0], array[2], array[3]];
  });

  return <HighchartsProvider Highcharts={Highcharts}>
    <HighchartsChart
      options={options}
      plotOptions={plotOptions}
      time={timeOptions}
      // accessibility={{
      //   announceNewData: {
      //     enabled: true
      //   }
      // }}
    >
      <Chart/>

      <Tooltip
        shared
        borderColor={'rgb(124, 181, 236)'}
        valueSuffix={'m'}
      />

      {/*{selectedInterval === '1d' && <Navigator>*/}
      {/*  <Navigator.Series/>*/}
      {/*</Navigator>*/}
      {/*}*/}

      <XAxis type={'datetime'}/>

      <YAxis>
        <LineSeries
          name={'Śr. Poziom Wody'}
          data={averages}
          zIndex={1}
          marker={{
            fillColor: 'white',
            lineWidth: 2,
            lineColor: Highcharts.getOptions().colors[0]
          }}
        />

        <AreaRangeSeries
          name={'Max - Min'}
          data={ranges}
          lineWidth={0}
          linkedTo={':previous'}
          color={Highcharts.getOptions().colors[0]}
          fillOpacity={0.3}
          zIndex={0}
          marker={{
            enabled: false
          }}
        />
        {/*<LineSeries value={0.0} witdth={0} color='red' id='zero' name="0.0m" dashStyle="shortdash" />*/}
      </YAxis>
    </HighchartsChart>
  </HighchartsProvider>;
}
