import {Box} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import './swal_custom_style.css';
import PhotoComponent from './PhotoComponent';
import {useCameraContext} from '../../context/camera/cameraContext';
import moment from 'moment';

export default function PhotosView({images, device}) {
  const {t, i18n} = useTranslation();
  const {state: {selectedDate}} = useCameraContext();

  const imgToDisplay = images.map(imgItem => (
    <PhotoComponent key={imgItem.timestamp} metadata={imgItem} device={device}/>
  ));

  return <Box display={'flex'} width={'100%'} gap={4} flexWrap={'wrap'}>
    {moment(selectedDate).isSame(moment.now(), 'day') ? imgToDisplay : imgToDisplay.reverse()}
  </Box>;
}
