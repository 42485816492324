import Box from '@mui/material/Box';
import {AirTwoTone, InvertColorsTwoTone, ThermostatTwoTone} from '@mui/icons-material';
import {Divider, Typography} from '@mui/material';
import './RiskInfoTooltip.css';
import {useTranslation} from 'react-i18next';
import PressureIcon from '../../components/icons/PressureIcon';
import {primaryColor} from '../../assets/jss/nextjs-material-dashboard';
import {formatDate, RISK_COLOR} from '../../utils';
import {useRiskContext} from '../../context/risk/riskContext';
import {useMeasurementsContext} from '../../context/measurements/measurementsContext';


export default function RiskAndWeatherTooltip() {
  const {t} = useTranslation();
  const {state: {riskAreas, riskSliderValue, riskCalculation}} = useRiskContext();
  const {state: {selectedDate}} = useMeasurementsContext();
  const labels = t('risk.riskLevelLabels', {returnObjects: true});
  const warnings = t('risk.warnings', {returnObjects: true});

  const {
    risk_pollution: pollutionRiskLvl,
    temp,
    windSpeed,
    humidity,
    pressure,
    precipProb: rainProb
  } = (riskAreas.length === 0 || riskAreas[0].values.length <= riskSliderValue) ? {} : riskAreas[0].values[riskSliderValue];

  const dividerColor = RISK_COLOR[pollutionRiskLvl] || 'grey';

  const {
    humidityTrend, humidityWarnings, precipTrend, precipWarnings, precipWarningsGreen, pressureTrend,
    pressureWarnings, tempTrend, tempWarnings, windWarnings, windWarningsGreen
  } = riskCalculation;

  return <Box width={400}>
    <Typography>{t('risk.acuText')} {formatDate(selectedDate)}: {riskSliderValue}:00 is <b style={{textTransform: 'uppercase'}}>{labels[pollutionRiskLvl]}</b></Typography>
    <br/>
    {/* WIND */}
    <Typography>{t('risk.weatherConditions')}:</Typography>
    <br/>
    <Box>
      <Box float={'left'} display={'flex'} gap={1} mb={1}>
        <AirTwoTone/>
        <Typography>{' '}{t('measurements.windSpeed')}: {windSpeed}m/s</Typography>
      </Box>
      <Box ml={3}>
        {windWarnings.map(warnCode => <><Typography variant={'caption'}>{warnings[warnCode]}</Typography><br/></>)}
      </Box>
    </Box>
    <Divider sx={{my: 1}} color={dividerColor}/>

    {/* TEMP */}
    <Box>
      <Box float={'left'} display={'flex'} gap={1} mb={1}>
        <ThermostatTwoTone/>
        <Typography> {' '}{t('measurements.temperature')}: {temp}°C</Typography>
      </Box>
      <Box ml={3}>
        {tempWarnings.map(warnCode => <><Typography variant={'caption'}>{warnings[warnCode]}</Typography><br/></>)}
      </Box>
    </Box>
    <Divider sx={{my: 1}} color={dividerColor}/>
    {/* HUMIDITY */}
    <Box>
      <Box float={'left'} display={'flex'} gap={1} mb={1}>
        <InvertColorsTwoTone/>
        <Typography> {' '} {t('measurements.humidity')}: {Math.round(humidity)}%</Typography>
      </Box>
      <Box ml={3}>
        {humidityWarnings.map(warnCode => <><Typography variant={'caption'}>{warnings[warnCode]}</Typography><br/></>)}
      </Box>
    </Box>
    <Divider sx={{my: 1}} color={dividerColor}/>

    {/* PRESSURE */}
    <Box>
      <Box float={'left'} display={'flex'} gap={1} mb={1}>
        <Box sx={{
          borderRadius: '50%',
          display: 'inline-block',
          width: '20px',
          height: '20px',
          backgroundColor: 'whitesmoke',
        }}>
          <PressureIcon color={primaryColor[0]} sx={{width: 20, height: 20}}/>
        </Box>
        <Typography>{' '}{t('measurements.pressure')}: {pressure} hPa</Typography>
      </Box>
      <Box ml={3}>
        {pressureWarnings.map(warnCode => <><Typography variant={'caption'}>{warnings[warnCode]}</Typography><br/></>)}
      </Box>
    </Box>
    <Divider sx={{my: 1}} color={dividerColor}/>

    {/* RAIN */}
    <Box>
      <Box float={'left'} display={'flex'} gap={1} mb={1}>
        <InvertColorsTwoTone/>
        <Typography> {' '} {t('risk.rainProb')}: {rainProb}% {' '} </Typography>
      </Box>
      <Box ml={3}>
        {precipWarnings.map(warnCode => <><Typography variant={'caption'}>{warnings[warnCode]}</Typography><br/></>)}
      </Box>
    </Box>
  </Box>;
}
