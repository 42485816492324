import React from 'react';
import FilePreview from './FilePreview';


export default function PreviewWrapper(props) {
  const {meta, filesProgress} = props;
  const {name} = meta;
  const progress = filesProgress[name];
  const percent = Math.round((progress?.loaded / progress?.total) * 100);
  const isUpload = percent !== 100;

  const newMeta = {
    ...meta,
    percent,
    status: ['uploading', 'done'].includes(meta.status) ? (percent === 100 ? 'done' : 'uploading') : meta.status
  };
  // console.log({newMeta, isUpload});
  return <FilePreview {...props} isUpload={isUpload} meta={newMeta}/>;
}
