// @mui material components
import {makeStyles} from '@mui/styles';
import colors from 'assets/theme/base/colors';
import linearGradient from 'assets/theme/functions/linearGradient';
import typography from 'assets/theme/base/typography';

export default makeStyles(({  }) => {
  const { gradients, transparent } = colors;
  const { fontWeightLight, fontWeightRegular, fontWeightMedium, fontWeightBold } = typography;

  return {
    suiTypography: {
      color: ({ textColor }) => (textColor === "inherit" ? "inherit" : colors[textColor].main),
      opacity: ({ opacity }) => opacity,
      textDecoration: "none",
    },

    suiTypography_light: {
      fontWeight: fontWeightLight,
    },

    suiTypography_regular: {
      fontWeight: fontWeightRegular,
    },

    suiTypography_medium: {
      fontWeight: fontWeightMedium,
    },

    suiTypography_bold: {
      fontWeight: fontWeightBold,
    },

    suiTypography_textTransform: {
      textTransform: ({ textTransform }) => textTransform,
    },

    suiTypography_verticalAlign: {
      verticalAlign: ({ verticalAlign }) => verticalAlign,
    },

    suiTypography_textGradient: {
      backgroundImage: ({ textColor }) =>
        textColor !== "inherit" &&
        textColor !== "text" &&
        textColor !== "white" &&
        linearGradient(gradients[textColor].main, gradients[textColor].state),
      display: "inline-block",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: transparent.main,
      position: "relative",
      zIndex: 1,
    },
  };
});
