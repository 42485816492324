import React, {useMemo, useReducer} from 'react';
import {Auth} from 'aws-amplify';
import PropTypes from 'prop-types';
import _ from 'lodash';

const initialState = () => {
  return {
    surveys: null,
    surveysByParam: {},
    currentParam: null,
    currentDate: null,
    // map with key: param-YYYY-MM-DD, and value: {
    //
    // }
    layersCache: {

    }
  };
};


const SurveysContext = React.createContext(initialState());
// expose the context via a custom hook.
export const useSurveysContext = () => React.useContext(SurveysContext);

function reducer(state, action) {
  switch (action.type) {
  case 'set-surveys':
    return {
      ...state,
      surveys: action.surveys,
      surveysByParam: action.surveys.reduce((acc, item) => ({...acc, ...item}), {})
    };
  case 'set-current-survey-param':
    return {
      ...state,
      currentParam: state.currentParam === action.surveyParam ? null : action.surveyParam
    };
  case 'set-current-survey-date':
    return {
      ...state,
      currentDate: action.date,
    };
  case 'add-survey-layer':
    return {
      ...state,
      layersCache: {
        ...state.layersCache,
        [action.param + '-' + action.date]: action.layer
      },
    };
  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function SurveysContextProvider({children}) {
  const [surveysReducerState, userDispatch] = useReducer(reducer, initialState());
  const userContext = useMemo(() => {
    return {
      state: surveysReducerState,
      dispatch: userDispatch
    };
  }, [surveysReducerState]);

  return <SurveysContext.Provider value={userContext}>
    {children}
  </SurveysContext.Provider>;
}

SurveysContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};
