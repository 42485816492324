import React from 'react';
import {Air, LineWeight, NearMe, Opacity, Thermostat, Thunderstorm, Water} from '@mui/icons-material';

export const oDataColor = {
  rain: 'info',
  wind: 'success',
  windlevel: 'success',
  winddir: 'success',
  temp: 'error',
  pressure: 'success',
  humidity: 'success',
  water: 'warning',
};


export const oDataIcons = {
  'rain': <Thunderstorm color={oDataColor['rain']}/>,
  'wind': <Air color={oDataColor['windlevel']}/>,
  'windlevel': <Air color={oDataColor['windlevel']}/>,
  'winddir': <NearMe color={oDataColor['winddir']}/>,
  'temp': <Thermostat color={oDataColor['temp']}/>,
  'pressure': <LineWeight color={oDataColor['pressure']}/>,
  'humidity': <Opacity color={oDataColor['humidity']}/>,
  'water': <Water color={oDataColor['water']}/>,
};

export const stationName = (name, serialNo, t) => {
  let fullName = name;
  if (['35', '18', '20', '310', '303', '1'].includes(serialNo)) {
    fullName += ` (${t('openData.onlyRain')})`;
  } else if (['2'].includes(serialNo)) {
    fullName += ` (${t('openData.rainAndWind')})`;
  } else if ('307' === serialNo) {
    fullName += ` (${t('openData.fullMeteo')})`;
  } else if (['28', '255', '254'].includes(serialNo)) {
    fullName += ` (${t('openData.onlyWaterLevel')})`;
  }
  return fullName;
};

export const stationIcon = serialNo => {
  if (['35', '18', '20', '310', '303', '1'].includes(serialNo)) {
    return oDataIcons['rain'];
  } else if (['28', '255', '254'].includes(serialNo)) {
    return oDataIcons['water'];
  } else if (serialNo === '2') {
    return [oDataIcons['rain'], oDataIcons['windlevel'], oDataIcons['winddir']];
  }

  return <></>;
};
export const colorByStation = serialNo => {
  if (['35', '18', '20', '310', '303', '1'].includes(serialNo)) {
    return 'primary';
  } else if (['2'].includes(serialNo)) {
    return 'success';
  } else if ('307' === serialNo) {
    return 'success';
  } else if (['28', '255', '254'].includes(serialNo)) {
    return 'warning';
  }
  return 'default';
};

export const compareStr = (a, b) => {
  try {
    if (a.name) {
      return a.name.localeCompare(b.name);
    } else {
      return a[0].localeCompare(b[0]);
    }
  } catch (e) {
    console.log("ERROR on compare ", a, b);
  }
};

export const compare = (a, b) => {
  if (parseInt(a.id) < parseInt(b.id)) {
    return -1;
  }
  if (parseInt(a.id) > parseInt(b.id)) {
    return 1;
  }
  return 0;
};
