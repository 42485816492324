import {Check, NotInterested} from '@mui/icons-material';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import SuiBox from '../SuiBox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {LoadingButton} from '@mui/lab';
import {validateEmail} from '../../utils';
import {Tags} from './ContactEmailInput';
import _ from 'lodash';
import {getRiskRecipients, saveRiskRecipients} from '../../api';
import {TextField} from '@mui/material';
import sortBy from 'lodash/sortBy';

export default function RiskRecipients() {
  const {t} = useTranslation();
  const {config: {clientId}} = useConfig().state;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [recipients, setRecipients] = useState([]);
  const [newRecipients, setNewRecipients] = useState([]);
  const [deletedRecipients, setDeletedRecipients] = useState([]);
  const [savedSuccess, setSavedSuccess] = useState(false);
  const tagRef = useRef();

  useEffect(() => {
      setLoading(true);
      getRiskRecipients(clientId)
        .then(result => {
          setRecipients(sortBy(result.map(a => a.toLowerCase())));
          setNewRecipients([]);
        })
        .catch(err => {
          console.error(err.response?.data);
          setError(err.response.data);
        }).finally(() => setLoading(false));
    }
    , []);

  const isValid = () => {
    return _.every(recipients, email => validateEmail(email));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const newEmail = tagRef.current.value;
    setRecipients([...recipients, newEmail]);
    setNewRecipients([...newRecipients, newEmail]);
    setSavedSuccess(false);
    // SAVE HERE
    tagRef.current.value = '';
  };

  const handleDelete = email => {
    setRecipients(recipients.filter(r => r !== email));
    setNewRecipients(newRecipients.filter(r => r !== email));
    setDeletedRecipients([...deletedRecipients, email]);
    setSavedSuccess(false);
  };

  const saveHandler = () => {
    setLoading(true);

    saveRiskRecipients(clientId, recipients)
      .then(result => {
        setRecipients(result);
        setNewRecipients([]);
        setDeletedRecipients([]);
        setSavedSuccess(true);
      })
      .catch(err => {
        console.log({err});
        setError(err.response.data);
      })
      .finally(() => setLoading(false));
  };

  return <SuiBox mt={2} component="form" pb={3} px={3}>
    {error && <Typography color={'error'}>{error}</Typography>}

    <Box>
      <Box flexWrap={'wrap'} display={'flex'} alignItems={'flex-end'} gap={2} sx={{margin: '0 0.2rem 0 0'}}>
        {recipients.map((email, index) => {
          return (
            <Tags isNew={newRecipients.includes(email)} email={email} key={index} onDelete={() => handleDelete(email)}/>
          );
        })}
      </Box>

      <TextField
        inputRef={tagRef}
        fullWidth
        variant="standard"
        sx={{maxWidth: 600, marginY: '1rem'}}
        placeholder={t('risk.addNextEmailPlaceholder')}
        onKeyDown={event => event.key === 'Enter' && handleOnSubmit(event)}
      />
    </Box>

    <Box display={'flex'} gap={2}>
      <LoadingButton
        sx={{minWidth: 300 }}
        loading={loading}
        disabled={deletedRecipients.length === 0 && newRecipients.length === 0}
        color={(error || !isValid()) ? 'error' : 'success'}
        variant={'contained'}
        startIcon={(error || !isValid()) ? <NotInterested/> : <Check/>}
        onClick={() => (!error & isValid()) && !savedSuccess && saveHandler()}>
        {savedSuccess && t('risk.changesSaved')}
        {!savedSuccess && (error ?  t('risk.repeat') : (isValid() ?  t('risk.saveChanges') :  t('risk.fixErrors')))}
      </LoadingButton>
    </Box>
  </SuiBox>;
}
