import React, {useMemo, useReducer} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';

const initialState = () => {
  return {
    // key is mmsi, value contains vessel info
    vessels: {},

    selectedDate: new Date(),
    last24: {},
    sliderPosition: 23
  };
};

const MarineTrafficContext = React.createContext(initialState());
// expose the context via a custom hook.
export const useMarineTrafficContext = () => React.useContext(MarineTrafficContext);

function reducer(state, action) {
  switch (action.type) {
  case 'set-vessels-position':
    return {
      ...state,
      vessels: action.vessels
    };

  case 'set-selectedDate':
    return {
      ...state,
      selectedDate: action.selectedDate,
      sliderPosition: moment(action.selectedDate).isSame(new Date(), "day") ? 23 : 0
    };
  case 'set-last24':
    return {
      ...state,
      last24: action.last24
    };
  case 'set-slider-position':
    return {
      ...state,
      sliderPosition: action.value
    };


  case 'update-positions':
    return {
      ...state,
      vessels: _.reduce(action.vessels,
        (acc, vesselData) => ({...acc, [vesselData.mmsi]: {...state.vessels[vesselData.mmsi], ...vesselData}}),
        state.vessels
      )
    };
  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function MarineTrafficContextProvider({children}) {

  const [marineTrafficReducerState, marineTrafficDispatch] = useReducer(reducer, initialState());
  const marineTrafficContext = useMemo(() => {
    return {
      state: marineTrafficReducerState,
      dispatch: marineTrafficDispatch
    };
  }, [marineTrafficReducerState]);

  return <MarineTrafficContext.Provider value={marineTrafficContext}>
    {children}
  </MarineTrafficContext.Provider>;
}

MarineTrafficContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};

// Example of vessel data:
/**
 {
  "ais_type_summary": "Passenger",
  "callsign": "PH2792",
  "course": 244,
  "data_source": "TER",
  "destination": "",
  "draught": 24,
  "dwt": null,
  "eta": ""
  "flag": "NL",
  "grt": null,
  "heading": 511,
  "imo": "0",
  "length": 28.6,
  "location": "52.392240,52.392240",
  "mmsi": "244750688",
  "rot": 0,
  "ship_id": "1496",
  "ship_name": "IJVEER 52",
  "ship_type": "60",
  "speed": 0,
  "status": null,
  "timestamp": 1602763940000,
  "type_name": "Inland, Ferry",
  "utc_seconds": 21,
  "width": 9,
  "year_built": "",
} **/