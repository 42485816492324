import React, {useEffect, useState} from 'react';
import LanguagePicker from '../LanguagePicker';
import Button from '@mui/material/Button';
import {atmesysColor, grayColor, warningColor} from '../../assets/jss/nextjs-material-dashboard';
import styled from '@mui/styles/styled';
import CoralLogo from '../CoralLogo';
import {useConfig} from 'context/clientConfig/clientConfigContext';
import {Box, IconButton, ListItemIcon, ListItemText, Menu, MenuList, Paper, Select} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {NAVIGATION_ITEMS} from '../../config';
import MenuItem from '@mui/material/MenuItem';
import {getClientConfig, getLatestEpisodes} from '../../api';
import './style.css';
import Typography from '@mui/material/Typography';
import {
  Cloud,
  ContentCopy,
  ContentCut,
  ContentPaste,
  MoodBad,
  Notifications,
  PowerSettingsNew,
  Settings,
  ThumbUpAlt
} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import HeaderMobile from './HeaderMobile';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import {primaryColor} from '../PortManagement/DockStats/colors';
import MMLOGO from 'assets/images/logo/mobileMonitoring-white.png';
import GDY_LOGO from 'assets/images/clientLogos/port_gdynia_logo_white.png';
import WODY_GDA_LOGO from 'assets/images/clientLogos/gdanskie-wody.png';
import ANWIL_LOGO from 'assets/images/clientLogos/anwil-logo-biale.png';
import UG_CMIOW from 'assets/images/clientLogos/ug-cmiow.png';
import {ReactComponent as ZMPGIcon} from 'assets/svg/portg-gdansk-logo.svg';
import {ReactComponent as AtmesysIcon} from 'assets/images/logo/logo-atmesys.svg';
import {ReactComponent as REM_LOGO} from 'assets/images/clientLogos/remontowa_logo.svg';
import {ReactComponent as LIP_LOGO} from 'assets/images/clientLogos/lip_logo.svg';
import MaintenancePanel from '../MaintenancePanel/MaintenancePanel';
import {useMaintenance} from '../../context/maintenance/maintenanceContext';

const CoralIconLogo = styled(CoralLogo)(({theme}) => ({
  fill: theme.palette.primary.contrastText,
}));

const LOGO_BY_CLIENT = {
  zpg: <ZMPGIcon width="200px"/>,
  gdy: <img src={GDY_LOGO} style={{marginLeft: 20, marginRight: 20}} height={50} alt={'Logo Port Gdynia'}/>,
  rem: <REM_LOGO width="200px" height="50px"/>,
  lip: <LIP_LOGO width="200px" height="50px"/>,
  wgd: <img src={WODY_GDA_LOGO} style={{marginLeft: 0, marginRight: 2}} height={50} alt={'Logo Gdańskie Wody'}/>,
  ugw: <img src={UG_CMIOW} height="50px" alt={'Logo UG'}/>,
  mbm: <img src={MMLOGO} width={300} style={{padding: 10}} alt={'Logo MBM'}/>,
  elb: <CoralIconLogo height="50px"/>,
  atm: <AtmesysIcon width="200px" height="50px"/>,
  anw: <img src={ANWIL_LOGO} width={100} alt={'Logo ANWIL S.A.'}/>,
};

export default function Header() {
  const {t} = useTranslation();
  const {state: {selectedMaintenance}, dispatch: maintenanceDispatch} = useMaintenance();
  const {state: {clientId, email, config: {modules, roles}}, dispatch} = useConfig();
  const {state: {config}} = useConfig();
  const navigate = useNavigate();
  const isMobile = false; // TODO Dopracować Mobile Header!!
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [last24hNotifs, setLast24hNotifs] = useState({});

  const allowableCount = last24hNotifs?.ALLOWABLE?.length || 0;
  const informCount = last24hNotifs?.INFORM?.length || 0;
  const alarmCount = last24hNotifs?.ALARM?.length || 0;
  const allEpisodesCount = allowableCount + informCount + alarmCount;

  useEffect(() => {
    getLatestEpisodes(clientId, '24h')
      .then(result => {
        setLast24hNotifs(result);
      });
  }, []);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClientChange = e => {
    const newClientId = e.target.value;
    getClientConfig(newClientId, 'admin')
      .then(config => {
        if (newClientId === 'ugw') {
          if (email.includes("@seadata.pl")) {
            config.modules.AIR = 1;
          }
        }
        dispatch({type: 'set-user', clientId: newClientId, config})
      });
  };
  const logout = () => dispatch({type: 'logout-user', email: null, clientId: null});

  const desktopNavigation = NAVIGATION_ITEMS(modules, roles)
    .map(({id, route, label, content}) =>
      <Box key={id}>
        <Button
          className={'btn hover-underline-animation'}
          sx={{
            color: window.location.href.includes(route.slice(3).split('#')[0]) ? '#fb8c00' : (clientId === 'atm' ? atmesysColor : 'white'),
          }}
          startIcon={content}
          onClick={() => navigate(route)}>{t('menu', {returnObjects: true})[label]}</Button>
      </Box>);

  return isMobile ? <HeaderMobile handleClientChange={handleClientChange} logout={logout}/> :
    <Box
      zIndex={2}
      width={'100%'}
      p={1}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      // sx={{background: clientId === 'atm' ? 'linear-gradient(60deg, #FFFFFF, rgba(79,174,165,0.3))' : 'linear-gradient(60deg, #192231, #007dc1)'}}
      sx={{background: ['atm'].includes(clientId) ? 'whitesmoke' : 'linear-gradient(60deg, #192231, #007dc1)'}}
    >

      {/* LOGO & EMAIL */}
      <Box sx={{maxHeight: '48px', display: 'inline-block', textAlign: 'center', marginBottom: 3}}>
        {LOGO_BY_CLIENT[clientId] || <CoralIconLogo/>}
        <Typography sx={{color: clientId === 'atm' ? atmesysColor : 'whitesmoke'}}
                    variant={'body2'}>{email}</Typography>
      </Box>

      {/* NAVBAR */}
      <Box display={'flex'} alignItems={'flex-end'} gap={8}>
        {desktopNavigation}
      </Box>

      {/* RIGHT TOOLBAR: Client Select, Lang Picker, Logout */}
      <Box mt={1} display={'flex'} alignItems={'flex-end'}>
        <Button
          onClick={handleClick}
          color={allEpisodesCount > 0 ? (alarmCount > 0 ? 'error' : 'secondary') : 'success'}
          size={'large'}
        >
          <Badge badgeContent={allEpisodesCount || '0'} color={allEpisodesCount === 0 ? 'success' : 'error'}
                 component={''}>
            <Notifications sx={{color: allEpisodesCount === 0 ? 'lightgreen' : 'inherit'}}/>
          </Badge>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {allEpisodesCount === 0 ?
            <MenuItem sx={{color: primaryColor[0]}} onClick={() => navigate('../notifications#list')}>
              <ListItemIcon>
                <ThumbUpAlt fontSize="small" color={'success'}/>
              </ListItemIcon>
              <ListItemText><Typography color={grayColor[1]}
                                        variant={'body2'}>{t('noExceedances')}</Typography></ListItemText>
            </MenuItem>
            : [
              <MenuItem key={'allowableItem'} sx={{color: primaryColor[0]}}
                        onClick={() => navigate('../notifications#list')}>
                <ListItemIcon>
                  <MoodBad fontSize="small" color={'warning'}/>
                </ListItemIcon>
                <ListItemText>
                  <Typography color={grayColor[1]}
                              variant={'body2'}>{t('exceedancesDetected')} {allEpisodesCount - alarmCount}</Typography>
                </ListItemText>
              </MenuItem>
              ,
              alarmCount > 0 && <MenuItem key={'informItem'} sx={{color: primaryColor[0]}}
                                          onClick={() => navigate('../notifications#list')}>
                <ListItemIcon>
                  <MoodBad fontSize="small" color={'error'}/>
                </ListItemIcon>
                <ListItemText>
                  <Typography color={grayColor[1]} variant={'body2'}> {t('alarmsDetected')} {alarmCount}</Typography>
                </ListItemText>
              </MenuItem>,
            ]}
          <Divider/>
          <MenuItem onClick={() => navigate('../notifications#config')} sx={{color: primaryColor[0]}}>
            <ListItemIcon>
              <Settings fontSize="small"/>
            </ListItemIcon>
            <ListItemText color={primaryColor[0]}><Typography color={'gray'}
                                                              variant={'caption'}>{t('configure')}</Typography></ListItemText>
          </MenuItem>
        </Menu>

        {(email?.includes('@seadata.pl') || email?.includes('camdrone') || email?.includes('mateuszblazejak')) &&
          <Select
            variant={'standard'}
            display={'flex'}
            size={'small'}
            sx={{
              color: 'white',
              marginRight: '1em',
              textAlign: 'center',
              '&:hover': {color: '#fb8c00'},
            }}
            disableUnderline
            value={clientId}
            label="Client"
            onChange={handleClientChange}
          >
            <MenuItem className={'hover-underline-animation'} value={'zpg'}>Port Gdańsk</MenuItem>
            {email?.includes('@seadata.pl') &&
              <MenuItem className={'hover-underline-animation'} value={'gdy'}>Port Gdynia</MenuItem>}
            <MenuItem className={'hover-underline-animation'} value={'rem'}>Remontowa</MenuItem>
            {email?.includes('@seadata.pl') &&
              <MenuItem className={'hover-underline-animation'} value={'lip'}>Liepaja</MenuItem>}
            {/*<MenuItem className={'hover-underline-animation'} value={'wgd'}>Gdańskie Wody</MenuItem>*/}
            <MenuItem className={'hover-underline-animation'} value={'ugw'}>UG - CMiOW</MenuItem>
            <MenuItem className={'hover-underline-animation'} value={'anw'}>ANWIL</MenuItem>
            {/*<MenuItem className={'hover-underline-animation'} value={'mbm'}>Konto Demo MM</MenuItem>*/}
            {/*{email?.includes('@seadata.pl') && <MenuItem className={'hover-underline-animation'} value={'elb'}>Port Elbląg</MenuItem>}*/}

          </Select>}

        {(roles?.includes('admin') || clientId === 'lip') && <LanguagePicker/>}

        <Box>
          <IconButton size={'small'}
                      sx={{
                        marginTop: 2,
                        color: clientId === 'atm' ? atmesysColor : grayColor[8],
                        ':hover': {backgroundColor: warningColor[0]}
                      }}
                      onClick={logout}>
            <PowerSettingsNew/>
          </IconButton>
        </Box>
      </Box>
      {/* Maintenance Panel MODAL */}
      {!!selectedMaintenance &&
        <MaintenancePanel maintenance={selectedMaintenance}
                          onClose={() => maintenanceDispatch({
                            type: 'set-selectedMaintenance',
                            selectedMaintenance: null
                          })}/>}
    </Box>;
}


const NotifContextMenu = ({}) =>
  <Paper sx={{width: 320, maxWidth: '100%'}}>
    <MenuList>
      <MenuItem>
        <ListItemIcon>
          <ContentCut fontSize="small"/>
        </ListItemIcon>
        <ListItemText>Cut</ListItemText>
        <Typography variant="body2" color="text.secondary">
          ⌘X
        </Typography>
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <ContentCopy fontSize="small"/>
        </ListItemIcon>
        <ListItemText>Copy</ListItemText>
        <Typography variant="body2" color="text.secondary">
          ⌘C
        </Typography>
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <ContentPaste fontSize="small"/>
        </ListItemIcon>
        <ListItemText>Paste</ListItemText>
        <Typography variant="body2" color="text.secondary">
          ⌘V
        </Typography>
      </MenuItem>
      <Divider/>
      <MenuItem>
        <ListItemIcon>
          <Cloud fontSize="small"/>
        </ListItemIcon>
        <ListItemText>Web Clipboard</ListItemText>
      </MenuItem>
    </MenuList>
  </Paper>;
