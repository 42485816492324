import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import {useTranslation} from 'react-i18next';
import VesselIcon from '../../components/icons/VesselIcon';
import {Tooltip, Typography} from '@mui/material';
import humanizeDuration from 'humanize-duration';
import {vesselColor} from './util';
import VesselCircle from './circle';
import HazardMaterialIcon from '../../assets/images/hazard-material.png';
import LighthouseImg from '../../assets/images/lighthouse_white.webp';
import AisStationIcon from '../icons/AisStationIcon';

const Vessel = ({shipInfo, mapBearing, onClick}) => {
  const {t, i18n} = useTranslation();
  const [circle, setCircle] = useState(false);

  const {mmsi, shipType, vesselType, typeName, name, status, timestamp, flag, destination, heading, speed} = shipInfo;
  // if (shipType === undefined) {
  //   return null
  // }

  console.log({shipInfo});

  const {fill, stroke} = vesselColor(shipType || vesselType);
  console.log({fill, stroke});

  const transform = `rotate(${heading - mapBearing}deg)  scale(1, 1)`;

  return (
    <Tooltip
      title={<Box sx={{fontSize: 16}} display={'flex'} flexDirection={'column'}>
        <div>{name ? <b>{name} [{flag || "PL"}]</b> : <b>MMSI: {mmsi}</b>} at <b>{(speed / 10).toFixed(1)} kn
          / {Math.round(heading)}°</b></div>
        {typeName &&
        <div>{t('vessels.type')}: <b>{t(`vessels.shipType`, {returnObjects: true})[typeName.toLowerCase()] || typeName.toLowerCase()}</b>
        </div>}
        <div>{t('general.status')}: <b>{t(`vessels.statusLabels.${status}`) || status}</b></div>
        <div>{t('vessels.destination')}: <b>{destination || "Stocznia Remontowa"}</b></div>
        <div>{t('vessels.positionReceived')}: {humanizeDuration(new Date().getTime() - timestamp, {
          units: ['d', 'h', 'm'],
          largest: 2,
          round: true,
          language: i18n.resolvedLanguage
        })} {t('vessels.ago')}
        </div>
      </Box>}
    >
      <Box onMouseEnter={() => setCircle(true)}
           onMouseLeave={() => setCircle(false)}
           onClick={() => onClick(shipInfo)}>
        {circle && <VesselCircle/>}
        {
          mmsi === "2610400" ? <AisStationIcon style={{transform: 'translate(-8px, -5px)', width:40, height: 40}}/> : typeName?.toLowerCase().includes('vts') ?
            <img style={{transform: 'translate(-8px, -8px)'}} width={40} height={40} src={LighthouseImg}
                 alt={typeName}/> :
            <VesselIcon style={{fill, stroke, transform}}/>
        }
        {typeName?.includes('Hazard') && <img src={HazardMaterialIcon} alt={shipType}/>}
        {name && <Typography variant={'caption'}>[{name}]</Typography>}
      </Box>
    </Tooltip>
  );
};

Vessel.propTypes = {
  shipInfo: PropTypes.object,
  mapBearing: PropTypes.number,
};

Vessel.defaultProps = {
  mapBearing: 0
};

export default Vessel;
