export const primaryColor = ["#062f4f", "#192231", "#007dc1", "#192231"];
export const grayColor = [
  "#999",
  "#777",
  "#3C4858",
  "#AAAAAA",
  "#D2D2D2",
  "#DDD",
  "#555555",
  "#333",
  "#eee",
  "#ccc",
  "#e4e4e4",
  "#E5E5E5",
  "#f9f9f9",
  "#f5f5f5",
  "#495057",
  "#e7e7e7",
  "#212121",
  "#c8c8c8",
  "#505050",
];

export const successColor = ["#4caf50", "#66bb6a", "#43a047", "#5cb860"];

export const blackColor = "#000";
const whiteColor = "#FFF";