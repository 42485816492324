import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useEffect, useState} from 'react';


export default function CustomLinearProgress({value, min, max, color, hidePercentage}) {
  const [interValue, setInterValue] = useState(min);
  const [inter, setInt] = useState(min);

  useEffect(() => {
    const interval = setInterval(() => {
      const stepValue = value / 20;
      setInterValue((prevValue) => prevValue + stepValue);
    }, 10);
    setInt(interval);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (interValue > value) {
      clearInterval(inter);
    }
  }, [interValue, inter]);

  return (
    <Box mt={hidePercentage ? 2 : 0} sx={{display: 'flex', alignItems: 'center'}}>
      <Box sx={{width: hidePercentage ? '85%' : '100%', mr: 1, color: color || 'darkgray'}}>
        <LinearProgress color="inherit" value={Math.max(0, Math.min(100, (interValue / max) * 100))}
                        variant="determinate" min={min} max={max}/>
      </Box>
      {!hidePercentage && <Box sx={{minWidth: 35}}>
        <Typography variant="body2" sx={{color: '#D3D3D3'}}>{`${Math.round((interValue / max) * 100)}%`}</Typography>
      </Box>}
    </Box>
  );
}
