import React, {useState} from 'react';
// Dropzone components
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import './styles.css'


import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {Add} from '@mui/icons-material';
import {Storage} from 'aws-amplify';
import PreviewWrapper from './PreviewWrapper';
import {useDockEventContext} from '../../context/dockEvent/dockEventContext';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import Swal from 'sweetalert2';
import {primaryColor} from '../../assets/jss/nextjs-material-dashboard';

const MAX_FILES = 5;
const MAX_SIZE_BYTES = 10 * 1024 * 1024; // 10 * 1MB


function FileDropzone({editMode, dockEvent}) {
  const {t} = useTranslation();
  const {email} = useConfig().state;
  const {state, dispatch} = useDockEventContext();
  const {files} = state;

  const [filesProgress, setFilesProgress] = useState({});
  const [maxFilesReached, setMaxFilesReached] = useState(false);

  // called every time a file"s `status` changes
  const handleChangeStatus = async (event, status, editMode) => {
    const {meta, file, remove, restart} = event;
    const fileName = file.name.replaceAll(' ', '_').replaceAll('(', '').replaceAll(')', '');
    const fileKey = dockEvent.dockId ? `tmp/${fileName}` : `${dockEvent.dockId}/${dockEvent.timestamp}/${fileName}`;
    switch (status) {
      case 'rejected_max_files':
        setMaxFilesReached(true);
        return;
      case 'preparing':
        if (files.length > MAX_FILES - 1) {
          Swal.fire(`${t("swal.maxFiles")}: ${MAX_FILES}!`, '', 'error');
          remove();
          return;
        }
        if (files.filter(f => fileName === f.name).length > 0) {
          Swal.fire(`${t("swal.fileAlreadyExists")}!`, '', 'error');
          remove();
          return;
        }

        await Storage.put(fileKey, file, {
          // acl: 'public-read',
          progressCallback: (progress) => {
            setFilesProgress({...filesProgress, [file.name]: progress});
          },
        });
        const newFile = {
          id: meta.id,
          s3Key: fileKey,
          name: fileName,
          size: file.size,
          addedBy: email,
          type: meta.type,
          uploadedDate: meta.uploadedDate,
          lastModified: file.lastModified,
          status: 'done'
        };
        // TODO ADD FILE
        // dispatch({type: 'add-dock-event-file', file: newFile});
        dispatch({type: 'update-dock-event', event: {files: [...files, newFile]}});
        remove();
        return;
      case 'removed':
        // await Storage.remove(fileKey);
        // dispatch({type: 'update-dock-event', event: {files: files.filter(f => f.fileUrl.includes(fileKey))}});
        return;
      default:
        return;
    }
  };

  return <Box>
    <Dropzone
      multiple={false}
      maxFiles={MAX_FILES}
      maxSizeBytes={MAX_SIZE_BYTES}
      onChangeStatus={async (event, status) => handleChangeStatus(event, status, editMode)}
      PreviewComponent={props => <PreviewWrapper {...props} filesProgress={filesProgress}/>}
      inputWithFilesContent={
        <Typography variant={'button'} color={maxFilesReached ? 'error' : 'primary'}>
          {maxFilesReached ? t("fileDropzone.tooManyFiles") : t("fileDropzone.addFile")}
        </Typography>
      }
      inputContent={<Box
        display="flex"
        gap={1}
        alignItems={"center"}
        height="100%"
      >
        <Add sx={{color: primaryColor[2]}}/>
        <Typography variant={'caption'} color="primary">
          {t('fileDropzone.addOrDropFilesHere')}
          {/*Przeciągnij pliki lub kliknij, aby dodać*/}
        </Typography>
      </Box>}
    />
  </Box>
}

// Typechecking props for the SuiDropzone
FileDropzone.propTypes = {};

export default FileDropzone;
