import React, {useMemo, useReducer} from 'react';
import {Auth} from 'aws-amplify';
import PropTypes from 'prop-types';

const initialState = () => {
  return {
    email: null,
    clientId: null,
    config: null
  };
};


const ClientConfigContext = React.createContext(initialState());
// expose the context via a custom hook.
export const useConfig = () => React.useContext(ClientConfigContext);

function reducer(state, action) {
  switch (action.type) {
  case 'set-user':
    return {
      ...state,
      email: action.email || state.email,
      clientId: action.clientId,
      config: action.config || state.config,
    };
  case 'logout-user':
    Auth.signOut()
      .then(() => {
        console.log('Log out done');
      })
      .catch(error => {
        console.log('Logout Failed', error);
      });
    return {
      ...state,
      email: null,
      clientId: null,
    };
  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function ClientConfigContextProvider({children}) {
  const [userReducerState, userDispatch] = useReducer(reducer, initialState());
  const userContext = useMemo(() => {
    return {
      state: userReducerState,
      dispatch: userDispatch
    };
  }, [userReducerState]);

  return <ClientConfigContext.Provider value={userContext}>
    {children}
  </ClientConfigContext.Provider>;
}

ClientConfigContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};
