import React, {useCallback, useEffect, useMemo, useState} from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapGL, {Layer, Marker, Source} from '@urbica/react-map-gl';
import Cluster from '@urbica/react-map-gl-cluster';
import Box from '@mui/material/Box';
import {ButtonGroup, Chip, Divider, Switch, Typography, useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Header from '../components/Header/Header';
import BaseMapSelector from '../components/BaseMapSelector/BaseMapSelector';
import {MAPBOX_TOKEN, MAPSTYLES} from '../config';
import {getAllSurveysIsolines, getSurveysIsolines} from '../api_surveys';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import {MOBILE_SCREEN, primaryColor} from '../assets/jss/nextjs-material-dashboard';
import SurveysClusterMarker from '../components/ClusterMarker/SurveysClusterMarker';
import SurveyWaterChart from '../components/AirChart/SurveyWaterChart';
import {QueryStats} from '@mui/icons-material';
import SurveyItem from '../components/SurveysMap/SurveyItem';
import {useDroneSurveysContext} from '../context/droneSurveys/droneSurveysContext';
import SurveyMarker from '../components/SurveysMap/SurveyMarker';
import SurveyInfoPopup from '../components/SurveysMap/SurveyInfoPopup';
import SatZalew from './SatZalew.png';
// import DroneZalew from './SurveyZalew.png';
import DroneZalew from './SurveyZalew-transparent.png';
import LegendSemi from './Legend-semiplain.png';
import DATAPOINT_PIN_IMG from 'assets/images/droneMission/pin-2.png';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';

const BOJKI_DANE = [
  {
    name: 'Bojka #1',
    location: [],
    measurements: []
  }
];
const OFFSET = 0.001;

const DroneSurveys = () => {
  const {t, i18n} = useTranslation();
  const [mapStyleId, setMapStyleId] = useState(MAPSTYLES[1].id);
  const [loading, setLoading] = useState(false);
  const [filled, setFilled] = useState(false);
  const [viewport, setViewport] = useState({
    latitude: 54.478542,
    longitude: 18.837588,
    zoom: 8
  });
  const [showChart, setShowChart] = useState(false);
  const [isolines, setIsolines] = useState({});
  const [datapointLocation, setDatapointLocation] = useState(null);
  const {state, dispatch} = useDroneSurveysContext();
  const {surveys, selectedSurvey, selectedMeasurement, hoveredItem} = state;
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);
  const [selected, setSelected] = useState(true);
  const [showSatData, setShowSatData] = useState(false);
  const [satDataOpacity, setSatDataOpacity] = useState(1.0);
  const [showDroneData, setShowDroneData] = useState(false);
  const [droneDataOpacity, setDroneDataOpacity] = useState(1.0);

  console.log({zoom: viewport.zoom});

  const onChartClose = () => setShowChart(false);

  const onDatapointHover = useCallback((location) => {
    setDatapointLocation(location);
  }, []);

  const handleFilledSwitch = () => {
    fetchIsolineData(selectedSurvey.sessionName, selectedSurvey.dateStr, selectedMeasurement, !filled);
    setFilled(!filled);
  };

  // GET all surveys
  useEffect(async () => {
    getAllSurveysIsolines()
      .then(result => dispatch({type: 'set-surveys', surveys: result}))
      .catch(err => console.error(err));
  }, []);

  const fetchIsolineData = (sName, dateStr, m, filled) => {
    const cacheKey = `${sName}_${dateStr}_${m}${filled ? '_filled' : ''}`;

    if (!_.keys(isolines).includes(cacheKey)) {
      setLoading(true);
      getSurveysIsolines(sName, dateStr, m, filled)
        .then(result => setIsolines({...isolines, [cacheKey]: result}))
        .catch(err => console.error(err))
        .finally(() => setLoading(false))
      ;
    }
  };

  const onSurveySelect = survey => {
    if (!selectedSurvey || survey.sessionName !== selectedSurvey.sessionName) {
      dispatch({type: 'set-selectedSurvey', selectedSurvey: survey});
      dispatch({type: 'set-selectedMeasurement', selectedMeasurement: survey.measurements[0]});
    } else {
      setShowChart(false);
      setDatapointLocation(null);
      dispatch({type: 'set-selectedSurvey', selectedSurvey: null});
    }
  };

  // When selectedSurvey Changes Reload Data
  useEffect(() => {
    if (selectedSurvey) {
      setViewport({
        zoom: 20,
        latitude: selectedSurvey.location[1],
        longitude: selectedSurvey.location[0],
      });
    } else {
      setShowChart(false);
    }
  }, [selectedSurvey]);

// When selectedMeasurment Changes Reload Data
  useEffect(() => {
    selectedSurvey && selectedMeasurement && fetchIsolineData(selectedSurvey.sessionName, selectedSurvey.dateStr, selectedMeasurement, filled);
  }, [selectedSurvey, selectedMeasurement]);


  const markers = selectedSurvey ? [] : surveys.map(s => SurveyMarker(s, dispatch, () => onSurveySelect(s)));

  const mapboxSources = useMemo(() => {
      if (!selectedSurvey) {
        return [];
      }
      const cacheKey = `${selectedSurvey.sessionName}_${selectedSurvey.dateStr}_${selectedMeasurement}${filled ? '_filled' : ''}`;
      if (!isolines[cacheKey]) {
        return [];
      }
      return _.map(isolines[cacheKey].mapboxSources, (sourceData, sourceId) => {
        return <Source key={sourceId} id={sourceId} type={'geojson'}
                       data={sourceData.data}/>;
      });
    }
    , [isolines, selectedSurvey, selectedMeasurement, filled]);

  const mapboxLayers = useMemo(() => {
      if (!selectedSurvey) {
        return [];
      }
      const cacheKey = `${selectedSurvey.sessionName}_${selectedSurvey.dateStr}_${selectedMeasurement}${filled ? '_filled' : ''}`;
      if (!isolines[cacheKey]) {
        return [];
      }

      return isolines[cacheKey].mapboxLayers.map((l) => {
        return <Layer key={l.id} {...l} />;
      });
    }
    , [isolines, selectedSurvey, selectedMeasurement, filled]);

  const onClusterClick = ({longitude, latitude}) => setViewport({longitude, latitude, zoom: viewport.zoom * 1.2});

  return <Box>
    <Header/>
    <Box display={'flex'}>
      {/* SIDEBAR */}
      <Box pt={2} sx={{
        width: 200,
        height: 'calc(100vh - 88px)',
        '@media screen and (max-width: 50em)': {display: 'none'}
      }}>
        <Box ml={2} mr={1} mb={5} display={'flex'} flexDirection={'column'} gap={1}>
          <Typography variant={'h6'}>{t('general.waterDroneMissions')}:</Typography>
        </Box>

        {surveys.map((survey) => <SurveyItem key={survey.sessionName} survey={survey}/>)}
        <Divider sx={{
          borderTop: '0px solid rgba(0, 0, 0, 0.12)',
          borderRight: '0px solid rgba(0, 0, 0, 0.12)',
          borderLeft: '0px solid rgba(0, 0, 0, 0.12)',
          backgroundColor: 'transparent',
          height: '0.0625rem',
          margin: '1rem 0px',
          borderBottom: 'none',
          opacity: 0.55,
          backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.5), rgba(52, 71, 103, 0)) !important'
        }} width={'100%'}/>

        <Box
          p={1}
          // textAlign={'center'}
          sx={{
            backgroundColor: showDroneData ? '#00d2ff' : 'inherit',
            cursor: 'pointer'
          }}
          onClick={() => {
            dispatch({type: 'set-selectedSurvey', selectedSurvey: null});
            !showDroneData && setViewport({
              latitude: 54.370329539454445,
              longitude: 19.426296491222466,
              zoom: 14
            });
            setShowDroneData(!showDroneData);
            // setShowSatData(false);
          }}
        >
          <Typography>Zalew Wiślany - Dron</Typography>
          <Typography sx={{fontSize: '11px'}} variant={'caption'}>2024-08-20</Typography>
        </Box>
        {showDroneData && <Box p={1}>
          <Chip
            size={'small'}
            color={'success'}
            sx={{p: 1, width: '100%', marginTop: '1em', marginBottom: '2em'}}
            variant={'filled'} label={<Typography color={'white'} variant={'button'}>Chlorofill - A</Typography>}
          />

          <Slider
            value={droneDataOpacity}
            onChange={(e) => setDroneDataOpacity(e.target.value)}
            min={0}
            max={1}
            step={0.1}
            marks
            valueLabelDisplay="auto"
            // valueLabelFormat={sliderLabelFormat}
            aria-labelledby="image-slider"
          />
        </Box>}
        <Divider sx={{
          borderTop: '0px solid rgba(0, 0, 0, 0.12)',
          borderRight: '0px solid rgba(0, 0, 0, 0.12)',
          borderLeft: '0px solid rgba(0, 0, 0, 0.12)',
          backgroundColor: 'transparent',
          height: '0.0625rem',
          margin: '1rem 0px',
          borderBottom: 'none',
          opacity: 0.55,
          backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.5), rgba(52, 71, 103, 0)) !important'
        }} width={'100%'}/>

        <Box
          p={1}
          // textAlign={'center'}
          sx={{backgroundColor: showSatData ? '#00d2ff' : 'inherit', cursor: 'pointer'}}
          onClick={() => {
            dispatch({type: 'set-selectedSurvey', selectedSurvey: null});
            !showSatData && setViewport({
              latitude: 54.370329539454445,
              longitude: 19.426296491222466,
              zoom: 14
            });
            setShowSatData(!showSatData);
            // setShowDroneData(false);
          }}>
          <Typography>Zalew Wiślany - Satellite</Typography>
          <Typography sx={{fontSize: '11px'}} variant={'caption'}>2024-08-20</Typography>
        </Box>
        {showSatData && <Box p={1}>
          <Chip
            size={'small'}
            color={'success'}
            sx={{p: 1, width: '100%', marginTop: '1em', marginBottom: '2em'}}
            variant={'filled'} label={<Typography color={'white'} variant={'button'}>Chlorofill - A</Typography>}
          />

          <Slider
            value={satDataOpacity}
            onChange={(e) => setSatDataOpacity(e.target.value)}
            min={0}
            max={1}
            step={0.1}
            marks
            valueLabelDisplay="auto"
            // valueLabelFormat={sliderLabelFormat}
            aria-labelledby="image-slider"
          />
        </Box>}

        <Divider sx={{mt: 15}}/>
        <Box ml={2} mr={1} mt={5} display={'flex'} flexDirection={'column'} gap={1}>
          <Typography variant={'h6'}>{t('general.uavDroneMissions')}:</Typography>
        </Box>

        <Box sx={{
          mt: 2,
          backgroundColor: 'lightblue',
          display: 'flex', flexDirection: 'column', pl: 1
        }}>
          <Typography>{t('general.plannedMissionName')}</Typography>
          <Typography>Zatoka Pucka</Typography>
          <Typography sx={{fontSize: '11px'}} variant={'caption'}>2024-10-15</Typography>
        </Box>
        <Box sx={{
          mt: 2,
          backgroundColor: 'lightblue',
          display: 'flex', flexDirection: 'column', pl: 1
        }}>
          <Typography>{t('general.plannedMissionName')}</Typography>
          <Typography>Zalew Wiślany</Typography>
          <Typography sx={{fontSize: '11px'}} variant={'caption'}>2024-10-15</Typography>
        </Box>
      </Box>

      {/* CONTENT */}
      <Box sx={{
        width: '100vw',
        height: 'calc(100vh - 88px)',
        '@media screen and (max-width: 50em)': {
          height: 'calc(100vh - 60px)'
        }
      }}>
        {loading && <Box sx={{position: 'absolute', top: '40%', left: '40%', zIndex: 1400}}>
          <CircularProgress variant={'indeterminate'} size={150}/>
        </Box>}

        <MapGL
          style={{height: '100%'}}
          viewportChangeMethod={'flyTo'}
          viewportChangeOptions={{
            duration: 1000
          }}
          {...viewport}
          onViewportChange={setViewport}
          mapStyle={mapStyleId}
          accessToken={MAPBOX_TOKEN}
        >
          <Cluster
            component={cluster => (<SurveysClusterMarker onClick={() => onClusterClick(cluster)} {...cluster} />)}>
            {markers}
          </Cluster>

          {/* POPUP */}
          {!selectedSurvey && hoveredItem && <SurveyInfoPopup survey={hoveredItem}/>}

          {/* DATA POINT LOCATION PIN */}
          {datapointLocation && showChart && <Marker
            longitude={datapointLocation[0]}
            latitude={datapointLocation[1]}
            anchor="top-left"
            offset={[-11, -11]}
          >
            <img src={DATAPOINT_PIN_IMG}/>
          </Marker>}

          {showSatData && <>
            <Source
              id="sat-zalew-source"
              type="image"
              url={SatZalew}

              coordinates={[
                [19.413942 - OFFSET, 54.382236 - OFFSET],   // Górny lewy róg (longitude, latitude)
                [19.43908 - OFFSET, 54.382236 - OFFSET],   // Górny prawy róg (longitude, latitude)
                [19.43908 - OFFSET, 54.356228 - OFFSET],   // Dolny prawy róg (longitude, latitude)
                [19.413942 - OFFSET, 54.356228 - OFFSET]    // Dolny lewy róg (longitude, latitude)
              ]}
            />
            <Layer
              id="sat-zalew-layer"
              source="sat-zalew-source"
              type="raster"
              paint={{'raster-opacity': satDataOpacity}} // Możesz zmieniać przezroczystość tutaj
            />
          </>}

          {/*    <Marker*/}
          {/*    longitude={19.426296491222466}*/}
          {/*  latitude={54.370329539454445}*/}
          {/*  anchor="center"*/}
          {/*  offset={[0, 0]}*/}
          {/*>*/}
          {/*  <img style={{opacity: 0.5}} src={SatZalew}/>*/}
          {/*</Marker>}*/}

          {showDroneData && <>
            <Source
              id="drone-zalew-source"
              type="image"
              url={DroneZalew}

              coordinates={[
                [19.413942 - OFFSET, 54.382236 - OFFSET],   // Górny lewy róg (longitude, latitude)
                [19.43908 - OFFSET, 54.382236 - OFFSET],   // Górny prawy róg (longitude, latitude)
                [19.43908 - OFFSET, 54.356228 - OFFSET],   // Dolny prawy róg (longitude, latitude)
                [19.413942 - OFFSET, 54.356228 - OFFSET]    // Dolny lewy róg (longitude, latitude)
              ]}
            />
            <Layer
              id="drone-zalew-layer"
              source="drone-zalew-source"
              type="raster"
              paint={{'raster-opacity': droneDataOpacity}} // Możesz zmieniać przezroczystość tutaj
            />
          </>}

          {mapboxSources}
          {mapboxLayers}
          {selectedSurvey && <Box sx={{
            borderRadius: 1,
            position: 'absolute',
            top: 20,
            left: 10,
          }}>
            <Switch
              color={'info'}
              checked={filled}
              onChange={handleFilledSwitch}
              value="filledSwitch"
            />
            <Typography variant={'button'}>Polygon</Typography>
          </Box>}
          {!isMobile && <BaseMapSelector
            selectedStyleId={mapStyleId}
            onStyleChange={(id) => setMapStyleId(id)}
          />}

          {(showSatData || showDroneData) && <Box sx={{
            position: 'absolute',
            bottom: 40,
            right: 10,
            height: '700px',
            // overflow: 'visible',
            // backgroundColor: 'red'
          }}>
            <img height={700} src={LegendSemi}/>
          </Box>}

          {/*  WYKRES */}
          {showChart && selectedSurvey && <Box sx={{
            position: 'absolute',
            bottom: 0,
            // left: -20,
            width: '80vw',
            // height: '25%',
            overflow: 'visible',
            backgroundColor: 'red'
          }}>

            <SurveyWaterChart sessionName={selectedSurvey.sessionName} dateStr={selectedSurvey.dateStr}
                              onClose={onChartClose} onDatapointHover={onDatapointHover}/>
          </Box>}

          {selectedSurvey && !showChart && <Box p={1} borderRadius={'50%'} sx={{
            cursor: 'pointer',
            backgroundColor: primaryColor[2],
            position: 'absolute',
            right: 20,
            bottom: 40
          }}>
            <QueryStats sx={{color: 'white', width: 50, height: 40}} onClick={() => setShowChart(true)}/>
          </Box>}
        </MapGL>
      </Box>

      {isMobile && <ButtonGroup
        fullWidth={true}
        sx={{position: 'absolute', borderRadius: 0}}
        orientation={'horizontal'}>
        <Button
          sx={{background: selected ? primaryColor[2] : 'inherit', color: selected ? 'white' : 'red'}}
          variant={'outlined'}>
          {t('general.waterDroneMissions')}</Button>
        <Button
          variant={'outlined'}
          sx={{background: selected ? primaryColor[2] : 'inherit', color: selected ? 'white' : 'red'}}
        >
          {t('general.uavDroneMissions')}
        </Button>
        <Button
          variant={'outlined'}
          sx={{background: selected ? primaryColor[2] : 'inherit', color: selected ? 'white' : 'red'}}
        >
          {t('general.plannedMissionName')}</Button>
      </ButtonGroup>}
    </Box>;
  </Box>
    ;
};

export default DroneSurveys;


