import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HumidityIcon = (props) => <SvgIcon {...props} viewBox="0 0 104 104">
  <g opacity=".9" fillRule="nonzero" fill={props.color}>
    <path
      d="M54.47 23.317a1.219 1.219 0 00-1.64 0c-.531.48-12.7 11.6-18.35 24.491a6.473 6.473 0 00-2.217-.374c-2.14 0-3.287.798-4.21 1.44-.805.56-1.44 1.003-2.814 1.003-1.37 0-2.006-.442-2.81-1.002-.922-.642-2.07-1.441-4.207-1.441a1.221 1.221 0 100 2.443c1.37 0 2.007.443 2.81 1.002.922.642 2.07 1.442 4.207 1.442 2.14 0 3.288-.8 4.21-1.442.806-.559 1.442-1.002 2.814-1.002 1.374 0 2.011.443 2.818 1.004.922.641 2.07 1.44 4.211 1.44 2.14 0 3.288-.799 4.212-1.44.805-.561 1.442-1.004 2.816-1.004a1.221 1.221 0 100-2.443c-2.14 0-3.289.798-4.212 1.44-.806.56-1.443 1.003-2.816 1.003-1.283 0-1.927-.391-2.662-.9 4.595-10.612 14.163-20.325 17.02-23.076 3.805 3.666 19.547 19.684 19.547 33.75 0 10.777-8.769 19.546-19.547 19.546-4.865 0-9.468-1.812-13.03-5.011 1.321-.245 2.174-.822 2.884-1.316.805-.56 1.442-1.003 2.816-1.003a1.221 1.221 0 100-2.443c-2.14 0-3.289.798-4.212 1.44-.805.56-1.443 1.003-2.816 1.003-1.374 0-2.011-.443-2.816-1.003-.924-.642-2.073-1.44-4.213-1.44s-3.287.798-4.209 1.44c-.805.56-1.441 1.003-2.814 1.003-1.371 0-2.007-.443-2.811-1.002-.921-.642-2.069-1.441-4.207-1.441a1.221 1.221 0 100 2.443c1.371 0 2.007.443 2.811 1.002.921.642 2.069 1.441 4.207 1.441 2.139 0 3.288-.799 4.21-1.44.805-.56 1.44-1.003 2.813-1.003 1.374 0 2.011.443 2.818 1.003.498.347 1.078.728 1.828 1.016C41.1 78.81 47.177 81.64 53.65 81.64c12.125 0 21.99-9.865 21.99-21.99 0-16.992-20.306-35.552-21.17-36.333z"
    ></path>
    <path
      d="M18.222 64.537c1.37 0 2.007.443 2.81 1.002.922.642 2.07 1.442 4.207 1.442a1.221 1.221 0 100-2.444c-1.37 0-2.006-.443-2.81-1.002-.922-.642-2.07-1.441-4.207-1.441a1.221 1.221 0 100 2.443zM32.263 62.094a1.221 1.221 0 100 2.443c1.374 0 2.011.443 2.817 1.004.923.641 2.072 1.44 4.212 1.44 2.14 0 3.288-.799 4.212-1.44.805-.561 1.442-1.004 2.816-1.004 1.397 0 2.108.455 3.007 1.03 1.036.662 2.212 1.414 4.323 1.414a1.221 1.221 0 100-2.444c-1.397 0-2.108-.454-3.006-1.03-1.037-.661-2.212-1.413-4.324-1.413-2.14 0-3.289.798-4.212 1.44-.806.56-1.443 1.003-2.816 1.003-1.374 0-2.011-.442-2.818-1.003-.922-.642-2.07-1.44-4.211-1.44zM32.263 54.764c-2.14 0-3.287.798-4.21 1.44-.805.56-1.44 1.003-2.814 1.003-1.37 0-2.006-.442-2.81-1.002-.922-.642-2.07-1.441-4.207-1.441a1.221 1.221 0 100 2.443c1.37 0 2.007.443 2.81 1.002.922.642 2.07 1.442 4.207 1.442 2.14 0 3.288-.8 4.21-1.442.806-.559 1.442-1.002 2.814-1.002a1.221 1.221 0 100-2.443zM43.504 58.21c.805-.56 1.442-1.003 2.816-1.003 1.371 0 2.007.443 2.811 1.002.921.642 2.069 1.442 4.207 1.442 2.139 0 3.288-.8 4.21-1.442.805-.56 1.441-1.002 2.813-1.002a1.221 1.221 0 100-2.443c-2.139 0-3.287.798-4.209 1.44-.805.56-1.441 1.003-2.814 1.003-1.371 0-2.007-.443-2.811-1.002-.921-.642-2.069-1.441-4.207-1.441-2.14 0-3.289.798-4.212 1.44-.806.56-1.443 1.003-2.816 1.003a1.221 1.221 0 100 2.444c2.14 0 3.288-.799 4.212-1.44z"
    ></path>
  </g>

</SvgIcon>;

export default HumidityIcon;
