import {Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Popup} from '@urbica/react-map-gl';
import {getDockInfo} from '../../api';
import {Skeleton} from '@mui/lab';
import Box from '@mui/material/Box';
import Flag from 'react-world-flags';
import {vesselImg} from '../MarineTraffic/util';
import moment from 'moment';
import PopupText from '../PopupText';
import {useTranslation} from 'react-i18next';
import {useConfig} from '../../context/clientConfig/clientConfigContext';


export default function DockInfoPopup({dockId, vesselNearby}) {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [dockInfo, setDockInfo] = useState({});
  const {config: {docks}} = useConfig().state;
  const hoveredDock = docks[dockId];
  const [latitude, longitude] = hoveredDock.center.split(',');
  const {occupied, event, vesselData, unloading, nextEvent} = dockInfo;

  useEffect(() => {
    setLoading(true);
    getDockInfo(dockId)
      .then(result => setDockInfo(result))
      .catch(err => {
        setError(err.response.data);
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [dockId]);

  return <Popup
    latitude={latitude}
    longitude={longitude}
    anchor={'bottom'}
    offset={[-15, -60]}
    closeButton={false}
    closeOnClick={false}
    maxWidth={'800px'}
  >
    <Box sx={{border: vesselNearby.length > 0 ? '1px solid red' : 'none'}} width={450} display={'flex'} flexDirection={'column'} padding={1} paddingTop={0.5}>
      <Box sx={{
        display: 'flex', flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Box>
          <Typography variant={'h4'} color={'primary'}>{hoveredDock.name}</Typography>
        </Box>
        {loading ? <Skeleton animation={'wave'} variant="text" width={200} height={50}/> : <Box sx={{
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          color: 'whitesmoke', padding: '0.4em 0.8em', borderRadius: '0.7em'
        }} width={200} backgroundColor={occupied ? '#cd5b45' : 'green'}>
          <Typography
            fontWeight={'bold'}>{occupied ? t('dockInfoPopup.occupied') : t('dockInfoPopup.free')}</Typography>
        </Box>}
      </Box>
      {error && <Typography color={'error'}>{error}</Typography>}

      {loading ? renderSkeleton() :
        occupied ? <Box sx={{display: 'flex', marginTop: '2em', marginBottom: '2em'}}>
            <Box sx={{marginRight: '1em'}}>
              <PopupText label={t('general.shipName')}>{vesselData.vesselName} </PopupText>
              <PopupText
                label={t('general.shipType')}>{t(`vessels.shipType`, {returnObjects: true})[vesselData.vesselType.toLowerCase()] || vesselData.vesselType.toLowerCase()}</PopupText>
              <PopupText label={t('general.status')}>{unloading ? t("dockInfoPopup.unloading") : t("dockInfoPopup.loading")}</PopupText>
              <PopupText label={t('dockInfoPopup.vesselFlag')}>&nbsp;<Flag height={20}
                                                                           style={{
                                                                             marginTop: 5,
                                                                             border: '1px solid #ADA9B4'
                                                                           }}
                                                                           code={vesselData.flag || 'pl'}/></PopupText>
            </Box>
            <img
              alt="ship img"
              src={vesselImg(vesselData?.imgId)}
              style={{
                marginLeft: 'auto',
                maxWidth: '150px',
                borderRadius: '0.65em',
                border: '1px solid #ADA9B4',
                float: 'right'
              }}/>
          </Box>
          :
          <Box sx={{marginTop: '2em'}}>
            <Typography fontWeight={'bold'}>{t('dockInfoPopup.dockIsFreeUntil')}</Typography>
            {vesselNearby.length ?
              <Box mt={2}>
                {vesselNearby.length > 1 ? <>
                  <Typography  fontWeight={'bold'}>{t('dockInfoPopup.nearbyVesselsDetected')}:</Typography>
                    <Box mt={1} pl={2} display={'flex'} flexDirection={'column'}>{vesselNearby.map(v => <Typography>{v.shipName}</Typography>)}</Box>
                  </>
                  :<Typography fontWeight={'normal'}>{t('dockInfoPopup.nearbyVesselDetected')}: <b>{vesselNearby[0].shipName}</b></Typography>}
              </Box>
              :
              <Typography sx={{mt: 2}} fontWeight={'normal'}>{t('dockInfoPopup.noNearbyVessels')}</Typography>
            }
          </Box>
      }

      {!loading && nextEvent && <Box sx={{
        display: 'flex', flexDirection: 'row', justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Box sx={{display: 'flex', flexDirection: 'column', marginBottom: '2em'}}>
          <Typography sx={{marginTop: 2, marginBottom: 1}}
                      fontWeight={'bold'}>{t('dockInfoPopup.nextReservation')}: </Typography>
          <PopupText label={t('general.shipName')}> <span style={{color: 'black'}}> {nextEvent.vesselName}</span></PopupText>
          <PopupText label={t('general.shipType')}> <span
            style={{color: 'black'}}> {t(`vessels.shipType`, {returnObjects: true})[nextEvent.vesselType.toLowerCase()] || nextEvent.vesselType.toLowerCase()}</span></PopupText>
          <PopupText label={t('dockInfoPopup.vesselFlag')}> <Flag height={20}
                                                                  style={{marginTop: 5, border: '1px solid #ADA9B4'}}
                                                                  code={nextEvent.flag || 'pl'}/></PopupText>
        </Box>

        <img style={{
          marginLeft: 'auto',
          maxWidth: '150px',
          borderRadius: '0.65em',
          border: '1px solid #ADA9B4',
          float: 'right'
        }}
             alt="ship img"
             src={vesselImg(nextEvent?.imgId || '1595601')}/>
      </Box>}

      {!loading && <Box>
        {occupied && <Typography fontWeight={'bold'}>{t('dockInfoPopup.bookingTime')}:</Typography>}
        {event &&
        <Typography>{new Date(event.start).toLocaleDateString()} - {new Date(event.end).toLocaleDateString()}</Typography>}
        {nextEvent &&
        <Typography>{moment().add(2, 'days').toDate().toLocaleDateString()} - {moment().add(8, 'days').toDate().toLocaleDateString()}</Typography>}
      </Box>}

    </Box>
  </Popup>;
};


const renderSkeleton = () => <Box mt={2} mb={2}>
  <Box display="flex">
    <Box sx={{marginRight: '1em'}}>
      <Skeleton animation={'wave'} variant="text" width={210} height={30}/>
      <Skeleton animation={'wave'} variant="text" width={210} height={30}/>
      <Skeleton animation={'wave'} variant="text" width={210} height={30}/>
      <Skeleton animation={'wave'} variant="text" width={210} height={30}/>
    </Box>
    <Skeleton sx={{borderRadius: '0.65em', border: '1px solid #ADA9B4',}} animation={'wave'} variant="rectangular"
              width={150} height={100}/>
  </Box>

  <Box sx={{marginTop: 2}}>
    <Skeleton animation={'wave'} variant="text" width={100} height={30}/>
    <Skeleton animation={'wave'} variant="text" width={210} height={30}/>
  </Box>
</Box>;
