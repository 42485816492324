import React, {useMemo, useReducer} from 'react';
import PropTypes from 'prop-types';

const initialState = () => {
  return {
    images: [],
    selectedDate: new Date(),
    filter: ['auto', 'custom', 'episode'],
    showAll: false // switch for all images for device (could be 1000+)
  };
};

const CameraContext = React.createContext(initialState());
// expose the context via a custom hook.
export const useCameraContext = () => React.useContext(CameraContext);

function reducer(state, action) {
  switch (action.type) {
  case 'set-images':
    return {
      ...state,
      images: action.images
    };

  case 'set-selectedDate':
    return {
      ...state,
      selectedDate: action.selectedDate,
      // filter: ['auto', 'custom', 'episode'],
      showAll: false
    };
  case 'set-filter':
    return {
      ...state,
      filter: action.filter
    };
  case 'toggle-showAll':
    return {
      ...state,
      showAll: !state.showAll
    };

  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function CameraContextProvider({children}) {
  const [state, dispatch] = useReducer(reducer, initialState());
  const cameraContext = useMemo(() => {
    return {
      state,
      dispatch
    };
  }, [state]);

  return <CameraContext.Provider value={cameraContext}>
    {children}
  </CameraContext.Provider>;
}

CameraContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};
