import React, {useEffect} from 'react';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {useMapContext} from '../../context/map/mapContext';
import {getWindWithPmData} from '../../api';
import {useWindRoseContext} from '../../context/windRose/windRoseContext';
import WindPmMarker from '../../components/WindPmMarker/WindPmMarker';
import {MAPSTYLES} from '../../config';

export default function WindPmMarkers({setViewport, mapStyleId}) {
  const {config: {clientId, devices}} = useConfig().state;
  const {state: {selectedMarkers}, dispatch: mapDispatch} = useMapContext();
  const {state: {selectedPmStation}, dispatch: windRoseDispatch} = useWindRoseContext();

  const dark = mapStyleId === MAPSTYLES[1].id;

  useEffect(() => {
    mapDispatch({type: 'set-loading', loading: true});
    const today = new Date().toISOString().slice(0, 'YYYY-MM-DD'.length);
    const serials = devices.filter(d => d.type === 'air').map(d => d.serialNo);
    getWindWithPmData(clientId, serials, today, today)
      .then(result => windRoseDispatch({type: 'set-wind-pm-data', widPmData: result}))
      .catch(err => mapDispatch({type: 'set-error', error: err.response.data}))
      .finally(() => mapDispatch({type: 'set-loading', loading: false}));
  }, [clientId]);


 return selectedMarkers.includes('WIND_PM') && devices
   .filter(device => device.type === 'air')
   .filter(device => !selectedPmStation || device.serialNo === selectedPmStation)
   .map(device => <WindPmMarker
     key={'WindPM-' + device.serialNo}
     setViewport={setViewport}
     device={device}
     dark={dark}
   />);
}
