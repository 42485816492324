import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const WindIcon = (props) => <SvgIcon {...props} viewBox="0 0 13 47"
                                     >
  <path
    d="M12.072.17c-2.008 1.196-3.85 1.62-5.523 1.274a1.07 1.07 0 01-.435-.005C4.72 1.818 2.834 1.402.456.193.331.635 1.95 1.998 5.31 4.281c.057.11.087 5.404.087 5.526v31.69c0 .461-.424.835-.947.835l-2.463.006 4.357 3.833 4.349-3.833-2.455-.006c-.523 0-.947-.374-.947-.834V9.807c0-.138.038-5.447.112-5.568 3.245-2.28 4.801-3.637 4.67-4.068z"></path>
</SvgIcon>;

export default WindIcon;
