import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PM10Icon = (props) => <SvgIcon {...props} viewBox="0 0 104 104">
    <g >
      <path fill={props.color}
        d="M29.737 71.14a10.1 10.1 0 011.64-.24c.587-.04 1.167-.06 1.74-.06.613 0 1.237.06 1.87.18s1.21.34 1.73.66.943.76 1.27 1.32c.327.56.49 1.273.49 2.14 0 .853-.153 1.573-.46 2.16a4.048 4.048 0 01-1.22 1.43 4.937 4.937 0 01-1.74.79c-.653.16-1.327.24-2.02.24h-.33a12.025 12.025 0 01-.97-.04 3.437 3.437 0 01-.34-.04V85h-1.66V71.14zm3.42 1.14c-.347 0-.68.007-1 .02-.32.013-.573.047-.76.1v5.8c.067.027.173.043.32.05l.46.02c.16.007.313.01.46.01h.32c.453 0 .903-.043 1.35-.13.447-.087.85-.243 1.21-.47.36-.227.65-.547.87-.96.22-.413.33-.94.33-1.58 0-.547-.103-1.003-.31-1.37a2.478 2.478 0 00-.82-.88 3.456 3.456 0 00-1.15-.47 5.974 5.974 0 00-1.28-.14zm18.52 3.32l.2-2.1h-.1l-.76 1.92-3.64 6.32h-.5l-3.86-6.34-.72-1.9h-.1l.28 2.08V85h-1.56V71h1.24l4.38 7.16.66 1.56h.04l.62-1.6 4.18-7.12h1.3v14h-1.66v-9.4zm5.52 7.92h2.8v-9.68l.2-1.18-.8.94-2.2 1.58-.76-1.02 4.4-3.4h.72v12.76h2.74V85h-7.1v-1.48zm9.6-5.52c0-2.4.39-4.207 1.17-5.42.78-1.213 1.917-1.82 3.41-1.82 1.613 0 2.783.597 3.51 1.79.727 1.193 1.09 3.01 1.09 5.45 0 2.4-.39 4.207-1.17 5.42-.78 1.213-1.923 1.82-3.43 1.82-1.587 0-2.747-.633-3.48-1.9-.733-1.267-1.1-3.047-1.1-5.34zm1.66 0c0 .867.057 1.657.17 2.37.113.713.29 1.327.53 1.84.24.513.543.913.91 1.2.367.287.803.43 1.31.43 1.013 0 1.757-.47 2.23-1.41.473-.94.71-2.417.71-4.43 0-.853-.05-1.64-.15-2.36-.1-.72-.267-1.34-.5-1.86-.233-.52-.537-.92-.91-1.2-.373-.28-.833-.42-1.38-.42-1 0-1.737.473-2.21 1.42-.473.947-.71 2.42-.71 4.42z"
        opacity=".904"></path>
      <g dataId="55296e5f8b">
        <path fill={props.color}
          d="M65.535 50.766a3.544 3.544 0 00-3.545-3.544c-2.658 0-3.544 1.587-3.544 3.544a3.544 3.544 0 107.089 0zm6.822-21.196a6.692 6.692 0 00-6.692 6.692c0 3.695.406 6.691 4.101 6.691 7.771 0 9.282-2.996 9.282-6.691a6.692 6.692 0 00-6.691-6.692zm-39.18 6.46c3.697 0 6.065-.46 6.693-4.102 1.079-6.26-2.996-9.282-6.692-9.282a6.692 6.692 0 000 13.384zm-6.69 13.15a2.224 2.224 0 002.223-2.225c0-1.228 0-2.224-2.224-2.224a2.224 2.224 0 000 4.448zM50.235 40.5c0-2.277-.831-4.124-4.124-4.124a4.124 4.124 0 104.124 4.124zm-7.55 7.864c-3.697 0-6.692 1.242-6.692 6.692a6.692 6.692 0 106.691-6.692zm18.786-32.312c-3.696 0-9.273.07-8.311 6.692.971 6.691 4.615 6.691 8.31 6.691a6.692 6.692 0 000-13.383zM74.989 48.42a2.756 2.756 0 00-2.757 2.756c0 1.522.69 2.757 2.757 2.757a2.756 2.756 0 000-5.513zM57.088 34.579c-3.185 0-4.311 1.93-4.311 4.31a4.311 4.311 0 108.622 0c0-2.38-.933-4.31-4.311-4.31z"
           opacity=".904" fillRule="nonzero"></path>
      </g>
    </g>
</SvgIcon>;

export default PM10Icon;
