import {BubbleChartOutlined, Check, Close, NotInterested} from '@mui/icons-material';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import MenuItem from '@mui/material/MenuItem';
import SuiBox from '../SuiBox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CustomSelect from '../CustomSelect/CustomSelect';
import {
  Checkbox,
  Chip,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  Select,
  Step,
  StepLabel,
  Stepper
} from '@mui/material';
import FormField from '../FormField';
import Avatar from '@mui/material/Avatar';
import {GROUP_BY_TYPE, iconByGroup} from '../DataSelectionForm/DataSelectionForm';
import Divider from '@mui/material/Divider';
import {dangerColor, warningColor} from '../../assets/jss/nextjs-material-dashboard';
import {primaryColor} from '../PortManagement/DockStats/colors';
import Typography from '@mui/material/Typography';
import SuiTypography from '../SuiTypography';
import {saveEpisodeConfig} from '../../api';
import {LoadingButton} from '@mui/lab';
import {unitsByParams, validateEmail, validatePhone} from '../../utils';
import {useNotificationsContext} from '../../context/notifications/notificationsContext';
import ContactEmailInput from './ContactEmailInput';
import _ from 'lodash';
import Button from '@mui/material/Button';
import ContactSMSInput from './ContactSMSInput';
import Swal from 'sweetalert2';
import {THRESHOLD_RANGE_INPUT} from '../measurementsUtil';
import {EpiCell} from './NotifsConfigTable';

const LEVELS = ["ALLOWABLE", "INFORM", "ALARM"];
const PERIODS = ["1h", "24h", "ANY"];
const EXCLUDED = ['dhtHumidity', 'dhtTemp', 'signalStrengthLvl', 'interval'];

const steps = ['Wybierz parametr', 'Ustaw limity', 'Stacje pomiarowe', 'Odbiorcy'];

export default function NewConfig() {
  const {t} = useTranslation();
  const {config: {clientId, devices, devicesWaterLevel}, email} = useConfig().state;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [availableMeasurements, setAvailableMeasurements] = useState([]);
  const [emailChecked, _setEmailChecked] = useState(true);
  const [smsChecked, _setSmsChecked] = useState(false);
  const {state: {currentConfig, configs}, dispatch} = useNotificationsContext();

  const setValue = (name, value) => dispatch({type: 'update-currentConfig', name, value});

  const onValueChange = newValue => {
    console.log("onValueChange", {newValue});
    if (Array.isArray(newValue)) {
      setValue('threshold', newValue[1]);
      setValue('thresholdLow', newValue[0]);
    } else if (!isNaN(newValue)) {
      setValue('threshold', newValue);
    } else {
      setValue('threshold', newValue.target.value);
    }
  }

  const setEmailChecked = value => {
    _setEmailChecked(value);
    // clean all emails if un-checked
    !value && dispatch({type: 'update-currentConfig', name: 'emailRecipients', value: []});
  }
  const setSmsChecked = value => {
    _setSmsChecked(value);
    !value && dispatch({type: 'update-currentConfig', name: 'smsRecipients', value: []});
  }

  const {enabled, substance, level, thresholdLow, threshold, period, stations, emailRecipients, smsRecipients} = currentConfig || {};
  useEffect(() => {
    if (emailRecipients && emailRecipients.length > 0) {
      _setSmsChecked(true);
    }
  },[emailRecipients]);

  const isValid = () => {
    return _.every(emailRecipients, email => validateEmail(email)) &&
      _.every(smsRecipients, phone => validatePhone(phone));
  }

  const cancelHandler = () => {
    dispatch({type: 'set-currentConfig', config: null});
  }

  console.log({currentConfig});
  const saveHandler = () => {
    setLoading(true);
    let newConfig = {
      ...currentConfig,
      substance,
      level,
      threshold: parseFloat(threshold),
      period,
      stations: stations.map(s => s.serialNo),
      enabled
    };

    delete newConfig.levelLabel

    newConfig.emailRecipients = emailChecked && emailRecipients.length > 0 ? emailRecipients : [];
    newConfig.smsRecipients = smsChecked && smsRecipients.length > 0 ? smsRecipients : [];
    newConfig.smsRecipients = newConfig.smsRecipients.map(smsR => (smsR.includes('+')) ? smsR : '+48' + smsR);

    console.log({threshold, newConfig});

    saveEpisodeConfig(clientId, newConfig)
      .then(result => {
        dispatch({
          type: 'set-configs',
          configs: newConfig.timestamp ? configs.map(c => c.timestamp === newConfig.timestamp ? {...c, ...newConfig} : c) : [...configs, result]
        });
        Swal.fire(t('risk.notifSaved'), '', 'success');
        cancelHandler();
      })
      .catch(err => {
        console.log({err});
        setError(err.response.data);
      })
      .finally(() => setLoading(false));
  }

  const handleDeleteStations = (toDelete) => setValue('stations', stations.filter(({serialNo}) => serialNo !== toDelete));

  const handleChangeStations = e => {
    const serialNoList = e.target.value;
    const newStations = deviceItems.filter(({serialNo}) => serialNoList.includes(serialNo))
    setValue('stations', newStations);
  };

  const activeStep = () => {
    if (substance) {
      if (level && threshold && period) {
        if (stations.length > 0) return 4;
        else return 2;
      } else return 1;
    } else return 0;
  };

  useEffect(() => {
    if (!devices?.length) return;
    // setStationNames(_.chain(devices)
    //   .keyBy('serialNo')
    //   .mapValues('name')
    //   .value());
    const a = _.uniq(devices.flatMap(d => d.measurements)).filter(m => !EXCLUDED.includes(m));
    if (email === "rwolak@seadata.pl") {
      a.push('waterLevel');
      // a.push('chlorophyll');
    }
    setAvailableMeasurements(a);
    // NO DEFAULT
    // if(a.length > 0) {
    //   setSubstance(a[0]);
    // }
  }, [devices]);

  const deviceItems = useMemo(() => ([...devices, ...(devicesWaterLevel || [])] )
    .filter(d => d.measurements.includes(substance))
    .map(d => ({
    ...d,
    name: d.name,
    group: GROUP_BY_TYPE[d.type]
  })), [devices, substance, devicesWaterLevel]);
  
  const substanceChangeHandler = e => {
    setValue('substance', e.target.value);
    setValue('stations', devices
      .filter(d => d.measurements.includes(e.target.value))
      .map(d => ({
        ...d,
        name: d.name,
        group: GROUP_BY_TYPE[d.type]
      })));
  };

  return <Box  mb={5}>
      {/* STEPPER */}
      <SuiBox p={1} display="flex" gap={4}>
        {/*<SuiTypography variant="h6">Nowa Reguła</SuiTypography>*/}

        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep()}>
            {t('risk.riskSteps', {returnObjects: true}).map((label, index) => {
              const labelProps = {};
              // if (isStepFailed(index)) {
              //   labelProps.optional = (
              //     <Typography variant="caption" color="error">
              //       Alert message
              //     </Typography>
              //   );
              //
              //   labelProps.error = true;
              // }

              return (
                <Step key={label}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      </SuiBox>
      {/* MAIN FORM */}
      <SuiBox mt={2} component="form" pb={3} px={3}>
        <Grid container spacing={5}>
          {/* FIRST LINE */}
          <Grid item xs={12} display="flex" gap={5} justifyContent={"space-between"}>
            <CustomSelect
              label={t('risk.parameter')}
              value={substance}
              onChange={substanceChangeHandler}>
              {availableMeasurements.map(m => <MenuItem key={m} value={m}>{t('measurements.parameters.' + m, m)}</MenuItem>)}
            </CustomSelect>
            {activeStep() >= 1 && <><CustomSelect
              label={t('risk.level')}
              value={level}
              onChange={e => setValue('level', e.target.value)}>
              {LEVELS.map(levelType =>
                <MenuItem value={levelType}>
                  <EpiCell episodeType={levelType} title={["PM10", "PM25", "NO2", "SO2", "C6H6"].includes(substance)  ? t('episodes.airExplanations.' + level) : t('episodes.explanations.' + level)}>
                      {t('episodes.severity.' + levelType, levelType)}
                    </EpiCell>
                </MenuItem>)}
            </CustomSelect>
              {THRESHOLD_RANGE_INPUT[substance] ? THRESHOLD_RANGE_INPUT[substance](onValueChange, thresholdLow, threshold) : <FormField
                label={t('risk.level') + ' ' + t('episodes.severity.' + level)}
                type="number"
                value={threshold}
                onChange={e => setValue('threshold', e.target.value)}
                suffix={unitsByParams(substance)}
                variant={'outlined'}
              />}

              <CustomSelect
                label={t('episodes.period')}
                minWidth={100}
                value={period}
                onChange={e => setValue('period', e.target.value)}>
                {PERIODS.map(m =>
                  <MenuItem value={m}>{t('episodes.' + m, m)}</MenuItem>)}
              </CustomSelect>
            </>}
          </Grid>

          {/* SEPARATOR VERY SLICK */}
          {activeStep() >= 2 && <Grid item xs={12}><Divider sx={{
            borderTop: '0px solid rgba(0, 0, 0, 0.12)',
            borderRight: '0px solid rgba(0, 0, 0, 0.12)',
            borderLeft: '0px solid rgba(0, 0, 0, 0.12)',
            backgroundColor: 'transparent',
            height: '0.0625rem',
            margin: '1rem 0px',
            borderBottom: 'none',
            opacity: 0.55,
            backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.5), rgba(52, 71, 103, 0)) !important'
          }} width={"100%"} /></Grid>}

          {activeStep() >= 2 && <Grid item xs={12}>
          {/*  STACJE POMIAROWE */}
            <Grid container>
              <Grid item maxWidth={300} xs={12} sm={4}
                    display="flex"
                    flexDirection="column"
                    height="100%">
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t('measurements.chooseStation')}:
                  </SuiTypography>
                </SuiBox>
                {/* DEVICES SELECT */}
                <Select
                  multiple
                  sx={{maxWidth: 250}}
                  size={'small'}
                  variant="outlined"
                  value={stations.map(({serialNo}) => serialNo)}
                  renderValue={(selected) => t('measurements.selected') + ': ' + selected.length}
                  onChange={handleChangeStations}
                >
                  {deviceItems.map(({serialNo, name, group}) =>
                    <MenuItem key={serialNo} value={serialNo}>
                      <Checkbox checked={stations.map(({serialNo}) => serialNo).indexOf(serialNo) > -1}/>
                      <ListItemText primary={name}/>
                      <ListItemIcon>
                        {group === 'AIR' ?
                          <BubbleChartOutlined sx={{background: 'transparent'}}
                                               color={'primary'}/> : iconByGroup[group]}
                      </ListItemIcon>
                    </MenuItem>)}
                </Select>
              </Grid>

              <Grid item xs={12} sm={8} >
                {/* DEVICES CHIPS */}
                <Box mt={2} flexWrap={'wrap'} display={'flex'} gap={3}>
                  {stations.map(({name, serialNo, group}) =>
                    <Chip
                      sx={{paddingTop: 2, paddingBottom: 2}}
                      variant={'outlined'}
                      key={serialNo}
                      label={name}
                      color={'primary'}
                      onDelete={() => handleDeleteStations(serialNo)}
                      avatar={<Avatar sx={{backgroundColor: 'white'}}>{iconByGroup[group]}</Avatar>}
                    />)}
                </Box>
              </Grid>
            </Grid>
          </Grid>}

          {/* SEPARATOR VERY SLICK */}
          {activeStep() >= 3 && <Grid item xs={12} >
            <Divider sx={{
              borderTop: '0px solid rgba(0, 0, 0, 0.12)',
              borderRight: '0px solid rgba(0, 0, 0, 0.12)',
              borderLeft: '0px solid rgba(0, 0, 0, 0.12)',
              backgroundColor: 'transparent',
              height: '0.0625rem',
              margin: '1rem 0px',
              borderBottom: 'none',
              opacity: 0.55,
              backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.5), rgba(52, 71, 103, 0)) !important'
            }} width={"100%"} />
            {!enabled ? <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Typography sx={{color: dangerColor[2]}} variant={'body1'}>
                {t('risk.inactiveWarning')}
              </Typography>
            </Grid> : !smsChecked && !emailChecked && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Typography sx={{color: dangerColor[2]}} variant={'body1'}>
                {t('risk.bothChannelsInactiveWarn')}</Typography>
            </Grid>}
          </Grid>}

          {/* ODBIORCY  */}
          {activeStep() >= 3 && <Grid item xs={12}>
            <Box>
              <FormControlLabel
                sx={{width: 100}}
                label={'E-mail'}
                control={<Checkbox size="small" sx={{
                  color: '#000000',
                  '&.Mui-checked': {
                    color: primaryColor[2],
                  },
                }} checked={emailChecked} onChange={() => setEmailChecked(!emailChecked)}/>}
              />
              {emailChecked && <ContactEmailInput/>}
            </Box>
            <Box>
              <FormControlLabel
                sx={{width: 100}}
                label={'SMS'}
                control={<Checkbox size="small" sx={{color: '#000000', '&.Mui-checked': {
                    color: warningColor[2],
                  }}} checked={smsChecked} onChange={() => setSmsChecked(!smsChecked)}/>}
              />
              {smsChecked && <ContactSMSInput/>}
            </Box>
          </Grid>}

          {activeStep() === 4 ? <Grid item xs={8}>
            {/*  ZAPIS? */}
            {error && <Typography color={'error'}>{error}</Typography>}
            <LoadingButton
              fullWidth
              loading={loading}
              color={(error || !isValid()) ? 'error' : 'success' }
              variant={'contained'}
              startIcon={(error || !isValid()) ? <NotInterested /> :<Check />}
              onClick={() => (!error & isValid()) && saveHandler()}>
              {error ? t('risk.repeat') : (isValid() ? t('risk.saveNotif') : t('risk.fixErrors'))}
            </LoadingButton>
          </Grid> : <Grid item xs={8}/>}
          <Grid item xs={4} display={'flex'} justifyItems={'flex-end'}>
            <Button
              fullWidth
              color={'error'}
              variant={'contained'}
              startIcon={<Close />}
              onClick={cancelHandler}>
              {t('general.cancel')}
            </Button>
          </Grid>
        </Grid>
      </SuiBox>
    </Box >;
}
