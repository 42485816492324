import { Storage } from "aws-amplify";

const REPORTS_BUCKET_URL = "https://yeti-reports.s3.eu-central-1.amazonaws.com/";

export async function s3Upload(file) {
  const filename = `${Date.now()}-${file.name}`;

  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type
  });

  return stored.key;
}

export async function s3Remove(filename) {
    const stored = await Storage.vault.remove(filename);

    return stored.key;
}

export async function s3List(clientId, path) {
  return await Storage.list(path, { customPrefix: { public: clientId } })
}

export async function s3Get(clientId, filename) {
  const stored = await Storage.get(filename, { customPrefix: { public: clientId } })
    .then(result => console.log(result))
    .catch(err => console.log(err));

  return stored.key;
}
