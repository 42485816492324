import React, {useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Tab} from '@mui/material';
import {LoadingButton, TabContext, TabList, TabPanel} from '@mui/lab';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './react-date-picker-styles.css';
import Box from '@mui/material/Box';
import {Close} from '@mui/icons-material';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';
import {useDockEventContext} from '../../../context/dockEvent/dockEventContext';
import VesselSearchForm from './VesselSearchForm';
import VesselInfo from './VesselInfo';
import CargoInfo from '../CargoInfo';
import FileDropzone from '../../FilesDropzone/FileDropzone';
import {deleteDockEvent, upsertDockEvent} from '../../../api';
import Typography from '@mui/material/Typography';
import {useConfig} from '../../../context/clientConfig/clientConfigContext';
import Swal from 'sweetalert2';
import {Storage} from 'aws-amplify';
import {removeFiles} from '../../../s3Util';
import Documents from './Documents';
import VesselParticulars from '../VesselParticulars';
import es from 'date-fns/locale/es';
import pl from 'date-fns/locale/pl';
import fr from 'date-fns/locale/fr';

registerLocale('es', es);
registerLocale('pl', pl);
registerLocale('fr', fr);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function DockEventDialog({onClose}) {
  const {t, i18n} = useTranslation();
  const {config} = useConfig().state;
  const {favouriteVessels} = useConfig().state.config?.portMgmt;
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const {state: dockEvent, dispatch} = useDockEventContext();
  const {editMode, dockId, start, end, vessel, notes, files} = dockEvent;

  const [tab, setTab] = useState('unloading');


  useEffect(() => {
    const favVessel = favouriteVessels.filter(f => f.mmsi === vessel.mmsi);
    favVessel[0] && setTab(favVessel[0].operation);
  }, [favouriteVessels, vessel]);

  const occupiedDates = [];
  // useMemo(() => dockEvents ? dockEvents[dockId].filter(evt => evt.timestamp !== dockEvent.timestamp)
  //   .map(evt => ({
  //     start: new Date(evt.start),
  //     end: new Date(evt.end)
  //   })) : [], [dockEvents, dockId, dockEvent]);

  const saveEvent = async () => {
    setSaving(true);
    const timestamp = editMode ? dockEvent.timestamp : new Date().getTime();

    const uploadedFilesPromises = Promise.all(files.filter(f => f.s3Key.includes('tmp/')).map(f => {
      const keyFrom = f.s3Key;
      const keyDest = `${dockId}/${timestamp}/${f.name}`;
      return Storage.copy({key: keyFrom}, {key: keyDest});
    }));

    const uploadedFiles = await uploadedFilesPromises;

    const newDockEvent = {
      dockId,
      timestamp,
      status: 'NEW',
      start: start.getTime(),
      end: end.getTime(),
      loadingInfo: dockEvent.loadingInfo,
      unloadingInfo: dockEvent.unloadingInfo,
      notes,
      files: files.map(f => ({...f, s3Key: f.s3Key.replace('tmp/', `${dockId}/${timestamp}/`)})),
      vessel
    };

    upsertDockEvent(newDockEvent, dockId)
      .then(r => {
        // remove files from S3 tmp folder
        files.filter(f => f.s3Key.includes('tmp/')).map(f => Storage.remove(f.s3Key));
        onClose(newDockEvent, editMode ? 'edited' : 'created');
      })
      .catch(error => {
        console.error('SAVE ERROR', error);
        setSaveError(error.toString());
      })
      .finally(() => {
        setSaving(false)
      });
  };

  const deleteEvent = () => {
    Swal.fire({
      title: `${t('dockEventDialog.cancelReservation')} '${vessel.name}' ${t('dockEventDialog.scheduledFor')} ${start.toLocaleDateString()}?`,
      icon: 'question',
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: t('general.yes'),
      denyButtonText: t('general.no'),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: t('dockEventDialog.deleteReservation'),
          html: t('dockEventDialog.waitUntilOperationComplete'),
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            Swal.hideLoading();
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log('I was closed by the timer');
          }
        });
        deleteDockEvent(dockId, dockEvent?.timestamp)
          .then((response) => {
            console.log('Event deleted', {response});
            removeFiles(dockEvent.files);
            onClose(dockEvent, 'deleted');
          })
          .catch((error) => {
            Swal.showValidationMessage(`${t('dockEventDialog.errorMessage')} ${error}`);
          })
          .finally(() => Swal.hideLoading());
      }
    });
  };

  const rejectChanges = () => {
    removeFiles(dockEvent.files.filter(f => f.s3Key.includes('tmp/')));
    onClose();
  };

  return <Dialog
    fullScreen
    open={true}
    onClose={onClose}
    TransitionComponent={Transition}
    sx={{zIndex: 60, padding: 5, paddingTop: 5, paddingLeft: 10, '@media screen and (max-width: 60em)': {padding: 0}}}
  >
    <DialogTitle>
      <div>{editMode ? t('dockEventDialog.edit') : t('dockEventDialog.new')} {t('dockEventDialog.eventAtDock')} - {config.docks[dockId]?.name || dockId}</div>
      <IconButton onClick={onClose} sx={{position: 'absolute', right: 10, top: 10}}>
        <Close/>
      </IconButton>
    </DialogTitle>
    <DialogContent sx={{marginTop: 1}}>
      <Grid container>
        <Grid item xs={6}>
          <Box mb={1} display={'flex'} gap={2} alignItems={'center'}>
            <Box>
              <Typography color={'primary'} variant={'body1'} sx={{mb: 1}}>{t("dockEventDialog.dockingTime")}:</Typography>
              <DatePicker
                locale={i18n.resolvedLanguage}
                excludeDateIntervals={occupiedDates}
                inline
                selectsRange
                monthsShown={2}
                startDate={start}
                endDate={end}
                onChange={(dates) => {
                  const [start, end] = dates;
                  dispatch({type: 'update-dock-event', event: {start, end}});
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          {vessel?.name ? <>
              <VesselInfo/>
            </> :
            <>
              <Typography color={'primary'} variant={'body1'} sx={{mb: 1}}>{t("dockEventDialog.searchForAVessel")}:</Typography>
              <VesselSearchForm/>
            </>}
        </Grid>
        {vessel.name && <Grid item xs={12}>
          <TabContext value={tab}>
            <TabList sx={{borderBottom: '1px solid #e8e8e8'}} variant={'fullWidth'} value={tab}
                     onChange={(event, newValue) => setTab(newValue)} aria-label="Event Tabs">
              <Tab value="unloading" label={t('dockEventDialog.unloading')}/>
              <Tab value="loading" label={t('dockEventDialog.loading')}/>
              <Tab value="documents" label={`${t('dockEventDialog.documents')}(${files?.length || 0})`}/>
              {vessel?.allParticulars && <Tab value="vessel" label={t('dockEventDialog.vesselGeneralInfo')}/>}
            </TabList>
            <TabPanel value={'unloading'}>
              <CargoInfo unloading={true}/>
            </TabPanel>
            <TabPanel value={'loading'}>
              <CargoInfo unloading={false}/>
            </TabPanel>
            <TabPanel value={'documents'}>
              <Box>
                <Box>
                  <FileDropzone editMode={editMode} dockEvent={dockEvent}/>
                </Box>
                <Box>
                  <Documents/>
                </Box>
              </Box>
            </TabPanel>
            {vessel?.allParticulars && <TabPanel value={'vessel'}>
              <Box>
                <VesselParticulars/>
              </Box>
            </TabPanel>}
          </TabContext>
        </Grid>}
      </Grid>

    </DialogContent>

    <DialogActions sx={{padding: 2}}>
      {/* Delete Button */}
      {editMode && <Button
        onClick={deleteEvent}
        sx={{marginRight: 'auto', width: 150}} variant={'outlined'} color={'error'}>
        {t('dockEventDialog.delete')}
      </Button>}

      {/* Reject Button */}
      <Button
        onClick={rejectChanges}
        sx={{width: 150}} variant={'contained'} color={'primary'}>
        {t('dockEventDialog.reject')}
      </Button>
      {/* Save Button */}
      <LoadingButton
        disabled={!(vessel?.name && vessel?.mmsi)}
        loading={saving}
        onClick={saveEvent}
        sx={{width: 150}} variant={'contained'} color={'success'}>
        {t('dockEventDialog.save')}
      </LoadingButton>
      {saveError &&
      <Typography sx={{float: 'right', marginTop: 3, marginRight: 2}} color={'error'}>{saveError}</Typography>}
    </DialogActions>
  </Dialog>;
};
