import React from 'react';
import MapIcon from '@mui/icons-material/Map';
import BarChartIcon from '@mui/icons-material/StackedBarChart';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import {Air, Construction, Dashboard, Notifications, TrackChanges, Waves} from '@mui/icons-material';

export const MAPBOX_TOKEN = 'pk.eyJ1IjoicndvbGFrIiwiYSI6ImNrdzZjMzVpMzJoczcyb250cHF0Z3Q5YjMifQ.KGRuHHGwGWsfA8PF-qts2A';

export const MAPSTYLES = [
  {
    'label': 'Satellite',
    'thumbnail': 'https://openicgc.github.io/img/orto.png',
    'id': 'mapbox://styles/mapbox/satellite-streets-v11',
    'firstTopLayer': 'place-other'
  },
  {
    'label': 'Light',
    'thumbnail': 'https://openicgc.github.io/img/osm-bright.png',
    'id': 'mapbox://styles/mapbox/light-v10',
    'firstTopLayer': 'place-other'
  },
  // {
  //   'label': 'Positron',
  //   'thumbnail': 'https://openicgc.github.io/img/positron.png',
  //   'id': 'https://geoserveis.icgc.cat/contextmaps/positron.json',
  //   'firstTopLayer': 'place_other'
  // },
  {
    'label': 'Full Dark',
    'thumbnail': 'https://openicgc.github.io/img/fulldark.png',
    'id': 'mapbox://styles/mapbox/dark-v10',
    'firstTopLayer': 'place-other'
  }
];
export const DARK_MAPSTYLES = [
  'https://geoserveis.icgc.cat/contextmaps/hibrid.json',
  'https://geoserveis.icgc.cat/contextmaps/fulldark.json',
];

export const INITIAL_MAPSTYLE = MAPSTYLES[1].id;


export const VARIABLES = {
  'PM10': {
    id: 'PM10',
    units: 'ug/m3',
  },
  'PM25': {
    id: 'PM25',
    units: 'ug/m3',
  },
  'O2': {
    id: 'O2',
    units: 'mg/L',
  },
  'pH': {
    id: 'pH',
    units: '',
  },
  'conductivity': {
    id: 'conductivity',
    // units: 'mS',
    units: 'µS',
  },
  'ORP': {
    id: 'ORP',
    units: 'mV',
  },
  'temp': {
    id: 'temp',
    units: 'ºC',
  }
};

export const NAVIGATION_ITEMS = (modules, roles) => {
  const items = [{
    id: 'mapView',
    route: '../map',
    label: 'map',
    content: <MapIcon/>
  }, {
    id: 'measurementsView',
    route: modules.AIR ? '../measurements#air' : '../measurements#water',
    // route: '../measurements',
    label: 'measurements',
    content: <BarChartIcon/>
  }];

  modules.AGRO && items.push({
    id: 'dashboardsView',
    route: '../dashboards',
    label: 'dashboards',
    content: <Dashboard />
  });

  modules.GDANSKIE_WODY && items.push({
    id: 'openDataView',
    route: '../open-data#reports',
    label: 'openData',
    content: <Waves />
  });

  modules.RISK && items.push({
    id: 'riskView',
    route: '../risk',
    label: 'risk',
    content: <Air/>
  });
  modules.RISK_OIL && items.push({
    id: 'riskView',
    route: '../risk',
    label: 'riskWater',
    content: <Waves />
  });
  modules.SURVEYS && items.push({
    id: 'surveysView',
    route: '../surveys',
    label: 'droneSurveys',
    content: <TrackChanges/>
  });

  modules.PORT_MANAGEMENT && items.push({
    id: 'portManagement',
    route: '../port-mgmt',
    label: 'portManagement',
    content: <DirectionsBoatIcon/>
  });

  // roles.includes('admin') && items.push({
  //   id: 'diag',
  //   route: '../diag',
  //   label: 'diagnostics',
  //   content: <Construction />
  // });

  items.push({
    id: 'maintenance',
    route: '../maintenance',
    label: 'maintenance',
    content: <Construction />
  });

  items.push({
    id: 'notifications',
    route: '../notifications',
    label: 'notifications',
    content: <Notifications />
  });

  return items;
};
