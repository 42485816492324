// @mui material components
import Grid from '@mui/material/Grid';
// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {infoColor, primaryColor} from 'assets/jss/nextjs-material-dashboard';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SeaDataLogo from 'assets/images/logo/seadata-logo-black.png';
import EULogo from 'assets/images/logo/EU-flag.jpg';
import GalateaLogo from 'assets/images/logo/galatea-logo.png';
import MMLogo from 'assets/images/logo/mobileMonitoring-logo.png';
import {Tooltip} from '@mui/material';
import {isMobileMonitoring} from '../../../utils';


function Footer() {
  const isMM = isMobileMonitoring();
  return (
    <SuiBox component="footer" py={6}>
      <Grid container justifyContent="center">
        <Grid item xs={10} lg={8}>
          <SuiBox display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" mb={3}>
            <SuiBox mr={{xs: 2, lg: 3, xl: 6}} display={'flex'}>
              <Tooltip title={'Created by SeaData Gdynia'} placement={'top'}>
                <a href={'https://seadata.pl'}>
                  <img width={200} src={SeaDataLogo}/>
                </a>
              </Tooltip>
            </SuiBox>
            <SuiBox mr={{xs: 2, lg: 3, xl: 6}} display={'flex'}>
              <Tooltip title={'Mobile Monitoring - monitoring wody, boje pomiarowe'} placement={'top'}>
                <a href={'https://mobilemonitroing.pl'}>
                  <img width={200} src={MMLogo}/>
                </a>
              </Tooltip>
            </SuiBox>
            {/*<SuiBox mr={{xs: 0, lg: 3, xl: 6}} display={'flex'}>*/}
            {/*  <Tooltip title={'Beneficiary: Water Robotics (France)'} placement={'top'}>*/}
            {/*    <a href={'https://www.water-robotics.eu'}>*/}
            {/*      <img width={150} src={WRLogo}/>*/}
            {/*    </a>*/}
            {/*  </Tooltip>*/}
            {/*</SuiBox>*/}

            {/*<SuiBox mr={{xs: 2, lg: 3, xl: 6}} display={'flex'}>*/}
            {/*  <Tooltip title={'Partner: Baltic Sea & Space Cluster'} placement={'top'}>*/}
            {/*    <a href={'https://www.bssc.pl'}>*/}
            {/*      <img width={180} src={BSSCLogo}/>*/}
            {/*    </a>*/}
            {/*  </Tooltip>*/}
            {/*</SuiBox>*/}
            {!isMM && <SuiBox mr={{xs: 2, lg: 3, xl: 6}} display={'flex'}>
              <Tooltip title={'Strona Sfinansowane ze środków EU.'} placement={'top'}>
                <a href={'https://galateaproject.eu'}>
                  <img width={180} src={GalateaLogo}/>
                </a>
              </Tooltip>
            </SuiBox>}
            {!isMM && <SuiBox display={'flex'}>
                <Tooltip title={"Strona Sfinansowane ze środków EU."} placement={"top"}>
                  <a href={"https://galateaproject.eu"}>
                    <img width={80} src={EULogo} />
                  </a>
                </Tooltip>
            </SuiBox>}
          </SuiBox>
        </Grid>
        <Grid item xs={12} lg={8}>
          <SuiBox display="flex" justifyContent="center" mt={1} mb={3}>
            <SuiBox mr={3} color="secondary">
              <a style={{color: primaryColor[2]}} href={"https://www.facebook.com/seadatagdynia"}>
                <FacebookIcon fontSize="small"/>
              </a>
            </SuiBox>
            <SuiBox mr={3} color="secondary">
              <a style={{color: primaryColor[2]}} href={"https://www.youtube.com/watch?v=DgH7lZooP-s"}>
                <YouTubeIcon fontSize="small"/>
              </a>
            </SuiBox>
            {/*<SuiBox mr={3} color="secondary">*/}
            {/*  <a style={{color: primaryColor[2]}} href={"https://www.instagram.com/camdrone_dawid_walczyna/"}>*/}
            {/*    <InstagramIcon fontSize="small"/>*/}
            {/*  </a>*/}
            {/*</SuiBox>*/}
            <SuiBox color="secondary">
              <a style={{color: primaryColor[2]}} href={"https://www.linkedin.com/company/33297830"}>
                <LinkedInIcon fontSize="small"/>
              </a>
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={12} lg={8} className="text-center">
          <SuiTypography variant="body2" color="secondary">
            Copyright &copy; 2023 by <a style={{color: infoColor[0]}} href={"https://seadata.pl"}>SeaData.pl</a>
          </SuiTypography>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Footer;
