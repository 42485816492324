import {
  AppBar,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableContainer,
  TableFooter,
  TablePagination,
  Typography,
  Tabs, Tab, Button
} from '@mui/material';
import {AddAlert, CalendarMonth} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import React, {useEffect, useMemo, useState} from 'react';
import {dangerColor, grayColor, primaryColor, warningColor} from '../../assets/jss/nextjs-material-dashboard';
import {useTranslation} from 'react-i18next';
import TableSortLabel from '@mui/material/TableSortLabel';
import {visuallyHidden} from '@mui/utils';
import {getComparator, stableSort, TablePaginationActions, TCell, THeader} from '../Table/tableUtills';
import {getEpisodesList} from '../../api';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import DatePicker from 'react-datepicker';
import moment from 'moment';


export default function Episodes() {
  const {t, i18n} = useTranslation();
  const {clientId} = useConfig().state.config;
  const [loading, setLoading] = useState(false);
  const [episodes, setEpisodes] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('timestamp');
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const onDateChange = date => {
    setYear(date.getFullYear());
    // setMonth(date.getMonth() + 1);
    setSelectedDate(new Date(date));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setLoading(true);
    getEpisodesList(clientId, year)
      .then(result => {
        setEpisodes(result);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [clientId, year]);

  const rows = useMemo(() => episodes.map(({
    PM10,
    episode_type,
    serialNo,
    name,
    period,
    timestamp,
    threshold,
    substance,
    value,
    level,
    thresholdLow,
    percentage
  }) => ({
    date: period === "24h" ? new Date(timestamp).toLocaleDateString() : new Date(timestamp).toLocaleString() ,
    timestamp,
    stationName: name,
    serialNo,
    severity: t('episodes.severity', {returnObjects: true})[episode_type || level],
    level: episode_type || level,
    period,
    substance: t('measurements.parameters', {returnObjects: true})[substance || 'PM10'],
    value: Math.round(PM10 || value),
    threshold: thresholdLow ? (thresholdLow + ' - ' + threshold): threshold,
    // percentage: Math.round((PM10 / threshold) * 100) + ' %'
    percentage: (percentage - 100) + ' %'
  })), [episodes]);

  return <Card variant={'outlined'} sx={{
    minHeight: '100%',
    maxWidth: 'calc(100vw - 160px)',
    '@media screen and (max-width: 50em)': {maxWidth: '100vh'}
  }}>
      <CardHeader
        action={<Box pr={2}>
          <DatePicker
            customInput={
              <Button variant={'outlined'} endIcon={<CalendarMonth/>}>
                {moment(selectedDate).locale(i18n.resolvedLanguage).format('YYYY')}
              </Button>
            }
            locale={i18n.resolvedLanguage}
            showYearPicker
            onChange={onDateChange}
            selected={selectedDate}
            maxDate={new Date()}
            minDate={new Date(2017, 1)}
          />
        </Box>}
        title={<Button sx={{'@media screen and (max-width: 50em)': {display: 'none'}}}
                       size={'large'} startIcon={<AddAlert/>}
                       color={'error'}>{t('episodes.heading')}</Button>}
        subheader={<Typography
          sx={{marginLeft: 2, color: grayColor[3], '@media screen and (max-width: 50em)': {display: 'none'}}}
          variant={'caption'}>{t('episodes.subheading')}</Typography>}
      />
      {/*MOBILE CARD TITLE*/}
      <Typography sx={{
        color: primaryColor[2],
        textAlign: 'center',
        fontSize: '1.25rem',
        '@media screen and (min-width: 50em)': {display: 'none'}
      }}>{t('measurements.episodes')} </Typography>

      <CardContent>
        <Box display="flex">
          {loading ? <CircularProgress size={100} sx={{margin: 'auto', marginTop: 10}}/> :
            <TableContainer>
              <Table aria-label="Episodes table">
                <TableHead>
                  <TableRow sx={{backgroundColor: 'whitesmoke'}}>
                    <TableCell width={20}><THeader>#</THeader></TableCell>
                    {[{id: 'timestamp', label: 'date'},
                      {id: 'stationName', label: 'stationName'},
                      {id: 'severity', label: 'severityLabel'},
                      {id: 'period', label: 'period'},
                      {id: 'substance', label: 'substance'},
                      {id: 'value', label: 'value'},
                      {id: 'threshold', label: 'threshold'},
                      {id: 'percentage', label: 'limit'}
                    ].map(({id, label}) =>
                      <TableCell key={id} sortDirection={orderBy === id ? order : false}
                                 align="center">
                        <TableSortLabel
                          active={orderBy === id}
                          direction={orderBy === id ? order : 'asc'}
                          onClick={event => handleRequestSort(event, id)}
                        >
                          <Typography sx={{color: primaryColor[2]}}>
                            {t('episodes.' + label)}
                          </Typography>
                          {orderBy === id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>

                        {/*<THeader>{t('portCalls.' + cellId)}</THeader>*/}

                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                      ? stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : stableSort(rows, getComparator(order, orderBy))
                  ).map(({
                    timestamp,
                    date,
                    stationName,
                    serialNo,
                    severity,
                    level,
                    period,
                    substance,
                    value,
                    threshold,
                    percentage
                  }, idx) => (
                    <TableRow
                      key={idx}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                      <TableCell width={20} align={'center'}><TCell>{idx + 1}</TCell></TableCell>
                      <TableCell align="center"><TCell>{date}</TCell></TableCell>
                      <TableCell align="center"><TCell>
                        <a style={{color: primaryColor[2]}}
                           href={`/#/pl/map?serialNo=${serialNo}`}>{stationName}</a>
                      </TCell></TableCell>
                      <TableCell align="center"><EpiCell level={level}>{severity}</EpiCell></TableCell>
                      <TableCell align="center"><TCell>{t('episodes', {returnObjects: true})[period] || period}</TCell></TableCell>
                      <TableCell align="center"><TCell>{substance}</TCell></TableCell>
                      <TableCell align="center"><TCell>{value}</TCell></TableCell>
                      <TableCell align="center"><TCell>{threshold}</TCell></TableCell>
                      <TableCell align="center"><TCell>{percentage}</TCell></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={{border: 'none'}}
                      translate={'yes'}
                      rowsPerPageOptions={[5, 10, 25, 50, {label: t('general.all'), value: -1}]}
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage={t('general.rowsPerPage')}
                      labelDisplayedRows={({
                        from,
                        to,
                        count
                      }) => `${from}–${to} z ${count !== -1 ? count : `${t('general.moreThan')} ${to}`}`}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          }
        </Box>
      </CardContent>
    </Card>;
}

const colorByLevel = {
  ALLOWABLE: primaryColor[0],
  INFORM: warningColor[0],
  ALARM: dangerColor[0]
};

export const EpiCell = ({children, level}) => <Typography
  fontWeight={'bold'}
  color={colorByLevel[level] || grayColor[1]}>
  {children}
</Typography>;
