import React, {useMemo, useReducer} from 'react';
import PropTypes from 'prop-types';
import {GROUP_BY_TYPE} from '../../components/DataSelectionForm/DataSelectionForm';

const defaultConfig = (email) => ({
  substance: '',
  level: 'ALLOWABLE',
  thresholdLow: null,
  threshold: 0,
  period: '1h',
  stations: [],
  enabled: true,
  emailRecipients: [email],
  smsRecipients: []
});

const initialState = () => {
  return {
    configs: [],
    currentConfig: null
  };
};


const NotificationsContext = React.createContext(initialState());
// expose the context via a custom hook.
export const useNotificationsContext = () => React.useContext(NotificationsContext);

function reducer(state, action) {
  switch (action.type) {
  case 'set-configs':
    return {
      ...state,
      configs: action.configs
    };
  case 'new-config':
    return {
      ...state,
      currentConfig: defaultConfig(action.email)
    };
  case 'edit-config':
    return {
      ...state,
      currentConfig: {
        ...action.config,
        stations: action.devices.filter(d => action.config.stations.includes(d.serialNo)).map(d => ({
          ...d,
          group: GROUP_BY_TYPE[d.type]
        }))
      }
    };
  case 'set-currentConfig':
    return {
      ...state,
      currentConfig: action.config
    };
  case 'update-currentConfig':
    return {
      ...state,
      currentConfig: {...state.currentConfig, [action.name]: action.value}
    };

  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function NotificationsContextProvider({children}) {
  const [state, dispatch] = useReducer(reducer, initialState());
  const userContext = useMemo(() => {
    return {
      state,
      dispatch
    };
  }, [state]);

  return <NotificationsContext.Provider value={userContext}>
    {children}
  </NotificationsContext.Provider>;
}

NotificationsContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};
