import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Header from '../components/Header/Header';
import {useConfig} from '../context/clientConfig/clientConfigContext';
import {useTranslation} from 'react-i18next';
import {Typography} from '@mui/material';
import IharDashboard1 from 'assets/images/dashboards/Ihar-1.png';
import IharDashboard2 from 'assets/images/dashboards/Ihar-2.png';
import Button from '@mui/material/Button';

const Dashboards = () => {
  const {t} = useTranslation();
  const {config: {devices}, email} = useConfig().state;

  const [selected, setSelected] = useState(0);

  const leftMenuItems = [
    <Typography>Menu Item 1</Typography>,
    <Typography>Menu Item 2</Typography>,
    <Typography>Menu Item 3</Typography>,
  ];

  return <Box>
    <Header/>
    <Box display={'flex'}>
      {/*SIDEBAR*/}
      <Box pt={10} sx={{
        pl: 1,
        width: 200,
        height: 'calc(100vh - 88px)',
        '@media screen and (max-width: 50em)': {
          height: 'calc(100vh - 60px)', display: 'none'
        }
      }}>
        {leftMenuItems}
      </Box>

      {/* Dashbords */}
      <Box sx={{
        width: '100vw',
        height: 'calc(100vh - 88px)',
        '@media screen and (max-width: 50em)': {
          height: 'calc(100vh - 60px)'
        }
      }}>

        {selected === 0 && <Box p={1} height={'100%'} width={'1280px'} position={'relative'}
             // sx={{backgroundImage: `url(${IharDashboard1})`, backgroundRepeat: 'no-repeat'}}
        >
          <Box color={'whitesmoke'} position='absolute' right={115} top={88}>
            <Typography fontWeight={'bold'} fontSize={'x-large'} variant={'button'}>23 °C</Typography>
          </Box>

          <Box color={'whitesmoke'} position='absolute' right={118} top={141}>
            <Typography fontWeight={'bold'} fontSize={'x-large'} variant={'button'}>89 %</Typography>
          </Box>
          <Box color={'whitesmoke'} position='absolute' right={110} top={141+50} display={'flex'} alignItems={'flex-end'} gap={1} >
            <Typography fontWeight={'bold'} fontSize={'x-large'} variant={'button'}>2.5</Typography>
            <Typography fontWeight={'bold'}>mm</Typography>
          </Box>

          <Box color={'whitesmoke'} position='absolute' right={100} top={141+53 + 50}  display={'flex'} alignItems={'flex-end'} gap={1} >
            <Typography fontWeight={'bold'} fontSize={'x-large'} variant={'button'}>2.0</Typography>
            <Typography fontWeight={'bold'}>km/h</Typography>
          </Box>

          <Box color={'whitesmoke'} position='absolute' right={80} top={141+53 + 50+ 50}  display={'flex'} alignItems={'flex-end'} gap={1} >
            <Typography fontWeight={'bold'} fontSize={'x-large'} variant={'button'}>40.0</Typography>
            <Typography fontWeight={'bold'}>W/m2</Typography>
          </Box>


          <Box color={'whitesmoke'} position='absolute' right={115} top={141+53 + 50+ 50 + 50} >
            <Typography fontWeight={'bold'} fontSize={'x-large'} variant={'button'}>28 °C</Typography>
          </Box>

          <Box width={170} position='absolute' right={35} top={141+53 + 50+ 50 + 50 + 95} >
            <Button fullWidth color={'success'} variant={'contained'}>
            <Typography  fontWeight={'bold'} fontSize={'large'} >0 UVI</Typography>
            </Button>
          </Box>

          <img src={IharDashboard1} />
        </Box>}

        {selected === 1 && <Box p={1} height={'100%'} width={'1280px'} position={'relative'}>
          <Box color={'whitesmoke'}
               position='absolute'
               right={115}
               top={88}
          >
            <Typography fontWeight={'bold'} fontSize={'x-large'} variant={'button'}>23 °C</Typography>
          </Box>
          <img src={IharDashboard2} />
        </Box>}

      </Box>
    </Box>

  </Box>;
};

export default Dashboards;
