import React, {useMemo, useReducer} from 'react';
import PropTypes from 'prop-types';
import {calculateRiskWarnings} from './riskCalculation';

const initialState = () => {
  return {
    riskLayers: [],
    riskAreas: [],
    riskSliderValue: 0,
    riskCalculation: {}
  };
};


const RiskContext = React.createContext(initialState());
// expose the context via a custom hook.
export const useRiskContext = () => React.useContext(RiskContext);

function reducer(state, action) {
  switch (action.type) {
  case 'toggle-riskLayer':
    return {
      ...state,
      riskLayers: state.riskLayers.length === 0 ? [action.layerId] : (state.riskLayers.includes(action.layerId) ? [] : [action.layerId])
    };
  case 'set-risk-areas':
    return {
      ...state,
      riskAreas: action.riskAreas,
      riskCalculation: calculateRiskWarnings(action.riskAreas, state.riskSliderValue, action.clientId),
    };
  case 'set-riskSliderValue':
    return {
      ...state,
      riskSliderValue: action.riskSliderValue,
      riskCalculation: calculateRiskWarnings(state.riskAreas, action.riskSliderValue, action.clientId)
    };

  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function RiskContextProvider({children}) {
  const [state, dispatch] = useReducer(reducer, initialState());
  const userContext = useMemo(() => {
    return {
      state,
      dispatch
    };
  }, [state]);

  return <RiskContext.Provider value={userContext}>
    {children}
  </RiskContext.Provider>;
}

RiskContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};
